// 提交獨立表單 - Webhook (Form)
export const EVENT_ID_INDEPENDENCE_FORM_SUBMIT = 1;
// 使用者登入 - Webhook (Promo)
export const EVENT_ID_USER_LOGIN = 2;
// 社群帳號綁定 - Webhook (Promo)
export const EVENT_ID_SOCIAL_BIND = 3;
// 參與活動 - Webhook (Promo)
export const EVENT_ID_PROMOTION_JOIN = 4;
// 提交報名表單 - Webhook (Promo)
export const EVENT_ID_FORM_SUBMIT_SIGN_UP = 5;
// 提交領獎表單 - Webhook (Promo)
export const EVENT_ID_FORM_SUBMIT_AWARDEE = 6;
// 配獎 - Webhook (Promo)
export const EVENT_ID_PRIZE_AWARD = 7;

// 提交獨立表單 - Facebook 轉換追蹤 (Form)
export const EVENT_ID_FB_INDEPENDENCE_FORM_SUBMIT = 8;
// 進入活動參加 - Facebook 轉換追蹤 (Promo)
export const EVENT_ID_FB_PROMOTION_JOIN = 9;
// 提交活動報名表單 - Facebook 轉換追蹤 (Promo)
export const EVENT_ID_FB_FORM_SUBMIT_SIGN_UP = 10;
// 審核通過事件 - Webhook (Promo)
export const EVENT_ID_PROMOTION_VERIFY = 11;

// 提交報名表單 - 愛酷 Webhook (Promo)
export const EVENT_ID_ACCU_FORM_SUBMIT_SIGN_UP = 12;
// 提交領獎表單 - 愛酷 Webhook (Promo)
export const EVENT_ID_ACCU_FORM_SUBMIT_AWARDEE = 13;

// 發票審核通知 - 威許 Webhook (Promo)
export const EVENT_ID_WISH_MECHANISM_VERIFY = 14;

// 貼標事件 - - Webhook (Tag)
export const EVENT_ID_INDEPENDENCE_TAG_SUBMIT = 15;

// 貼標事件 - - MAAC webhook (Tag)
export const EVENT_ID_MAAC_TAG_SUBMIT = 16;

// 貼標事件 - - Omnichat webhook (Tag)
export const EVENT_ID_OMNICHAT_TAG_SUBMIT = 17;

// 貼標事件 - - BotBonnie webhook (Tag)
export const EVENT_ID_BOT_BONNIE_TAG_SUBMIT = 18;
