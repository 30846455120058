import querystring from 'query-string';

import { feverApiFetchOpts, fetchOpts, fetchWith, methods } from 'api/commom';
import { formApiHeaderFeverHost } from 'config/envVariable';
import { getFirebaseIdToken } from 'features/login/utils/firebaseManager';
import { FEVER_API_ENDPOINT } from 'config/envVariable';
import * as formTypes from 'config/formTypes';

const formFetch = fetchWith(FEVER_API_ENDPOINT);

export function getFormVersion(formId) {
    return formFetch(`/forms/${formId}/version`, feverApiFetchOpts())
        .then(res => res.json())
        .then(({ items: { version } }) => version);
}

export function getFormsListApi(sponsorId, { page, perPage, search, additionalQs }) {
    const qs = querystring.stringify({ page, search, perPage: perPage || undefined });
    const composedQs = [qs, additionalQs].filter(Boolean).join('&');

    return formFetch(
        `/sponsors/${sponsorId}/forms${composedQs ? `?${composedQs}` : composedQs}`,
        feverApiFetchOpts()
    );
}

export function createFormApi(sponsorId, body = {}) {
    return formFetch(
        `/sponsors/${sponsorId}/forms`,
        feverApiFetchOpts({
            method: methods.POST,
            body: { templateType: formTypes.FORM_BLANK, ...body }
        })
    );
}

export function updateFormApi(sponsorId, formId, body) {
    return formFetch(
        `/sponsors/${sponsorId}/forms/${formId}`,
        feverApiFetchOpts({ method: methods.POST, body })
    );
}

export function deleteFormApi(sponsorId, formId) {
    return formFetch(
        `/sponsors/${sponsorId}/forms/${formId}`,
        feverApiFetchOpts({ method: methods.DELETE, headers: { sponsorId } })
    );
}

export function getFormRepliesApi(sponsorId, formId, page, decrypt = undefined) {
    const qs = querystring.stringify({ page, decrypt });
    return formFetch(`/sponsors/${sponsorId}/forms/${formId}/answers?${qs}`, feverApiFetchOpts());
}

export async function downloadFormReportApi(sponsorId, formId) {
    const firebaseToken = await getFirebaseIdToken();
    return formFetch(
        `/form/${formId}/export`,
        fetchOpts('GET', null, {
            headers: {
                sponsorId,
                'fever-host': formApiHeaderFeverHost,
                'Content-Type': 'text/csv',
                Accept: 'text/csv',
                Authorization: `Bearer ${firebaseToken}`
            }
        })
    );
}

export function downloadNewFormReportApi(sponsorId, formId) {
    return formFetch(
        `/sponsors/${sponsorId}/forms/${formId}/export`,
        feverApiFetchOpts({
            headers: {
                'Content-Type': 'text/csv',
                Accept: 'text/csv'
            }
        })
    );
}

export function downloadPromoFormReportApi(sponsorId, formId) {
    return formFetch(
        `/sponsors/${sponsorId}/forms/${formId}/export`,
        feverApiFetchOpts({
            headers: {
                'Content-Type': 'text/csv',
                Accept: 'text/csv'
            }
        })
    );
}

export function downloadUserFileApi(sponsorId, formId, fileName) {
    return formFetch(
        `/sponsors/${sponsorId}/upload/forms/${formId}/${fileName}`,
        feverApiFetchOpts({
            headers: {
                'Content-Type': '',
                Accept: ''
            }
        })
    );
}

export function getUserS3FileUrl(code) {
    return formFetch(`/uploads/preview?code=${code}`);
}
