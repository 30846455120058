import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { sendSignInLinkToEmail, fetchSignInMethodsForEmail } from 'firebase/auth';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { system700, system900 } from 'features/common/Mui/styles/color';
import { queryStrKeys, verifyEmailTypes } from 'features/login/utils/config';
import { auth, firebaseProviderIds } from 'features/login/utils/firebaseManager';
import { getQueryString } from 'features/login/utils/manipulateUrl';
import {
    errMsgWrapperStyle,
    textFieldDefaultStyle,
    mainButtonSolidStyle,
    mainButtonHollowStyle
} from 'features/login/styles/comman';
import { generateEmailValidateRegexp } from 'features/login/utils/validate';

import Button from 'features/common/Mui/Button';
import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';
import ConfirmVerifyEmailSended from 'features/login/components/pageEmailVerify/ConfirmVerifyEmailSended';

export default function EmailVerifyForgetPassword() {
    const { t } = useTranslation();
    const [email, setEmail] = useState('');
    const [isVerifyEmailSend, setIsVerifyEmailSend] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    function handleEmailChange({ target: { value } }) {
        setErrMsg('');
        setEmail(value);
    }
    function validateInput() {
        let valid = true;
        if (!generateEmailValidateRegexp().test(email)) {
            valid = false;
            setErrMsg(i18n.t('label.invalid_email'));
        }
        return valid;
    }
    async function handleSendVerifyEmail() {
        try {
            if (validateInput()) {
                setIsSendingEmail(true);
                const signInMethods = await fetchSignInMethodsForEmail(auth, email);
                const noFirebaseAccount = signInMethods.length === 0;
                const noPasswordSignInProvider = !signInMethods.includes(
                    firebaseProviderIds.PASSWORD
                );

                if (noFirebaseAccount) {
                    return setErrMsg(i18n.t('label.firebase_email_not_exist'));
                } else if (noPasswordSignInProvider) {
                    return setErrMsg(i18n.t('label.firebase.account.no_password_provider'));
                } else {
                    await sendSignInLinkToEmail(auth, email, {
                        handleCodeInApp: true,
                        url: `${window.location.origin}/app/email_verify_landing${getQueryString({
                            add: {
                                [queryStrKeys.VERIFY_EMAIL_TYPE]: verifyEmailTypes.FORGET_PASSWORD,
                                [queryStrKeys.VERIFY_EMAIL]: email
                            }
                        })}`
                    });
                    setIsVerifyEmailSend(true);
                }
            }
        } catch (error) {
            console.error('FB email verify', error);
        } finally {
            setIsSendingEmail(false);
        }
    }

    return (
        <div css={containerStyle}>
            {isVerifyEmailSend ? (
                <ConfirmVerifyEmailSended email={email} />
            ) : (
                <>
                    <Typography variant="h5" css={titleStyle}>
                        {t('label.forget_password')}
                    </Typography>
                    <Typography variant="subtitle1" css={descStyle}>
                        {t('desc.forget_password')}
                    </Typography>
                    <TextField
                        variant="filled"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        placeholder={t('placeholder.input_email')}
                        css={[textFieldDefaultStyle, inputStyle]}
                        error={Boolean(errMsg)}
                    />
                    <div css={errMsgWrapperStyle}>
                        {Boolean(errMsg) && <Typography variant="caption">{errMsg}</Typography>}
                    </div>
                    <div css={btnWrapperStyle}>
                        <ButtonWithLoadingEffect
                            isLoading={isSendingEmail}
                            css={[mainButtonSolidStyle, btnStyle]}
                            onClick={handleSendVerifyEmail}
                            disabled={!email}
                        >
                            <Typography variant="subtitle2">
                                {t('btn.send_reset_pass_email')}
                            </Typography>
                        </ButtonWithLoadingEffect>
                        <Link
                            css={btnStyle}
                            to={historyObj => ({ ...historyObj, pathname: '/app/login' })}
                        >
                            <Button
                                variant="outlined"
                                css={[mainButtonHollowStyle, css(`margin-top: 24px;`)]}
                            >
                                <Typography variant="subtitle2">
                                    {t('btn.back_to_login')}
                                </Typography>
                            </Button>
                        </Link>
                    </div>
                </>
            )}
        </div>
    );
}

const containerStyle = css`
    max-width: 360px;
    > * {
        letter-spacing: 0.5px;
    }
`;

const titleStyle = css`
    text-align: center;
    color: ${system900.color};
`;

const descStyle = css`
    margin-top: 24px;
    font-weight: 400;
    color: ${system700};
`;

const inputStyle = css`
    margin-top: 24px;
`;

const btnWrapperStyle = css`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const btnStyle = css`
    width: 60%;
`;
