import { getUserSponsors } from 'api/sponsor';

const UPDATES_SPONSORS_LIST = 'account/UPDATES_SPONSORS_LIST';

export default function reducer(state = [], { type, payload }) {
    switch (type) {
        case UPDATES_SPONSORS_LIST:
            return payload;
        default:
            return state;
    }
}

export function initSponsorList(data) {
    return {
        type: UPDATES_SPONSORS_LIST,
        payload: data
    };
}

export function updateSponsorList() {
    return async (dispatch, getState) => {
        const { sponsor } = getState();
        const sponsors = await getUserSponsors();
        dispatch({
            type: UPDATES_SPONSORS_LIST,
            payload: appendSponsorIfNotInList(sponsors, sponsor)
        });
    };
}

export function appendSponsorIfNotInList(sponsorList = [], sponsor = {}) {
    const { id, uuid, uniqueName, name } = sponsor;
    const notInList = sponsorList.find(s => s.id === id) === undefined;
    return notInList ? [{ id, uuid, uniqueName, name }, ...sponsorList] : sponsorList;
}
