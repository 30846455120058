import React from 'react';
import PropTypes from 'prop-types';
import { createTheme, StylesProvider } from '@material-ui/core/styles';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';

import { functional, form700, error, promo700, promo500 } from 'features/common/Mui/styles/color';

export const promoTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            light: promo500.color,
            main: functional.color,
            dark: promo700.color,
            contrastText: '#fff'
        },
        secondary: { main: form700.color }
    },
    typography: {
        fontFamily: [
            'Noto Sans TC',
            'Noto Sans CJK TC',
            'Roboto',
            'PingFang TC',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(',')
    }
});

export const formTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: functional.color,
            contrastText: '#fff'
        },
        secondary: { main: error.color, contrastText: '#fff' }
    },
    typography: {
        fontFamily: [
            'Noto Sans TC',
            'Noto Sans CJK TC',
            'Roboto',
            'PingFang TC',
            'Helvetica',
            'Arial',
            'sans-serif'
        ].join(',')
    }
});

function ThemeProvider({ children, theme }) {
    return (
        <MuiThemeProvider theme={theme}>
            <StylesProvider injectFirst>{children}</StylesProvider>
        </MuiThemeProvider>
    );
}

ThemeProvider.propTypes = {
    children: PropTypes.node,
    theme: PropTypes.object
};

export default ThemeProvider;
