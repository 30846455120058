import {
    validateCampaignMessage,
    setValidationMessageErrors,
    clearValidationMessageErrors
} from 'store/module/message';
import {
    SCHEDULE_DISPLAY,
    MESSAGE_DISPLAY_POSITION,
    TRIGGER_TIMING,
    DISPLAY_FREQUENCY
} from 'features/campaigns/CampaignOnsite/const';
import campaignFailMsg from 'features/campaigns/config/error';
import {
    updateReduxObjKeyHandleDelete,
    deleteReduxObjKey,
    mergeAndRemoveKey,
    transformApiResponse
} from 'utils/objectManipulate';
import {
    getCampaignApi,
    updateCampaignApi,
    updateCampaignStatusApi,
    updateCampaignMessageApi
} from 'api/campaign';
import { CAMPAIGN_STATUS } from 'config/campaignType';
import { filterUndefinedOrNull } from 'utils/filter';
import { setFailed } from './notify';

export const REPLACE_CAMPAIGN = 'REPLACE_CAMPAIGN';
export const REPLACE_SETTING_CAMPAIGN = 'REPLACE_SETTING_CAMPAIGN';
export const UPDATE_CAMPAIGN = 'UPDATE_CAMPAIGN';
export const UPDATE_SETTING_CAMPAIGN = 'UPDATE_SETTING_CAMPAIGN';
export const DELETE_CAMPAIGN_ONSITE_SPEC_KEY = 'DELETE_CAMPAIGN_ONSITE_SPEC_KEY';
export const SET_CAMPAIGN_STATUS = 'SET_CAMPAIGN_STATUS';
export const SET_CAMPAIGN_EDITING = 'SET_CAMPAIGN_EDITING';
export const SET_CAMPAIGN_SAVING = 'SET_CAMPAIGN_SAVING';
export const SET_VALIDATION_ERRORS = 'CAMPAIGN/SET_VALIDATION_ERRORS';
export const CLEAR_VALIDATION_ERRORS = 'CAMPAIGN/CLEAR_VALIDATION_ERRORS';
export const CLEAR_SPECIFIC_VALIDATION_ERROR = 'CAMPAIGN/CLEAR_SPECIFIC_VALIDATION_ERROR';
export const START_PUBLISHED_EDITING = 'START_PUBLISHED_EDITING';
export const CANCEL_PUBLISHED_EDITING = 'CANCEL_PUBLISHED_EDITING';

export const defaultState = {
    id: 0,
    // Store previous state for cancellation
    previousData: null,
    status: CAMPAIGN_STATUS.UNPUBLISHED,
    isEditing: false,
    isSaving: false,
    validationErrors: null,
    previewUrl: ''
};

const reducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case REPLACE_CAMPAIGN:
            return action.payload;
        case REPLACE_SETTING_CAMPAIGN:
            return {
                ...state,
                settingCampaign: {
                    ...state.settingCampaign,
                    ...action.payload
                },
                isEditing: true
            };
        case UPDATE_CAMPAIGN: {
            return {
                ...updateReduxObjKeyHandleDelete(state, action.payload, action.updateAction),
                isEditing: true
            };
        }
        case UPDATE_SETTING_CAMPAIGN: {
            return {
                ...state,
                settingCampaign: {
                    ...state.settingCampaign,
                    ...updateReduxObjKeyHandleDelete(
                        state.settingCampaign,
                        action.payload,
                        action.updateAction
                    )
                },
                isEditing: true
            };
        }
        case DELETE_CAMPAIGN_ONSITE_SPEC_KEY: {
            return deleteReduxObjKey(state, action.payload);
        }
        case SET_CAMPAIGN_STATUS: {
            return {
                ...state,
                status: action.payload
            };
        }
        case SET_CAMPAIGN_SAVING: {
            return {
                ...state,
                isSaving: action.payload
            };
        }
        case SET_CAMPAIGN_EDITING: {
            return {
                ...state,
                isEditing: action.payload
            };
        }
        case SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.payload
            };
        case START_PUBLISHED_EDITING:
            return {
                ...state,
                previousData: structuredClone({
                    settingCampaign: state.settingCampaign
                }),
                isEditing: true
            };
        case CANCEL_PUBLISHED_EDITING:
            return {
                ...state,
                previousData: null,
                settingCampaign: state.previousData?.settingCampaign || state.settingCampaign,
                isEditing: false,
                validationErrors: null
            };
        case CLEAR_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: null
            };
        case CLEAR_SPECIFIC_VALIDATION_ERROR: {
            if (!state.validationErrors) {
                return state;
            }

            // Create new validation errors object without the specified key
            const { [action.payload]: removedError, ...remainingErrors } = state.validationErrors;

            // If there are no remaining errors, set to null instead of empty object
            const newValidationErrors =
                Object.keys(remainingErrors).length === 0 ? null : remainingErrors;

            return {
                ...state,
                validationErrors: newValidationErrors
            };
        }
        default:
            return state;
    }
};

export const setStatus = status => ({
    type: SET_CAMPAIGN_STATUS,
    payload: status
});

export const setEditing = isEditing => ({
    type: SET_CAMPAIGN_EDITING,
    payload: isEditing
});

export const setSaving = isSaving => ({
    type: SET_CAMPAIGN_SAVING,
    payload: isSaving
});

export const startEditing = () => ({
    type: START_PUBLISHED_EDITING
});

export const cancelEditing = () => ({
    type: CANCEL_PUBLISHED_EDITING
});

export const setValidationErrors = errors => ({
    type: SET_VALIDATION_ERRORS,
    payload: errors
});

export const clearValidationErrors = () => ({
    type: CLEAR_VALIDATION_ERRORS
});

export const clearSpecificValidationError = key => ({
    type: CLEAR_SPECIFIC_VALIDATION_ERROR,
    payload: key
});

export function saveCampaign({ hasValidation = false }) {
    return async (dispatch, getState) => {
        const {
            campaignOnsite: { id: campaignId, name, settingCampaign },
            sponsor: { id: sponsorId },
            message: {
                id: messageId,
                type,
                content,
                promotionUuid,
                settingCampaignMessage,
                settingCampaignStyle
            }
        } = getState();

        const { messageTaskUuid, messagePromoUuid, ...settings } = settingCampaign;
        const campaignSettings = {
            Campaigns: settings
        };

        const messageSettings = {
            Campaigns: settingCampaignMessage,
            Styles: settingCampaignStyle
        };

        if (messageTaskUuid) {
            campaignSettings.Tasks = {
                uuid: messageTaskUuid
            };
        }

        if (messagePromoUuid) {
            campaignSettings.Promotions = {
                uuid: messagePromoUuid
            };
        }

        if (hasValidation) {
            dispatch(clearValidationErrors());
            dispatch(clearValidationMessageErrors());

            const campaignErrors = validateCampaignSettings(settingCampaign);
            const messageErrors = validateCampaignMessage({
                ...settingCampaignMessage,
                ...settingCampaignStyle,
                promotionUuid,
                messageType: type
            });

            const allErrors = { ...campaignErrors, ...messageErrors };

            if (Object.keys(campaignErrors).length > 0) {
                dispatch(setValidationErrors(campaignErrors));
            }

            if (Object.keys(messageErrors).length > 0) {
                dispatch(setValidationMessageErrors(messageErrors));
            }

            if (Object.keys(allErrors).length > 0) {
                return {
                    success: false,
                    campaignErrors,
                    messageErrors
                };
            }
        }

        try {
            dispatch(setSaving(true));
            await updateCampaignApi(
                sponsorId,
                campaignId,
                filterUndefinedOrNull({
                    name,
                    settings: campaignSettings
                })
            );
            await updateCampaignMessageApi(
                sponsorId,
                campaignId,
                messageId,
                filterUndefinedOrNull({
                    type,
                    content,
                    promotionUuid,
                    settings: messageSettings
                })
            );
            dispatch(setEditing(false));
            dispatch(setSaving(false));
            return { success: true };
        } catch (error) {
            console.error(error);
            dispatch(setSaving(false));
            return {
                success: false,
                error: error.message
            };
        }
    };
}

export function publishCampaign() {
    return async (dispatch, getState) => {
        const {
            campaignOnsite: { id: campaignId },
            sponsor: { id: sponsorId }
        } = getState();

        try {
            dispatch(setSaving(true));
            await updateCampaignStatusApi(sponsorId, campaignId, CAMPAIGN_STATUS.PUBLISHED);
            dispatch(setStatus(CAMPAIGN_STATUS.PUBLISHED));
            dispatch(setSaving(false));
            return { success: true };
        } catch (error) {
            dispatch(setSaving(false));
            console.error(error.message);
            const errCode = error?.payload?.message || error?.message;
            dispatch(setFailed(campaignFailMsg[errCode] || 'campaign.publish_failed'));
            return {
                success: false,
                error: errCode
            };
        }
    };
}

export function pauseCampaign() {
    return async (dispatch, getState) => {
        const {
            campaignOnsite: { id: campaignId },
            sponsor: { id: sponsorId }
        } = getState();
        try {
            dispatch(setSaving(true));
            await updateCampaignStatusApi(sponsorId, campaignId, CAMPAIGN_STATUS.PAUSED);
            dispatch(setStatus(CAMPAIGN_STATUS.PAUSED));
            dispatch(setSaving(false));
        } catch (error) {
            dispatch(setSaving(false));
            console.error('Failed to publish:', error);
        }
    };
}

export function updateCampaign(payload, updateAction) {
    return {
        type: UPDATE_CAMPAIGN,
        payload,
        updateAction
    };
}

export function saveAndUpdateCampaign(payload) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId },
            campaignOnsite: { id: campaignId }
        } = getState();

        await updateCampaignApi(sponsorId, campaignId, payload);

        dispatch({
            type: UPDATE_CAMPAIGN,
            payload: payload
        });
    };
}

export function updateSettingCampaign(payload, updateAction) {
    return {
        type: UPDATE_SETTING_CAMPAIGN,
        payload,
        updateAction
    };
}

export function saveAndUpdateSettingCampaign(payload) {
    return async (dispatch, getState) => {
        const {
            campaignOnsite: { id: campaignId, settingCampaign },
            sponsor: { id: sponsorId }
        } = getState();

        const settings = {
            Campaigns: payload
        };
        await updateCampaignApi(sponsorId, campaignId, { settings });

        dispatch({
            type: REPLACE_SETTING_CAMPAIGN,
            payload: mergeAndRemoveKey(settingCampaign, payload)
        });
    };
}

export function getCampaignData(campaignId) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId }
        } = getState();

        try {
            const { settings, ...campaign } = await getCampaignApi(sponsorId, campaignId);

            const payload = transformApiResponse(
                filterUndefinedOrNull({
                    ...campaign,
                    settingCampaign: {
                        ...settings.Campaigns,
                        messageTaskUuid: settings?.Tasks?.uuid,
                        messagePromoUuid: settings?.Promotions?.uuid
                    },
                    settingCampaignStyle: {
                        messageTemplateId: settings?.Styles?.messageTemplateId
                    }
                }),
                {
                    prefixes: ['enable', 'is', 'status'],
                    suffixes: ['Type', 'Seconds', 'Id'],
                    Constructor: Number
                }
            );

            dispatch({
                type: REPLACE_CAMPAIGN,
                payload
            });
            return payload;
        } catch (error) {
            console.error(error.message);
            const errCode = error?.payload?.message || error?.message;
            dispatch(setFailed(campaignFailMsg[errCode] || 'campaign.fetch_fail'));
        }
    };
}

/**
 * @param {Array} payload - [wantToDeletKeyA, wantToDeletKeyB] 把要刪除的 key name 丟進陣列
 */
export function deleteCampaignOnsiteSpecKey(payload) {
    return {
        type: DELETE_CAMPAIGN_ONSITE_SPEC_KEY,
        payload
    };
}

export const validateCampaignSettings = settingCampaign => {
    const errors = {};

    // 排程顯示驗證
    if (settingCampaign.enableMessageDateTimeLimit === SCHEDULE_DISPLAY.ENABLE) {
        const startDate = new Date(settingCampaign.startDate);
        const endDate = new Date(settingCampaign.endDate);

        if (!startDate || !endDate) {
            errors.dateRange = 'label.errors.effective_data_range';
        } else if (endDate <= startDate) {
            errors.dateRange = 'label.errors.end_times_must_later_than_start_time';
        }
    }

    // 驗證顯示頻率
    if (
        ![
            DISPLAY_FREQUENCY.EVERY_VISIT,
            DISPLAY_FREQUENCY.ONCE_PER_DAY,
            DISPLAY_FREQUENCY.SHOW_ONCE
        ].includes(settingCampaign.displayFrequencyType)
    ) {
        errors.displayFrequencyType = 'label.errors.display_frequency_type';
    }

    // 驗證觸發時機
    if (settingCampaign.triggerTimingType === TRIGGER_TIMING.WAIT_ON_PAGE) {
        if (!settingCampaign.triggerTimingSeconds || settingCampaign.triggerTimingSeconds <= 0) {
            errors.triggerTimingSeconds = 'label.errors.trigger_timing_seconds';
        }
    }

    // 驗證全部頁面
    if (
        settingCampaign.messageTriggeredPageType === MESSAGE_DISPLAY_POSITION.MULTI_TASK_PROMO_PAGE
    ) {
        if (!settingCampaign.messagePromoUuid) {
            errors.messagePromoUuid = 'label.errors.missing_select';
        }
        if (!settingCampaign.messageTaskUuid) {
            errors.messageTaskUuid = 'label.errors.missing_select';
        }
    }

    // 驗證部分頁面
    if (settingCampaign.messageTriggeredPageType === MESSAGE_DISPLAY_POSITION.SPECIFIC_PAGE) {
        if (
            settingCampaign?.messagePageExclude !== undefined &&
            settingCampaign.messagePageExclude.length <= 0
        ) {
            errors.messagePageExclude = 'label.errors.missing_keywords';
        }
        if (!settingCampaign?.messagePageInclude) {
            errors.messagePageInclude = 'label.errors.missing_keywords';
        }
    }

    // 驗證全部頁面
    if (settingCampaign.messageTriggeredPageType === MESSAGE_DISPLAY_POSITION.ALL_PAGE) {
        if (
            settingCampaign?.messagePageExclude !== undefined &&
            settingCampaign.messagePageExclude.length <= 0
        ) {
            errors.messagePageExclude = 'label.errors.missing_keywords';
        }
    }

    return errors;
};

export default reducer;
