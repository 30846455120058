import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import queryString from 'query-string';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import Typography from '@material-ui/core/Typography';
import { FormattedMessage } from 'react-intl';

import { system500 } from 'features/common/Mui/styles/color';
import { getUserS3FileUrl } from 'api/forms';

export default function ImageReader() {
    const { code } = queryString.parse(window.location.search) || {};
    const [hasErr, setError] = useState(false);

    useEffect(() => {
        async function downloadFile(code) {
            try {
                const resp = await getUserS3FileUrl(code);
                if (resp.status !== 200) {
                    console.log('File download failed');
                    setError(true);
                    return;
                }
                const {
                    items: { url }
                } = await resp.json();
                if (!url) {
                    throw new Error();
                }
                window.location.replace(url);
            } catch (error) {
                console.log(error);
                setError(true);
            }
        }

        downloadFile(code);
    }, [code]);

    return (
        <div css={style}>
            <div>
                <InsertDriveFileIcon />
                <Typography variant="h6">
                    <FormattedMessage
                        id={hasErr ? 'label.file_not_found' : 'label.file_fetching'}
                    />
                </Typography>
            </div>
        </div>
    );
}

const style = css`
    color: ${system500.color};
    height: 100%;
    text-align: center;
    > * {
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        > svg {
            font-size: 100px;
        }
    }
`;
