export const IS_LOCAL_DEV = process.env.NODE_ENV === 'development';
export const BUILD_ID = process.env.REACT_APP_BUILD_ID || '';
export const APP_RUNNING_ENV = process.env.REACT_APP_RUNNING_ENV || 'prod';
export const FB_APP_ID = process.env.REACT_APP_FB_APP_ID || '232661746905427';

// env variable 在 prod 注入不進來先 hardcoded
export const IS_LIVE_PROD = APP_RUNNING_ENV === 'prod' && !IS_LOCAL_DEV;
export const IS_DEV = APP_RUNNING_ENV === 'dev';
export const GLOBAL_LIFF_ID = IS_LIVE_PROD ? '1654689974-YnaByKaZ' : '1608309930-D00eq7P7';
export const ADMIN_LOGIN_LIFF_ID = IS_LIVE_PROD ? '2001102041-OR4azX96' : '2001102041-WN43XZb7';
export const OLD_SPONSOR_DISABLE_DATE =
    process.env.REACT_APP_OLD_SPONSOR_DISABLE_DATE || '2022-03-02 04:30:00';
export const FEVER_HOST = process.env.REACT_APP_LOCAL_FEVER_HOST || window.location.host;
export const FROALA_KEY =
    process.env.REACT_APP_FROALA_V2_KEY_GENERAL || 'ZF3G4I3A7aD6C4C3F2G3D2J2C9C3D5tooB-8iJ-7A-21d1sxsC-9B1vyz==';
export const FROALA_V4_KEY =
    process.env.REACT_APP_FROALA_V4_KEY_GENERAL || 'kRB4zD5B2C1G3C1E1qYFa1UQRFQIVc2MSMd1IWPNb1IFd1yD2I2D1B2C7D2D1C5G1A1==';
export const SENTRY_REACT_DSN =
    process.env.REACT_APP_SENTRY_REACT_DSN || 'https://105f84dc11397f162f06b772979a7324@o512271.ingest.us.sentry.io/4506907271954432';
export const SENTRY_TRACES_SAMPLE_RATE =
    process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE ||
    '0.00001';
export const INTERCOM_SECRET_KEY =
    process.env.REACT_APP_INTERCOM_SECRET_KEY || 'xdsqVb3gLQtypbClG8EZFFSh4Mx_PRTcHLTQT073';
export const MOCK_API_ENDPOINT = 'http://localhost:3002';

export const {
    FEVER_API_ENDPOINT,
    ZINES_API_ENDPOINT,
    LAMBDA_UA_REPORT_ENDPOINT,
    GA4_LAMBDA_ENDPOINT,
    IMG_PROXY_PATH,
    THIRD_PARTY_AUTH_ENDPOINT,
    SDK_ENV_SUFFIX_BY_ENV,
    LINE_CLIENT_ID
} = (runningEnv => {
    switch (runningEnv) {
        case 'prod':
            return {
                FEVER_API_ENDPOINT: 'https://api.feversocial.com',
                ZINES_API_ENDPOINT: 'https://api.feversocial.com/zines',
                LAMBDA_UA_REPORT_ENDPOINT:
                    'https://dee21ea9va.execute-api.ap-southeast-1.amazonaws.com/prod/getGA',
                GA4_LAMBDA_ENDPOINT: 'https://py0vyqqzw6.execute-api.ap-southeast-1.amazonaws.com',
                IMG_PROXY_PATH: `https://image.fevercdn.com`,
                THIRD_PARTY_AUTH_ENDPOINT: 'https://feversocial.com',
                SDK_ENV_SUFFIX_BY_ENV: '',
                LINE_CLIENT_ID: '6tfp4KZ1pFgVHLTscxFBBI'
            };
        case 'dev':
        default:
            return {
                FEVER_API_ENDPOINT: 'https://api.dev.feversocial.com',
                ZINES_API_ENDPOINT: 'https://api.dev.feversocial.com/zines',
                LAMBDA_UA_REPORT_ENDPOINT:
                    'https://ky6ozwnhma.execute-api.ap-southeast-1.amazonaws.com/dev/getGA',
                GA4_LAMBDA_ENDPOINT: 'https://yq49uxdqk4.execute-api.ap-southeast-1.amazonaws.com',
                IMG_PROXY_PATH: `https://image.fevercdn.com/dev`,
                THIRD_PARTY_AUTH_ENDPOINT: 'https://dev.feversocial.com',
                SDK_ENV_SUFFIX_BY_ENV: '-dev',
                LINE_CLIENT_ID: '6tfp4KZ1pFgVHLTscxFBBI'
            };
    }
})(APP_RUNNING_ENV);

export const tokenCookieName = 'fever_token';

export const feverOrigin = FEVER_HOST ? `https://${FEVER_HOST}` : '';

export const formApiHeaderFeverHost = (() => {
    const list = ['feversocial.com', 'dev.feversocial.com'];
    const { host: currentHost } = window.location;
    return list.includes(currentHost) || currentHost.includes('localhost') ? '' : currentHost;
})();

export const UNIVERSAL_ANALYTICS_EXPIRED_DATE = '2023-05-31';
