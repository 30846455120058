import React from 'react';
import { useSelector } from 'react-redux';
import i18next from 'i18next';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';
import { above, below, breakpoints } from 'utils/breakpoint';
import Navbar from 'features/login/components/Navbar';
import { useMediaQuery } from '@material-ui/core';

export default function LayoutOuter({ children }) {
    const { name: appName } = useSelector(s => s.app);
    const isMobile = useMediaQuery(`(max-width:${breakpoints.xsm}px)`);

    return (
        <div css={pageStyle}>
            <div css={containerStyle}>
                <div css={contentWrapperStyle}>
                    <div>
                        <div css={brandingWrapperStyle}>
                            <img
                                src="https://assets.fevercdn.com/nc-admin/images/admin_brand_logo_white.png"
                                alt="feversocial"
                            />
                            <Typography variant={isMobile ? 'h6' : 'h1'}>
                                {appName}
                                {i18next.t('label.login.feversocial_marketing_management_system')}
                            </Typography>
                        </div>
                    </div>
                    <div>{children}</div>
                </div>
                {!isMobile && <Navbar />}
            </div>
        </div>
    );
}

const pageStyle = css`
    height: 100%;
    weight: 100%;
    overflow: auto;
`;

const containerStyle = css`
    min-height: 720px;
    height: 100%;
    display: flex;
    flex-direction: column;
    &::before {
        content: '';
        position: fixed;
        z-index: -1;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-color: transparent;
        background: url('https://assets.fevercdn.com/nc-admin/images/admin_home.jpg') center 0
            no-repeat;
    }
`;

const contentWrapperStyle = css`
    flex: 1 1 auto;
    display: flex;
    > * {
        width: 50%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        ${below('sm')} {
            width: 100%;
            height: auto;
            margin: 24px 0;
        }
    }
    ${below('sm')} {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
    }
`;

const brandingWrapperStyle = css`
    max-width: 60%;
    display: flex;
    flex-direction: column;
    ${below('xlg')} {
        max-width: 90%;
        justify-content: center;
        align-items: center;
    }
    > img {
        max-width: 250px;
        ${below('sm')} {
            width: 160px;
        }
    }
    > h1 {
        color: #fff;
        font-size: 40px;
        margin-top: 24px;
        ${above('xlg')} {
            font-size: 48px;
        }
    }
    > h6 {
        color: #fff;
        margin-top: 4px;
        font-family: Noto Sans TC;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        letter-spacing: 0.15px;
    }
`;
