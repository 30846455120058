import { getFirebaseUserUId } from 'features/login/utils/firebaseManager';
import { planTypes } from 'config/planKeys';
import { retrieveCurrentActivePlan } from 'hooks/useSponsorPlanStatus';
import { IS_LOCAL_DEV } from 'config/envVariable';
import { getGameTypeModuleName } from 'config/promoFormType';
import { hmacSha256Hex } from 'utils/crypto';
import { INTERCOM_SECRET_KEY } from 'config/envVariable';

function pushDataLayer(data = '') {
    if (data && !IS_LOCAL_DEV) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(data);
    }
}

function transformUniqueName(uniqueName) {
    // Ticket: https://feversocial.atlassian.net/browse/SUPPORT-3768
    const specialUniqueNameMapping = {
        feversocial: 'fever'
    };
    return specialUniqueNameMapping[uniqueName] ?? uniqueName;
}

export function gtmPushInitData({ partner = {}, sponsor = {}, account = {} } = {}) {
    const { uniqueName } = partner;
    const processedUniqueName = transformUniqueName(uniqueName);
    const { id: sponsorId, name: sponsorName, plans } = sponsor;
    const { id: userId, email, name } = account;
    const guid = getFirebaseUserUId();

    const initDataConfigMap = new Map([
        ['event', 'render'],
        ['action', 'render_done'],
        ['guid', guid],
        ['name', name],
        ['email', email],
        ['userId', userId],
        ['uniqueName', processedUniqueName],
        ['sponsorId', String(sponsorId)],
        ['sponsorName', sponsorName],
        ['site', window.location.hostname],
        ['hash', hmacSha256Hex(guid, INTERCOM_SECRET_KEY)]
    ]);
    if (retrieveCurrentActivePlan(plans)?.planName) {
        initDataConfigMap.set('plan', retrieveCurrentActivePlan(plans)?.planName);
        initDataConfigMap.set('planStartDate', retrieveCurrentActivePlan(plans)?.startDate);
        initDataConfigMap.set('planEndDate', retrieveCurrentActivePlan(plans)?.endDate);
    }
    if (retrieveCurrentActivePlan(plans, planTypes.POINT_PLAN)?.planName) {
        initDataConfigMap.set(
            'planPoints',
            retrieveCurrentActivePlan(plans, planTypes.POINT_PLAN)?.planName
        );
        initDataConfigMap.set(
            'planPointStartDate',
            retrieveCurrentActivePlan(plans, planTypes.POINT_PLAN)?.startDate
        );
        initDataConfigMap.set(
            'planPointsEndDate',
            retrieveCurrentActivePlan(plans, planTypes.POINT_PLAN)?.endDate
        );
    }
    pushDataLayer(Object.fromEntries(initDataConfigMap));
}

export function gtmPushLogin({ partner = {}, account = {} } = {}) {
    const { uniqueName } = partner;
    const processedUniqueName = transformUniqueName(uniqueName);
    const { id: userId, email } = account;
    const guid = getFirebaseUserUId();

    pushDataLayer({
        event: 'login',
        guid,
        email,
        userId: userId,
        uniqueName: processedUniqueName,
        site: window.location.hostname,
        hash: hmacSha256Hex(guid, INTERCOM_SECRET_KEY)
    });
}

export function gtmPushCreateSponsor({ partner = {}, sponsor = {}, account = {} }) {
    const { uniqueName } = partner;
    const { id: sponsorId, name: sponsorName } = sponsor;
    const { id: userId, email, name } = account;
    const processedUniqueName = transformUniqueName(uniqueName);
    const guid = getFirebaseUserUId();
    pushDataLayer({
        event: 'sponsorCreate',
        sponsorId,
        sponsorName,
        uniqueName: processedUniqueName,
        guid,
        name,
        email,
        userId,
        hash: hmacSha256Hex(guid, INTERCOM_SECRET_KEY)
    });
}

export function gtmPushUserRegister({ account = {} }) {
    const { id: userId, email, name } = account;
    const guid = getFirebaseUserUId();
    pushDataLayer({
        event: 'signup',
        guid,
        name,
        email,
        userId,
        hash: hmacSha256Hex(guid, INTERCOM_SECRET_KEY)
    });
}

export function gtmPushCreateForm(formId) {
    pushDataLayer({
        event: 'formCreate',
        formId
    });
}

export function gtmPushCreatePage(pageId) {
    pushDataLayer({
        event: 'pageCreate',
        pageId
    });
}

export function gtmPushCreatePromo({ promoId, sponsorId, gameType, uniqueName, site }) {
    pushDataLayer({
        event: 'promoCreate',
        promoId,
        sponsorId,
        gameType: getGameTypeModuleName(gameType),
        uniqueName,
        site
    });
}

export function gtmPushChangeSponsor({ sponsorId, sponsorName, plans }) {
    const sponsorConfigMap = new Map([
        ['event', 'sponsorChange'],
        ['sponsorId', String(sponsorId)],
        ['sponsorName', sponsorName]
    ]);
    if (retrieveCurrentActivePlan(plans)?.planName) {
        sponsorConfigMap.set('plan', retrieveCurrentActivePlan(plans)?.planName);
        sponsorConfigMap.set('planStartDate', retrieveCurrentActivePlan(plans)?.startDate);
        sponsorConfigMap.set('planEndDate', retrieveCurrentActivePlan(plans)?.endDate);
    }
    if (retrieveCurrentActivePlan(plans, planTypes.POINT_PLAN)?.planName) {
        sponsorConfigMap.set(
            'planPoints',
            retrieveCurrentActivePlan(plans, planTypes.POINT_PLAN)?.planName
        );
        sponsorConfigMap.set(
            'planPointStartDate',
            retrieveCurrentActivePlan(plans, planTypes.POINT_PLAN)?.startDate
        );
        sponsorConfigMap.set(
            'planPointsEndDate',
            retrieveCurrentActivePlan(plans, planTypes.POINT_PLAN)?.endDate
        );
    }
    pushDataLayer(Object.fromEntries(sponsorConfigMap));
}

/**
 * 將活動複製事件推送至 Google Tag Manager
 * @param {Object} params - 活動複製事件的參數
 * @param {string|number} params.sponsorId - 複製活動到哪個 sponsor ID
 * @param {string} params.gameType - 複製成功後的活動類型
 * @param {string|number} params.promoId - 複製成功後的新活動 ID
 * @param {string} params.uniqueName - 活動唯一名稱
 * @param {number} params.sourcePromoId - 複製來源活動 ID
 * @param {string} params.sourceUniqueName - 複製來源 partner uniqueName
 * @param {string} params.site - 當下網址
 * @param {boolean} params.copyTemplate - 如果是從 template copy 的，copyTemplate 請設定為 true
 */
export function gtmPushCopyPromo({
    sponsorId,
    gameType,
    promoId,
    uniqueName,
    sourcePromoId,
    sourceUniqueName,
    site,
    copyTemplate
}) {
    const processedUniqueName = transformUniqueName(uniqueName);
    const processedSourceUniqueName = transformUniqueName(sourceUniqueName);
    pushDataLayer({
        event: 'promoCopy',
        promoId,
        sponsorId,
        gameType: getGameTypeModuleName(gameType),
        uniqueName: processedUniqueName,
        site,
        source: processedSourceUniqueName,
        sourcePromoId,
        copyTemplate
    });
}
