import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import i18n from 'i18next';
import { css } from '@emotion/core';
import { Typography } from '@material-ui/core';
import { ENTER_KEY_CODE } from 'utils/common';

const useStyles = makeStyles(() => ({
    root: {
        margin: '0 16px',
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        maxWidth: '400px',
        borderRadius: '100px',
        background: 'rgba(255, 255, 255, 0.8)',
        boxShadow: 'none',
        border: '1px solid #ECECEC'
    },
    input: {
        padding: '0 12px',
        flex: 1
    },
    iconButton: {
        padding: '8px',
        color: '#000',
        '&.MuiButtonBase-root.Mui-disabled': {
            color: '#000000',
            cursor: 'auto'
        }
    },
    divider: {
        height: 28,
        margin: 4
    },
    select: {
        '&:focus': {
            background: 'rgba(0, 0, 0,0)'
        },
        '&.MuiSelect-select.Mui-disabled': {
            color: '#000000',
            cursor: 'auto'
        }
    },
    outLineSelect: {
        padding: '0 10px 0 16px'
    },
    selectIcon: {
        marginRight: '8px',
        '&.Mui-disabled': {
            color: '#000000',
            cursor: 'auto'
        }
    }
}));

// searchTypeOptions 範例
// [
//     {
//         value: searchTypes.JOIN_MEMBER_INFO,
//         label: () => i18n.t('search.list.join_member_info'),
//     },
//     {
//         value: searchTypes.JOIN_RESULT,
//         label: () => i18n.t('search.list.join_result'),
//     }
// ]

/**
 * @description 帶有下拉選單的輸入框
 * @param {Func} onSearch 查詢事件
 * @param {Func} updateSearchKey: 更新查詢內容
 * @param {Func} updateSearchType: 更新查詢類型
 * @param {String} searchKey: 查詢內容,
 * @param {Number} searchType: 查詢類型
 * @param {String} placeholder: Input Placeholder
 * @param {Array} searchTypeOptions: 查詢類型設定
 * @param {Style} style: 樣式
 * @param {Boolean} isEnterSearch: 啟用 Enter 觸發查詢事件
 * @param {Func} onHandleOpenSelect: select Open 事件
 * @param {Func} onHandleCloseSelect: select Close 事件
 * @return {Component} ListInputSearch
 */

export default function ListInputSearch({
    onSearch,
    updateSearchKey,
    updateSearchType,
    searchKey,
    searchType,
    placeholder,
    searchTypeOptions,
    style,
    isEnterSearch = true,
    onHandleOpenSelect = () => {},
    onHandleCloseSelect = () => {}
}) {
    const classes = useStyles();

    return (
        <Paper component="form" className={classes.root} css={css(style)}>
            <Select
                className={classes.outLineSelect}
                classes={{ select: classes.select, icon: classes.selectIcon }}
                value={searchType}
                onChange={e => {
                    updateSearchType(e.target.value);
                }}
                disableUnderline
                renderValue={val => {
                    const current = searchTypeOptions.filter(item => item.value === val);
                    return (
                        <Typography variant="subtitle1">
                            {(current[0]?.label ?? (() => ''))()}
                        </Typography>
                    );
                }}
                onOpen={onHandleOpenSelect} // 打開時觸發
                onClose={onHandleCloseSelect} // 關閉時觸發
            >
                {searchTypeOptions?.map(st => (
                    <MenuItem key={st.value} value={st.value}>
                        {(st?.label ?? (() => ''))()}
                    </MenuItem>
                ))}
            </Select>
            <Divider className={classes.divider} orientation="vertical" />
            <InputBase
                className={classes.input}
                placeholder={placeholder}
                inputProps={{ 'aria-label': i18n.t('placeholder.search.join_result') }}
                value={searchKey}
                onChange={e => updateSearchKey(e.target.value)}
                onKeyPress={e => (e.which === ENTER_KEY_CODE && isEnterSearch ? onSearch() : false)}
            />
            <IconButton className={classes.iconButton} aria-label="menu" disabled>
                <SearchIcon />
            </IconButton>
        </Paper>
    );
}
