const INIT_PROMO = 'promo/INIT_INFO';
const RESET_PROMO = 'promo/RESET_PROMO';
const defaultState = {};

export default function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case INIT_PROMO:
            return { ...payload };
        case RESET_PROMO:
            return {};
        default:
            return state;
    }
}

export function initPromoInfo(data) {
    return {
        type: INIT_PROMO,
        payload: { ...data }
    };
}

export function resetPromoInfo() {
    return {
        type: RESET_PROMO
    };
}
