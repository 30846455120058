import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { getRoleTypeByRoleName, ON_SITE_STAFF } from 'config/roleType';
import { paths } from 'features/login/hooks/useInitAndRedirectApp';

const ProtectedRoute = ({ component: Component, LoadingComp, path, ...rest }) => {
    const accountRole = useSelector(s => s.sponsor?.accountRole);
    const location = useLocation();
    const newPath = location?.pathname.split('/')[1];
    // 可以造訪得路由
    const accessRouteList = Array.from(
        new Set(
            Object.values(accountRole?.permissionsRoute || {}).flatMap(routeSet => [...routeSet])
        )
    );

    const noPermissionRedirectUrl = (() => {
        switch (getRoleTypeByRoleName(accountRole?.roleName)) {
            case ON_SITE_STAFF:
            default:
                return paths.PERMISSION_DENIED;
        }
    })();

    return (
        <Route
            {...rest}
            LoadingComp={LoadingComp}
            render={props =>
                accessRouteList.includes(`/${newPath}`) ? (
                    <Component {...props} />
                ) : (
                    <Redirect to={`${noPermissionRedirectUrl}${location.search}`} />
                )
            }
        />
    );
};

export default ProtectedRoute;
