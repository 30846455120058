import React, { useEffect, useState, useCallback } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import { css } from '@emotion/core';
import querystring from 'query-string';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { isSignInWithEmailLink, linkWithCredential, EmailAuthProvider } from 'firebase/auth';
import { setUserInfo, setIsLogin } from 'store/module/firebase';
import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';
import Panel from 'features/login/components/Panel';
import ThirdPartyLoginBlock from 'features/login/components/ThirdPartyLoginBlock';
import DialogWrongLogin from 'features/login/components/pageEmailVerifyLanding/DialogWrongLogin';
import {
    useRedirectApp,
    toCreateAccountPage,
    toSignInPage
} from 'features/login/hooks/useInitAndRedirectApp';
import useApiFailedHandler from 'features/webhooks/useApiErrorHandler';
import useHistoryWithCurrentSearchHash from 'features/login/hooks/useHistoryWithCurrentSearchHash';
import useUsableFirebaseProviders from 'features/login/hooks/useUsableFirebaseProviders';
import {
    auth,
    firebaseEmailLinkQueryStrKeys,
    signout,
    firebaseApiErrCodes,
    firebaseProviderIds,
    firebaseProviderPlatformMapping
} from 'features/login/utils/firebaseManager';
import { getQueryString } from 'features/login/utils/manipulateUrl';
import { queryStrKeys } from 'features/login/utils/config';
import { mainButtonSolidStyle } from 'features/login/styles/comman';
import { getUserAccountData } from 'api/account';
import {
    FIREBASE_VERIFY_EMAIL_FAILED,
    FIREBASE_LINK_WITH_CREDENTIAL_FAILED
} from 'config/errorTypes';

const ACTION = {
    TO_LOGOUT: 'logout',
    TO_LOGIN: 'login'
};

export default function EmailVerifyLanding() {
    const apiFailedHandler = useApiFailedHandler();
    const showProvider = useUsableFirebaseProviders();
    const dispatch = useDispatch();
    const redirectApp = useRedirectApp();
    const history = useHistoryWithCurrentSearchHash();
    const [verifyInfo] = useState(() => {
        const qsObj = querystring.parse(window.location.search);
        return {
            providerId: qsObj[queryStrKeys.PROVIDER_ID],
            email: qsObj[queryStrKeys.VERIFY_EMAIL],
            uid: qsObj[queryStrKeys.FIREBASE_UID]
        };
    });

    const [isProcessing, setIsProcessing] = useState(true);
    const [nextAction, setNextAction] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [openWrongLoginDialog, setOpenWrongLoginDialog] = useState(false);

    const thirdPartyLoginConfig = [
        {
            isShow: showProvider.GOOGLE,
            isDisabled: verifyInfo.providerId !== firebaseProviderIds.GOOGLE,
            providerId: firebaseProviderIds.GOOGLE,
            iconFileName: 'icon_google.svg',
            text: 'btn.continue_with.google',
            callback: handleVerifyEmail
        },
        {
            isShow: showProvider.MICROSOFT,
            isDisabled: verifyInfo.providerId !== firebaseProviderIds.MICROSOFT,
            providerId: firebaseProviderIds.MICROSOFT,
            iconFileName: 'icon_microsoft.svg',
            text: 'btn.continue_with.microsoft',
            callback: handleVerifyEmail
        },
        {
            isShow: showProvider.LINE,
            isDisabled: verifyInfo.providerId !== firebaseProviderIds.LINE,
            providerId: firebaseProviderIds.LINE,
            iconFileName: 'icon_line.svg',
            text: 'btn.continue_with.line',
            callback: handleVerifyEmail
        },
        {
            isShow: showProvider.FB,
            isDisabled: verifyInfo.providerId !== firebaseProviderIds.FACEBOOK,
            providerId: firebaseProviderIds.FACEBOOK,
            iconFileName: 'icon_facebook.svg',
            text: 'btn.continue_with.fb',
            callback: handleVerifyEmail
        }
    ];

    const handleNotFoundAuthUserError = () => {
        setNextAction(ACTION.TO_LOGIN);
        setIsProcessing(false);
    };

    async function handleVerifyEmail() {
        try {
            setLoading(true);
            if (auth?.currentUser?.emailVerified) {
                let account = await getUserAccountData();
                if (auth.currentUser.uid === verifyInfo?.uid && !account) {
                    return toCreateAccountPage(history);
                }
            }
            if (auth?.currentUser?.uid !== verifyInfo?.uid) {
                setNextAction(ACTION.TO_LOGOUT);
                setOpenWrongLoginDialog(true);
            } else {
                processSignInLinkWithCredential();
            }
        } catch (error) {
            apiFailedHandler(
                error,
                i18next.t('notify.verify_email.failed'),
                FIREBASE_VERIFY_EMAIL_FAILED
            );
        } finally {
            setLoading(false);
        }
    }
    const processSignInLinkWithCredential = useCallback(async () => {
        try {
            await linkWithCredential(
                auth.currentUser,
                EmailAuthProvider.credentialWithLink(verifyInfo.email, window.location.href)
            );
            dispatch(setUserInfo({ email: verifyInfo.email }));
            setNextAction('');
            history.replace({
                search: getQueryString({
                    remove: [
                        ...firebaseEmailLinkQueryStrKeys,
                        queryStrKeys.VERIFY_EMAIL_TYPE,
                        queryStrKeys.VERIFY_EMAIL,
                        queryStrKeys.FIREBASE_UID
                    ]
                })
            });
            await redirectApp();
        } catch (error) {
            if (error.code === firebaseApiErrCodes.AUTH_EXPIRED_ACTION_CODE) {
                setIsProcessing(true);
            }
            if (error.code === firebaseApiErrCodes.AUTH_INVALID_ACTION_CODE) {
                setNextAction(ACTION.TO_LOGOUT);
                setIsProcessing(false);
            }
            if (error.code === firebaseApiErrCodes.AUTH_PROVIDER_ALREADY_LINKED) {
                setNextAction(ACTION.TO_LOGOUT);
                setOpenWrongLoginDialog(true);
            } else {
                apiFailedHandler(
                    error,
                    i18next.t('notify.sign_in.failed'),
                    FIREBASE_LINK_WITH_CREDENTIAL_FAILED
                );
            }
        } finally {
            setLoading(false);
            setIsProcessing(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, verifyInfo.email]);

    useEffect(() => {
        // Checks if an incoming link is a sign-in with email link
        if (isSignInWithEmailLink(auth, window.location.href)) {
            if (auth.currentUser) {
                processSignInLinkWithCredential();
            } else {
                handleNotFoundAuthUserError();
            }
        } else {
            toSignInPage();
        }
    }, [processSignInLinkWithCredential]);

    return (
        <div css={containerStyle}>
            {isProcessing ? (
                <CircularProgress />
            ) : (
                <>
                    {nextAction === ACTION.TO_LOGIN && (
                        <Panel style={panelStyle}>
                            <Typography css={titleStyle} variant="h6">
                                {i18next.t('label.login_again')}
                            </Typography>
                            <ThirdPartyLoginBlock config={thirdPartyLoginConfig} />
                        </Panel>
                    )}
                    {nextAction === ACTION.TO_LOGOUT && (
                        <div css={[centerStyle, css(`width: 100%; gap: 16px`)]}>
                            <Typography variant="subtitle1">
                                {i18next.t('label.login_again')}
                            </Typography>
                            <ButtonWithLoadingEffect
                                isLoading={isLoading}
                                css={[mainButtonSolidStyle, css(`width: 100%`)]}
                                onClick={async () => {
                                    setLoading(true);
                                    await signout();
                                    dispatch(setIsLogin(false));
                                    setNextAction(ACTION.TO_LOGIN);
                                }}
                            >
                                <Typography variant="subtitle2">
                                    {i18next.t('notify.login_session_expired')}
                                </Typography>
                            </ButtonWithLoadingEffect>
                        </div>
                    )}
                </>
            )}
            <DialogWrongLogin
                platform={firebaseProviderPlatformMapping[verifyInfo?.providerId]}
                open={openWrongLoginDialog}
                onClose={() => setOpenWrongLoginDialog(false)}
            />
        </div>
    );
}

const centerStyle = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const containerStyle = css`
    width: 360px;
    padding: 16px 24px;
    ${centerStyle}
`;

const titleStyle = css`
    text-align: center;
    margin: 16px 0 24px;
`;

const panelStyle = css`
    padding: 16px 24px;
`;
