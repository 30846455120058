import DashboardIcon from '@material-ui/icons/Dashboard';
import { ReactComponent as PromoIcon } from 'assets/svg/promo.svg';
import TrackChangesIcon from '@material-ui/icons/TrackChanges';
import PublicIcon from '@material-ui/icons/Public';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { ReactComponent as PointIcon } from 'assets/svg/point_system_logo.svg';
import { ReactComponent as CampaignIcon } from 'assets/svg/campaign.svg';
import SettingsIcon from '@material-ui/icons/Settings';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

import {
    system900,
    system800,
    system100,
    fever500,
    form,
    zines,
    promo,
    reminder
} from 'features/common/Mui/styles/color';
import { feverOrigin } from 'config/envVariable';

// NOTE: 這邊的 id 不能亂取，要根據 /admin/:projectId 來命名
export const projectIds = {
    PROMO: 'promo',
    PROMOS: 'promos',
    ZINES: 'zines',
    FORM: 'form',
    WEBHOOK: 'webhook',
    SPONSOR: 'sponsor',
    ACCOUNT: 'account',
    MEMBER: 'member',
    DASHBOARD: 'dashboard',
    CAMPAIGN: 'campaign',
    POINT: 'point',
    ON_SITE_ASSISTANT: 'on-site-assistant'
};

const projects = {
    byId: {
        [projectIds.DASHBOARD]: {
            to: { pathname: '/admin/dashboard' },
            i18nId: 'label.sidebar.links.dashboard',
            logoI18n: 'label.sidebar.links.dashboard',
            label: 'Dashboard',
            IconComp: ({ isProjectLogo, ...rest }) => <DashboardIcon {...rest} />,
            color: system900.color,
            hoverColor: system100.color
        },
        [projectIds.PROMO]: {
            link: `${feverOrigin}/admin`,
            i18nId: 'sidebar.links.promo',
            label: 'Promo',
            IconComp: ({ isProjectLogo, ...rest }) => <TrackChangesIcon {...rest} />,
            iconColor: promo.color,
            color: system900.color,
            hoverColor: system100.color,
            isExternal: true
        },
        [projectIds.PROMOS]: {
            to: { pathname: '/admin/promos' },
            i18nId: 'sidebar.links.promo_new',
            label: 'Promo',
            IconComp: ({ isProjectLogo, ...rest }) => {
                return isProjectLogo ? <PromoIcon {...rest} /> : <TrackChangesIcon {...rest} />;
            },
            iconColor: promo.color,
            color: system900.color,
            hoverColor: system100.color
        },
        [projectIds.ON_SITE_ASSISTANT]: {
            to: { pathname: '/admin/on-site-assistant' },
            i18nId: '',
            label: 'Promo',
            IconComp: ({ isProjectLogo, ...rest }) => {
                return isProjectLogo ? <PromoIcon {...rest} /> : <TrackChangesIcon {...rest} />;
            },
            iconColor: promo.color,
            color: system900.color,
            hoverColor: system100.color
        },
        [projectIds.ZINES]: {
            to: { pathname: '/admin/zines' },
            i18nId: 'sidebar.links.zines',
            label: 'Zines',
            IconComp: ({ isProjectLogo, ...rest }) => <PublicIcon {...rest} />,
            iconColor: zines.color,
            color: system900.color,
            hoverColor: system100.color
        },
        [projectIds.FORM]: {
            to: { pathname: '/admin/form' },
            i18nId: 'sidebar.links.forms',
            label: 'Forms',
            IconComp: ({ isProjectLogo, ...rest }) => <ListAltIcon {...rest} />,
            iconColor: form.color,
            color: system900.color,
            hoverColor: system100.color
        },
        [projectIds.CAMPAIGN]: {
            to: { pathname: '/admin/campaign' },
            i18nId: 'sidebar.links.campaign-tool',
            logoI18n: 'sidebar.links.campaign-tool',
            IconComp: ({ isProjectLogo, ...rest }) => <CampaignIcon {...rest} />,
            iconColor: fever500.color,
            color: system900.color,
            hoverColor: system100.color
        },
        [projectIds.POINT]: {
            to: { pathname: '/admin/point' },
            i18nId: 'sidebar.links.point',
            logoI18n: 'sidebar.links.point',
            IconComp: ({ isProjectLogo, ...rest }) => <PointIcon {...rest} />,
            iconColor: reminder.color,
            color: system900.color,
            hoverColor: system100.color,
            // 用 svg 而非 mui 的 icon，logo size 要額外設定
            style: { width: 36, height: 36 }
        },
        [projectIds.WEBHOOK]: {
            to: { pathname: '/admin/webhook' },
            i18nId: 'sidebar.links.edit_webhook',
            logoI18n: 'sidebar.links.edit_webhook',
            label: 'WebHooks',
            IconComp: ({ isProjectLogo, ...rest }) => <SettingsIcon {...rest} />,
            color: system800.color,
            hoverColor: system100.color
        },
        [projectIds.SPONSOR]: {
            to: { pathname: '/admin/sponsor/info' },
            i18nId: 'sidebar.links.sponsor_setting',
            logoI18n: 'sidebar.links.sponsor_setting',
            label: 'Sponsor',
            IconComp: ({ isProjectLogo, ...rest }) => <LocationCityIcon {...rest} />,
            color: system800.color,
            hoverColor: system100.color
        },
        [projectIds.ACCOUNT]: {
            to: { pathname: '/admin/account' },
            i18nId: 'sidebar.links.account_setting',
            logoI18n: 'sidebar.links.account_setting',
            label: 'Account',
            IconComp: ({ isProjectLogo, ...rest }) => <PersonIcon {...rest} />,
            color: system800.color,
            hoverColor: system100.color
        },
        [projectIds.MEMBER]: {
            to: { pathname: '/admin/sponsor/admin_setting' },
            i18nId: 'sidebar.links.account_setting',
            IconComp: ({ isProjectLogo, ...rest }) => <PersonAddIcon {...rest} />,
            color: system800.color,
            hoverColor: system100.color
        }
    }
};
export default projects;

export const projectEntrances = [
    { id: projectIds.PROMOS, overwriteProps: {} },
    { id: projectIds.ZINES, overwriteProps: {} },
    { id: projectIds.FORM, overwriteProps: {} },
    { id: projectIds.POINT, overwriteProps: {} },
    { id: projectIds.CAMPAIGN, overwriteProps: {} }
];

export const settingEntrances = [
    { id: projectIds.WEBHOOK, overwriteProps: {} },
    { id: projectIds.SPONSOR, overwriteProps: {} },
    {
        id: projectIds.MEMBER,
        overwriteProps: { i18nId: 'label.dashboard.menu_entrace_member_manage' }
    }
];
