import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';

import { mainBtnStyle } from 'features/login/styles/comman';

import Button from 'features/common/Mui/Button';

export default function ToSigninBlock() {
    const { t } = useTranslation();
    return (
        <div css={containerStyle}>
            <Typography variant="subtitle2" css={labelStyle}>
                {t('label.to_login')}
            </Typography>
            <Link to={historyObj => ({ ...historyObj, pathname: '/app/login' })}>
                <Button variant="outlined" css={btnStyle}>
                    <Typography variant="subtitle2">{t('btn.to_login')}</Typography>
                </Button>
            </Link>
        </div>
    );
}

const containerStyle = css`
    margin-top: 32px;
`;

const labelStyle = css`
    color: #e55b61;
    text-align: center;
`;

const btnStyle = css`
    ${mainBtnStyle}
    margin-top: 16px;
    border-color: #e55b61;
    color: #e55b61;
`;
