import { timezoneList } from 'config/timezone';
import dayjs from 'dayjs';

export function getUserTimezone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export function utcDT2TimezoneDTStr({ dateStr, timezone, format = 'YYYY-MM-DD HH:mm:ss' }) {
    // 支援 2024-03-15T11:32:02.000000Z 、 2024-03-15 11:32:02 UTC 格式轉換
    const targetTimeZone = Boolean(timezone) ? timezone : getUserTimezone();
    return isUTC(dateStr)
        ? utc2TimezoneDTStr(dateStr, targetTimeZone, format)
        : utcDTStr2TimezoneDTStr(dateStr, targetTimeZone, format);
}

export function utcDTStr2UTC(dateStr = '') {
    return new Date(`${dateStr.replace(/\s/, 'T')}Z`).getTime();
}

export function isUTC(dateStr = '') {
    if (typeof dateStr !== 'string') return false;
    const UTC_Pattern = /T.*Z$/;
    return UTC_Pattern.test(dateStr);
}

export function utcDTStr2TimezoneDTStr(dateStr = '', timezone, format) {
    return utc2TimezoneDTStr(utcDTStr2UTC(dateStr), timezone, format);
}

export function utc2TimezoneDTStr(timestamp, timezone, format = 'YYYY-MM-DD HH:mm:ss') {
    return timezone
        ? dayjs(timestamp).tz(timezone).format(format)
        : dayjs(timestamp).format(format);
}

export function timezoneDTStr2LocalDTStr(dateStr = '', timezone) {
    const tzOffset = getTimezoneOffset(timezone);
    return getDateTimeStr(`${dateStr.replace(/\s/, 'T')}${tzOffset}`);
}

export function utcDateStr2LocalDateStr(dateStr) {
    return getDateTimeStr(`${dateStr.replace(/\s/, 'T')}Z`);
}

export function getDayRange(date1, date2) {
    const d1 = dayjs(date1);
    return d1.diff(date2, 'day');
}

export function hasTimezoneDTStartInLocal(startDate, timezone) {
    return Date.now() >= timezoneDTStr2UTC(startDate, timezone);
}

export function hasTimezoneDTEndInLocal(endDate, timezone) {
    return Date.now() >= timezoneDTStr2UTC(endDate, timezone);
}

export function getLocalTimezone() {
    return dayjs.tz.guess();
}

export function getDateTimeStr(dateData) {
    return dayjs(dateData).format('YYYY-MM-DD HH:mm:ss');
}

export function getDateTimeStrToDay(dateData) {
    return dayjs(dateData).format('YYYY-MM-DD');
}

export function dateTimeStrIsToday(dateStr) {
    return dayjs(timezoneDTStr2UTC(dateStr)).isToday();
}

export function utc2UtcDTStr(timestamp, dateOnly) {
    const str = new Date(timestamp).toISOString().replace('T', ' ').split('.')[0];
    return dateOnly ? str.split(' ')[0] : str;
}

export function hasUtcDTStartInLocal(startDate) {
    return Date.now() >= utcDTStr2UTC(startDate);
}

export function isBefore(a, b) {
    return dayjs(a).isBefore(dayjs(b));
}

export function UTC_toLocalTime(timeString, format) {
    if (format) return dayjs(timeString).utc(true).local().format(format);
    return dayjs(timeString).utc(true).local();
}

/**
 * @param {*} dateStr: [String] Ex: '2022-11-05 00:00:00'
 * @param {*} timezone:[String] Ex: 'Asia/Taipei'
 * @returns {*} UTC dateStr: Ex: '2022-11-05 00:00:00'
 */
// NOTE: 要根據 timezone 時區去調整 UTC 的時間
export function timezoneDTStr2UtcDTStr(dateStr, timezone) {
    return timeStamp2UtcDtStr(timezoneDTStr2UTC(dateStr, timezone));
}

/**
 * timezones
 * @type {Array.<{ name: string, utc: string, label: string, tzCode: string }>}
 * – utc, a string from '-11:00' to '+14:00' representing the UTC offset
 * - tzCode, the value from the tz standard
 */

function getTimezoneOffset(timezone) {
    return (timezoneList.find(t => t.tzCode === timezone) || {}).utc || 'Z';
}

/**
 * Depend on timezone and transform common date string to utc timestamp
 * @param {*} dateStr: [String] Ex: '2022-11-05 00:00:00'
 * @param {*} timezone:[String] Ex: 'Asia/Taipei'
 * @returns timestamp: [Number]
 */
export function timezoneDTStr2UTC(dateStr = '', timezone) {
    const tzOffset = getTimezoneOffset(timezone);
    // new Date('2022-11-06T23:59:59-08:00').getTime();
    return new Date(`${dateStr.replace(/\s/, 'T')}${tzOffset}`).getTime();
}

export function timeStamp2UtcDtStr(timestamp) {
    const [str] = new Date(timestamp).toISOString().split('.');
    return str.replace('T', ' ');
}

export function compareWithExpiredDate({ startDate, endDate, expiredDate }) {
    if (
        dayjs(startDate).isAfter(dayjs(expiredDate)) &&
        dayjs(endDate).isAfter(dayjs(expiredDate))
    ) {
        return { isBefore: false, isBetween: false, isAfter: true };
    }

    if (
        dayjs(startDate).isBefore(dayjs(expiredDate)) &&
        dayjs(endDate).isAfter(dayjs(expiredDate))
    ) {
        return { isBefore: false, isAfter: false, isBetween: true };
    }

    if (
        dayjs(startDate).isBefore(dayjs(expiredDate)) &&
        dayjs(endDate).isBefore(dayjs(expiredDate))
    ) {
        return { isBefore: true, isAfter: false, isBetween: false };
    }

    return { isBefore: false, isBetween: false, isAfter: true };
}
