export const queryStrKeys = {
    VERIFY_EMAIL_TYPE: 'verify_email_type',
    VERIFY_EMAIL: 'verify_email',
    FIREBASE_UID: 'firebase_uid',
    UPDATE_EMAIL_TO: 'update_email_to',
    UPDATE_EMAIL_FROM: 'update_email_from',
    PROVIDER_ID: 'provider_id',
    FEVER_HOST: 'fever_host',
    REF_URL: 'refUrl'
};

export const verifyEmailTypes = {
    FORGET_PASSWORD: 'forget_password',
    SIGN_UP: 'signup',
    EMAIL_VERIFY: 'email_verify',
    UPDATE_EMAIL: 'update_email'
};
