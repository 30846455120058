import React, { useState } from 'react';
import { css } from '@emotion/core';
import Input from '@material-ui/core/Input';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import { system600 } from 'features/common/Mui/styles/color';

function SearchField(
    {
        isFocused,
        placeholder,
        onSearch,
        onChange = () => {},
        defaultValue = '',
        endAdornment = <SearchIcon />,
        style,
        ...props
    },
    ref
) {
    const [inputValue, setInputValue] = useState(defaultValue);

    const searchHandler = () => {
        onSearch(inputValue);
    };

    const changeHandler = e => {
        setInputValue(e.target.value);
        onChange(e.target.value);
    };

    const ENTER_KEY_CODE = 13;

    return (
        <div css={[css(style)]} ref={ref}>
            <Input
                autoFocus={isFocused}
                value={inputValue}
                onChange={changeHandler}
                endAdornment={<InputAdornment position="end">{endAdornment}</InputAdornment>}
                placeholder={placeholder}
                css={[container, css(style)]}
                onKeyPress={e => (e.which === ENTER_KEY_CODE ? searchHandler(e) : false)}
                {...props}
            />
        </div>
    );
}

const container = css`
    display: flex;
    justify-content: space-between;
    align-item: center;
    background-color: #ffffff;
    border-bottom: 1px solid ${system600.color};
    padding: 7.5px 16px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    & .MuiInputBase-root {
        font-size: 0.875rem;
    }
`;

export default React.forwardRef(SearchField);
