import { useSelector } from 'react-redux';

import { firebaseProviderIds } from 'features/login/utils/firebaseManager';

export default function useUsableFirebaseProviders() {
    const { adminGidpProviders = [] } = useSelector(s => s.app);
    const hasProviderWhiteList = adminGidpProviders.length > 0;
    const showEmailPass =
        !hasProviderWhiteList || adminGidpProviders.includes(firebaseProviderIds.EMAIL);
    const showFb =
        !hasProviderWhiteList || adminGidpProviders.includes(firebaseProviderIds.FACEBOOK);
    const showGoogle =
        !hasProviderWhiteList || adminGidpProviders.includes(firebaseProviderIds.GOOGLE);
    const showLine = !hasProviderWhiteList || adminGidpProviders.includes(firebaseProviderIds.LINE);
    const showMicrosoft =
        !hasProviderWhiteList || adminGidpProviders.includes(firebaseProviderIds.MICROSOFT);

    return {
        EMAIL_PASS: showEmailPass,
        FB: showFb,
        GOOGLE: showGoogle,
        LINE: showLine,
        MICROSOFT: showMicrosoft
    };
}
