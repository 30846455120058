import React from 'react';
import i18n from 'i18next';
import { Trans } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';
import { fever500, system700 } from 'features/common/Mui/styles/color';
import LayoutInner from 'features/login/components/LayoutInner';
import useFreshChat from 'hooks/useFreshChat';
import { getRedirectUrlWithFallbackSponsor } from 'api/commom';
import { generateLoginUrl } from 'utils/login';

export default function PagePermissionDenied() {
    const { open: openFreshChat = () => {} } = useFreshChat();
    const handleClick = e => {
        e.preventDefault();
        openFreshChat();
    };
    return (
        <LayoutInner showMenu={false}>
            <Box>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 24,
                        marginBottom: 96,
                        textAlign: 'center'
                    }}
                >
                    <Typography variant="h5">{i18n.t('permission_denied.title')}</Typography>
                    <Typography
                        variant="subtitle2"
                        style={{ textAlign: 'center', color: '#616161' }}
                    >
                        {i18n.t('permission_denied.desc')}
                    </Typography>
                </Box>
                <Box style={{ textAlign: 'center', color: system700.color }}>
                    <Typography variant="subtitle2">
                        <Trans
                            i18nKey="permission_denied.contact_link"
                            components={[
                                <span
                                    css={{ color: fever500.color, cursor: 'pointer' }}
                                    onClick={handleClick}
                                >
                                    0
                                </span>
                            ]}
                        />
                    </Typography>
                    <Typography variant="subtitle2">
                        <Trans
                            i18nKey="permission_denied.create_sponsor"
                            components={[
                                <a
                                    href={generateLoginUrl(getRedirectUrlWithFallbackSponsor())}
                                    rel="noopener noreferrer"
                                    css={{ color: fever500.color }}
                                >
                                    0
                                </a>
                            ]}
                        />
                    </Typography>
                </Box>
            </Box>
        </LayoutInner>
    );
}
