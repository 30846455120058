import { belowContain } from 'utils/breakpoint';

export const bgTypes = {
    COLOR: 1,
    IMG: 2,
    FROALA: 3
};

export default function generateBgStyleByType({
    isFixedMode,
    type,
    imageUrl,
    mobileImgUrl,
    color = 'rgba(219,219,219,1)'
}) {
    switch (type) {
        case bgTypes.IMG:
            return `
            background-color: ${color};
            ${imageUrl ? `background-image: url('${imageUrl}');` : ''}
            ${isFixedMode ? 'background-attachment: fixed;' : ''}
            background-repeat: no-repeat;
            background-position: center center;
            background-size: cover;
            ${
                mobileImgUrl
                    ? `${belowContain('xsm')}{ background-image: url('${mobileImgUrl}'); }`
                    : ''
            }
        `;
        case bgTypes.FROALA:
            return '';
        case bgTypes.COLOR:
        default:
            return `background-color: ${color};`;
    }
}
