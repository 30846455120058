import getMgmFlags from 'utils/getMgmFlags';

const UPDATE_FLAGS = 'flags/UPDATE_FLAGS';
const UPDATE_MGM_FLAGS = 'flags/UPDATE_MGM_FLAGS';

export const DEVICE_TYPE = {
    DESKTOP: 'desktop',
    MOBILE: 'mobile'
};

const defaultState = {
    isMgmShare: false,
    isMgmMission: false,
    isOpenTagsFeature: false,
    isSponsorUseGlobalPoints: false,
    isPointSystemChecked: false,
    showMainFunctionBtn: false,
    previewMode: DEVICE_TYPE.MOBILE,
    settingCampaignSubPanelType: '',
    settingStyleSubPanelType: {
        subPanel: '',
        expandedPanel: []
    }
};
const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_MGM_FLAGS: {
            const { isMgmShare, isMgmMission } = getMgmFlags(action.payload.mgmRelation);
            return { ...state, isMgmShare, isMgmMission };
        }
        case UPDATE_FLAGS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default reducer;

export function setMgmStateInited(mgmState) {
    return {
        type: UPDATE_MGM_FLAGS,
        payload: { mgmRelation: mgmState }
    };
}

export function setTagsStateInit(tagsState) {
    return {
        type: UPDATE_FLAGS,
        payload: { isOpenTagsFeature: tagsState }
    };
}

export function setSponsorUseGlobalPoints(state) {
    return {
        type: UPDATE_FLAGS,
        payload: { isSponsorUseGlobalPoints: state }
    };
}

export function setPointSystemChecked(state) {
    return {
        type: UPDATE_FLAGS,
        payload: { isPointSystemChecked: state }
    };
}

export function setSettingCampaignSubPanelType(settingCampaignSubPanelType) {
    return {
        type: UPDATE_FLAGS,
        payload: {
            settingCampaignSubPanelType
        }
    };
}

export function setSettingStyleSubPanelType(settingStyleSubPanelType) {
    return {
        type: UPDATE_FLAGS,
        payload: {
            settingStyleSubPanelType
        }
    };
}

// ALAN TODO: 確認有無需要使用
export function toggleMainFunctionBtn(showMainFunctionBtn) {
    return {
        type: UPDATE_FLAGS,
        payload: { showMainFunctionBtn }
    };
}

export function setPreviewMode(previewMode) {
    return {
        type: UPDATE_FLAGS,
        payload: { previewMode }
    };
}
