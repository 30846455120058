const getMgmFlags = (mgmConfig = {}) => {
    const mgmMapping = Array.isArray(mgmConfig) ? mgmConfig : Object.entries(mgmConfig);
    const { mgmShare: isMgmShare = false, mgmMission: isMgmMission = false } = mgmMapping.reduce(
        (result, [k, v]) => {
            if (mgmConfig[k] && k === 'mgmRole') {
                return { ...result, [v]: true };
            }
            return { ...result, [v]: false };
        },
        {}
    );

    return { isMgmShare, isMgmMission };
};

export default getMgmFlags;
