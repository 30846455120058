import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';

import { mainButtonHollowStyle } from 'features/login/styles/comman';

import Button from 'features/common/Mui/Button';

export default function BtnBackToAccountSetting({ style }) {
    const { t } = useTranslation();
    return (
        <Link to={historyObj => ({ ...historyObj, pathname: '/admin/account' })}>
            <Button variant="outlined" css={[mainButtonHollowStyle, css(style)]}>
                <Typography variant="subtitle2">{t('btn.back_to_account_setting')}</Typography>
            </Button>
        </Link>
    );
}
