const NORMAL = 'flag/NORMAL';
const SUCCESS = 'flag/SUCCESS';
const FAILED = 'flag/FAILED';
const LOADING = 'flag/LOADING';
const WARNING = 'flag/WARNING';

export default function reducer(state = {}, action) {
    switch (action.type) {
        case NORMAL:
            return {};
        case WARNING:
        case LOADING:
        case SUCCESS:
        case FAILED:
            return Object.assign({}, state, {
                mode: action.mode,
                source: action.source,
                textId: action.textId
            });
        default:
            return state;
    }
}

export function setWarning(source, textId) {
    return {
        type: WARNING,
        mode: 'Warning',
        source,
        textId
    };
}

export function setFailed(source, textId) {
    return {
        type: FAILED,
        mode: 'Failed',
        source,
        textId
    };
}

export function setSuccess(source, textId) {
    return {
        type: SUCCESS,
        mode: 'Success',
        source,
        textId
    };
}

export function setLoading(source, textId) {
    return {
        type: LOADING,
        mode: 'Loading',
        source,
        textId
    };
}

export function setNormal(source, textId) {
    return {
        type: NORMAL,
        mode: 'Normal',
        source,
        textId
    };
}
