import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { isSignInWithEmailLink, signInWithEmailLink, updatePassword, signOut } from 'firebase/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

import { queryStrKeys } from 'features/login/utils/config';
import { auth, firebaseEmailLinkQueryStrKeys } from 'features/login/utils/firebaseManager';
import { getQueryString } from 'features/login/utils/manipulateUrl';
import { paths } from 'features/login/hooks/useInitAndRedirectApp';

import useHistoryWithCurrentSearchHash from 'features/login/hooks/useHistoryWithCurrentSearchHash';

import BlockPasswordInput from 'features/login/components/pageEmailVerifyLanding/BlockPasswordInput';
import BlockInfoAndConfirmRedirect from 'features/login/components/BlockInfoAndConfirmRedirect';

export default function EmailVerifyLandingSignupAndForgetPassword() {
    const { t } = useTranslation();
    const history = useHistoryWithCurrentSearchHash();
    const [email] = useState(
        () => querystring.parse(window.location.search)[queryStrKeys.VERIFY_EMAIL]
    );
    const [displayType, setDisplayType] = useState('');

    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            setDisplayType(displayTypes.PASSWORD);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function handleConfirmPassword({ password }) {
        try {
            const credential = await signInWithEmailLink(auth, email, window.location.href);
            await updatePassword(credential.user, password);
            history.replace({
                search: getQueryString({
                    remove: [
                        ...firebaseEmailLinkQueryStrKeys,
                        queryStrKeys.VERIFY_EMAIL_TYPE,
                        queryStrKeys.VERIFY_EMAIL
                    ]
                })
            });
            await signOut(auth);
            setDisplayType(displayTypes.PASSWORD_CONFIRM);
        } catch (error) {
            console.error('set password', error);
        }
    }

    if (displayType === displayTypes.PASSWORD) {
        return <BlockPasswordInput email={email} onSave={handleConfirmPassword} />;
    }
    if (displayType === displayTypes.PASSWORD_CONFIRM) {
        return (
            <BlockInfoAndConfirmRedirect
                title={t('label.password_set_done')}
                desc={t('label.login_again')}
                btnText={t('btn.to_login_page')}
                redirectPath={paths.LOGIN}
            />
        );
    }
    return <CircularProgress />;
}

const displayTypes = {
    PASSWORD: 'PASSWORD',
    PASSWORD_CONFIRM: 'PASSWORD_CONFIRM'
};
