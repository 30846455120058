import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { css } from '@emotion/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { amber, green } from '@material-ui/core/colors';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import WarningIcon from '@material-ui/icons/Warning';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import i18n from 'i18next';

const variantIcon = {
    success: CheckCircleIcon,
    warning: WarningIcon,
    error: ErrorIcon,
    info: InfoIcon
};

const useStyles = makeStyles(theme => ({
    success: {
        backgroundColor: green[600]
    },
    error: {
        backgroundColor: theme.palette.error.dark
    },
    info: {
        backgroundColor: theme.palette.primary.main
    },
    warning: {
        backgroundColor: amber[700]
    },
    message: {
        color: '#fff',
        display: 'flex',
        padding: '6px 16px',
        flexGrow: '1',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderRadius: '4px',
        maxWidth: '400',
        minWidth: '344',
        '@media (min-width: 600px)': {
            flexGrow: 'initial',
            minWidth: '288px'
        }
    }
}));

const Message = React.forwardRef(({ id, variant, message }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();

    const handleDismiss = () => {
        closeSnackbar(id);
    };
    const Icon = variantIcon[variant];

    return (
        <Paper className={clsx(classes[variant], classes.message)} ref={ref}>
            <Icon css={css(iconMarkStyle)} />
            <Typography variant="subtitle2">{i18n.t(message)}</Typography>
            <div css={css(iconWrapperStyle)}>
                <IconButton onClick={handleDismiss} css={css(buttonStyle)}>
                    <CloseIcon />
                </IconButton>
            </div>
        </Paper>
    );
});

Message.propTypes = {
    variant: PropTypes.string,
    message: PropTypes.string,
    id: PropTypes.number.isRequired
};

export default Message;

const iconMarkStyle = `
    margin-right: 8px;
`;

const buttonStyle = `
    color: #fff;
`;

const iconWrapperStyle = `
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: -8px;
    padding-left: 16px;
`;
