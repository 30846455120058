import React, { forwardRef } from 'react';
import { css } from '@emotion/core';
import i18n from 'i18next';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import RootRef from '@material-ui/core/RootRef';
import { Box, TableHead, Typography } from '@material-ui/core';
import { system050, system900 } from 'features/common/Mui/styles/color';
import LoadingTable from 'features/point/components/table/LoadingTable';
import { dp03 } from 'features/common/Mui/styles/shadow';

export const HOVER_MODE = {
    TRANSPARENT: 1,
    WHITE_BG: 2,
    PAPER: 3
};

/**
 * @method FeverTable - 表格元件
 * @param {Object}   props
 * @param {Boolean}  [props.isAlwaysHideHeader=false]     - 是否隱藏 Header 欄位
 * @param {Boolean}  [props.isFetching=true]              - 是否讀取中
 * @param {Boolean}  [props.isHideHeaderWhenNoData=false] - Header 在沒有資料的時候是否顯示
 * @param {Boolean}  [props.isStickyHeader=false]         - Header 是否置頂
 * @param {Boolean}  [props.isDynamicLoading=false]       - 是否啟用動態加載中
 * @param {Object[]} props.tableConfig                    - 表格設定檔案
 * @param {String}   props.tableConfig[].key              - 對應的資料的 key 也是 map 索引的 key，不可重複
 * @param {String}   props.tableConfig[].style            - body 與 header 的共用樣式
 * @param {String}   props.tableConfig[].headerAlign      - header 欄位內容對齊位置
 * @param {String}   props.tableConfig[].headerCell       - header 欄位內容
 * @param {Object}   props.tableConfig[].headerStyle      - header 欄位樣式
 * @param {String}   props.tableConfig[].bodyAlign        - body 欄位文字內容對齊位置
 * @param {String}   props.tableConfig[].bodyCell         - body 欄位內容
 * @param {Object}   props.tableConfig[].bodyStyle        - body 欄位樣式
 * @param {Object}   props.tableContainerStyle            - TableContainer 樣式
 * @param {Object}   props.tableStyle                     - Table 樣式
 * @param {Object}   props.tableBodyRowStyle              - TBody 底下 TableRow 樣式
 * @param {Number}   [props.loadingRow=5]                 - loading 效果顯示行數
 * @param {Object[]} props.list                           - 表格資料
 * @param {String}   props.list[].id                      - 資料 id 也是 map 索引的 key，不可重複
 * @param {Function} props.onRowClick                     - 點擊列事件
 * @param {Array} props.rowMapKey                         - 列 Map 所使用的 key，會根據陣列內的 key 透過每列資料來組成新的 key
 * @param {Object}   props.onEnableRowClick               - 是否 Enable 列點擊事件 （ cursor 樣式 )
 * @returns {JSX.Element}
 */
function FeverTable(
    {
        isAlwaysHideHeader = false,
        isFetching = true,
        isHideHeaderWhenNoData = false,
        isStickyHeader = false,
        isDynamicLoading = false,
        tableConfig,
        tableContainerStyle,
        tableStyle,
        tableBodyRowStyle,
        loadingRow = 5,
        list = [],
        onRowClick = () => {},
        onEnableRowClick = () => false,
        rowMapKey = [],
        cusOuterStyle,
        hoverMode = ''
    },
    ref
) {
    const isNoData = list.length === 0;
    const mappingRowMapKey = row => {
        let mapKey = '';
        rowMapKey.forEach(key => {
            mapKey += row[key];
        });
        return mapKey;
    };

    return (
        <>
            {isFetching && !isDynamicLoading ? (
                <LoadingTable loadingRow={loadingRow} customTableStyle={tableStyle} />
            ) : (
                <div css={css([outerStyle(isNoData), cusOuterStyle])}>
                    <TableContainer css={[containerStyle, tableContainerStyle]}>
                        <Table css={[tablesStyle, tableStyle]} stickyHeader={isStickyHeader}>
                            {!isAlwaysHideHeader && !(isHideHeaderWhenNoData && list.length === 0) && (
                                <>
                                    <TableHead>
                                        <TableRow>
                                            {tableConfig.map(config => (
                                                <TableCell
                                                    style={{
                                                        ...config.style,
                                                        ...(config.headerStyle
                                                            ? config.headerStyle
                                                            : {})
                                                    }}
                                                    key={config.key}
                                                    {...(config.headerAlign && {
                                                        align: config.headerAlign
                                                    })}
                                                >
                                                    {config.headerCell()}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                </>
                            )}
                            <TableBody>
                                {list.length > 0 &&
                                    list.map((row, index) => {
                                        const enableRowCLick = onEnableRowClick(row);

                                        const tableRow = (
                                            <TableRow
                                                key={
                                                    rowMapKey.length > 0
                                                        ? mappingRowMapKey(row)
                                                        : row.historyUuid ||
                                                          row.entryUuid ||
                                                          row.uuid ||
                                                          row.id ||
                                                          row.sponsorId ||
                                                          row.entryId
                                                }
                                                css={css([
                                                    typeof tableBodyRowStyle === 'function'
                                                        ? tableBodyRowStyle(row)
                                                        : tableBodyRowStyle,
                                                    cursorStyle(enableRowCLick, hoverMode)
                                                ])}
                                                onClick={() => {
                                                    onRowClick(row);
                                                }}
                                            >
                                                {tableConfig.map(config => (
                                                    <TableCell
                                                        key={`${config.key}${row.id}`}
                                                        style={{
                                                            ...config.style,
                                                            ...(config.bodyStyle
                                                                ? config.bodyStyle
                                                                : {})
                                                        }}
                                                        {...(config.bodyAlign && {
                                                            align: config.bodyAlign
                                                        })}
                                                    >
                                                        {config.bodyCell && config.bodyCell(row)}
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        );
                                        return Boolean(ref) && list.length === index + 1 ? (
                                            <RootRef
                                                rootRef={ref}
                                                key={
                                                    rowMapKey.length > 0
                                                        ? mappingRowMapKey(row)
                                                        : row.historyUuid ||
                                                          row.entryUuid ||
                                                          row.uuid ||
                                                          row.id ||
                                                          row.entryId
                                                }
                                            >
                                                {tableRow}
                                            </RootRef>
                                        ) : (
                                            tableRow
                                        );
                                    })}
                            </TableBody>
                        </Table>
                        {isNoData && (
                            <Box css={css(noDataContainerStyle)}>
                                <Typography variant="caption" style={{ color: system900.color }}>
                                    {i18n.t('label.table.no_data')}
                                </Typography>
                            </Box>
                        )}
                        {isDynamicLoading && <LoadingTable loadingRow={loadingRow} />}
                    </TableContainer>
                </div>
            )}
        </>
    );
}

export default forwardRef(FeverTable);

FeverTable.displayName = 'FeverTable';

const outerStyle = isNoData => css`
    ${isNoData &&
    ` & .MuiTableContainer-root {
        overflow-x: hidden;
        min-height: unset;
    }`}
`;

const containerStyle = css`
    min-height: 360px;
`;

const tablesStyle = css`
    min-width: 700px;

    & .MuiTableCell-root {
        padding: 12px 8px;
    }
    & .MuiTableBody-root .MuiTableCell-root {
        border-top: 4px solid ${system050.color};
        border-bottom: none;
    }
`;

const noDataContainerStyle = css`
    text-align: center;
    width: 100%;
    padding: 16px 24px;
`;

const cursorStyle = (enableClick, hoverMode) => css`
    ${enableClick ? 'cursor:pointer;' : 'cursor:auto;'}

    & :hover {
        ${enableClick && hoverMode === HOVER_MODE.WHITE_BG && 'position: relative;'}
        ${enableClick
            ? hoverMode === HOVER_MODE.PAPER || hoverMode === HOVER_MODE.TRANSPARENT
                ? 'box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.2), 0px 3px 4px rgba(0, 0, 0, 0.12),0px 3px 3px rgba(0, 0, 0, 0.14);'
                : hoverMode === HOVER_MODE.WHITE_BG
                ? dp03
                : ''
            : ''}
    }
`;
