import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';

import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';
import { system900, system800 } from 'features/common/Mui/styles/color';
import { mainButtonSolidStyle } from 'features/login/styles/comman';
import { displayTypes } from 'features/login/components/pageEmailVerifyLanding/EmailVerifyLandingUpdateEmail';
import { auth } from 'features/login/utils/firebaseManager';
import { getQueryString } from 'features/login/utils/manipulateUrl';
import { queryStrKeys, verifyEmailTypes } from 'features/login/utils/config';

import { sendSignInLinkToEmail } from 'firebase/auth';
import { setSuccess } from 'store/module/notify';

export default function CheckResendUpdateEmailVerify({ setDisplayType, email }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const handleSendUpdateEmail = async () => {
        try {
            await sendSignInLinkToEmail(auth, email, {
                handleCodeInApp: true,
                url: `${window.location.origin}/app/email_verify_landing${getQueryString({
                    add: {
                        [queryStrKeys.VERIFY_EMAIL_TYPE]: verifyEmailTypes.UPDATE_EMAIL,
                        [queryStrKeys.UPDATE_EMAIL_FROM]: email
                    }
                })}`
            })
                .then(async () => {
                    dispatch(setSuccess(t('label.send_verify_mail')));
                    setDisplayType(displayTypes.RESEND_CONFIRM);
                })
                .catch(error => {
                    console.log('Update Email Failed.', error);
                });
        } catch (error) {
            console.error('FB email verify', error);
        }
    };

    return (
        <div css={containerStyle}>
            <Typography variant="h5" css={titleStyle}>
                {t('label.sponsor.invite_code_invalid')}
            </Typography>
            <Typography variant="subtitle1" css={descStyle}>
                {t('label.update_email_resend_link_invalid')}
            </Typography>
            <div css={btnWrapperStyle}>
                <ButtonWithLoadingEffect
                    css={[mainButtonSolidStyle, btnStyle]}
                    onClick={() => {
                        handleSendUpdateEmail();
                    }}
                >
                    <Typography variant="subtitle2">{t('label.update_email_resend')}</Typography>
                </ButtonWithLoadingEffect>
            </div>
        </div>
    );
}

const containerStyle = css`
    > * {
        letter-spacing: 0.5px;
        text-align: center;
    }
    max-width: 360px;
`;

const titleStyle = css`
    color: ${system900.color};
`;

const descStyle = css`
    margin-top: 24px;
    text-align: left;
    color: ${system800.color};
`;

const btnWrapperStyle = css`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const btnStyle = css`
    width: 216px;
`;
