import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { verifyEmailTypes } from 'features/login/utils/config';
import { auth, firebaseApiErrCodes } from 'features/login/utils/firebaseManager';
import { setVerifyEmailInfo, setInputEmail } from 'store/module/firebase';
import { removeBtnDefaultStyle } from 'styles/common';
import { system800 } from 'features/common/Mui/styles/color';
import {
    mainButtonSolidStyle,
    errMsgWrapperStyle,
    textFieldDefaultStyle
} from 'features/login/styles/comman';
import { generateEmailValidateRegexp, generatePasswordRegexp } from 'features/login/utils/validate';
import useHistoryWithCurrentSearchHash from 'features/login/hooks/useHistoryWithCurrentSearchHash';
import { useRedirectApp, paths } from 'features/login/hooks/useInitAndRedirectApp';
import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';
import { FilledInput, IconButton, InputAdornment } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

export default function SignInWithEmailPassword({ disabled, onLogin = () => {} }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const redirectApp = useRedirectApp();
    const history = useHistoryWithCurrentSearchHash();
    const { currentInputEmail } = useSelector(s => s.firebase);
    const [password, setPassword] = useState('');
    const [emailErrMsg, setEmailErrMsg] = useState('');
    const [passwordErrMsg, setPasswordErrMsg] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isLogining, setIsLogining] = useState(false);

    function validateInput() {
        let valid = true;
        if (!currentInputEmail) {
            valid = false;
            setEmailErrMsg(i18n.t('label.error.required.email'));
        } else if (!generateEmailValidateRegexp().test(currentInputEmail)) {
            valid = false;
            setEmailErrMsg(i18n.t('label.invalid_email'));
        }
        if (!password) {
            valid = false;
            setPasswordErrMsg(i18n.t('label.error.required.password'));
        } else if ([...password].length < 6) {
            valid = false;
            setPasswordErrMsg(i18n.t('label.error.word_limit.password'));
        } else if (!generatePasswordRegexp().test(password)) {
            valid = false;
            setPasswordErrMsg(i18n.t('label.error.invalid_char.password'));
        }
        return valid;
    }
    async function handleEmailLogin() {
        try {
            if (validateInput()) {
                onLogin(true);
                setIsLogining(true);
                await signInWithEmailAndPassword(auth, currentInputEmail, password);
                await redirectApp({ action: 'doLogin' });
            }
        } catch (error) {
            switch (error.code) {
                case firebaseApiErrCodes.AUTH_USER_NOT_FOUND:
                    setEmailErrMsg(i18n.t('label.error.account.wrong'));
                    break;
                case firebaseApiErrCodes.AUTH_WRONG_PASSWORD:
                    setPasswordErrMsg(i18n.t('label.wrong_old_password'));
                    break;
                default:
                    console.error('email login', error);
            }
        } finally {
            setIsLogining(false);
            onLogin(false);
        }
    }
    function handleToForgetPassword() {
        dispatch(
            setVerifyEmailInfo({
                verifyEmailType: verifyEmailTypes.FORGET_PASSWORD
            })
        );
        history.push({ pathname: paths.EMAIL_VERIFY });
    }
    function handleEmailChange({ target: { value } }) {
        dispatch(setInputEmail(value));
        setEmailErrMsg('');
    }
    function handlePasswordChange({ target: { value } }) {
        setPassword(value);
        setPasswordErrMsg('');
    }

    return (
        <div>
            <TextField
                variant="filled"
                fullWidth
                value={currentInputEmail}
                onChange={handleEmailChange}
                placeholder={t('placeholder.input_email')}
                css={textFieldDefaultStyle}
                error={Boolean(emailErrMsg)}
            />
            <div css={errMsgWrapperStyle}>
                {Boolean(emailErrMsg) && <Typography variant="caption">{emailErrMsg}</Typography>}
            </div>
            <FilledInput
                type={showPassword ? 'text' : 'password'}
                fullWidth
                value={password}
                onChange={handlePasswordChange}
                placeholder={t('placehoder.input_password')}
                css={textFieldDefaultStyle}
                error={Boolean(passwordErrMsg)}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => {
                                setShowPassword(c => !c);
                            }}
                            edge="end"
                        >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            <div css={errMsgWrapperStyle}>
                {Boolean(passwordErrMsg) && (
                    <Typography variant="caption">{passwordErrMsg}</Typography>
                )}
            </div>
            <ButtonWithLoadingEffect
                isLoading={isLogining}
                disabled={disabled}
                css={mainButtonSolidStyle}
                onClick={handleEmailLogin}
            >
                <Typography variant="subtitle2">{t('btn.login')}</Typography>
            </ButtonWithLoadingEffect>
            <div css={forgetPasswordBtnWrapperStyle}>
                <button
                    css={[forgetPasswordBtnStyle, removeBtnDefaultStyle]}
                    onClick={handleToForgetPassword}
                >
                    {t('btn.to_forget_password')}
                </button>
            </div>
        </div>
    );
}

const forgetPasswordBtnWrapperStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
`;

const forgetPasswordBtnStyle = css`
    color: ${system800.color};
    font-size: 14px;
`;
