import { useHistory, useLocation } from 'react-router-dom';

export default function useHistoryWithCurrentSearchHash() {
    const history = useHistory();
    const location = useLocation();
    const { search, hash, pathname } = location;

    return {
        original: history,
        push: obj => history.push({ search, hash, pathname, ...obj }),
        replace: obj => history.push({ search, hash, pathname, ...obj })
    };
}
