import { compose, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from './reducers';
import { campaignEditingMiddleware, campaignEditingSyncMiddleware } from './middleware';
import { IS_LOCAL_DEV } from 'config/envVariable';

export default function initStore(initialState) {
    const middleware = [thunk, campaignEditingMiddleware, campaignEditingSyncMiddleware];
    const store = createStore(
        createRootReducer(),
        initialState,
        compose(
            applyMiddleware(...middleware),
            window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
        )
    );
    if (IS_LOCAL_DEV && module.hot) {
        module.hot.accept('./reducers', () => {
            const nextReducer = createRootReducer(store.asyncReducers);
            store.replaceReducer(nextReducer);
        });
    }

    store.asyncReducers = {};
    // Add method to inject reducers dynamically
    store.injectReducer = (key, reducer) => {
        store.asyncReducers[key] = reducer;
        store.replaceReducer(createRootReducer(store.asyncReducers));
    };
    // Remove reducer
    store.removeReducer = key => {
        delete store.asyncReducers[key];
        // Clean up state
        delete store.getState()[key];
        store.replaceReducer(createRootReducer(store.asyncReducers));
    };

    return store;
}
