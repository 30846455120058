import { feverApiFetchOpts, fetches } from 'api/commom';

export function getPartnerSiteConfig() {
    return fetches(`/services/partner/admin/auth/settings`, feverApiFetchOpts());
}

export function getPartner() {
    return fetches(`/services/partner`, feverApiFetchOpts());
}

export function getPartnerSetting() {
    return fetches(`/services/partner/settings`, feverApiFetchOpts());
}

export async function getUserPartner() {
    const [partner, partnerSetting] = await Promise.all([getPartner(), getPartnerSetting()]);
    return {
        ...partner,
        settings: { ...partnerSetting }
    };
}
