import shortid from 'shortid';
import querystring from 'query-string';

import { fetchOpts, fetchWith, methods } from 'api/commom';
import { FEVER_HOST, ZINES_API_ENDPOINT, feverOrigin } from 'config/envVariable';
import { generateLoginUrl } from 'utils/login';
import { signout } from 'features/login/utils/firebaseManager';

const zinesServerFetch = fetchWith(feverOrigin);
const zinesFetch = fetchWith(ZINES_API_ENDPOINT);

const hasPermit = (shouldRedirect = true) => async res => {
    if (res.status === 401) {
        await signout();
        if (shouldRedirect) {
            window.location.href = generateLoginUrl();
        }
    }
    return res;
};

export function getPageListAPI(sponsorId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pageList?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: getPageListAPI`);
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function getPageMetaAPI(sponsorId, pageId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}/meta?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: getPageMetaAPI`);
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function setNewPageAPI(sponsorId) {
    return zinesFetch(`/sponsors/${sponsorId}/pages?host=${FEVER_HOST}`, fetchOpts(methods.POST))
        .then(res => {
            if (res.status >= 400) {
                console.log(`HTTP ${res.status}: setNewPageAPI`);
                return Promise.reject(res);
            }
            return res.json();
        })
        .then(json => json);
}

export function setHomePageAPI(sponsorId, data) {
    return zinesFetch(
        `/sponsors/${sponsorId}/settings/homepage?host=${FEVER_HOST}`,
        fetchOpts(methods.PATCH, data)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: setHomePageAPI`);
            return Promise.reject(res);
        }
        return res.status >= 200;
    });
}

export function setValidateAPI(data) {
    return zinesFetch(
        `/settings/website/username/validate?host=${FEVER_HOST}`,
        fetchOpts(methods.POST, data)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: setValidateAPI`);
            return Promise.reject(res);
        }
        return res.status >= 200;
    });
}

export function getWebsiteAPI(sponsorId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/settings/website?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: getWebsiteAPI`);
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function setWebsiteAPI(sponsorId, data) {
    return zinesFetch(
        `/sponsors/${sponsorId}/settings/website?host=${FEVER_HOST}`,
        fetchOpts(methods.PATCH, data)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: setWebsiteAPI`);
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function getMediaAPI(sponsorId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/settings/media?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: getMediaAPI`);
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function setMediaAPI(sponsorId, data) {
    return zinesFetch(
        `/sponsors/${sponsorId}/settings/media?host=${FEVER_HOST}`,
        fetchOpts(methods.PATCH, data)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: setMediaAPI`);
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function getTrackAPI(sponsorId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/settings/trackcode?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: getTrackAPI`);
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function setTrackAPI(sponsorId, data) {
    return zinesFetch(
        `/sponsors/${sponsorId}/settings/trackcode?host=${FEVER_HOST}`,
        fetchOpts(methods.PATCH, data)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: setTrackAPI`);
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function setRegisterAPI(sponsorId, data) {
    return zinesFetch(
        `/sponsors/${sponsorId}/register?host=${FEVER_HOST}`,
        fetchOpts(methods.PUT, data)
    ).then(res => {
        if (res.status >= 400) {
            console.log(`HTTP ${res.status}: setRegisterAPI`);
            return Promise.reject(res);
        }
        return res.status === 200;
    });
}

export function getLabelsAPI(sponsorId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/labels?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`getLabelsAPI ${res.status}`);
        }
        return res.json();
    });
}

export async function createChannelAPI(sponsorId, labelId, { pageId, channelId, mode }, newPageId) {
    if (mode === 'reassign-page') {
        await deleteChannelAPI({ sponsorId, labelId: channelId });
    }
    let createChannelId;
    let createChannelPageId;
    if (mode === 'reassign-page') {
        createChannelId = channelId;
        createChannelPageId = newPageId;
    } else if (mode === 'reassign-channel') {
        createChannelId = labelId;
        createChannelPageId = pageId;
    } else {
        createChannelId = labelId;
        createChannelPageId = newPageId;
    }
    const tagFeedSection = generateTagFeedSection(createChannelId);
    let thumbnail;
    if (createChannelPageId > 0) {
        thumbnail = '';
        const [, { id: tagFeedSectionId }] = await Promise.all([
            deleteAllLableOfPageApi(sponsorId, createChannelPageId),
            addSectionAPI(createChannelPageId, tagFeedSection)
        ]);
        const { sections, sorting } = await getPageSectionsAndSortingAPI(createChannelPageId);
        const { shouldSort, sorted } = sortChannelPageSections(sections, sorting, tagFeedSectionId);
        if (shouldSort) {
            await updSortingAPI(createChannelPageId, sorted);
        }
    } else {
        const emptyPageId = 5473;
        ({ pageId: createChannelPageId } = await copyTemplatePageAPI(sponsorId, emptyPageId));
        thumbnail =
            'https://s3-ap-southeast-1.amazonaws.com/assets.fevercdn.com/nc-admin/images/cover+photo_category.jpg';
        await addSectionAPI(createChannelPageId, tagFeedSection);
    }
    const result = await zinesFetch(
        `/sponsors/${sponsorId}/channel?host=${FEVER_HOST}`,
        fetchOpts(methods.POST, { id: createChannelId, thumbnail, pageId: createChannelPageId })
    );
    if (result.status >= 400) {
        throw new Error(`createChannelAPI ${result.status}`);
    }
    return result.json();
}

function generateTagFeedSection(labelId) {
    return {
        anchorName: '',
        data: [{ id: labelId }],
        layout: 'normal',
        textColor: '#999999',
        bgColor: '#ffffff',
        bgGradientColor: {
            deg: -135,
            start: 'rgb(13, 77, 185)',
            end: 'rgb(113, 171, 246)'
        },
        bgCoverUrl: '',
        bgMobileUrl: '',
        bgVideoUrl: '',
        coverMask: 'normal',
        bgType: 'color',
        customData: {},
        templateId: 7,
        hidden: false,
        deleted: false,
        type: 'TagFeed',
        _id: shortid.generate()
    };
}

function sortChannelPageSections(sections, sorting, tagFeedSectionId) {
    const { tagFeedIndex, headerIndex } = sections.reduce((obj, { type, id }, index) => {
        if (type === 'Header') {
            obj.headerIndex = index;
        } else if (type === 'TagFeed' && id === tagFeedSectionId) {
            obj.tagFeedIndex = index;
        }
        return obj;
    }, {});
    sorting = [...sorting];
    if (tagFeedIndex === undefined) {
        return { shouldSort: false };
    } else if (headerIndex === undefined) {
        const [tagFeed] = sorting.splice(tagFeedIndex, 1);
        return { shouldSort: true, sorted: [tagFeed, ...sorting] };
    } else if (tagFeedIndex === headerIndex + 1) {
        return { shouldSort: false };
    } else {
        const temp = sorting[headerIndex + 1];
        sorting[headerIndex + 1] = sorting[tagFeedIndex];
        sorting[tagFeedIndex] = temp;
        return { shouldSort: true, sorted: sorting };
    }
}

function getPageSectionsAndSortingAPI(pageId) {
    return zinesFetch(`/page/${pageId}?host=${FEVER_HOST}`, fetchOpts(methods.GET))
        .then(res => {
            if (res.status >= 400) {
                return Promise.reject(`getPageSectionsAndSortingAPI ${res.status}`);
            }
            return res.json();
        })
        .then(({ sections: { data, sorting } }) => ({ sections: data, sorting }));
}

function updSortingAPI(pageId, data) {
    return zinesFetch(
        `/page/${pageId}/sorting?host=${FEVER_HOST}`,
        fetchOpts(methods.PUT, { data })
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`updSortingAPI ${res.status}`);
        }
        return res.json();
    });
}

export function createLabelAPI(sponsorId, text, forChannel = false) {
    return zinesFetch(
        `/sponsors/${sponsorId}/labels?host=${FEVER_HOST}`,
        fetchOpts(methods.POST, { text, hasChannel: forChannel })
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`createLabelAPI ${res.status}`);
        }
        return res.json();
    });
}

export function setLabelAPI(sponsorId, labelId, text) {
    return zinesFetch(
        `/sponsors/${sponsorId}/labels/${labelId}?host=${FEVER_HOST}`,
        fetchOpts(methods.PATCH, { text })
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`setLabelAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function deleteLabelAPI(sponsorId, labelId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/labels/${labelId}?host=${FEVER_HOST}`,
        fetchOpts(methods.DELETE)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`deleteLabelAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function getChannelOfPageAPI(sponsorId, pageId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}/channel?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`getChannelOfPageAPI ${res.status}`);
        }
        return res.json();
    });
}

export function getLabelsOfPageAPI(sponsorId, pageId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}/labels?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`getLabelsAPI ${res.status}`);
        }
        return res.json();
    });
}

export function setLabelOfPageAPI(sponsorId, pageId, status) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}/labels?host=${FEVER_HOST}`,
        fetchOpts(methods.PATCH, status)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`setLabelOfPageAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function deleteAllLableOfPageApi(sponsorId, pageId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}/labels?host=${FEVER_HOST}`,
        fetchOpts(methods.DELETE)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`deleteAllLableOfPageApi ${res.status}`);
        }
        return res.status === 200;
    });
}

export function deleteLabelOfPageAPI(sponsorId, pageId, labelId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}/labels/${labelId}?host=${FEVER_HOST}`,
        fetchOpts(methods.DELETE, { checked: false })
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`deleteLabelOfPageAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function setChannelOfPageAPI(sponsorId, pageId, id) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}/channel/${id}?host=${FEVER_HOST}`,
        fetchOpts(methods.POST)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`setChannelOfPageAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function getPagesAPI(
    sponsorId,
    {
        _limit = 10,
        _page = 1,
        _keyword = undefined,
        _label = undefined,
        _pageId = undefined,
        replaceQs = ''
    }
) {
    const commonQs = querystring.stringify({
        host: FEVER_HOST
    });
    const oldQsFormat = querystring.stringify({
        _limit,
        _page,
        _keyword,
        _label,
        _pageId
    });

    const composedQs = replaceQs ? `${replaceQs}&${commonQs}` : `${oldQsFormat}&${commonQs}`;
    return zinesFetch(`/sponsors/${sponsorId}/pages?${composedQs}`, fetchOpts(methods.GET)).then(
        res => {
            if (res.status >= 400) {
                return Promise.reject(`getPageListAPI ${res.status}`);
            }
            return res.json();
        }
    );
}

export function createPageAPI(sponsorId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages?host=${FEVER_HOST}`,
        fetchOpts(methods.POST)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`createPageAPI ${res.status}`);
        }
        return res.json();
    });
}

export function setPageAPI(sponsorId, pageId, queries) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}?host=${FEVER_HOST}`,
        fetchOpts(methods.PATCH, queries)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`setPageAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function deletePageAPI(sponsorId, pageId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/pages/${pageId}?host=${FEVER_HOST}`,
        fetchOpts(methods.DELETE)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`deletePageAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function addSectionAPI(pageId, setting) {
    return zinesFetch(
        `/page/${pageId}/section?host=${FEVER_HOST}`,
        fetchOpts(methods.POST, setting)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function getPageAPI(pageId, query = {}, setting) {
    return zinesFetch(
        `/page/${pageId}?${querystring.stringify({ ...query, host: FEVER_HOST })}`,
        fetchOpts(methods.GET, setting)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(res);
        }
        return res.json();
    });
}

export function copyPageAPI(sponsorId, pageId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/copyPage/${pageId}?host=${FEVER_HOST}`,
        fetchOpts(methods.POST)
    )
        .then(res => res.json())
        .catch(err => {
            console.log(`HTTP ${err.status}: copyPageAPI`);
            return { msg: 'fetchFail', data: { status: err.status, rawData: err } };
        });
}

export function clearZinesPageCacheApi({ sponsorId, pageId, feDomain, beDomain, uniqueName }) {
    return zinesServerFetch(
        `/zapi/sponsors/${sponsorId}/pages/${pageId}/cache/clear`,
        fetchOpts(methods.POST, { feDomain, beDomain, uniqueName })
    );
}

export function clearZinesSponsorCacheApi({ sponsorId, feDomain, beDomain, uniqueName }) {
    return zinesServerFetch(
        `/zapi/sponsors/${sponsorId}/cache/clear`,
        fetchOpts(methods.POST, { feDomain, beDomain, uniqueName })
    );
}

export function getChannelsAPI(sponsorId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/channels?host=${FEVER_HOST}`,
        fetchOpts(methods.GET)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`getChannelsAPI ${res.status}`);
        }
        return res.json();
    });
}

export function deleteChannelAPI({ sponsorId, labelId }) {
    return zinesFetch(
        `/sponsors/${sponsorId}/channel/${labelId}?host=${FEVER_HOST}`,
        fetchOpts(methods.DELETE)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`deleteChannelAPI ${res.status}`);
        }
        return res.json();
    });
}

export function getTemplatesAPI(sponsorId, defaultTemp = false) {
    if (defaultTemp) {
        let api = `https://s3-ap-southeast-1.amazonaws.com/assets.fevercdn.com/config/zines-admin/system-template.json`;
        if (ZINES_API_ENDPOINT === `https://2017-api.zines.cc`) {
            api = `https://s3-ap-southeast-1.amazonaws.com/assets.fevercdn.com/config/zines-admin/system-template-dev.json`;
        }
        return fetch(api)
            .then(hasPermit())
            .then(res => {
                if (res.status >= 400) {
                    return Promise.reject(`getTemplatesAPI Default ${res.status}`);
                }
                return res.json();
            });
    } else {
        return zinesFetch(
            `/sponsors/${sponsorId}/templates?host=${FEVER_HOST}`,
            fetchOpts(methods.GET)
        ).then(res => {
            if (res.status >= 400) {
                return Promise.reject(`getTemplatesAPI ${res.status}`);
            }
            return res.json();
        });
    }
}

export function createTemplateAPI({ sponsorId, pageId, setting }) {
    return zinesFetch(
        `/sponsors/${sponsorId}/template/${pageId}?host=${FEVER_HOST}`,
        fetchOpts(methods.POST, setting)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`createTemplateAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function updateTemplateAPI({ sponsorId, pageId, setting }) {
    return zinesFetch(
        `/sponsors/${sponsorId}/template/${pageId}?host=${FEVER_HOST}`,
        fetchOpts(methods.PATCH, setting)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`createTemplateAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function deleteTemplateAPI({ sponsorId, pageId }) {
    return zinesFetch(
        `/sponsors/${sponsorId}/template/${pageId}?host=${FEVER_HOST}`,
        fetchOpts(methods.DELETE)
    ).then(res => {
        if (res.status >= 400) {
            return Promise.reject(`deleteTemplateAPI ${res.status}`);
        }
        return res.status === 200;
    });
}

export function copyTemplatePageAPI(sponsorId, pageId) {
    return zinesFetch(
        `/sponsors/${sponsorId}/copyTemplatePage/${pageId}?host=${FEVER_HOST}`,
        fetchOpts(methods.POST)
    )
        .then(res => {
            const returnObj = res.json();
            return returnObj;
        })
        .catch(err => {
            console.log(`HTTP ${err.status}: copyPageAPI`);
            return { msg: 'fetchFail', data: { status: err.status, rawData: err } };
        });
}
