import i18next from 'i18next';
import _mergeWith from 'lodash/mergeWith';
import { produce } from 'immer';

export function isObject(item) {
    return Object(item) === item;
}

export function isJSONParsable(str) {
    return typeof str === 'string' && (str[0] === '[' || str[0] === '{');
}

export function objFirstLevelJSONStringify(obj = {}) {
    return Object.entries(obj).reduce((newObj, [key, val]) => {
        val = isObject(val) ? JSON.stringify(val) : val;
        newObj[key] = val;

        return newObj;
    }, {});
}

export function objFirstLevelJSONParse(obj = {}) {
    return Object.entries(obj).reduce((newObj, [key, val]) => {
        val = isJSONParsable(val) ? JSON.parse(val) : val;
        newObj[key] = val;

        return newObj;
    }, {});
}

export const reduxObjKeysUpdateActions = {
    MERGE: 'merge',
    REPLACE: 'replace'
};
export function updateReduxObjKeyHandleDelete(
    currentState,
    newState,
    updateAction = reduxObjKeysUpdateActions.MERGE
) {
    const currentDeletedKeyArray = currentState?.deleted ? [...currentState.deleted] : [];
    const newDeletedKeyArray = currentDeletedKeyArray.filter(
        deletedKey => newState[deletedKey] === undefined
    );
    const nextState =
        updateAction === reduxObjKeysUpdateActions.MERGE
            ? _mergeWith(
                  {},
                  currentState,
                  newState,
                  { deleted: newDeletedKeyArray },
                  (_, srcVal) => {
                      if (Array.isArray(srcVal)) {
                          return srcVal;
                      }
                  }
              )
            : { ...currentState, ...newState, deleted: newDeletedKeyArray };

    return nextState;
}

/**
 * Deletes specified keys from a nested Redux state object and tracks deleted keys
 * in a deleted array at the parent level.
 *
 * @param {Object} state - The initial state object
 * @param {string[]} deleted - Array of dot-notation paths to delete
 * @returns {Object} New state with deleted keys removed and tracked in deleted array
 */
export function deleteReduxObjKey(state = {}, deleted = []) {
    return produce(state, draft => {
        // Handle non-nested keys
        const nonNestedKeys = deleted.filter(key => !key.includes('.'));
        nonNestedKeys.forEach(key => {
            if (draft[key] !== undefined) {
                delete draft[key];
                if (!draft.deleted) {
                    draft.deleted = [];
                }
                if (!draft.deleted.includes(key)) {
                    draft.deleted.push(key);
                }
            }
        });

        // Group deletions by parent path
        const deletionsByParent = {};

        deleted.forEach(keyPath => {
            const pathSegments = keyPath.split('.');
            const parentPath = pathSegments.slice(0, -1).join('.');
            const keyToDelete = pathSegments[pathSegments.length - 1];

            if (!deletionsByParent[parentPath]) {
                deletionsByParent[parentPath] = [];
            }
            deletionsByParent[parentPath].push(keyToDelete);
        });

        // Process each parent path
        Object.entries(deletionsByParent).forEach(([parentPath, keysToDelete]) => {
            // Navigate to parent object
            let current = draft;
            const segments = parentPath.split('.');

            for (const segment of segments) {
                if (!current[segment]) return;
                current = current[segment];
            }

            // Initialize deleted array if needed
            if (!Array.isArray(current.deleted)) {
                current.deleted = [];
            }

            // Delete keys and add to deleted array
            keysToDelete.forEach(key => {
                if (current[key] !== undefined) {
                    delete current[key];
                    if (!current.deleted.includes(key)) {
                        current.deleted.push(key);
                    }
                }
            });
        });
    });
}

export function mergeAndRemoveKey(original, target) {
    const { deleted = [], ...restTarget } = target;
    const copyOriginal = { ...original };
    if (deleted.length > 0) {
        for (const key of deleted) {
            delete copyOriginal[key];
        }
    }
    return {
        ...copyOriginal,
        ...restTarget
    };
}

export const convertMappingToOptions = (mapping, labels, renders = {}, ...rest) => {
    return Object.values(mapping).map(value => {
        const option = {
            id: value,
            label: i18next.t(labels[value]),
            ...rest
        };

        if (renders[value]) {
            option.render = renders[value];
        }

        return option;
    });
};

/**
 * Checks if a key matches any of the specified prefixes or suffixes.
 * @param {string} key - The key to check.
 * @param {Object} options - Configuration for prefixes and suffixes.
 * @param {string[]} [options.prefixes=[]] - Array of prefixes to match.
 * @param {string[]} [options.suffixes=[]] - Array of suffixes to match.
 * @returns {boolean} - Returns `true` if the key matches a prefix or suffix, otherwise `false`.
 */
export const matchKey = (key, { prefixes = [], suffixes = [] }) =>
    prefixes.some(prefix => key.startsWith(prefix)) ||
    suffixes.some(suffix => key.endsWith(suffix));

/**
 * Converts a string value to a specified type if the key matches prefixes or suffixes.
 * @param {string} key - The key to check for matching prefixes or suffixes.
 * @param {any} value - The value associated with the key.
 * @param {Object} options - Configuration for type conversion.
 * @param {string[]} [options.prefixes=['enable']] - Array of prefixes to match.
 * @param {string[]} [options.suffixes=['Id', 'id']] - Array of suffixes to match.
 * @param {Function} [options.Constructor=Number] - Constructor function for type conversion (default is `Number`).
 * @returns {Object} - Returns an object with the key and the converted (or original) value.
 */
export const typeConversion = (
    key,
    value,
    { prefixes = ['enable'], suffixes = ['Id', 'id'], Constructor = Number }
) => {
    if (typeof value === 'string' && matchKey(key, { prefixes, suffixes })) {
        const convertedValue = Constructor(value);
        return !isNaN(convertedValue) ? { [key]: convertedValue } : { [key]: value };
    }
    return { [key]: value };
};

/**
 * Transforms an API response by converting stringified numeric values to their respective types.
 * @param {Object} [apiResp={}] - The API response object to transform.
 * @param {Object} [options={}] - Configuration for key matching and type conversion.
 * @param {string[]} [options.prefixes=['enable']] - Array of prefixes to match.
 * @param {string[]} [options.suffixes=['Id', 'id']] - Array of suffixes to match.
 * @param {Function} [options.Constructor=Number] - Constructor function for type conversion (default is `Number`).
 * @returns {Object} - Returns a new object with transformed values.
 */
export const transformApiResponse = (apiResp = {}, options = {}) => {
    const transform = obj =>
        Object.entries(obj).reduce((acc, [key, value]) => {
            acc[key] =
                typeof value === 'object' && value !== null
                    ? transform(value)
                    : typeConversion(key, value, options)[key];
            return acc;
        }, {});
    return transform(apiResp);
};

/**
 * Reverses an object's keys and values using Object.entries() and Object.fromEntries().
 * Best for small objects and when code readability is priority.
 *
 * @template K, V
 * @param {Record<K, V>} obj - The object to reverse
 * @param {ReverseOptions} [options] - Configuration options
 * @returns {Record<V, K>} A new object with reversed key-value pairs
 * @throws {TypeError} If the input is not an object
 *
 * @example
 * // Basic usage
 * const original = { a: 1, b: 2, c: 3 };
 * reverseObjectEntries(original);
 * // Returns: { '1': 'a', '2': 'b', '3': 'c' }
 *
 * @example
 * // Handling duplicate values
 * const withDupes = { a: 1, b: 1, c: 2 };
 * reverseObjectEntries(withDupes, { handleDuplicates: true });
 * // Returns: { '1': ['a', 'b'], '2': 'c' }
 */
export function reverseObjectEntries(obj, options = {}) {
    if (!obj || typeof obj !== 'object') {
        throw new TypeError('Input must be an object');
    }

    const entries = Object.entries(obj);

    if (options.handleDuplicates) {
        return entries.reduce((acc, [key, value]) => {
            if (acc[value]) {
                acc[value] = Array.isArray(acc[value]) ? [...acc[value], key] : [acc[value], key];
            } else {
                acc[value] = key;
            }
            return acc;
        }, {});
    }

    return Object.fromEntries(entries.map(([key, value]) => [value, key]));
}
