import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';

import { system900, system800, system700 } from 'features/common/Mui/styles/color';

export default function ConfirmVerifyEmailSended({ email }) {
    const { t } = useTranslation();
    return (
        <div css={containerStyle}>
            <Typography variant="h5" css={titleStyle}>
                {t('label.get_ur_validate_mail')}
            </Typography>
            <Typography variant="subtitle1" css={descStyle}>
                {t('desc.get_ur_validate_mail.instruction', { email })}
            </Typography>
            <Typography variant="subtitle2" css={subDescStyle}>
                {t('desc.get_ur_validate_mail.time_limit')}
            </Typography>
            <Typography variant="subtitle2" css={helpTitleStyle}>
                {t('desc.get_ur_validate_mail.help')}
            </Typography>
            <Typography variant="subtitle2" css={helpDescStyle}>
                {t('desc.login.check_email_and_connect_to_us')}
            </Typography>
        </div>
    );
}

const containerStyle = css`
    > * {
        text-align: center;
        letter-spacing: 0.5px;
    }
`;

const titleStyle = css`
    color: ${system900.color};
`;

const descStyle = css`
    margin-top: 24px;
    font-weight: 500;
    color: ${system800.color};
`;

const subDescStyle = css`
    margin-top: 16px;
    color: ${system700.color};
`;

const helpTitleStyle = css`
    margin-top: 96px;
    color: ${system700.color};
`;

const helpDescStyle = css`
    color: ${system700.color};
`;
