import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';
import Dialog from 'features/common/Mui/Dialog';

export default function DialogWrongLogin({ platform = '-', open, onClose = () => {} }) {
    const { t } = useTranslation();

    return (
        <Dialog
            open={open}
            title={t('label.login.wrong')}
            color="primary"
            endButtonText={t('button.label.confirm')}
            startButton={null}
            onSuccess={onClose}
            disableContentPadding
            disableBackdropClick
        >
            <div css={contentStyle}>
                <Typography variant="subtitle2">
                    {t('desc.login.wrong', {
                        platform
                    })}
                </Typography>
            </div>
        </Dialog>
    );
}

const contentStyle = css`
    width: 340px;
    height: 72px;
    box-sizing: border-box;
    padding: 16px;
`;
