import React from 'react';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';

export default function DivederWithText({ style, children }) {
    return (
        <div css={[containerStyle, css(style)]}>
            <div className="fever-divider" />
            <div className="fever-divider-text">
                <Typography variant="body2">{children}</Typography>
            </div>
        </div>
    );
}

const containerStyle = css`
    position: relative;
    display: flex;
    justify-content: center;
    > .fever-divider-text {
        position: relative;
        z-index: 1;
        background-color: #fff;
        color: #b6b6b6;
        padding: 0 8px;
    }
    > .fever-divider {
        position: absolute;
        z-index: 0;
        border: 0.5px solid rgba(0, 0, 0, 0.12);
        top: calc(50% - 1px);
        left: 0;
        width: 100%;
    }
`;
