import { setMgmStateInited } from './flags';
const INIT_MGM_INFO = 'promo/INIT_MGM_INFO';
const defaultState = {};

export default function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case INIT_MGM_INFO:
            return { ...payload };
        default:
            return state;
    }
}

export function initMgmRelationInfo(data) {
    return async dispatch => {
        dispatch({
            type: INIT_MGM_INFO,
            payload: { ...data }
        });
        dispatch(setMgmStateInited(data));
    };
}
