import { START_PUBLISHED_EDITING, CANCEL_PUBLISHED_EDITING } from 'store/module/campaignOnsite';

export const campaignEditingSyncMiddleware = store => next => action => {
    const result = next(action);

    if (action.type === START_PUBLISHED_EDITING || action.type === CANCEL_PUBLISHED_EDITING) {
        const state = store.getState();
        const campaignEditing = state.campaignOnsite.isEditing;
        const messageEditing = state.message.isEditing;

        if (campaignEditing !== messageEditing) {
            store.dispatch({
                type: action.type
            });
        }
    }

    return result;
};
