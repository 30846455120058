import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';
import useUsableFirebaseProviders from 'features/login/hooks/useUsableFirebaseProviders';
import Panel from 'features/login/components/Panel';
import LayoutOuter from 'features/login/components/LayoutOuter';
import DivederWithText from 'features/login/components/DivederWithText';
import SignInWithEmailPassword from 'features/login/components/pageLogin/SignInWithEmailPassword';
import ToSignupBlock from 'features/login/components/pageLogin/ToSignupBlock';
import PolicyBlock from 'features/login/components/PolicyBlock';
import ThirdPartyLoginBlock from '../ThirdPartyLoginBlock';
import Cookies from 'js-cookie';
import LoadingAnimation from 'features/common/LoadingAnimation';

export default function PageSignin() {
    const { t } = useTranslation();
    const showProvider = useUsableFirebaseProviders();
    const [isLogining, setIsLogining] = useState(false);
    const showDivider =
        showProvider.EMAIL_PASS &&
        (showProvider.FB || showProvider.GOOGLE || showProvider.LINE || showProvider.MICROSOFT);
    const lastLoginHost = Cookies.get('last_login_host') || '';
    const isSiteLogin = window.location.pathname === '/app/site/login';
    const isNeedRedirect = isSiteLogin && Boolean(lastLoginHost);

    if (isNeedRedirect) {
        // 如果網址是透過 /app/site/login 進入登入頁面且 Cookie 有上次登入紀錄就進行導轉回 /app/login
        window.location.href = `https://${lastLoginHost}/app/login${window.location.search}`;
    }

    return isNeedRedirect ? (
        <LoadingAnimation></LoadingAnimation>
    ) : (
        <LayoutOuter>
            <Panel style={panelStyle}>
                <Typography css={titleStyle} variant="h6">
                    {t('label.login')}
                </Typography>
                <ThirdPartyLoginBlock />
                {showDivider && (
                    <DivederWithText style={`margin: 16px 0;`}>
                        {t('label.divider_or')}
                    </DivederWithText>
                )}
                {showProvider.EMAIL_PASS && (
                    <SignInWithEmailPassword disabled={isLogining} onLogin={setIsLogining} />
                )}
                <PolicyBlock style={`margin-top: ${showProvider.EMAIL_PASS ? 8 : 16}px;`} />
                <ToSignupBlock />
            </Panel>
        </LayoutOuter>
    );
}

const titleStyle = css`
    text-align: center;
    margin: 16px 0 24px;
`;

const panelStyle = css`
    padding: 16px 24px;
`;
