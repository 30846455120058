import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';
import useUsableFirebaseProviders from 'features/login/hooks/useUsableFirebaseProviders';
import Panel from 'features/login/components/Panel';
import LayoutOuter from 'features/login/components/LayoutOuter';
import SignupWithEmail from 'features/login/components/pageSignup/SignupWithEmail';
import DivederWithText from 'features/login/components/DivederWithText';
import PolicyBlock from 'features/login/components/PolicyBlock';
import ToSigninBlock from 'features/login/components/pageSignup/ToSigninBlock';
import ThirdPartyLoginBlock from '../ThirdPartyLoginBlock';

export default function PageSignup() {
    const { t } = useTranslation();
    const showProvider = useUsableFirebaseProviders();
    const showDivider =
        showProvider.EMAIL_PASS &&
        (showProvider.FB || showProvider.GOOGLE || showProvider.LINE || showProvider.MICROSOFT);

    return (
        <LayoutOuter>
            <Panel>
                <Typography css={titleStyle} variant="h6">
                    {t('label.account_register')}
                </Typography>
                {showProvider.EMAIL_PASS && <SignupWithEmail />}
                {showDivider && (
                    <DivederWithText style={`margin: 16px 0;`}>
                        {t('label.divider_or')}
                    </DivederWithText>
                )}
                <ThirdPartyLoginBlock />
                <PolicyBlock style={`margin-top: 24px;`} />
                <ToSigninBlock />
            </Panel>
        </LayoutOuter>
    );
}

const titleStyle = css`
    text-align: center;
    margin: 16px 0 32px;
`;
