import { css } from '@emotion/core';
import { above } from 'utils/breakpoint';
import { error } from 'features/common/Mui/styles/color';

export const mainBtnStyle = css`
    width: 100%;
    border-radius: 4px;
    padding: 10px 16px;
    ${above('xlg')} {
        padding: 14px 16px;
    }
`;

export const mainButtonSolidStyle = css`
    ${mainBtnStyle}
    background-color: #df3038;
    color: #fff;
    &.Mui-disabled {
        background-color: #f3b4b7;
        color: #fff;
        &:hover {
            background-color: #f3b4b7;
        }
    }
    &:hover {
        background-color: #df3038;
        color: #fff;
    }
`;

export const mainButtonHollowStyle = css`
    ${mainBtnStyle}
    margin-top: 16px;
    border-color: #e55b61;
    color: #e55b61;
`;

export const textFieldDefaultStyle = css`
    & .MuiFilledInput-input {
        padding: 14.5px 16px;
    }
`;

export const autocompleteDefaultStyle = css`
    .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] .MuiAutocomplete-input {
        padding: 0px;
    }

    & .MuiAutocomplete-inputRoot[class*='MuiFilledInput-root'] {
        padding: 14.5px 16px;
    }
`;

export const autocompleteOutlineStyle = css`
    & .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding: 0 0 0 12px;
    }

    & .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input {
        padding: 14.5px 0;
    }

    &.MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon
        .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] {
        padding-right: 64px;
    }

    &.MuiAutocomplete-root {
        width: 100%;
    }

    & .MuiAutocomplete-endAdornment {
        top: auto;
    }

    & .MuiAutocomplete-clearIndicator {
        padding: 2px;
    }

    & .MuiAutocomplete-popupIndicator {
        padding: 0px;
    }
`;

export const errMsgWrapperStyle = css`
    padding-left: 16px;
    height: 26px;
    color: #dd2025;
    display: flex;
    align-items: center;
`;

export const helperTextStyle = isError => css`
    margin-left: 16px;
    color: ${isError ? error.color : 'rgba(0, 0, 0, 0.54)'};
`;
