import * as Sentry from '@sentry/react';
import {
    IS_DEV,
    IS_LIVE_PROD,
    IS_LOCAL_DEV,
    SENTRY_REACT_DSN,
    SENTRY_TRACES_SAMPLE_RATE
} from 'config/envVariable';
import { isNetworkResourceError } from 'utils/errorObjectChecker';

export function initErrorLogService() {
    if ((IS_LIVE_PROD || IS_DEV) && !IS_LOCAL_DEV) {
        const DEFAULT_TRACES_SAMPLE_RATE = 0.00001;
        const tracesSampleRate = isNaN(parseFloat(SENTRY_TRACES_SAMPLE_RATE))
            ? DEFAULT_TRACES_SAMPLE_RATE
            : parseFloat(SENTRY_TRACES_SAMPLE_RATE) || DEFAULT_TRACES_SAMPLE_RATE;

        Sentry.init({
            dsn: SENTRY_REACT_DSN,
            normalizeDepth: 8,
            maxBreadcrumbs: 80,
            environment: IS_DEV ? 'dev' : 'production',
            integrations(integrations) {
                return [
                    ...integrations.filter(i => i.name !== 'GlobalHandlers'),
                    ...(IS_LIVE_PROD ? [Sentry.browserTracingIntegration()] : [])
                ];
            },
            tracesSampleRate,
            tracePropagationTargets: [
                'localhost',
                /^https:\/\/(?:[a-zA-Z0-9-]+\.)?feversocial\.com/
            ],
            beforeSend(event, hint) {
                const { originalException = {}, captureContext: { extra = {} } = {} } = hint;

                if (isNetworkResourceError(originalException) || isNetworkResourceError(extra)) {
                    return null;
                }

                return event;
            }
        });
    }
}

export function sentryCaptureException(err) {
    try {
        const options = { extra: { ...(err.extraInfo ?? {}), deviceDate: new Date().toString() } };
        return Sentry.captureException(err, options);
    } catch (error) {
        console.error('Error on sentry capture:', error);
    }
}
