/**
 * 將數字標上千分位符號
 * @param {number} num 要轉換的數字
 * @returns
 */
export const numberWithCommas = num => {
    try {
        return new Intl.NumberFormat().format(Math.abs(num));
    } catch (e) {
        console.error('numberWithCommas failed:', e);
        return num;
    }
};

/**
 * 四捨五入取得小數點某位數
 * @param {number} num 要轉換的數字
 * @param {number} position 取到第幾位數
 * @returns
 */
export const getDecimalPointNumber = (num, position = 2) => {
    if (typeof num !== 'number') return num;
    return +(Math.round(num + `e+${position}`) + `e-${position}`);
};

/**
 * 兩數字相除後四捨五入轉換成百分比至小數點某位數
 * @param {number} numerator 分子
 * @param {number} denominator 分母
 * @param {number} position 小數點取到第幾位數
 * @returns
 */
export const dividedPercentNumber = (numerator, denominator, position = 2) => {
    if (typeof numerator !== 'number' || typeof denominator !== 'number') return;

    return (
        Math.round(
            parseFloat((numerator / denominator).toPrecision(12)) *
                100 *
                Number(`1${'0'.repeat(position)}`)
        ) / Number(`1${'0'.repeat(position)}`)
    );
};

// Function to calculate the absolute difference in milliseconds between two dates
export const getDifference = (date1, date2) => Math.abs(date1 - date2);
