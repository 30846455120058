import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _maxBy from 'lodash/maxBy';
import dayjs from 'dayjs';
import { planTypes } from 'config/planKeys';
import {
    utc2TimezoneDTStr,
    getLocalTimezone,
    getDateTimeStr,
    getDayRange,
    isBefore,
    UTC_toLocalTime
} from 'utils/dateTime';
import { getDifference } from 'utils/math';

// 方案到期日期
const EXPIRED_DATE_KEY = 'endDate';

function getLatestExpiredYearPlanRemainingDays(yearPlans) {
    if (yearPlans.length === 0) return -1;

    const TIME_ZONE = getLocalTimezone();
    const latestExpiredPlan = _maxBy(yearPlans, EXPIRED_DATE_KEY);
    const latestExpiredDate = (() => {
        const utcTimeStamp = new Date(
            `${latestExpiredPlan[EXPIRED_DATE_KEY].replace(/\s/, 'T')}Z`
        ).getTime();

        return utc2TimezoneDTStr(utcTimeStamp, TIME_ZONE);
    })();
    const currentDate = getDateTimeStr(Date());

    return getDayRange(latestExpiredDate, currentDate);
}

// 年約
export const getIsYearPlanOrder = plan => !plan?.totalPromotionLimit;

// 單檔
export const getIsSinglePlanOrder = plan => plan?.totalPromotionLimit;

// 輕用量活動方案
export const getLightUsagePlanOrder = plan =>
    Boolean(plan?.totalPromotionLimit) && plan?.totalPromotionLimit !== 1;

export const isNotExpired = plan => isBefore(new Date(), UTC_toLocalTime(plan.endDate));

export const isNotPointPlan = plan => plan.planGroup !== planTypes.POINT_PLAN;

export const isPointPlan = plan => plan.planGroup === planTypes.POINT_PLAN;

// 判斷是否是人次限定方案
const checkHasTotalEntryLimitMonthlyPlan = planInfo => Boolean(planInfo?.entryLimit);

// 判斷是否是人次限定方案，而且人次數已用完 (limiter 等於 0)
const isEntryFullyPlan = planInfo => {
    if (!checkHasTotalEntryLimitMonthlyPlan(planInfo)) {
        return false;
    }
    const { limiter = 0 } = planInfo.entryLimit;
    return limiter === 0;
};

export function useSelectEffectiveOrders() {
    // Redux plans 已過濾有 deletedAt 的方案
    const plans = useSelector(s => s.sponsor.plans);
    const orders = useMemo(() => plans || [], [plans]);
    const effectiveOrders = useMemo(() => orders.filter(order => isNotExpired(order)), [orders]);
    return effectiveOrders;
}

/**
 * 方案篩選
 * @property {array} effectiveOrders 未過期且未被終止的方案
 * @property {array} yearPlans 年約方案
 * @property {array} lightUsagePlans 輕用量方案
 * @property {object} newestYearOrLightUsagePlan 年約及輕用量方案中最新一筆的方案
 * @returns
 */
export function useGeneratePlans() {
    const effectiveOrders = useSelectEffectiveOrders();
    const yearPlans = effectiveOrders.filter(plan => getIsYearPlanOrder(plan));
    const lightUsagePlans = effectiveOrders.filter(plan => getLightUsagePlanOrder(plan));
    const yearAndLightUsagePlans = effectiveOrders.filter(
        plan => getIsYearPlanOrder(plan) || getLightUsagePlanOrder(plan)
    );

    return {
        effectiveOrders,
        yearPlans,
        lightUsagePlans,
        newestYearOrLightUsagePlan: yearAndLightUsagePlans[0] || {}
    };
}

export default function useSponsorPlanStatus() {
    const {
        yearPlans,
        lightUsagePlans,
        effectiveOrders,
        newestYearOrLightUsagePlan
    } = useGeneratePlans();
    const hasTotalEntryLimitMonthlyPlan = checkHasTotalEntryLimitMonthlyPlan(
        newestYearOrLightUsagePlan
    );
    const hasAlreadyFullyEntryPlan = isEntryFullyPlan(newestYearOrLightUsagePlan);
    const { isCounting } = usePlanEntryLimitInfo(newestYearOrLightUsagePlan);
    const latestExpiredPlanRemainingDays = useMemo(
        () => getLatestExpiredYearPlanRemainingDays(yearPlans),
        [yearPlans]
    );
    const isYearPlanLessThanOrEqualToNinetyDays =
        yearPlans.length > 0 && latestExpiredPlanRemainingDays <= 90;
    const hasPlan = effectiveOrders.length > 0;
    const hasYearPlan = yearPlans.length > 0;
    const hasLightUsagePlan = lightUsagePlans.length > 0;

    return {
        isTotalEntryLimitMonthlyPlanCounting: isCounting,
        hasPlan,
        hasYearPlan,
        hasLightUsagePlan,
        hasTotalEntryLimitMonthlyPlan,
        hasAlreadyFullyEntryPlan,
        latestExpiredPlanRemainingDays,
        isYearPlanLessThanOrEqualToNinetyDays
    };
}

export function usePlanEntryLimitInfo(planInfo) {
    if (!checkHasTotalEntryLimitMonthlyPlan(planInfo)) {
        return {
            isEntryLimitPlan: false,
            isEntryFully: false,
            currentUsedEntry: 0,
            currentUsedEntryPercent: 0,
            monthlyCount: 0
        };
    }
    // limiter: remaining available entry (剩餘次數)
    // monthly: maximum available entry per month (總數)
    const { limiter, monthly = 0 } = planInfo.entryLimit;

    // 若快取被清除，limiter 會變成 null
    if (limiter === null) {
        return {
            isCounting: true,
            isEntryLimitPlan: true,
            isEntryFully: false,
            currentUsedEntry: '-',
            currentUsedEntryPercent: 0,
            monthlyCount: monthly
        };
    }

    const currentUsedEntry = monthly - limiter;
    const currentUsedEntryPercent = Number(((currentUsedEntry / monthly) * 100).toFixed(2));
    const isEntryFully = limiter === 0;

    return {
        isEntryLimitPlan: true,
        isEntryFully,
        currentUsedEntry,
        currentUsedEntryPercent,
        monthlyCount: monthly
    };
}

export function useGetSponsorTotalEntryLimitMonthlyPlanInfo() {
    const plans = useSelector(s => s.sponsor.plans);
    const memoPlans = useMemo(() => plans || [], [plans]);
    const availYearPlans = useMemo(
        () => memoPlans.filter(plan => isNotExpired(plan)).filter(plan => getIsYearPlanOrder(plan)),

        [memoPlans]
    );
    const totalEntryLimitMonthlyPlan =
        availYearPlans.find(checkHasTotalEntryLimitMonthlyPlan) ?? {};

    return usePlanEntryLimitInfo(totalEntryLimitMonthlyPlan);
}

export function retrieveCurrentActivePlan(plans = [], type = planTypes.PROMO_PLAN) {
    const calculatePlanTimesDifference = currentDate => plan => {
        const startDate = dayjs(plan.startDate);
        const endDate = dayjs(plan.endDate);
        if (currentDate.isAfter(startDate) && currentDate.isBefore(endDate)) {
            return 0;
        }
        // 判斷這個 plan 目前是 endDate 離今天接近，還是 startDate 離今天接近
        const differenceToStart = getDifference(startDate, currentDate);
        const differenceToEnd = getDifference(endDate, currentDate);
        return Math.min(differenceToStart, differenceToEnd);
    };

    const findClosestPlan = data => {
        const currentDate = dayjs();
        return data.reduce((closestPlan, newPlan) => {
            const difference = calculatePlanTimesDifference(currentDate)(newPlan);
            const closestDifference = calculatePlanTimesDifference(currentDate)(closestPlan);
            return difference < closestDifference ? newPlan : closestPlan;
        });
    };

    // [860rm84de][866ahhq9b] 過濾點數類型的方案
    const validPlans = plans.filter(p => !p.deletedAt && isNotExpired(p));
    const retrievePlans = (planType => {
        switch (planType) {
            case planTypes.POINT_PLAN:
                return validPlans.filter(p => isPointPlan(p));
            case planTypes.PROMO_PLAN:
            default:
                return validPlans.filter(p => isNotPointPlan(p));
        }
    })(type);

    if (retrievePlans.length > 1) {
        // 在有效的訂單列表中，優先用年約，沒有的話用單檔
        // 年約：totalPromotionLimit = false，不限次數上線活動 Ex: 點數方案、人次限定年約方案
        // 單檔：totalPromotionLimit = true，限制次數上線活動 Ex: 標準版-單檔30
        const priorityPlans =
            retrievePlans.filter(getIsYearPlanOrder).length > 0
                ? retrievePlans.filter(getIsYearPlanOrder)
                : retrievePlans.filter(getIsSinglePlanOrder);
        // [86ep6v6qm] 取到現在進行中的方案
        const currentActivePlan = findClosestPlan(priorityPlans);
        return currentActivePlan;
    } else {
        return retrievePlans[0];
    }
}
