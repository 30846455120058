import i18n from 'i18next';
import { switchMapKeyValuePairs } from 'utils/common';

export const ADMIN = 'admin';
export const SUPER_EDITOR = 'super_editor';
export const EDITOR = 'editor';
export const CONTRIBUTOR = 'contributor';
export const ON_SITE_STAFF = 'on_site_staff';

export const roleTypeMap = new Map([
    [ADMIN, '主辦單位管理員'],
    [SUPER_EDITOR, '總編輯'],
    [EDITOR, '編輯'],
    [CONTRIBUTOR, '小編'],
    [ON_SITE_STAFF, 'On-site Staff']
]);

export const getRoleTypeByRoleName = roleName => switchMapKeyValuePairs(roleTypeMap).get(roleName);

export const getRoleByRoleName = roleName => {
    const roleType = getRoleTypeByRoleName(roleName);
    return i18n.t(`label.role_type.${roleType}`);
};

export default roleTypeMap;

// 不可使用點數中心人工調整的權限
const canNotManualAdjustmentRole = new Set([
    roleTypeMap.get(CONTRIBUTOR),
    roleTypeMap.get(ON_SITE_STAFF)
]);
export const hasCanNotManualAdjustmentRole = roleName => canNotManualAdjustmentRole.has(roleName);
