import qs from 'query-string';
import dayjs from 'dayjs';

import { feverOrigin } from 'config/envVariable';

export const ENTER_KEY_CODE = 13;

export function numberFormat(num) {
    return num && num.toString().replace(/(?=(?!^)(\d{3})+$)/g, ',');
}

export function secondsToHms(second) {
    const h = `0${Math.floor(second / 3600)}`;
    const m = `0${Math.floor((second % 3600) / 60)}`;
    const s = `0${Math.floor((second % 3600) % 60)}`;

    return `${h.slice(-2)}:${m.slice(-2)}:${s.slice(-2)}`;
}

export function checkImgURL(url) {
    return url.toLowerCase().match(/\.(jpeg|jpg|gif|png|svg)/) != null;
}

export function isSelectingText() {
    const selection = window.getSelection || document.getSelection;
    const selectedText = selection ? selection().toString().trim() : '';
    return Boolean(selectedText);
}

export function replaceQueryString(targetString, payload) {
    const targetObj = qs.parse(targetString);
    const newString = qs.stringify({ ...targetObj, ...payload });
    return newString ? `?${newString}` : '';
}

/**
 * 將查詢參數附加到 URL 字串中
 *
 * @param {string} url - 原始 URL 字串
 * @param {Object.<string, string|number>} params - 要附加的查詢參數物件
 * @returns {string} 包含新查詢參數的完整 URL 字串
 *
 * @example
 * const url = 'https://api.example.com/data?sort=desc';
 * const params = { page: 1, limit: 10 };
 * appendQueryParams(url, params);
 * // 回傳: 'https://api.example.com/data?sort=desc&page=1&limit=10'
 */
export const appendQueryParams = (url, params) => {
    const [baseUrl, queryString] = url.split('?');
    const searchParams = new URLSearchParams(queryString);

    for (const [key, value] of Object.entries(params)) {
        searchParams.append(key, value);
    }

    return `${baseUrl}?${searchParams.toString()}`;
};

export function getTestQueryString(url) {
    const [, queryString] = url.split('?');
    const searchParams = new URLSearchParams(queryString);

    const result = {};
    for (const [key, value] of searchParams) {
        result[key] = value;
    }

    return result;
}

export function openFormEditor(username, formId) {
    window.open(`${feverOrigin}/form/${username}/${formId}/edit`);
}

export function normalizeList(idKeyName, array = []) {
    return array.reduce(
        (obj, item) => {
            const idVal = item[idKeyName];
            obj.byId[idVal] = item;
            obj.allIds.push(idVal);
            return obj;
        },
        { byId: {}, allIds: [] }
    );
}

export function utcDateString2LocalDateString(dateStr) {
    return dayjs(`${dateStr.replace(/\s/, 'T')}Z`).format('YYYY-MM-DD HH:mm:ss');
}

export function checkStringContainUrl(str) {
    // eslint-disable-next-line no-useless-escape
    const regexp = /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+/i;
    return regexp.test(String(str));
}

// 文字輸入框輸入數字去除開頭 0 ，若有輸入非數字的值，會返回 null
export const removeFirstZeroByStringNumber = (val = '0') => {
    if (val === '' || val === '0' || val === '00') {
        // 防止多個 0 及空值出現
        return '0';
    } else {
        const numberRegex = new RegExp(/^[0-9]+(\.[0-9]{0,2})?$/);

        if (Boolean(val.match(numberRegex))) {
            // 符合數字正則
            const splitDot = val.split('.');
            const isFloat = splitDot.length === 2;

            if (isFloat) {
                // 有小數
                const splitInt = splitDot[0].split('');

                if (splitInt.length > 1 && splitInt[0] === '0') {
                    // 以 0 開頭卻不是小於 1 的小數，需要移除開頭 0  Ex. 01.11 => 1.11
                    let finalNumber = '';
                    for (let i = 1; i < splitInt.length; i++) {
                        finalNumber += splitInt[i];
                    }
                    return finalNumber + splitInt[1];
                } else return val;
            } else {
                // 整數
                const splitNumber = val.split('');
                if (splitNumber[0] === '0') {
                    // 0 開頭的整數要移除開頭 0 Ex. 0123 => 123
                    let finalNumber = '';
                    for (let i = 1; i < splitNumber.length; i++) {
                        finalNumber += splitNumber[i];
                    }
                    return finalNumber;
                } else return val;
            }
        } else return null;
    }
};

export const getKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};

export const switchMapKeyValuePairs = originalMap =>
    new Map([...originalMap.entries()].map(([k, v]) => [v, k]));
