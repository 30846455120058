import { css } from '@emotion/core';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

import { colVcenHcen } from 'assets/style/flex.css';
import projects from 'config/projects';

function LoadingAnimation() {
    const { iconClass, IconComp, color, iconColor } = getProjectConfig(window.location.pathname);
    return createPortal(
        <TransitionStyle>
            {IconComp ? (
                <IconComp style={{ height: 100, width: 100, color: iconColor }} />
            ) : (
                <span className={iconClass} css={iconStyle(iconColor)} />
            )}
            <ContainerStyle themeColor={color}>
                <ul>
                    <li>
                        <span>Inspire</span> them.
                    </li>
                    <li>
                        <span>Amuse</span> them.
                    </li>
                    <li>
                        <span>Quiz</span> them.
                    </li>
                    <li>
                        <span>Move</span> them.
                    </li>
                    <li>
                        <span>Go Viral.</span>
                    </li>
                </ul>
            </ContainerStyle>
        </TransitionStyle>,
        document.getElementById('root')
    );
}

export default LoadingAnimation;

const iconStyle = color => css`
    width: 100px;
    height: 100px;
    color: ${color};

    &:before {
        color: inherit;
    }
`;

const TransitionStyle = styled.div`
    ${colVcenHcen}
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: #eee;
    z-index: 999;
`;

const cambiar = keyframes`
    0% { margin-top: 0 }
    10% { margin-top: 0 }
    20% { margin-top: -50px; }
    30% { margin-top: -50px; }
    40% { margin-top: -100px; }
    50% { margin-top: -100px; }
    60% { margin-top: -150px; }
    70% { margin-top: -150px; }
    80% { margin-top: -200px; }
    90% { margin-top: -200px; }
    100% { margin-top: 0 }
`;

const ContainerStyle = styled.div`
    height: 50px;
    color: #818181;
    font-size: 40px;
    line-height: 50px;
    overflow: hidden;
    animation: colorchange 10s;
    -webkit-animation: colorchange 10s;
    > ul {
        padding-left: 10px;
        list-style: none;
        animation: ${cambiar} 5s infinite;
        text-align: center;

        li > span {
            color: ${({ themeColor }) => themeColor};
        }
    }
`;

function getProjectConfig(pathname) {
    const [, id] = pathname.match(/\/(?:admin|create)\/(\w+)/) || [];
    return projects.byId[id] || projects.byId.zines;
}
