const defaultState = {};

const INIT_AVAILABLE_PLAN_ITEMS = 'availablePlanItems/INIT_AVAILABLE_PLAN_ITEMS';
const REPLACE_AVAILABLE_PLAN_ITEMS = 'availablePlanItems/REPLACE_AVAILABLE_PLAN_ITEMS';

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case INIT_AVAILABLE_PLAN_ITEMS:
        case REPLACE_AVAILABLE_PLAN_ITEMS:
            return { ...action.payload };
        default:
            return state;
    }
}

export function initAvailablePlanItems(availablePlanItems) {
    return {
        type: INIT_AVAILABLE_PLAN_ITEMS,
        payload: availablePlanItems
    };
}

export function replaceAvailablePlanItems(availablePlanItems) {
    return {
        type: REPLACE_AVAILABLE_PLAN_ITEMS,
        payload: availablePlanItems
    };
}
