import firebaseApp from 'features/login/utils/initFirebase';
import {
    getAuth,
    signOut,
    getIdToken,
    GoogleAuthProvider,
    FacebookAuthProvider,
    OAuthProvider
} from 'firebase/auth';

export const auth = getAuth(firebaseApp);

export const firebaseProviderIds = {
    EMAIL: 'email', // for fever use
    PASSWORD: 'password',
    EMAIL_LINK: 'emailLink',
    FACEBOOK: 'facebook.com',
    GOOGLE: 'google.com',
    LINE: 'oidc.line',
    MICROSOFT: 'microsoft.com'
};

export const firebaseProviderPlatformMapping = {
    [firebaseProviderIds.FACEBOOK]: 'Facebook',
    [firebaseProviderIds.GOOGLE]: 'Google',
    [firebaseProviderIds.LINE]: 'Line',
    [firebaseProviderIds.MICROSOFT]: 'Microsoft'
};

// https://firebase.google.com/docs/auth/admin/errors
export const firebaseApiErrCodes = {
    AUTH_USER_NOT_FOUND: 'auth/user-not-found',
    AUTH_WRONG_PASSWORD: 'auth/wrong-password',
    AUTH_CREDENTIAL_ALREADY_IN_USE: 'auth/credential-already-in-use',
    // 要第三方登入 (Ex: Line) 的 email 已經被其他第三方登入 (Ex: Google) 綁定過，請直接登入該第三方登入 (Ex: Google) ，再手動綁定其第三方登入 (Ex: Line)
    AUTH_ACCOUNT_EXISTS_WITH_DIFF_CREDENTIAL: 'auth/account-exists-with-different-credential',
    AUTH_EMAIL_ALREADY_IN_USE: 'auth/email-already-in-use',
    // Email 驗證連結信打開後，目標 Email 已經被綁定，所以Email 驗證連結信如果打開第二次會產生此 error
    AUTH_PROVIDER_ALREADY_LINKED: 'auth/provider-already-linked',
    // Line, Facebook, Microsoft 登入後，若發現 firebase user 缺少 identifier，會要求 user 去綁定，可以用 email address, phone number, 或是其他 accepted unique identifier 當做 firebase user identifier
    AUTH_MISSING_IDENTIFIER: 'auth/missing-identifier',
    // 此 browser 登入的 firebase account 與要 email 認證的 firebase account 是不同 account
    AUTH_INVALID_ACTION_CODE: 'auth/invalid-action-code',
    // 網址上 qs 的 OOB_CODE 已經過期
    AUTH_EXPIRED_ACTION_CODE: 'auth/expired-action-code',
    // Firebase API 參數有誤
    AUTH_ARGUMENT_ERROR: 'auth/argument-error',
    // Safari 或是 chrome 有開啟「阻擋彈出式視窗」導致流程失敗
    AUTH_POPUP_BLOCKED: 'auth/popup-blocked',
    AUTH_POPUP_CLOSED_BY_USER: 'auth/popup-closed-by-user'
};

// 使用 OAuthProvider 的登入方式
const useOAuthProvider = [firebaseProviderIds.LINE, firebaseProviderIds.MICROSOFT];
export const isUseOAuthProvider = providerId => useOAuthProvider.indexOf(providerId) !== -1;

export const Providers = {
    [firebaseProviderIds.GOOGLE]: GoogleAuthProvider,
    [firebaseProviderIds.FACEBOOK]: FacebookAuthProvider,
    [firebaseProviderIds.LINE]: OAuthProvider,
    [firebaseProviderIds.MICROSOFT]: OAuthProvider
};

export const firebaseEmailLinkQueryStrKeys = ['apiKey', 'oobCode', 'mode', 'lang'];

export function getProviderIdFromUser() {
    return auth.currentUser.providerData.map(p => p.providerId);
}

export function signout() {
    return signOut(auth);
}

export function getFirebaseUserUId() {
    const { uid } = auth.currentUser ?? {};
    return uid;
}

const EXPIRE_IN_MINUTES = 30 * 60 * 1000;
let timeStamp = Date.now();
let firebaseToken = '';

export async function getFirebaseIdToken() {
    if (auth.currentUser) {
        const t = await getIdToken(auth.currentUser);
        timeStamp = Date.now();
        firebaseToken = t;
        return t;
    } else {
        return '';
    }
}

export const firebaseTokenStaticGetter = {
    get token() {
        const now = Date.now();
        if (now - timeStamp >= EXPIRE_IN_MINUTES) {
            getFirebaseIdToken();
        }
        return firebaseToken;
    }
};
