import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { sendSignInLinkToEmail } from 'firebase/auth';
import CircularProgress from '@material-ui/core/CircularProgress';

import { queryStrKeys, verifyEmailTypes } from 'features/login/utils/config';
import { auth } from 'features/login/utils/firebaseManager';
import { getQueryString } from 'features/login/utils/manipulateUrl';

import ConfirmVerifyEmailSended from 'features/login/components/pageEmailVerify/ConfirmVerifyEmailSended';

export default function EmailVerifySignup() {
    const { verifyEmail } = useSelector(s => s.firebase);
    const [isPageReady, setIsPageReady] = useState(false);

    useEffect(() => {
        async function handle() {
            await sendSignInLinkToEmail(auth, verifyEmail, {
                handleCodeInApp: true,
                url: `${window.location.origin}/app/email_verify_landing${getQueryString({
                    add: {
                        [queryStrKeys.VERIFY_EMAIL_TYPE]: verifyEmailTypes.SIGN_UP,
                        [queryStrKeys.VERIFY_EMAIL]: verifyEmail
                    }
                })}`
            });
            setIsPageReady(true);
        }
        handle();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!isPageReady) {
        return <CircularProgress />;
    }
    return <ConfirmVerifyEmailSended email={verifyEmail} />;
}
