import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { LocalizationProvider } from '@material-ui/pickers';
import DayjsUtils from '@material-ui/pickers/adapter/dayjs';
import enLocale from 'dayjs/locale/en';
import zhTwLocale from 'dayjs/locale/zh-tw';

const ZH_TW = 'zh-TW';
const EN_US = 'en-US';

const localeMap = {
    [EN_US]: enLocale,
    [ZH_TW]: zhTwLocale
};

export default function Provider({ children }) {
    const locale = localeMap[i18n.language] || localeMap[ZH_TW];
    return (
        <LocalizationProvider dateAdapter={DayjsUtils} locale={locale}>
            {children}
        </LocalizationProvider>
    );
}

Provider.propTypes = {
    children: PropTypes.element
};
