import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import MuiButton from '@material-ui/core/Button';

const useStyles = makeStyles({
    button: {
        padding: props => (props.variant === 'outlined' ? '5px 16px' : '6px 16px'),
        boxShadow: 'none',
        textTransform: 'initial',
        '&:active': {
            boxShadow: 'none'
        },
        '&:hover': {
            boxShadow: 'none'
        }
    }
});

function Button({
    forwardRef = () => {},
    disabled,
    children,
    startIcon,
    endIcon,
    variant,
    className,
    color,
    onClick = () => {},
    disableAllRipple,
    style,
    ...props
}) {
    const classes = useStyles({ variant });
    return (
        <MuiButton
            disableFocusRipple={disableAllRipple}
            disableRipple={disableAllRipple}
            disableTouchRipple={disableAllRipple}
            ref={forwardRef}
            startIcon={startIcon}
            endIcon={endIcon}
            variant={variant}
            color={color}
            onClick={onClick}
            className={clsx(classes.button, className)}
            disabled={disabled}
            style={style}
            {...props}
        >
            {children}
        </MuiButton>
    );
}

export default Button;

Button.propTypes = {
    forwardRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
    disabled: PropTypes.bool,
    children: PropTypes.any,
    startIcon: PropTypes.node,
    endIcon: PropTypes.node,
    variant: PropTypes.string,
    className: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
    style: PropTypes.oneOfType([PropTypes.object, PropTypes.string, PropTypes.array])
};
