import Cookie from 'js-cookie';

import { updateSponsorApi, updateSponsorSettingsApi, getSponsor } from 'api/sponsor';
import generateUpdatePayload from 'utils/generateUpdatePayload';
import { replaceAvailablePlanItems } from 'store/module/availablePlanItems';
import { setFailed } from 'store/module/flag';
import { cookieKeys } from 'config/cookie';

const INIT_SPONSOR = 'sponsor/INIT_SPONSOR';
const UPDATE_SPONSOR = 'sponsor/UPDATE_SPONSOR';
export const CHANGE_SPONSOR = 'sponsor/CHANGE_SPONSOR';
const defaultState = { settings: {} };

export default function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case UPDATE_SPONSOR:
            return { ...state, ...payload, settings: { ...state.settings, ...payload.settings } };
        case CHANGE_SPONSOR:
        case INIT_SPONSOR:
            return { ...payload };
        default:
            return state;
    }
}

export function initSponsor(data) {
    return {
        type: INIT_SPONSOR,
        payload: data
    };
}

export function changeSponsor(sponsorId) {
    return async (dispatch, getState) => {
        const { sponsor: { id } = {} } = getState();
        if (sponsorId === id) {
            return;
        }
        try {
            const sponsorData = await getSponsor(sponsorId);
            dispatch(replaceAvailablePlanItems(sponsorData.availablePlanItems));
            dispatch({
                type: CHANGE_SPONSOR,
                payload: sponsorData.sponsor
            });
            Cookie.set(cookieKeys.LATEST_SELECTED_SPONSOR_ID, sponsorId, {
                expires: 7
            });
            return sponsorData.sponsor;
        } catch (error) {
            console.error(error);
            dispatch(setFailed('ChangeSponsor', 'message_connection_error'));
        }
    };
}

export function updateSponsorData(data) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id, settings: settingOriginData }
        } = getState();
        const { settings, ...restData } = data;
        let promises = [];
        if (Object.keys(restData).length !== 0) {
            promises.push(updateSponsorApi(id, restData));
        }
        if (settings) {
            const originSameSettings = getSameObjectKey(settingOriginData, settings);
            const removeEmptySettings = removeEmptyObject(settings);
            const addRemoveSettings = generateUpdatePayload(
                originSameSettings,
                removeEmptySettings
            );
            promises.push(updateSponsorSettingsApi(id, addRemoveSettings));
        }
        await Promise.all(promises);
        dispatch({
            type: UPDATE_SPONSOR,
            payload: data
        });
    };
}

export function getSameObjectKey(origin, target) {
    return Object.keys(target).reduce((object, k) => {
        object[k] = origin[k];
        return object;
    }, {});
}

export function removeEmptyObject(obj) {
    return Object.keys(obj).reduce((object, k) => {
        if (obj[k] !== '') {
            object[k] = obj[k];
        }
        return object;
    }, {});
}
