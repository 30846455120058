import React from 'react';
import { css } from '@emotion/core';

export default function Panel({ children, style }) {
    return <div css={[containerStyle, css(style)]}>{children}</div>;
}

const containerStyle = css`
    width: 360px;
    background-color: #fff;
    border-radius: 12px;
    padding: 24px;
    box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2), 0px 6px 30px 5px rgba(0, 0, 0, 0.12),
        0px 16px 24px 2px rgba(0, 0, 0, 0.14);
`;
