import i18next from 'i18next';
import { campaignMessageTemplateTypes } from 'config/campaignType';
import { DEVICE_TYPE } from 'store/module/flags';
import { getCustomDialogStyle } from 'frontend-froala-editor/src/lib/combineFroala/BasicDivFroala';
import { getCustomIconStyle } from 'frontend-froala-editor/src/lib/combineFroala/BasicIconFroala';
import { getCustomBannerStyle } from 'frontend-froala-editor/src/lib/combineFroala/BasicBannerFroala';
import {
    DIALOG_MAX_WIDTH,
    BANNER_MAX_WIDTH,
    MOBILE_PREVIEW_WIDTH
} from 'features/campaigns/CampaignOnsite/const';
import { black, promo700, reminder, white } from 'features/common/Mui/styles/color';

export const getPositionStyles = (placement = 'bottom-right', gapX = 0, gapY = 0) => {
    // For center position
    if (placement === 'center') {
        return {
            position: 'fixed',
            top: 'calc(var(--container-top) + var(--container-height) * 0.5)',
            left: 'calc(var(--container-left) + var(--container-width) * 0.5)',
            transform: 'translate(-50%, -50%)'
        };
    }

    const [first, second] = placement.split('-');

    // Left, Right positions
    if (second === undefined && (first === 'left' || first === 'right')) {
        return {
            position: 'fixed',
            top: `calc(var(--container-top) + var(--container-height) * 0.5 + ${gapY}px)`,
            [first]:
                first === 'left'
                    ? `calc(var(--container-left) + ${gapX}px)`
                    : `calc(100vw - var(--container-right) + ${gapX}px)`,
            transform: 'translateY(-50%)'
        };
    }

    // Top, Bottom positions
    if (second === undefined && (first === 'top' || first === 'bottom')) {
        return {
            position: 'fixed',
            [first]:
                first === 'top'
                    ? `calc(var(--container-top) + ${gapY}px)`
                    : `calc(100vh - var(--container-bottom) + ${gapY}px)`,
            left: `calc(var(--container-left) + var(--container-width) * 0.5 + ${gapX}px)`,
            transform: 'translateX(-50%)'
        };
    }

    // Corner positions (top-left, top-right, bottom-left, bottom-right)
    return {
        position: 'fixed',
        [first]:
            first === 'top'
                ? `calc(var(--container-top) + ${gapY}px)`
                : `calc(100vh - var(--container-bottom) + ${gapY}px)`,
        [second]:
            second === 'left'
                ? `calc(var(--container-left) + ${gapX}px)`
                : `calc(100vw - var(--container-right) + ${gapX}px)`
    };
};

/**
 * Calculate maximum dimensions for the Froala editor based on device and container
 * @param {string} previewMode - Current preview mode (MOBILE/DESKTOP)
 * @param {number} messageGapX - Horizontal gap
 * @param {number} messageGapY - Vertical gap
 * @param {number} ratio - Aspect ratio for sizing
 * @param {number} templateId - Template ID
 * @returns {{ width: string, height: string }} CSS-ready dimension values
 */
const generateFroalaEditorMaxSizeCss = (
    previewMode,
    messageGapX = 0,
    messageGapY = 0,
    ratio = 1,
    templateId
) => {
    // Calculate width and height with proper spacing
    const screenWidth = 'var(--container-width)';
    const screenHeight = 'var(--container-height)';
    const width = `calc(${screenWidth} - ${messageGapX}px)`; // 畫布的寬減掉X軸間距就是圖片能拉出最大的寬度
    const height = `calc(${screenHeight} - ${messageGapY}px)`; // 畫布的寬減掉Y軸間距就是圖片能拉出最大的高度
    const heightBasedWidth = `calc(var(--container-height) / ${ratio})`; // 根據圖片比例 (Ex:正方形、長方形) 計算出圖片能拉出最大的高度
    const isMobile = previewMode === DEVICE_TYPE.MOBILE;

    const imageSizeHandler = templateId => {
        switch (templateId) {
            case campaignMessageTemplateTypes.floatingButtonTemplate1:
            case campaignMessageTemplateTypes.floatingButtonTemplate2:
            case campaignMessageTemplateTypes.floatingButtonTemplate3:
                return {
                    // For mobile, use simple container-based calculations
                    width: isMobile ? width : heightBasedWidth,
                    height
                };
            case campaignMessageTemplateTypes.dialogTemplate1:
            case campaignMessageTemplateTypes.dialogTemplate2:
            case campaignMessageTemplateTypes.dialogTemplate3:
                return {
                    width: `${DIALOG_MAX_WIDTH}px`,
                    height: 'auto'
                };
            case campaignMessageTemplateTypes.bannerTemplate1:
                return {
                    width: isMobile ? `${MOBILE_PREVIEW_WIDTH}px` : `${BANNER_MAX_WIDTH}px`,
                    height: 'auto'
                };
            default:
                return {
                    width: '100%',
                    height: '100%'
                };
        }
    };
    return imageSizeHandler(templateId);
};

/**
 * Generate size constraint CSS for images
 * @param {string} previewMode - Current preview mode
 * @param {number} ratio - Aspect ratio for sizing
 * @param {number} messageGapX - Horizontal gap
 * @param {number} messageGapY - Vertical gap
 * @param {number} messageTemplateId - Template ID
 * @returns {string} CSS for max-width and max-height
 */
export const getSizeConstraints = (
    previewMode,
    ratio,
    messageGapX,
    messageGapY,
    messageTemplateId
) => {
    const { width, height } = generateFroalaEditorMaxSizeCss(
        previewMode,
        messageGapX,
        messageGapY,
        ratio,
        messageTemplateId
    );

    return `
        max-width: ${width} !important;
        max-height: ${height} !important;
    `;
};

/**
 * 處理按鈕元素的 HTML 字串
 * @param {string} htmlString - 原始的 HTML 字串
 * @param {Object} options - 配置選項
 * @param {boolean} [options.isCloseButton=false] - 是否為關閉按鈕
 * @returns {string} 處理後的 HTML 字串
 */
const processElement = (htmlString, { isCloseButton = false, isActionButton = false } = {}) => {
    let processedString = htmlString.replace(/\s+contenteditable="[^"]*"/g, ''); // 移除 contenteditable 屬性

    if (isCloseButton) {
        // 新增 data-id 屬性到 <a> 標籤
        processedString = processedString.replace(
            /<a\s+([^>]*?)>/,
            '<a $1 data-id="fever-close-button">'
        );
    }

    // #86err3bk2 設定一個 flag 讓倒數計時器知道這個按鈕是 action button，可以放置此位置
    if (isActionButton) {
        // 新增 data-id 屬性到 <a> 標籤
        processedString = processedString.replace(
            /<a\s+([^>]*?)>/,
            '<a $1 data-id="fever-action-button">'
        );
    }

    return processedString;
};

/**
 * 處理元素的 HTML 字串
 * @param {string} htmlString - 原始的 HTML 字串
 * @returns {string} 處理後的 HTML 字串
 */
const processHrefElement = htmlString => {
    if (!htmlString) return '';

    let processedString = htmlString
        .replace(/href="#"/gi, 'href="javascript:void(0);"') // 替換空 href
        .replace(/<a\s+([^>]*?)href="[^"]*"\s*([^>]*)>/gi, '<a $1 $2>'); // 移除 href 屬性

    return processedString;
};

export const generateCloseButtonElements = (
    eleId,
    templateId,
    text = i18next.t('label.close_window')
) => {
    return `<a id="${eleId}" class="custom-button-plugin" style="display: inline-block; margin: 4px 0px 0px 0px; padding: 14px 40px; letter-spacing: 1px; cursor: pointer; text-decoration: none !important; color: ${
        Number(templateId) === campaignMessageTemplateTypes.dialogTemplate3 ? '#000000' : '#ffffff'
    }; border-radius: 5px; border-color: transparent; font-size: 14px">
        <p>${text}</p>
    </a>`;
};

export const generateImageElements = (eleId, templateId) => {
    switch (templateId) {
        case campaignMessageTemplateTypes.floatingButtonTemplate1:
            return `<img id="${eleId}" src="https://assets.fevercdn.com/nc-admin/images/icon-01.svg" />`;
        case campaignMessageTemplateTypes.floatingButtonTemplate2:
            return `<img id="${eleId}" src="https://assets.fevercdn.com/nc-admin/images/Icon-02.svg" />`;
        case campaignMessageTemplateTypes.floatingButtonTemplate3:
            return `<img id="${eleId}" src="https://assets.fevercdn.com/nc-admin/images/Icon-03.svg" />`;
        case campaignMessageTemplateTypes.dialogTemplate1:
            return `<img id="${eleId}" src="https://assets.fevercdn.com/nc-admin/images/Dialog1.png" style="width: ${DIALOG_MAX_WIDTH}px" />`;
        case campaignMessageTemplateTypes.dialogTemplate2:
            return `<img id="${eleId}" src="https://assets.fevercdn.com/nc-admin/images/Dialog2.png" style="width: ${DIALOG_MAX_WIDTH}px" />`;
        case campaignMessageTemplateTypes.dialogTemplate3:
            return `<img id="${eleId}" src="https://assets.fevercdn.com/nc-admin/images/Dialog3.png" style="width: ${DIALOG_MAX_WIDTH}px" />`;
        case campaignMessageTemplateTypes.bannerTemplate1:
            return `<img id="${eleId}" src="https://assets.fevercdn.com/nc-admin/images/Banner1.png" style="width: ${BANNER_MAX_WIDTH}px" />`;
        case campaignMessageTemplateTypes.bannerTemplate3:
            return `<img id="${eleId}" src="https://assets.fevercdn.com/nc-admin/images/Banner3%20-%20Img.png" />`;
        default:
            return '';
    }
};

const DIALOG_BUTTON_MARGIN = 16;
export const generateButtonElements = (eleId, templateId) => {
    switch (templateId) {
        case campaignMessageTemplateTypes.floatingButtonTemplate3:
            return `<a class="custom-button-plugin" id="${eleId}" style="display: inline-block; margin: 4px 0; padding: 8px 12px; letter-spacing: 1px; cursor: pointer; text-decoration: none !important; color: ${
                white.color
            }; background-color: ${
                promo700.color
            }; border-radius: 32px; border-color: transparent; font-family: Noto Sans TC; font-weight: 500; font-size: 14px; line-height: 16px; letter-spacing: 1px; text-align: center;"><p>${i18next.t(
                'label.onsite.get_rewards'
            )}</p></a>`;
        case campaignMessageTemplateTypes.dialogTemplate2:
            return `<a class="custom-button-plugin" id="${eleId}" style="display: inline-block; margin: ${DIALOG_BUTTON_MARGIN}px; padding: 14px 40px; letter-spacing: 1px; cursor: pointer; text-decoration: none !important; color: ${
                black.color
            }; background-color: ${
                white.color
            }; border-radius: 8px; border-color: transparent; width: calc(100% - ${
                DIALOG_BUTTON_MARGIN * 2
            }px);font-size: 14px;"><p>${i18next.t('label.onsite.sign_in_now')}</p></a>`;
        case campaignMessageTemplateTypes.dialogTemplate3:
            return `<a class="custom-button-plugin" id="${eleId}" style="display: inline-block; margin: 8px 0px 0px 0px; padding: 14px 40px; letter-spacing: 1px; cursor: pointer; text-decoration: none !important; color: ${
                black.color
            }; background-color: ${
                reminder.color
            }; border-radius: 20px; border-color: transparent; font-size: 14px"><p>${i18next.t(
                'label.onsite.join_now'
            )}</p></a>`;
        case campaignMessageTemplateTypes.bannerTemplate2:
            return `<a class="custom-button-plugin" id="${eleId}" style="display: inline-block; margin: 0px 0px 0px 8px; padding: 10px 20px; letter-spacing: 1px; cursor: pointer; text-decoration: none !important; color: ${
                black.color
            }; background-color: ${
                white.color
            }; border-radius: 12px; border-color: transparent; font-size: 14px"><p>${i18next.t(
                'label.onsite.join_now'
            )}</p></a>`;
        case campaignMessageTemplateTypes.bannerTemplate3:
            return `<a class="custom-button-plugin" id="${eleId}" style="display: inline-block; margin: 0px 0px 0px 8px; padding: 10px 20px; letter-spacing: 1px; cursor: pointer; text-decoration: none !important; color: ${
                black.color
            }; background-color: ${
                white.color
            }; border-radius: 12px; border-color: transparent; font-size: 14px;"><p>${i18next.t(
                'label.onsite.join_now'
            )}</p></a>`;
        default:
            return '';
    }
};

export const generateTextareaElements = (eleId, templateId) => {
    switch (templateId) {
        case campaignMessageTemplateTypes.floatingButtonTemplate2:
            return `<div id="${eleId}" style="color: ${white.color}">
            <p class="inner" style="white-space: pre-line; margin : 8px 16px 8px 8px">${i18next.t(
                'label.onsite.join_promo'
            )}</p>
            </div>`;
        case campaignMessageTemplateTypes.dialogTemplate2:
            return `<div id="${eleId}"  style="color: ${white.color};margin:16px 0px 4px;">
            <h4 class="inner-title" style="text-align: center;">${i18next.t(
                'label.onsite.dialog_template_default_title'
            )}</h4>
            <p class="inner" style="text-align: center;">${i18next.t(
                'label.onsite.dialog_template_default_subtitle'
            )}</p>
            </div>`;
        case campaignMessageTemplateTypes.bannerTemplate2:
            return `<div id="${eleId}" style="color: ${white.color}">
            <p class="inner" style="text-align: left;"><strong>${i18next.t(
                'label.onsite.banner_template_default_title'
            )}</strong></p>
            </div>`;
        default:
            return '';
    }
};

/**
 * 根據模板 ID 生成 Froala HTML 內容
 * @param {Object} params - 生成模板所需的參數
 * @param {string} params.template_type - 模板類型 ID
 * @param {Object} params.elements - 模板元素內容
 * @param {string} [params.elements.imgMobileElements=''] - 手機版圖片元素 HTML
 * @param {string} [params.elements.textAreaElements=''] - 文字區塊元素 HTML
 * @param {string} [params.elements.buttonElements=''] - 按鈕元素 HTML
 * @param {string} [params.elements.closeButtonElements=''] - 關閉按鈕元素 HTML
 * @returns {string} 生成的 Froala HTML 內容
 */
export const generateFroalaHtmlByTemplateId = ({ template_type: templateId, ...elements }) => {
    const {
        imgMobileElements = '',
        textAreaElements = '',
        buttonElements = '',
        closeButtonElements = ''
    } = elements;

    const processedButtonElements = processElement(buttonElements, {
        isActionButton: true
    });
    const processedTextareaElements = processElement(textAreaElements);
    const processedCloseButtonElements = processElement(closeButtonElements, {
        isCloseButton: true
    })
        ? `<div>${processElement(closeButtonElements, { isCloseButton: true })}</div>`
        : '';

    const convertStyleObjectToString = styleObj =>
        Object.entries(styleObj)
            .map(([key, value]) => `${key.replace(/([A-Z])/g, '-$1').toLowerCase()}: ${value};`)
            .join(' ');

    const templateConfig = {
        // 浮動按鈕樣式 1 (只有圖片)
        [campaignMessageTemplateTypes.floatingButtonTemplate1]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomIconStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.floatingButtonTemplate1
                )
            )}"><div>${imgMobileElements}</div></div>`,

        // 浮動按鈕樣式 2 (圖片和文字平行)
        [campaignMessageTemplateTypes.floatingButtonTemplate2]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomIconStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.floatingButtonTemplate2
                )
            )}"><div>${imgMobileElements}</div><div>${processedTextareaElements}</div></div>`,

        // 浮動按鈕樣式 3 (圖片和按鈕垂直)
        [campaignMessageTemplateTypes.floatingButtonTemplate3]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomIconStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.floatingButtonTemplate3
                )
            )}"><div>${imgMobileElements}</div><div>${processedButtonElements}</div></div>`,

        // 彈跳視窗樣式 1 (只有圖片)
        [campaignMessageTemplateTypes.dialogTemplate1]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomDialogStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.dialogTemplate1
                )
            )}"><div>${imgMobileElements}</div></div>`,

        // 彈跳視窗樣式 2 (圖片＋文字＋按鈕)
        [campaignMessageTemplateTypes.dialogTemplate2]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomDialogStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.dialogTemplate2
                )
            )}"><div>${imgMobileElements}</div><div>${processedTextareaElements}</div><div>${processedButtonElements}</div>${processedCloseButtonElements}</div>`,

        // 彈跳視窗樣式 3 (圖片＋按鈕)
        [campaignMessageTemplateTypes.dialogTemplate3]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomDialogStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.dialogTemplate3
                )
            )}"><div>${imgMobileElements}</div><div>${processedButtonElements}</div>${processedCloseButtonElements}</div>`,

        // 提示橫幅樣式 1 (只有背景圖片)
        [campaignMessageTemplateTypes.bannerTemplate1]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomBannerStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.bannerTemplate1
                )
            )}"><div>${imgMobileElements}</div></div>`,

        // 提示橫幅樣式 2 ( 文字 ＋ 按鈕)
        [campaignMessageTemplateTypes.bannerTemplate2]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomBannerStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.bannerTemplate2
                )
            )}"><div>${processedTextareaElements}</div><div>${processedButtonElements}</div></div>`,

        // 提示橫幅樣式 3 ( 圖片 ＋ 按鈕)
        [campaignMessageTemplateTypes.bannerTemplate3]: () =>
            `<div class="froala-viewer" data-element-id=${templateId} style="${convertStyleObjectToString(
                getCustomBannerStyle(
                    campaignMessageTemplateTypes,
                    campaignMessageTemplateTypes.bannerTemplate3
                )
            )}"><div>${imgMobileElements}</div><div>${processedButtonElements}</div></div>`
    };

    const generateTemplate = templateConfig[templateId];
    if (!generateTemplate) {
        console.warn(`未知的模板 ID: ${templateId}`);
        return '';
    }
    // 最後要濾掉所有的 href 標籤，ticket #86erqyj57
    return processHrefElement(generateTemplate());
};
