import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Avatar from '@material-ui/core/Avatar';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { css } from '@emotion/core';
import { Link } from 'react-router-dom';

import { system600 } from 'features/common/Mui/styles/color';
import { dp03 } from 'features/common/Mui/styles/shadow';
import { signout } from 'features/login/utils/firebaseManager';
import { generateLoginUrl } from 'utils/login';

import Button from 'features/common/Mui/Button';

function AvatarInfoPanel() {
    const { t } = useTranslation();
    const { user } = useSelector(s => s.firebase);
    const hasFeverAccount = useSelector(s => Boolean(s.account.uuid));
    const { avatarSrc: src, name, email } = user;

    async function handleSignout() {
        await signout();
        window.location.href = generateLoginUrl();
    }

    return (
        <div css={[containerStyle, css(dp03)]}>
            <div css={rowItemStyle}>
                <Avatar alt={name} css={avatarStyle} src={src} />
                <div>
                    <Typography variant="subtitle2" css={nameStyle}>
                        {name}
                    </Typography>
                    <Typography variant="caption" css={emailStyle}>
                        {email}
                    </Typography>
                </div>
            </div>
            <Divider css={css('width: 100%;')} />
            {hasFeverAccount && (
                <Link
                    css={css('width: 100%;')}
                    to={history => ({ ...history, pathname: '/admin/account' })}
                >
                    <Button css={btnStyle} startIcon={<AccountCircleIcon />}>
                        {t('account.btn.account_setting')}
                    </Button>
                </Link>
            )}
            <Button css={btnStyle} startIcon={<ExitToAppIcon />} onClick={handleSignout}>
                {t('account.btn.logout')}
            </Button>
        </div>
    );
}

export default AvatarInfoPanel;

const containerStyle = css`
    width: 240px;
    box-sizing: border-box;
    border-radius: 8px;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    // TODO: 沒這顏色
    background-color: #eceff1;
`;

const avatarStyle = css`
    width: 32px;
    height: 32px;
    margin-right: 8px;
`;

const btnStyle = css`
    width: 100%;
    transition: all 0.2s linear;
    border-radius: unset;
    justify-content: unset;
    // TODO: 沒這顏色
    color: #3c5059;
    &:hover {
        background-color: #ececec;
    }
`;

const nameStyle = css`
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.1px;
    // TODO: 沒這顏色
    color: #3c5059;
`;

const emailStyle = css`
    color: ${system600.color};
    overflow: hidden;
    text-overflow: ellipsis;
    letter-spacing: 0.4px;
`;

const rowItemStyle = css`
    width: 100%;
    display: flex;
    padding: 8px 16px;
    align-items: center;
`;
