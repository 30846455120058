import React, { useEffect, useState } from 'react';
import i18next from 'i18next';
import querystring from 'query-string';
import Typography from '@material-ui/core/Typography';
import LayoutInner from 'features/login/components/LayoutInner';
import EmailVerifyLandingSignupAndForgetPassword from 'features/login/components/pageEmailVerifyLanding/EmailVerifyLandingSignupAndForgetPassword';
import EmailVerifyLanding from 'features/login/components/pageEmailVerifyLanding/EmailVerifyLanding';
import EmailVerifyLandingUpdateEmail from './EmailVerifyLandingUpdateEmail';
import { queryStrKeys, verifyEmailTypes } from 'features/login/utils/config';
import useApiFailedHandler from 'features/webhooks/useApiErrorHandler';
import { VERIFY_EMAIL_LANDING_FAILED } from 'config/errorTypes';

export default function PageEmailVerifyLanding() {
    const apiFailedHandler = useApiFailedHandler();
    const [emailVerifyType] = useState(
        () => querystring.parse(window.location.search)[queryStrKeys.VERIFY_EMAIL_TYPE]
    );

    useEffect(() => {
        if (
            ![
                verifyEmailTypes.SIGN_UP,
                verifyEmailTypes.FORGET_PASSWORD,
                verifyEmailTypes.UPDATE_EMAIL,
                verifyEmailTypes.EMAIL_VERIFY
            ].includes(emailVerifyType)
        ) {
            apiFailedHandler(
                {
                    emailVerifyType,
                    currentPageUrl: window.location.href
                },
                i18next.t('notify.verify_email_fail'),
                VERIFY_EMAIL_LANDING_FAILED
            );
        }
    }, [apiFailedHandler, emailVerifyType]);

    const content = (() => {
        if (
            emailVerifyType === verifyEmailTypes.SIGN_UP ||
            emailVerifyType === verifyEmailTypes.FORGET_PASSWORD
        ) {
            return <EmailVerifyLandingSignupAndForgetPassword />;
        } else if (emailVerifyType === verifyEmailTypes.UPDATE_EMAIL) {
            return <EmailVerifyLandingUpdateEmail />;
        } else if (emailVerifyType === verifyEmailTypes.EMAIL_VERIFY) {
            return <EmailVerifyLanding />;
        }
        return <Typography>{i18next.t('notify.verify_email_fail')}</Typography>;
    })();
    return <LayoutInner>{content}</LayoutInner>;
}
