import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { getFullCoverBgProps } from 'styles/common';
import BlockInfoAndConfirmRedirect from '../BlockInfoAndConfirmRedirect';

export default function UpdateEmailComplete() {
    const { t } = useTranslation();

    return (
        <>
            <div css={headerWrapperStyle}>
                <img
                    src="https://assets.fevercdn.com/nc-admin/icons/fever_logo.svg"
                    alt="feversocial"
                    css={brandLogoStyle}
                />
            </div>
            <div css={containerStyle}>
                <BlockInfoAndConfirmRedirect
                    desc={t('label.login_again')}
                    title={t('label.account_setting_complete')}
                    btnText={t('btn.to_login_page')}
                    redirectPath={'/app/login'}
                />
            </div>
        </>
    );
}

const containerStyle = css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${getFullCoverBgProps({
        imgUrl: 'https://assets.fevercdn.com/nc-admin/images/admin_inner.jpg'
    })}
`;

const headerWrapperStyle = css`
    flex: 0 0 64px;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;
`;

const brandLogoStyle = css`
    height: 36px;
`;
