import React, { Fragment, useState } from 'react';
import useUsableFirebaseProviders from 'features/login/hooks/useUsableFirebaseProviders';
import { firebaseProviderIds } from 'features/login/utils/firebaseManager';
import ContinueWithLoginBtn from 'features/login/components/ContinueWithLoginBtn';

export default function ThirdPartyLoginBlock({ config }) {
    const showProvider = useUsableFirebaseProviders();
    const [isLogining, setIsLogining] = useState(false);

    const thirdPartyLoginConfig = [
        {
            isShow: showProvider.GOOGLE,
            providerId: firebaseProviderIds.GOOGLE,
            iconFileName: 'icon_google.svg',
            text: 'btn.continue_with.google'
        },
        {
            isShow: showProvider.MICROSOFT,
            providerId: firebaseProviderIds.MICROSOFT,
            iconFileName: 'icon_microsoft.svg',
            text: 'btn.continue_with.microsoft'
        },
        {
            isShow: showProvider.LINE,
            providerId: firebaseProviderIds.LINE,
            iconFileName: 'icon_line.svg',
            text: 'btn.continue_with.line'
        },
        {
            isShow: showProvider.FB,
            providerId: firebaseProviderIds.FACEBOOK,
            iconFileName: 'icon_facebook.svg',
            text: 'btn.continue_with.fb'
        }
    ];

    return (
        config ?? thirdPartyLoginConfig
    ).map(({ isShow, isDisabled, providerId, iconFileName, text, callback }) => (
        <Fragment key={text}>
            {isShow ? (
                <ContinueWithLoginBtn
                    isLogining={isLogining}
                    setLogining={setIsLogining}
                    disabled={isDisabled}
                    providerId={providerId}
                    iconFileName={iconFileName}
                    text={text}
                    loginSuccessCallback={callback}
                />
            ) : null}
        </Fragment>
    ));
}
