import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { onAuthStateChanged, fetchSignInMethodsForEmail } from 'firebase/auth';
import querystring from 'query-string';
import Cookie from 'js-cookie';

import { auth, signout } from 'features/login/utils/firebaseManager';
import {
    setIsLogin,
    setUserInfo,
    setVerifyEmailInfo,
    setLoginSignUpErrorType,
    loginSignUpErrorTypes
} from 'store/module/firebase';
import { queryStrKeys, verifyEmailTypes } from 'features/login/utils/config';
import {
    getUserAccountData,
    getIsHasOldAccountInfoApi,
    createAccountApi,
    updateAccountEmail
} from 'api/account';
import { getUserSponsors, getSponsor, bindingECommerceSponsor } from 'api/sponsor';
import { getPartnerSiteConfig, getUserPartner } from 'api/partner';
import { initAccount } from 'store/module/account';
import { initPartner } from 'store/module/partner';
import { initSponsor } from 'store/module/sponsor';
import { initSponsorList, appendSponsorIfNotInList } from 'store/module/sponsors';
import { initAvailablePlanItems } from 'store/module/availablePlanItems';
import { switchLocale } from 'store/module/i18n';
import { setAppInited, setAppState } from 'store/module/app';
import { gtmPushInitData, gtmPushLogin } from 'utils/tracking';
import { objFirstLevelJSONParse } from 'utils/objectManipulate';
import { cookieKeys } from 'config/cookie';
import { getRoleTypeByRoleName, ON_SITE_STAFF } from 'config/roleType';

import useHistoryWithCurrentSearchHash from 'features/login/hooks/useHistoryWithCurrentSearchHash';
import useCheckIsValidEmailDomain from 'features/login/hooks/useCheckIsValidEmailDomain';
import { FEVER_API_ENDPOINT } from 'config/envVariable';
import { basenameRouteKeys, adminRouteKeys } from 'config/permissionsFunctionMapping';
import Cookies from 'js-cookie';

export default function useInitAndRedirectApp() {
    const dispatch = useDispatch();
    const redirectApp = useRedirectApp();
    const { isInited } = useSelector(s => s.app);
    const [isPartnerSiteConfigInited, setPartnerSiteConfigInited] = useState(false);
    const [isFirebaseLoginInited, setFirebaseLoginInited] = useState(false);

    useEffect(() => {
        async function init() {
            const resp = await getPartnerSiteConfig();
            dispatch(setAppState(objFirstLevelJSONParse(resp)));
            setPartnerSiteConfigInited(true);
        }
        init();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isPartnerSiteConfigInited) {
            const needInitApp = !pathsNoNeedAppInit();
            if (needInitApp) {
                const unSubscribe = onAuthStateChanged(auth, async user => {
                    try {
                        if (user) {
                            dispatch(setIsLogin(Boolean(user)));
                            dispatch(
                                setUserInfo({
                                    name: user.displayName,
                                    email: user.email,
                                    avatarSrc: user.photoURL
                                })
                            );
                            const signInMethods = await fetchSignInMethodsForEmail(
                                auth,
                                user.email
                            );
                            dispatch(
                                setUserInfo({
                                    signInMethods
                                })
                            );
                        }
                        dispatch(setIsLogin(Boolean(user)));
                    } catch (error) {
                        console.error('Init', error);
                    } finally {
                        setFirebaseLoginInited(true);
                    }
                });
                return () => unSubscribe();
            } else {
                dispatch(setAppInited());
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPartnerSiteConfigInited]);

    useEffect(() => {
        async function redirect() {
            if (!pathsShouldNotBeRedirectedAtFirst.has(window.location.pathname)) {
                await redirectApp();
            }
            dispatch(setAppInited());
        }
        if (isFirebaseLoginInited) {
            redirect();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFirebaseLoginInited]);

    return { isInited };
}

export function useRedirectApp() {
    const dispatch = useDispatch();
    const history = useHistoryWithCurrentSearchHash();
    const location = useLocation();
    const isValidEmailDomain = useCheckIsValidEmailDomain();

    const { inviteCode, inviteRole } = useSelector(s => s.app);

    async function redirectApp(data) {
        const isLogin = auth.currentUser !== null;
        const emailVerified = isLogin && auth.currentUser.emailVerified;
        const email = isLogin && auth.currentUser.email;

        if (isLogin) {
            if (!emailVerified) {
                if (Boolean(auth.currentUser.providerData.find(p => p.providerId === 'password')))
                    toVerifiedUpdateEmailPage(dispatch, history, email);
                else {
                    // MISSING_IDENTIFIER
                    if (window.location.href.includes('provider_id')) {
                        toEmailVerifyLandingPage(history);
                    } else {
                        toVerifiedEmailPage(dispatch, history);
                    }
                }
            } else {
                await initFeverUserData(data);
            }
        } else {
            const { search } = window.location;
            const { redirectAction } = querystring.parse(search);
            switch (true) {
                case redirectAction === 'updateEmailComplete':
                    toUpdateEmailCompletePage(history);
                    break;
                default:
                    toSignInPage(history);
                    break;
            }
        }
    }
    async function initFeverUserData(data) {
        const { pathname: currentPathname, search } = window.location;
        const { refUrl, merchant_id: merchantId } = querystring.parse(search);
        let account = await getUserAccountData();
        if (!account) {
            if (isValidEmailDomain(auth.currentUser.email)) {
                const hasOldAccountData = await getIsHasOldAccountInfoApi().then(
                    ({ exist } = {}) => exist
                );
                if (hasOldAccountData) {
                    // recover old account data and overwrite email
                    await createAccountApi({
                        inviteToken: inviteCode,
                        settings: { email: auth.currentUser.email }
                    });
                    account = await getUserAccountData();
                }
                if (!account) {
                    return toCreateAccountPage(history);
                }
            } else {
                return toInvalidDomainErrorPage();
            }
        }
        if (account) {
            dispatch(initAccount(account));
        }

        const partner = await getUserPartner();
        if (partner) {
            dispatch(initPartner(partner));
        }

        if (currentPathname === paths.LOGIN) {
            gtmPushLogin({ partner, account });
        }
        const sponsors = await getUserSponsors();
        const hasAnySponsors = Array.isArray(sponsors) && sponsors.length !== 0;
        if (
            data?.action === 'doLogin' &&
            hasAnySponsors &&
            auth.currentUser.email !== account.email
        ) {
            try {
                await updateAccountEmail({
                    sponsorId: sponsors[0].id,
                    adminUuid: account.uuid,
                    body: { email: auth.currentUser.email }
                });
            } catch (error) {
                console.log('error', error);
            }
        }

        if (data?.action === 'doLogin') {
            Cookies.set('last_login_host', window.location.host, {
                domain: '.feversocial.com'
            });
        }

        // 剛輸入建立完自己的 admin + 是被邀請到他人的 sponsor 做編輯修改，所以 sponsor list 有資料，但是自己並沒有建立自己的 sponsor
        // 已建立完自己的 admin + 是被邀請到他人的 sponsor 做編輯修改，所以 sponsor list 有資料，但是自己並沒有建立自己的 sponsor
        // 已建立完自己的 admin + 是被邀請到他人的 sponsor 做編輯修改 + 已建立自己的 sponsor，所以 sponsor list 有資料
        if (account && hasAnySponsors && Boolean(refUrl)) {
            return (window.location.href = refUrl);
        }

        if (currentPathname === paths.CREATE_INVITATION) {
            return;
        } else if (Boolean(refUrl) && refUrl.includes(paths.CREATE_INVITATION)) {
            return (window.location.href = refUrl);
        }

        // 有 merchantId 就知道是要走後端建立 sponsor 流程，帶此user去授權拿 state，在用 state 換新的 sponsor Id 或是若已換過就直接登入進後台
        if (merchantId) {
            const platform = window.location.hostname.split('.')[0];
            // access 此變數 在 domain 是 dev.feversocial.com、local.feversocial.com、feversocial.com 時，直接給 shopline 是因為在開發時，我要傳
            // shopline 作為 platform 給 API 當參數，但此情形只有 shopline.feversocial.com 能符合，待在上 prod 前要能 work，此行需要寫死
            const access = ['dev', 'local', 'feversocial'].includes(platform)
                ? 'shopline'
                : platform;
            return toThirdPartyOAuthInitFromEC(access, search);
        }

        if (!hasAnySponsors) {
            const { state } = querystring.parse(search);
            // 確定不是要走後端建立 sponsor 流程
            if (!state && !merchantId) {
                return toCreateSponsorPage(history);
            }
        }

        if (
            inviteRole === ON_SITE_STAFF &&
            currentPathname !== `/admin${adminRouteKeys.ON_SITE_ASSISTANT}`
        ) {
            history.replace({
                ...location,
                pathname: `/admin${adminRouteKeys.ON_SITE_ASSISTANT}`
            });
        }

        // 在無權限頁不需要往下執行 call API 拿 sponsor data 的行為
        if (currentPathname === paths.PERMISSION_DENIED) {
            return;
        }

        // sponsor 跟 role OK 都要是 200 ，response 才有值
        let response = await getSponsorAndHandleNoPermission(sponsors);

        if (response?.sponsor !== undefined) {
            gtmPushInitData({ partner, account, sponsor: response.sponsor });
            dispatch(initSponsor(response.sponsor));
            dispatch(initAvailablePlanItems(response.availablePlanItems));
            dispatch(initSponsorList(appendSponsorIfNotInList(sponsors, response.sponsor)));
            dispatch(switchLocale(account.language));
            redirectAfterDataInited(history);
        } else {
            gtmPushInitData({ partner, account });
            dispatch(initPartner(partner));
            dispatch(switchLocale(account.language));
        }
    }
    async function toInvalidDomainErrorPage() {
        await signout();
        dispatch(setLoginSignUpErrorType(loginSignUpErrorTypes.INVALID_EMAIL_DOMAIN));
        history.push({ pathname: paths.ERROR });
    }

    async function getSponsorAndHandleNoPermission(sponsors) {
        let result = {};
        const { pathname: currentPathname, search } = window.location;
        const { sponsorId: sponsorIdFromQs, state: sponsorIdFromState } = querystring.parse(search);
        // 從 shopline 後台去建立 sponsor 的過程中，此 user 不需要透過一建立 sponsor 的流程建立 sponsor，所以要繞過
        if (sponsors.length <= 0 && !Boolean(sponsorIdFromState)) {
            return toCreateSponsorPage(history);
        }

        let sponsorIndex = 0;
        // sponsorId 權重是 state in url > sponsorId in url > sponsor in cookie > sponsor list 第一筆
        const isOnSiteStaffRole = roleName => getRoleTypeByRoleName(roleName) === ON_SITE_STAFF;
        const retrieveAdminRoleFromSponsorsList = async (sponsors, index) => {
            if (!Boolean(sponsors[index])) return toCreateSponsorPage(history);
            const currentSponsor = sponsors[index];
            let response = await getSponsor(currentSponsor?.id);
            // sponsor A 訪問 sponsor B 頁面遇到 403 權限不足，倒轉去 "無使用權限" 頁面
            if (response === 403) {
                toPermissionNotDeniedPage(history);
            }
            // sponsor A 訪問 sponsor B 頁面遇到此 sponsor 是 on site staff，就往下檢查下一個 sponsor 是否有 admin 權限能讓我去訪問 dashboard
            // 如果不是透過邀請連結的 On-site-staff 權限線進入 dashboard 會去 403 頁面，
            // 有邀請連結的話(inviteCode)，會自動倒轉到 on site tool 頁面
            // 從 403 無權限到 app/login 頁面，若發現此 user 無建立過自己的 sponsor，只有被人邀請當 On-site-staff ，就直接帶他送去建立 sponsor 頁面
            if (
                !Boolean(inviteCode) &&
                currentPathname === paths.LOGIN &&
                isOnSiteStaffRole(response?.sponsor?.accountRole?.roleName)
            ) {
                sponsorIndex++;
                return retrieveAdminRoleFromSponsorsList(sponsors, sponsorIndex);
            }
            // sponsor A 訪問 sponsor B 頁面遇到 非預期錯誤，用其他 sponsor id 再 call 一次
            if (response === null) {
                const fallbackSponsorId = sponsors[0]?.id;
                response = await getSponsor(fallbackSponsorId);
            }
            return response;
        };

        const currentSelectedSponsorId = await (async () => {
            const latestSelectedSponsorId = Cookie.get(cookieKeys.LATEST_SELECTED_SPONSOR_ID);
            switch (true) {
                case Boolean(sponsorIdFromState && !sponsorIdFromQs):
                    const sponsorId = await bindingECommerceSponsor({
                        state: sponsorIdFromState
                    });
                    return sponsorId;
                case Boolean(sponsorIdFromQs):
                    return sponsorIdFromQs;
                case Boolean(latestSelectedSponsorId):
                    return latestSelectedSponsorId;
                default:
                    return null;
            }
        })();
        if (currentSelectedSponsorId === null) {
            result = retrieveAdminRoleFromSponsorsList(sponsors, sponsorIndex);
        } else {
            result = await getSponsor(currentSelectedSponsorId);
            if (isOnSiteStaffRole(result?.sponsor?.accountRole?.roleName)) {
                Cookie.remove(cookieKeys.LATEST_SELECTED_SPONSOR_ID);
                result = retrieveAdminRoleFromSponsorsList(sponsors, sponsorIndex);
            }
        }

        return result;
    }

    return redirectApp;
}

export const APP_MAIN_PATH = `${basenameRouteKeys.ADMIN}${adminRouteKeys.DASHBOARD}`;
export const paths = {
    LOGIN: '/app/login',
    SITE_LOGIN: '/app/site/login',
    SIGN_UP: '/app/signup',
    EMAIL_VERIFY: '/app/email_verify',
    EMAIL_VERIFY_LANDING: '/app/email_verify_landing',
    UPDATE_EMAIL_COMPLETE: '/app/update_email_complete',
    ERROR: '/app/error',
    PERMISSION_DENIED: '/app/permission_denied',
    CREATE_ACCOUNT: '/create/account',
    CREATE_SPONSOR: '/create/sponsor',
    CREATE_INVITATION: '/create/invitation',
    UTILS_IMG_READER: '/utils/img_reader'
};
export const pathsShouldNotSetAsRefUrl = new Set([
    paths.LOGIN,
    paths.SITE_LOGIN,
    paths.SIGN_UP,
    paths.CREATE_ACCOUNT,
    paths.CREATE_SPONSOR,
    paths.EMAIL_VERIFY,
    paths.EMAIL_VERIFY_LANDING,
    paths.ERROR,
    paths.UPDATE_EMAIL_COMPLETE,
    paths.PERMISSION_DENIED
]);
const pathsAvailableWhenNoLogin = new Set([paths.LOGIN, paths.SITE_LOGIN, paths.SIGN_UP]);
const pathsShouldNotBeRedirectedAtFirst = new Set([paths.EMAIL_VERIFY_LANDING]);

function redirectAfterDataInited(history) {
    const { pathname, search } = window.location;
    if (pathsAvailableWhenNoLogin.has(pathname) || pathname === paths.EMAIL_VERIFY_LANDING) {
        history.replace({
            pathname: APP_MAIN_PATH,
            search
        });
    }
}
function pathsNoNeedAppInit() {
    const { pathname } = window.location;
    return pathname.startsWith('/utils');
}

export function toSignInPage(history) {
    const { pathname } = window.location;
    if (!pathsAvailableWhenNoLogin.has(pathname)) {
        history.replace({
            pathname: paths.LOGIN,
            search: getQueryStringHandleRefUrl()
        });
    }
}

function toVerifiedEmailPage(dispatch, history) {
    dispatch(
        setVerifyEmailInfo({
            verifyEmailType: verifyEmailTypes.EMAIL_VERIFY
        })
    );
    const { pathname } = window.location;
    if (pathname !== paths.EMAIL_VERIFY) {
        history.replace({
            pathname: paths.EMAIL_VERIFY,
            search: getQueryStringHandleRefUrl()
        });
    }
}

function toEmailVerifyLandingPage(history) {
    const { pathname } = window.location;
    if (pathname !== paths.EMAIL_VERIFY) {
        history.replace({
            pathname: paths.EMAIL_VERIFY_LANDING,
            search: getQueryStringHandleRefUrl()
        });
    }
}

function toUpdateEmailCompletePage(history) {
    const { pathname } = window.location;
    if (pathname !== paths.EMAIL_VERIFY) {
        history.replace({
            pathname: paths.UPDATE_EMAIL_COMPLETE,
            search: getQueryStringHandleRefUrl()
        });
    }
}

function toVerifiedUpdateEmailPage(dispatch, history, email) {
    dispatch(
        setVerifyEmailInfo({
            verifyEmailType: verifyEmailTypes.UPDATE_EMAIL,
            [queryStrKeys.UPDATE_EMAIL_TO]: email
        })
    );
    const { pathname } = window.location;
    if (pathname !== paths.EMAIL_VERIFY) {
        history.replace({
            pathname: paths.EMAIL_VERIFY,
            search: getQueryStringHandleRefUrl()
        });
    }
}
export function toCreateAccountPage(history) {
    const { pathname } = window.location;
    if (pathname !== paths.CREATE_ACCOUNT)
        history.replace({
            pathname: paths.CREATE_ACCOUNT,
            search: getQueryStringHandleRefUrl()
        });
}

function toCreateSponsorPage(history) {
    const { pathname } = window.location;
    if (pathname !== paths.CREATE_SPONSOR) {
        history.replace({
            pathname: paths.CREATE_SPONSOR,
            search: getQueryStringHandleRefUrl()
        });
    }
}

export function toThirdPartyOAuthInitFromEC(platform, search) {
    window.location.href = `${FEVER_API_ENDPOINT}/services/ec/oauth/${platform}/from/ec/init${search}`;
}

export function toThirdPartyOAuthInitFromFever(platform) {
    window.location.href = `${FEVER_API_ENDPOINT}/services/ec/oauth/${platform}/from/fever/init`;
    return `${FEVER_API_ENDPOINT}/services/ec/oauth/${platform}/from/fever/init`;
}

export function toPermissionNotDeniedPage(history) {
    const { pathname } = window.location;
    if (pathname !== paths.PERMISSION_DENIED) {
        history.replace({
            pathname: paths.PERMISSION_DENIED,
            search: getQueryStringHandleRefUrl()
        });
    }
}

function getQueryStringHandleRefUrl() {
    const { href, pathname, search } = window.location;
    const { refUrl } = querystring.parse(search);
    const nextRefUrl = (() => {
        if (refUrl) {
            return refUrl;
        } else if (!pathsShouldNotSetAsRefUrl.has(pathname)) {
            return href;
        } else {
            return undefined;
        }
    })();
    return search.includes('merchant_id') ? search : querystring.stringify({ refUrl: nextRefUrl });
}
