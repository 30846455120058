export const EXCEPTION_FAILED = 'Exception-Fail';
export const COMPONENT_CRASH = 'Component-Crash';
export const DOWNLOAD_WINNER_REPORT_FAILED = 'Download-Winner-Report-Failed';
export const UPLOAD_FILE_FAILED = 'Upload-File-Failed';
export const CREATE_ACCOUNT_FAILED = 'Create-Account-Failed';
export const CREATE_SPONSOR_FAILED = 'Create-Sponsor-Failed';
export const VALIDATE_INVITE_CODE_FAILED = 'Validate-Invite-Code-Failed';
export const UPDATE_ACCOUNT_INFO_FLOW_FAILED = 'Update-Account-Info-Flow-Failed';
export const SYNC_FB_RELATED_OLD_SPONSOR_FAILED = 'Sync-FB-Related-Old-Sponsor-Failed';
export const GET_ON_SITE_ASSISTANT_PROMO_LIST_FAILED = 'Get-On-Site-Assistant-Promo-List-Failed';
export const SWITCH_POINT_POOL_FAILED = 'Switch-Point-Pool-Failed';
export const SEARCH_POINT_POOL_ENTRY_RECORD_FAILED = 'Search-Point-Pool-Entry-Record-Failed';
export const UPDATE_POINT_POOL_STATUS_FAILED = 'Update-Point-Pool-Status-Failed';
export const MANUAL_ADJUSTMENT_POINT_FAILED = 'Manual-Adjustment-Point-Failed';
export const UPDATE_POINT_POOL_NAME_FAILED = 'Update-Point-Pool-Name-Failed';
export const ENTRY_RECORD_DIALOG_GET_PROMOTION_FLOW_FAILED =
    'Entry-Record-Dialog-Get-Promotion-Flow-Failed';
export const GET_PROMO_LIST_BY_POINT_POOL_FAILED = 'Get-Promo-List-By-Point-Pool-Failed';
export const POINT_SYSTEM_INIT_FAILED = 'Point-System-Init-Failed';
export const GET_POINT_CENTER_DASHBOARD_DATA_FAILED = 'Get-Point-Center-Dashboard-Data-Failed';
export const CREATE_POINT_POOL_FLOW_FAILED = 'Create-Point-Pool-Flow-Failed';
export const DELETE_MEMBER_ENTRY_FAILED = 'Delete-Member-Entry-Failed';
export const RECOVER_MEMBER_ENTRY_FAILED = 'Recovery-Member-Entry-Failed';
export const CREATE_AND_UPDATE_ENTRY_VALUE_FAILED = 'Create-And-Update-Entry-Value-Failed';
export const UPDATE_STORE_REDEEM_STATUS_FAILED = 'Update-Store-Redeem-Status-Failed';
export const UPDATE_DISPLAY_TYPE_FAILED = 'Update-Display-Type-Failed';
export const GET_JOIN_ENTRY_FLOW_FAILED = 'Get-Join-Entry-Flow-Failed';
export const CLEAR_TEST_DATA_FAILED = 'Clear-Test-Data-Failed';
export const GET_PRIZE_REDEEM_REPORT_STATISTICS_FAILED =
    'Get-Prize-Redeem-Report-Statistics-Failed';
export const GET_UUID_BY_ENTRY_ID_FAILED = 'Get-Uuid-By-Entry-Id-Failed';
export const DRAW_PRIZE_WINNER_FAILED = 'Draw-Prize-Winner-Failed';
export const CREATE_WINNER_FAILED = 'Create-Winner-Failed';
export const UPDATE_PRIZE_ANNOUNCE_FLOW_FAILED = 'Update-Prize-Announce-Flow-Failed';
export const DELETE_PRIZE_WINNER_FLOW_FAILED = 'Delete-Prize-Winner-Flow-Failed';
export const UPDATE_STORE_REDEEM_STATUS_FLOW_FAILED = 'Update-Store-Redeem-Status-Flow-Failed';
export const CLAIM_TO_CARRIER_FLOW_FAILED = 'Claim-To-Carrier-Flow-Failed';
export const SEND_PRIZE_WINNER_EMAIL_FAILED = 'Send-Prize-Winner-Email-Failed';
export const GET_REFERRAL_RANK_LIST_FAILED = 'Get-Referral-Rank-List-Failed';
export const GET_REFERRAL_ANALYTICS_FLOW_FAILED = 'Get-Referral-Analytics-Flow-Failed';
export const GET_USED_REFERRAL_CODE_SEARCH_FLOW_FAILED =
    'Get-Used-Referral-Code-Search-Flow-Failed';
export const GET_PROMO_RELATED_FAILED = 'Get-Promo-Related-Failed';
export const PUBLISH_PROMO_FLOW_FAILED = 'Publish-Promo-Flow-Failed';
export const CREATE_PROMO_WITH_TEMPLATE_FLOW_FAILED = 'Create-Promo-With-Template-Flow-Failed';
export const CREATE_INVITE_CODE_FAILED = 'Create-Invite-Code-Failed';
export const GET_ROLE_LIST_FAILED = 'Get-Role-List-Failed';
export const GET_ADMIN_LIST_FAILED = 'Get-Admin-List-Failed';
export const DELETE_ADMIN_FAILED = 'Delete-Admin-Failed';
export const UPDATE_ADMIN_ROLE_FAILED = 'Update-Admin-Role-Failed';
export const UPDATE_SPONSOR_DATA_FAILED = 'Update-Sponsor-Data-Failed';
export const USE_INFINITE_SCROLL_FAILED = 'Use-Infinite-Scroll-Failed';
export const ADD_GLOBAL_POOL_POINT_FAILED = 'Add-Global-Pool-Point-Failed';
export const SET_GLOBAL_POINT_POOL_TO_SPONSOR_FAILED = 'Set-Global-Point-Pool-To-Sponsor-Failed';
export const ALLOCATION_POINTS_LIMIT_EXCEEDED = 'Allocation-Points-Limit-Exceeded';
export const GET_GLOBAL_POOL_REMAIN_ALLOCATION_POINT_FAILED =
    'Get-Global-Pool-Remain-Allocation-Point-Failed';
export const CANCEL_ALLOCATION_OR_ADDITION_POINT_FAILED =
    'Cancel-Allocation-Or-Addition-Point-Failed';
export const CANCEL_ALLOCATION_OR_ADDITION_POINT_RECORD_NOT_FOUND =
    'Cancel-Allocation-Or-Addition-Point-Record-Not-Found';
export const CANCEL_ALLOCATION_OR_ADDITION_POINT_PROMO_PUBLISH =
    'Cancel-Allocation-Or-Addition-Point-Promo-Publish';
export const UPDATE_ALLOCATION_SPONSOR_ROLE_FAILED = 'Update-Allocation-Sponsor-Role-Failed';
export const DOWNLOAD_REPORT_FAILED = 'Download-Report-Failed';
export const GET_SPONSOR_REMAIN_ALLOCATION_POINT_FAILED =
    'Get-Sponsor-Remain-Allocation-Point-Failed';
export const GET_SPONSOR_GLOBAL_POINT_POOL_STATISTICS_FAILED =
    'Get-Sponsor-Global-Point-Pool-Statistics-Failed';
export const GET_GLOBAL_POINT_POOL_STATISTICS_FAILED = 'Get-Global-Point-Pool-Statistics-Failed';
export const INIT_ALLOCATION_POINT_FAILED = 'Init-Allocation-Point-Failed';
export const GET_ALLOCATION_SPONSOR_LIST_FAILED = 'Get-Allocation-Sponsor-List-Failed';
export const ABANDONED_RECEIPT_FAILED = 'Abandoned_Receipt_Failed';
export const CREATE_WEBHOOK_FAILED = 'Create-Webhook-Failed';
export const GET_MEMBER_STATISTICS_FAILED = 'Get-Member-Statistics-Failed';
export const GET_CHECKPOINT_CARDS_FAILED = 'Get-Checkpoint-Cards-Failed';
export const VERIFY_EMAIL_LANDING_FAILED = 'Verify-Email-Landing-Failed';
export const FIREBASE_SIGN_IN_FAILED = 'Firebase-Sign-In-Failed';
export const MANUAL_SIGN_IN_FAILED = 'Manual-Sign-In-Failed';
export const FIREBASE_SIGN_IN_CREDENTIAL_FAILED = 'Firebase-Sign-In-Credential-Failed';
export const FIREBASE_LINK_WITH_CREDENTIAL_FAILED = 'Firebase-Link-With-Credential-Failed';
export const FIREBASE_VERIFY_EMAIL_FAILED = 'Firebase-Verify-Email-Failed';
export const CREATE_CAMPAIGN_ONSITE_FAILED = 'Create-Campaign-Onsite-Failed';
export const CREATE_CAMPAIGN_ONSITE_MESSAGE_FAILED = 'Create-Campaign-Onsite-Message-Failed';
export const SAVE_CAMPAIGN_PREVIEW_URL_FAILED = 'Save-Campaign-Preview-Url-Failed';
export const CAMPAIGN_NOT_FOUND = 'Campaign-Not-Found';
export const MESSAGE_NOT_FOUND = 'Message-Not-Found';
export const SAVE_CAMPAIGN_DOMAIN_WHITE_LIST_FAILED = 'Save-Campaign-Domain-White-List-Failed';
export const PUBLISH_CAMPAIGN_DOMAIN_WHITE_LIST_FAILED =
    'Publish-Campaign-SDK-Domain-White-List-Failed';
export const PUBLISH_CAMPAIGN_TIMEZONE_VALIDATE_FAILED = 'Publish-Campaign-Setting-Timezone-Failed';
export const PUBLISH_CAMPAIGN_PLAN_VALIDATE_FAILED = 'Publish-Campaign-Plan-Invalid-Failed';
