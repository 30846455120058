/* global FB */
import { useState, useEffect, useCallback } from 'react';
import { FB_APP_ID, THIRD_PARTY_AUTH_ENDPOINT, IS_LOCAL_DEV } from 'config/envVariable';
import { getFacebookFanPage, storeFacebookFanPageToken, retryHookdeck } from 'api/sponsor';
import { authorizePlatformMapping } from 'features/authorizes/config';

const STATE_PARAM = '{st=state123abc,ds=123456789}';
const redirectUrl = IS_LOCAL_DEV
    ? `https://${window.location.host}${authorizePlatformMapping.meta.path}`
    : `${THIRD_PARTY_AUTH_ENDPOINT}${authorizePlatformMapping.meta.path}`;

const manuallyFacebookLoginFlowUrl = `https://www.facebook.com/v15.0/dialog/oauth?client_id=${FB_APP_ID}&redirect_uri=${encodeURIComponent(
    redirectUrl
)}&state=${STATE_PARAM}&auth_type=rerequest&response_type=token`;

export const AUTHORIZE_TYPE = {
    FACEBOOK: 'facebook',
    INSTAGRAM: 'instagram',
    REAUTHORIZATION: 'reauthorization'
};

export const reAuthFanPageToken = async (userToken, sponsorId, facebookFanPageId) => {
    try {
        const fanPageData = await getFacebookFanPage({
            sponsorId,
            shortLiveFacebookUserToken: userToken
        });
        const needToReAuthFanPage = fanPageData.find(page => page.id === String(facebookFanPageId));
        const needToReAuthFanPageId = needToReAuthFanPage.id || facebookFanPageId;
        await storeFacebookFanPageToken(sponsorId, needToReAuthFanPageId);
        await retryHookdeck({ sponsorId });
        return true;
    } catch (error) {
        console.error(error);
    }
};

export const getFansList = async (userToken, sponsorId) => {
    try {
        const fanPageData = await getFacebookFanPage({
            sponsorId,
            shortLiveFacebookUserToken: userToken
        });
        const facebookFanPageList = fanPageData.map(page => getFanPage(page));

        return facebookFanPageList;
    } catch (error) {
        console.error(error);
    }
};

export const getAccountList = async (userToken, sponsorId) => {
    try {
        const fanPageData = await getFacebookFanPage({
            sponsorId,
            shortLiveFacebookUserToken: userToken
        });
        const igData = fanPageData.map(async page => await getIGAccount(page, userToken));
        const result = await Promise.all(igData);
        const instagramAccountList = result.filter(item => item.igUserId);

        return instagramAccountList;
    } catch (error) {
        console.error(error);
    }
};

export const getRedirectToFBAuthPagePath = ({
    authType,
    facebookFanPageId,
    instagramBusinessAccountId
}) => {
    switch (authType) {
        case AUTHORIZE_TYPE.FACEBOOK:
            return `${manuallyFacebookLoginFlowUrl}&scope=pages_show_list,pages_read_engagement,pages_manage_metadata,business_management`;
        case AUTHORIZE_TYPE.INSTAGRAM:
            return `${manuallyFacebookLoginFlowUrl}&scope=pages_show_list,pages_read_engagement,pages_manage_metadata,instagram_basic,instagram_manage_messages,instagram_manage_comments,business_management`;
        case AUTHORIZE_TYPE.REAUTHORIZATION:
            if (Boolean(facebookFanPageId))
                return `${manuallyFacebookLoginFlowUrl}&scope=pages_show_list,pages_read_engagement,pages_manage_metadata,business_management`;
            if (Boolean(facebookFanPageId) && Boolean(instagramBusinessAccountId))
                return `${manuallyFacebookLoginFlowUrl}&scope=pages_show_list,pages_read_engagement,pages_manage_metadata,instagram_basic,instagram_manage_messages,instagram_manage_comments,business_management`;
            break;
        default:
            console.error(
                'Can not get authType, facebookFanPageId, instagramBusinessAccountId:',
                authType,
                facebookFanPageId,
                instagramBusinessAccountId
            );
            return `${manuallyFacebookLoginFlowUrl}&scope=pages_show_list,pages_read_engagement,pages_manage_metadata,business_management`;
    }
};

export async function getUserInfo() {
    return new Promise(async (res, rej) => {
        FB.api('/me?fields=email,name', function (response) {
            if (response.error) {
                rej(response.error);
            } else {
                res(response);
            }
        });
    });
}

// NOTE: SDK 拿 Fan Page 方式暫時不用，因爲改成單一同視窗導轉
export async function getFansPages() {
    return new Promise(async (res, rej) => {
        FB.api('/me?fields=accounts{app_id,name,access_token}', function (response) {
            if (response.error) {
                rej(response.error);
            } else {
                res(response);
            }
        });
    });
}

// NOTE: SDK 拿 IG Account 方式暫時不用，因爲改成單一同視窗導轉
export async function getIGAccounts() {
    return new Promise((resolve, reject) => {
        FB.api('/me/accounts?fields=instagram_business_account{name,username,id}', function (
            response
        ) {
            if (response.error) {
                reject(response.error);
            } else {
                resolve(response);
            }
        });
    });
}

// 呼叫 FB 登入&授權視窗，scope 是粉絲專頁權限
export function getAuthFanspage(callback) {
    FB.login(
        function () {
            callback();
        },
        { scope: 'pages_show_list,pages_read_engagement,pages_manage_metadata,business_management' }
    );
}

// 呼叫 FB 登入&授權視窗，scope 是IG商業帳號權限 + 粉絲專頁權限
export function getAuthIGAccount(callback) {
    FB.login(
        function () {
            callback();
        },
        {
            scope:
                'pages_show_list,pages_read_engagement,pages_manage_metadata,instagram_basic,instagram_manage_messages,instagram_manage_comments,business_management'
        }
    );
}

export function getFanPage(page) {
    const { id: pageId, name: pageName } = page;
    let result = {
        pageId,
        pageName
    };
    return result;
}

export async function getIGAccount(page, userToken) {
    const { id: pageId, name: pageName } = page;
    let result = {
        pageId,
        pageName
    };
    try {
        const instagramData = await (
            await fetch(
                `https://graph.facebook.com/v15.0/${pageId}?fields=instagram_business_account{name,username,id}&access_token=${userToken}`
            )
        ).json();
        result.igUserId = instagramData?.instagram_business_account?.id;
        result.igUserName = instagramData?.instagram_business_account?.name;
        result.igUserAccount = instagramData?.instagram_business_account?.username;
    } catch (error) {
        result.error = {
            message: error.message
        };
    }
    return result;
}

// NOTE: Manually Build a Login Flow
export function getAuthFanpageManually() {
    window.location.href = getRedirectToFBAuthPagePath({ authType: AUTHORIZE_TYPE.FACEBOOK });
}

// NOTE: Manually Build a Login Flow
export function getAuthIGAccountManually() {
    window.location.href = getRedirectToFBAuthPagePath({ authType: AUTHORIZE_TYPE.INSTAGRAM });
}

export async function getLongLivePageToken({ facebookFanPageId, longLivedUserAccessToken }) {
    const resp = await (
        await fetch(
            `https://graph.facebook.com/${facebookFanPageId}?fields=access_token&access_token=${longLivedUserAccessToken}`
        )
    ).json();
    return resp;
}

export async function getPageDetail({ facebookFanPageId, facebookFanPageToken }) {
    const resp = await (
        await fetch(
            `https://graph.facebook.com/${facebookFanPageId}?fields=name&access_token=${facebookFanPageToken}`
        )
    ).json();
    return resp;
}

export function useInitFacebookSDK({ shouldInit = true }) {
    const [initFb, setInitFb] = useState(false);
    const [shortLivedUserToken, setShortLivedUserToken] = useState(null);

    // Function to trigger Facebook login manually
    const loginWithFacebook = useCallback(() => {
        return new Promise((resolve, reject) => {
            if (initFb) {
                FB.login(
                    function (response) {
                        if (response.authResponse) {
                            const accessToken = response.authResponse.accessToken;
                            setShortLivedUserToken(accessToken);
                            resolve(accessToken);
                        }
                    },
                    { scope: 'public_profile,email' }
                );
            } else {
                reject('Facebook SDK not initialized');
            }
        });
    }, [initFb]);

    // Handle the login status
    const handleLoginStatus = useCallback(response => {
        if (response.status === 'connected') {
            const accessToken = response.authResponse.accessToken;
            setShortLivedUserToken(accessToken);
        }
    }, []);

    useEffect(() => {
        // Load the Facebook SDK script
        function loadFbLoginApi() {
            window.fbAsyncInit = function () {
                FB.init({
                    appId: FB_APP_ID,
                    status: true,
                    cookie: true,
                    xfbml: true,
                    version: 'v20.0'
                });

                // Once the SDK is initialized, set initFb to true
                setInitFb(true);
                // Optionally check login status immediately after init
                FB.getLoginStatus(function (response) {
                    handleLoginStatus(response);
                });

                FB.Event.subscribe('auth.authResponseChange', handleLoginStatus);
            };

            // Load the SDK asynchronously
            (function (d, s, id) {
                let js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) return;
                js = d.createElement(s);
                js.id = id;
                js.src = 'https://connect.facebook.net/en_US/sdk.js';
                fjs.parentNode.insertBefore(js, fjs);
            })(document, 'script', 'facebook-jssdk');
        }

        if (!initFb && shouldInit) {
            loadFbLoginApi();
        }
    }, [handleLoginStatus, initFb, shouldInit]);

    return { initFb, shortLivedUserToken, loginWithFacebook };
}
