import querystring from 'query-string';

export const APP_INIT = '@app/APP_INIT';
export const UPDATE_APP_STATE = '@app/UPDATE_APP_STATE';

const initState = {
    name: '',
    isInited: false,
    adminEmailDomains: [],
    adminGidpProviders: [],
    inviteCode: (() => {
        const { inviteCode, refUrl = '' } = querystring.parse(window.location.search);
        const {
            query: { inviteCode: inviteCodeInRefUrl }
        } = querystring.parseUrl(refUrl);
        return inviteCode ?? inviteCodeInRefUrl ?? undefined;
    })(),
    inviteRole: (() => {
        const { inviteRole, refUrl = '' } = querystring.parse(window.location.search);
        const {
            query: { inviteRole: inviteRoleInRefUrl }
        } = querystring.parseUrl(refUrl);
        return inviteRole ?? inviteRoleInRefUrl ?? undefined;
    })()
};

export default function reducer(state = initState, action) {
    switch (action.type) {
        case APP_INIT:
            return { ...state, isInited: true };
        case UPDATE_APP_STATE:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}

export function setAppInited() {
    return {
        type: APP_INIT
    };
}

export function setAppState({ name, adminEmailDomains, adminGidpProviders }) {
    return {
        type: UPDATE_APP_STATE,
        payload: {
            name,
            adminEmailDomains: adminEmailDomains ?? [],
            adminGidpProviders: adminGidpProviders ?? []
        }
    };
}
