import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Divider from '@material-ui/core/Divider';

import { above } from 'utils/breakpoint';

import SelectorAdminLang from 'features/login/components/SelectorAdminLang';

export default function Navbar() {
    const { t } = useTranslation();

    return (
        <div css={navBarWrapperStyle}>
            <a href="https://info.feversocial.com/tw/" target="_blank" rel="noopener noreferrer">
                {t('label.home')}
            </a>
            <Divider css={dividerStyle} orientation="vertical" />
            <a
                href="https://info.feversocial.com/tw/products-358"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('promo_header_tools')}
            </a>
            <Divider css={dividerStyle} orientation="vertical" />
            <a
                href="https://info.feversocial.com/tw/campaign-manager-6"
                target="_blank"
                rel="noopener noreferrer"
            >
                {t('pagelist_table_promo_module')}
            </a>
            <Divider css={dividerStyle} orientation="vertical" />
            <a href="https://info.feversocial.com/help" target="_blank" rel="noopener noreferrer">
                {t('label.help_center')}
            </a>
            <Divider css={dividerStyle} orientation="vertical" />
            <SelectorAdminLang color={`#fff`} />
            <Divider css={dividerStyle} orientation="vertical" />
        </div>
    );
}

const navBarWrapperStyle = css`
    color: #fff;
    > a {
        color: inherit;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    ${above('xlg')} {
        margin-bottom: 40px;
    }
`;

const dividerStyle = css`
    margin: 0 24px;
`;
