import { toLowerCase } from 'utils/manipulateString';

const errObjTypes = {
    CHUNK_LOAD_ERROR: 'chunkloaderror',
    TYPE_ERROR: 'typeerror',
    ABORT_ERROR: 'aborterror'
};

export function isChunkLoadError(errObj = {}) {
    const errName = toLowerCase(errObj.name);
    const errCode = toLowerCase(errObj.code);
    const errMessage = toLowerCase(errObj.message);
    return [
        errName === errObjTypes.CHUNK_LOAD_ERROR,
        errCode === 'css_chunk_load_failed',
        errMessage.includes('loading chunk')
    ].some(e => e);
}

export function isUnauthorizedToGetAccessToken(errObj = {}) {
    const { status, code, message } = normalizeApiErrInfo(errObj);
    return status === 401 || (code === 'lai-au0-f0' && message === 'token is required');
}

export function isTooManyAttemptErr(errObj) {
    const { status, message } = normalizeApiErrInfo(errObj);
    return status === 429 && message === 'too many attempts.';
}

export function isFetchNetworkError(errObj = {}) {
    const errName = toLowerCase(errObj.name);
    const errMessage = toLowerCase(errObj.message);
    return (
        errName === errObjTypes.ABORT_ERROR ||
        (errName === errObjTypes.TYPE_ERROR &&
            [
                errMessage === '已取消',
                errMessage === 'cancelled',
                errMessage === '要求逾時。',
                errMessage === 'the request timed out.',
                errMessage === 'network request failed',
                errMessage === 'the network connection was lost.',
                errMessage === '網路連線中斷。',
                errMessage === 'internet 連線已斷開。',
                errMessage === 'internet連線已斷開。',
                errMessage === '無法完成作業。軟體導致連線失敗',
                errMessage === 'networkerror when attempting to fetch resource.',
                errMessage === '無法連接伺服器。'
            ].some(e => e))
    );
}

function isOneOfErrors(checkers = [], errObj) {
    return checkers.some(c => c(errObj));
}

function isWebKitError(errObj = {}) {
    const errName = toLowerCase(errObj.name);
    const errMessage = toLowerCase(errObj.message);
    return errName === errObjTypes.TYPE_ERROR && [errMessage.startsWith('webkit')].some(e => e);
}

function isDNSLookupError(errObj = {}) {
    const errName = toLowerCase(errObj.name);
    const errMessage = toLowerCase(errObj.message);
    return (
        errName === errObjTypes.TYPE_ERROR &&
        [errMessage.includes('無法找到指定主機名稱的伺服器')].some(e => e)
    );
}

function isSSLConnectionError(errObj = {}) {
    const errName = toLowerCase(errObj.name);
    const errMessage = toLowerCase(errObj.message);
    return (
        errName === errObjTypes.TYPE_ERROR &&
        [errMessage.includes('發生ssl錯誤，無法建立與伺服器的安全連線')].some(e => e)
    );
}

function isFailedToLoadError(errObj = {}) {
    const errName = toLowerCase(errObj.name);
    const errMessage = toLowerCase(errObj.message);
    return errName === errObjTypes.TYPE_ERROR && errMessage === 'load failed';
}

function isFailedToFetchError(errObj = {}) {
    const errName = toLowerCase(errObj.name);
    const errMessage = toLowerCase(errObj.message);
    return errName === errObjTypes.TYPE_ERROR && errMessage === 'failed to fetch';
}

function isPreFlightError(errObj = {}) {
    const errName = toLowerCase(errObj.name);
    const errMessage = toLowerCase(errObj.message);
    return (
        errName === errObjTypes.TYPE_ERROR && errMessage === 'preflight response is not successful'
    );
}

function isCORSDueToServerNoResponse(errObj = {}) {
    // 當 server 無法回應時，包括無法回出正確 header 時，因為 header 沒有 allow origin 瀏覽器會當作 cors
    const errName = toLowerCase(errObj.name);
    const errMessage = toLowerCase(errObj.message);
    return (
        errName === errObjTypes.TYPE_ERROR &&
        errMessage.includes('is not allowed by access-control-allow-origin')
    );
}

function normalizeApiErrInfo(errObj = {}) {
    const { method, httpStatus, payload: { code, data, message } = {} } =
        errObj.extraInfo ?? errObj;
    return {
        method,
        status: httpStatus,
        data: data ?? {},
        code: String(code).toLowerCase(),
        message: String(message).toLowerCase()
    };
}

const feverErrCodes = {
    FAILED_TO_FETCH: 'fv-g-ff-001',
    FAILED_TO_LOAD: 'fv-g-fl-001',
    ERR_PREFLIGHT: 'fv-g-pf-001',
    FAILED_RESP_CORS: 'fv-g-sfc-001',
    ERR_DNS_LOOK_UP: 'fv-g-dl-001',
    ERR_SSL_CONNECT: 'fv-g-ssl-001',
    ERR_WEBKIT: 'fv-g-wk-001',
    ERR_DUPLICATE_GET_WINNER: 'fv-p-gw-001',
    ERR_FORM_DUPLICATE: 'fv-f-001',
    ERR_CREATE_ENTRIES_TIMEOUT: 'fv-ce-001'
};

export function getGeneralErrCode(errObj) {
    switch (true) {
        case isFailedToFetchError(errObj):
            return feverErrCodes.FAILED_TO_FETCH;
        case isFailedToLoadError(errObj):
            return feverErrCodes.FAILED_TO_LOAD;
        case isPreFlightError(errObj):
            return feverErrCodes.ERR_PREFLIGHT;
        case isCORSDueToServerNoResponse(errObj):
            return feverErrCodes.FAILED_RESP_CORS;
        case isDNSLookupError(errObj):
            return feverErrCodes.ERR_DNS_LOOK_UP;
        case isSSLConnectionError(errObj):
            return feverErrCodes.ERR_SSL_CONNECT;
        case isWebKitError(errObj):
            return feverErrCodes.ERR_WEBKIT;
        default:
            return '';
    }
}

export function isNetworkResourceError(errObj = {}) {
    return isOneOfErrors([isChunkLoadError, isFetchNetworkError], errObj);
}
