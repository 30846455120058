import i18next from 'i18next';
import * as formTypes from 'config/formTypes';
import * as lotteryTypes from 'config/promoLotteryTypes';
import { planKeys } from 'config/planKeys';

/**
 * Game 類型
 *
 * - 2: 心理測驗 (formbased)
 * - 7: 登錄給獎 (空 formbased)
 * - 5: 問答小學堂 (formbased)
 * - 6: 問卷調查 (formbased)
 * - 8: 大輪盤 (空 formbased + 純獎)
 * - 9: 議題投票 (formbased)
 * - 10: 戳戳樂 (空 formbased + 純獎)
 * - 11: 刮刮卡 (空 formbased + 純獎)
 * - 12: 發票 (formbased)
 * - 13: 紅包雨 (空 formbased + 純獎)
 * - 14: 圖片上傳 (ugc)
 * - 15: 簽到模組
 * - 16: 徵圖比賽 (投票 + ugc 14)
 * - 17: 創意相框 (ugc)
 * - 18: 拉霸 (空 formbased + 純獎)
 * - 19: 自由選
 * - 20: 翻牌遊戲
 * - 21: 留言模組 (ugc)
 * - 22: 票選比賽 (投票 + admin 上稿 ugc 14 / ugc 25)
 * - 23: 圖片闖關
 * - 24: 相框合成比賽 (投票 + ugc 17)
 * - 25: 影片上傳 (ugc)
 * - 26: 徵片比賽 (投票 + ugc 25)
 * - 27: IG 模組
 * - 28: 發票 4.0
 * - 32: 點數兌獎
 * - 33: 邀請模組
 * - 34: 分享獎
 * - 35: 註冊模組
 * - 36: 多元任務模組
 * - 37: 訂單模組
 * - 38: 購物車模組
 */
export const promotionTypes = {
    PSYCHO_QUIZ: 2,
    QUIZ: 5,
    SURVEY: 6,
    SIGN_FORM: 7,
    WIN_WHEEL: 8,
    VOTE: 9,
    POKE_GAME: 10,
    SCRATCH_CARD: 11,
    INVOICE: 12,
    RED_ENVELOPE_RAIN: 13,
    PHOTO_UPLOAD: 14,
    CHECK_IN: 15,
    CONTEST_PHOTO_UPLOAD: 16,
    PHOTO_FRAME_COMPOSITE: 17,
    SLOTS: 18,
    PICK_THE_PRIZE: 19,
    MEMORY_MATCH: 20,
    COMMENT: 21,
    CONTEST_ADMIN_UPLOAD: 22,
    CHALLENGE_PHOTO_FRAME_COMPOSITE: 23,
    CONTEST_PHOTO_FRAME_COMPOSITE: 24,
    VIDEO_UPLOAD: 25,
    CONTEST_VIDEO_UPLOAD: 26,
    INSTAGRAM: 27,
    PURCHASE_REWARD: 28,
    POINTS_REDEEM: 32,
    MGM_SHARE: 33,
    MGM_REFERRAL: 34,
    SIGN_UP: 35,
    MULTI_CHALLENGE: 36,
    ORDER_REWARD: 37,
    CART_REWARD: 38
};

// NOTE: 用於建立活動時，Modal 上的 switch tab 需要分類 [#866a2ebwt]
export const promoCategoryType = {
    ALL: 0,
    QUIZ_AND_SURVEY: 1,
    PURCHASE_REWARD: 2,
    PRIZE: 3,
    TASK: 4,
    UGC: 5,
    CONTEST: 6,
    POINT: 7,
    MGM: 8
};

export const gamesConfig = {
    PSYCHO_QUIZ: {
        type: promotionTypes.PSYCHO_QUIZ,
        planKey: planKeys.modules.PSYCHO_QUIZ,
        formBasedType: formTypes.FORM_PSYCHO_QUIZ,
        i18nNameKey: 'label.game_type_psycho_quiz',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_psychological_test.png',
        descriptionText: 'label.psycho_quiz.description_text',
        displaySeq: 1,
        moduleNameForWebhook: 'personalityQuiz'
    },
    SIGN_FORM: {
        type: promotionTypes.SIGN_FORM,
        planKey: planKeys.modules.SIGN_FORM,
        i18nNameKey: 'label.game_type_signup_form',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_sign_form.png',
        descriptionText: 'label.sign_form.description_text',
        displaySeq: 30,
        moduleNameForWebhook: 'enterToWin'
    },
    QUIZ: {
        type: promotionTypes.QUIZ,
        planKey: planKeys.modules.QUIZ,
        formBasedType: formTypes.FORM_QUIZ,
        i18nNameKey: 'label.game_type_quiz',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_quiz.png',
        descriptionText: 'label.quiz.description_text',
        displaySeq: 0,
        moduleNameForWebhook: 'qaQuiz'
    },
    SURVEY: {
        type: promotionTypes.SURVEY,
        planKey: planKeys.modules.SURVEY,
        formBasedType: formTypes.FORM_SURVEY,
        i18nNameKey: 'label.game_type_survey',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_survey.png',
        descriptionText: 'label.survey.description_text',
        displaySeq: 3,
        moduleNameForWebhook: 'survey'
    },
    WIN_WHEEL: {
        type: promotionTypes.WIN_WHEEL,
        planKey: planKeys.modules.WIN_WHEEL,
        purePrizeGameLotteryType: lotteryTypes.WIN_WHEEL,
        i18nNameKey: 'label.game_type_win_wheel',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_winwheel.png',
        descriptionText: 'label.win_wheel.description_text',
        displaySeq: 6,
        moduleNameForWebhook: 'luckyWheel'
    },
    VOTE: {
        type: promotionTypes.VOTE,
        planKey: planKeys.modules.VOTE,
        formBasedType: formTypes.FORM_VOTE,
        i18nNameKey: 'label.game_type_vote',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_vote.png',
        descriptionText: 'label.vote.description_text',
        displaySeq: 2,
        moduleNameForWebhook: 'poll'
    },
    POKE_GAME: {
        type: promotionTypes.POKE_GAME,
        planKey: planKeys.modules.POKE_GAME,
        purePrizeGameLotteryType: lotteryTypes.POKE_GAME,
        i18nNameKey: 'label.poke_game',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_poke_game.png',
        descriptionText: 'label.poke_game.description_text',
        displaySeq: 29,
        moduleNameForWebhook: 'pokeAPrize'
    },
    SCRATCH_CARD: {
        type: promotionTypes.SCRATCH_CARD,
        planKey: planKeys.modules.SCRATCH_CARD,
        purePrizeGameLotteryType: lotteryTypes.SCRATCH_CARD,
        i18nNameKey: 'label.sketch_prize',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_scratch_card.png',
        descriptionText: 'label.scratch_card.description_text',
        displaySeq: 7,
        moduleNameForWebhook: 'scratchCards'
    },
    INVOICE: {
        type: promotionTypes.INVOICE,
        planKey: planKeys.modules.INVOICE,
        formBasedType: formTypes.FORM_INVOICE,
        i18nNameKey: 'label.game_type_invoice',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_invoice.png',
        descriptionText: 'label.invoice.description_text',
        displaySeq: 28,
        moduleNameForWebhook: 'purchaseToWin'
    },
    RED_ENVELOPE_RAIN: {
        type: promotionTypes.RED_ENVELOPE_RAIN,
        planKey: planKeys.modules.RED_ENVELOPE_RAIN,
        purePrizeGameLotteryType: lotteryTypes.RED_ENVELOPE_RAIN,
        i18nNameKey: 'label.red_envelope_rain',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_red_envelope_rain.png',
        descriptionText: 'label.red_envelope_rain.description_text',
        displaySeq: 27,
        moduleNameForWebhook: 'prizeShower'
    },
    PHOTO_UPLOAD: {
        type: promotionTypes.PHOTO_UPLOAD,
        planKey: planKeys.modules.PHOTO_UPLOAD,
        i18nNameKey: 'label.photo_upload',
        isUgcGame: true,
        canLinkWithContestModule: true,
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_photo_upload.png',
        descriptionText: 'label.photo_upload.description_text',
        displaySeq: 26,
        moduleNameForWebhook: 'photoUpload'
    },
    CHECK_IN: {
        type: promotionTypes.CHECK_IN,
        planKey: planKeys.modules.CHECK_IN,
        i18nNameKey: 'label.game_type_check_in',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_check_in.png',
        descriptionText: 'label.check_in.description_text',
        displaySeq: 8,
        moduleNameForWebhook: 'checkIn'
    },
    CONTEST_PHOTO_UPLOAD: {
        type: promotionTypes.CONTEST_PHOTO_UPLOAD,
        planKey: planKeys.modules.CONTEST_PHOTO_UPLOAD,
        i18nNameKey: 'label.game_type_photo_contest',
        hasUgcFlow: true,
        ugcLinkKeys: [planKeys.modules.PHOTO_UPLOAD],
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_photo_contest.png',
        descriptionText: 'label.contest_photo_upload.description_text',
        displaySeq: 4,
        moduleNameForWebhook: 'photoContest'
    },
    PHOTO_FRAME_COMPOSITE: {
        type: promotionTypes.PHOTO_FRAME_COMPOSITE,
        planKey: planKeys.modules.PHOTO_FRAME_COMPOSITE,
        i18nNameKey: 'label.game_type_creative_photo_frame',
        hasRelatedPromoOptions: true,
        isUgcGame: true,
        canLinkWithContestModule: true,
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_photo_composite.png',
        descriptionText: 'label.photo_frame_composite.description_text',
        displaySeq: 25,
        moduleNameForWebhook: 'creativeFrame'
    },
    SLOTS: {
        type: promotionTypes.SLOTS,
        planKey: planKeys.modules.SLOTS,
        purePrizeGameLotteryType: lotteryTypes.SLOTS,
        i18nNameKey: 'label.slots',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_slots.png',
        descriptionText: 'label.slots.description_text',
        displaySeq: 24,
        moduleNameForWebhook: 'slot'
    },
    PICK_THE_PRIZE: {
        type: promotionTypes.PICK_THE_PRIZE,
        planKey: planKeys.modules.PICK_THE_PRIZE,
        purePrizeGameLotteryType: lotteryTypes.PICK_THE_PRIZE,
        i18nNameKey: 'label.game_type_pick_the_prize',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_choose.png',
        descriptionText: 'label.pick_the_prize.description_text',
        displaySeq: 23,
        moduleNameForWebhook: 'pickThePrize'
    },
    MEMORY_MATCH: {
        type: promotionTypes.MEMORY_MATCH,
        planKey: planKeys.modules.MEMORY_MATCH,
        i18nNameKey: 'label.game_type_flop_game',
        hasRelatedPromoOptions: true,
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_memory_match.png',
        descriptionText: 'label.memory_match.description_text',
        displaySeq: 22,
        moduleNameForWebhook: 'memoryMatch'
    },
    COMMENT: {
        type: promotionTypes.COMMENT,
        planKey: planKeys.modules.COMMENT,
        i18nNameKey: 'label.game_type_message_activity',
        hasRelatedPromoOptions: true,
        isUgcGame: true,
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_comment.png',
        descriptionText: 'label.comment.description_text',
        displaySeq: 21,
        moduleNameForWebhook: 'comment'
    },
    CONTEST_ADMIN_UPLOAD: {
        type: promotionTypes.CONTEST_ADMIN_UPLOAD,
        planKey: planKeys.modules.CONTEST_ADMIN_UPLOAD,
        i18nNameKey: 'label.game_type_voting_contest',
        hasUgcFlow: false,
        ugcLinkKeys: [planKeys.modules.PHOTO_UPLOAD, planKeys.modules.VIDEO_UPLOAD],
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_voting_contest.png',
        descriptionText: 'label.contest_admin_upload.description_text',
        displaySeq: 20,
        moduleNameForWebhook: 'votingContest'
    },
    CONTEST_PHOTO_FRAME_COMPOSITE: {
        type: promotionTypes.CONTEST_PHOTO_FRAME_COMPOSITE,
        planKey: planKeys.modules.CONTEST_PHOTO_FRAME_COMPOSITE,
        i18nNameKey: 'label.game_type_frame_composite_contest',
        hasUgcFlow: true,
        ugcLinkKeys: [planKeys.modules.PHOTO_FRAME_COMPOSITE],
        imageSrc:
            'https://assets.fevercdn.com/nc-admin/images/promo_module_frame_composite_contest.png',
        descriptionText: 'label.contest_photo_frame_composite.description_text',
        displaySeq: 19,
        moduleNameForWebhook: 'framedPhotoContest'
    },
    CHALLENGE_PHOTO_FRAME_COMPOSITE: {
        type: promotionTypes.CHALLENGE_PHOTO_FRAME_COMPOSITE,
        planKey: planKeys.modules.CHALLENGE_PHOTO_FRAME_COMPOSITE,
        i18nNameKey: 'label.game_type_challenge_photo_frame_composite',
        hasRelatedPromoOptions: true,
        hasRelatedPromoTask: true,
        isUgcGame: true,
        imageSrc:
            'https://assets.fevercdn.com/nc-admin/images/promo_module_challenge_photo_composite.png',
        descriptionText: 'label.challenge_photo_frame_composite.description_text',
        displaySeq: 9,
        moduleNameForWebhook: 'photoChallenge'
    },
    VIDEO_UPLOAD: {
        type: promotionTypes.VIDEO_UPLOAD,
        planKey: planKeys.modules.VIDEO_UPLOAD,
        i18nNameKey: 'label.game_type_video_upload',
        isUgcGame: true,
        canLinkWithContestModule: true,
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_video_upload.png',
        descriptionText: 'label.video_upload.description_text',
        displaySeq: 18,
        moduleNameForWebhook: 'videoUpload'
    },
    CONTEST_VIDEO_UPLOAD: {
        type: promotionTypes.CONTEST_VIDEO_UPLOAD,
        planKey: planKeys.modules.CONTEST_VIDEO_UPLOAD,
        i18nNameKey: 'label.game_type_video_competition',
        hasUgcFlow: true,
        ugcLinkKeys: [planKeys.modules.VIDEO_UPLOAD],
        imageSrc:
            'https://assets.fevercdn.com/nc-admin/images/promo_module_contest_video_upload.png',
        descriptionText: 'label.contest_video_upload.description_text',
        displaySeq: 17,
        moduleNameForWebhook: 'videoContest'
    },
    INSTAGRAM: {
        type: promotionTypes.INSTAGRAM,
        planKey: planKeys.modules.INSTAGRAM,
        i18nNameKey: 'label.game_type_instagram',
        isUgcGame: true,
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_instagram_module.png',
        descriptionText: 'label.instagram.description_text',
        displaySeq: 16,
        moduleNameForWebhook: 'instagram'
    },
    PURCHASE_REWARD: {
        type: promotionTypes.PURCHASE_REWARD,
        planKey: planKeys.modules.PURCHASE_REWARD,
        hasRelatedPromoOptions: true,
        i18nNameKey: 'label.game_type_purchase_reward',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_invoice.png',
        descriptionText: 'label.purchase_reward.description_text',
        displaySeq: 5,
        moduleNameForWebhook: 'purchaseReward'
    },
    POINTS_REDEEM: {
        type: promotionTypes.POINTS_REDEEM,
        planKey: planKeys.modules.POINTS_REDEEM,
        purePrizeGameLotteryType: lotteryTypes.POINTS_REDEEM,
        hasRelatedPromoOptions: true,
        i18nNameKey: 'label.game_type_points_redeem',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_points_redeem.svg',
        descriptionText: 'label.points_redeem.description_text',
        displaySeq: 15,
        moduleNameForWebhook: 'pointsRedeem'
    },
    MGM_SHARE: {
        type: promotionTypes.MGM_SHARE,
        planKey: planKeys.modules.MGM_SHARE,
        i18nNameKey: 'label.game_type_mgm_share',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_refer_a_friend.svg',
        descriptionText: 'label.mgm_share.description_text',
        displaySeq: 13,
        moduleNameForWebhook: 'mgmShare'
    },
    SIGN_UP: {
        type: promotionTypes.SIGN_UP,
        planKey: planKeys.modules.SIGN_UP,
        i18nNameKey: 'label.game_type_sign_up',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_sign_up_reward.svg',
        descriptionText: 'label.sign_up.description_text',
        displaySeq: 14,
        moduleNameForWebhook: 'sign_up'
    },
    MULTI_CHALLENGE: {
        type: promotionTypes.MULTI_CHALLENGE,
        planKey: planKeys.modules.MULTI_CHALLENGE,
        i18nNameKey: 'label.game_type_multi_challenge',
        hasRelatedPromoTask: true,
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_multi_challenge.svg',
        descriptionText: 'label.multi_challenge.description_text',
        displaySeq: 12,
        moduleNameForWebhook: 'multiChallenge'
    },
    ORDER_REWARD: {
        type: promotionTypes.ORDER_REWARD,
        planKey: planKeys.modules.ORDER_REWARD,
        hasRelatedPromoOptions: true,
        i18nNameKey: 'label.game_type_order_reward',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_order_reward.png',
        descriptionText: 'label.order_reward.description_text',
        displaySeq: 11,
        moduleNameForWebhook: 'orderReward'
    },
    CART_REWARD: {
        type: promotionTypes.CART_REWARD,
        planKey: planKeys.modules.CART_REWARD,
        hasRelatedPromoOptions: true,
        i18nNameKey: 'label.game_type_cart_reward',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/promo_module_cart_reward.png',
        descriptionText: 'label.cart_reward.description_text',
        displaySeq: 10,
        moduleNameForWebhook: 'addToCart'
    }
};

export const promoFormTemplateMapping = Object.entries(gamesConfig).reduce(
    (obj, [, { type, formBasedType }]) => {
        if (formBasedType !== undefined) {
            obj[type] = formBasedType;
        }
        return obj;
    },
    {}
);

export const promoLotteryTemplateMapping = Object.entries(gamesConfig).reduce(
    (obj, [, { type, purePrizeGameLotteryType }]) => {
        if (purePrizeGameLotteryType !== undefined) {
            obj[type] = purePrizeGameLotteryType;
        }
        return obj;
    },
    {}
);
export function isPurePrizeGames(gameType) {
    return promoLotteryTemplateMapping[gameType] !== undefined;
}

const ugcGames = new Set([
    ...Object.entries(gamesConfig)
        .map(([, { type, isUgcGame }]) => (isUgcGame ? type : undefined))
        .filter(t => t !== undefined)
]);
export function isUgcGames(gameType) {
    return ugcGames.has(gameType);
}

const ugcGamesCanLinkWithContestModule = new Set([
    ...Object.entries(gamesConfig)
        .map(([, { type, canLinkWithContestModule }]) =>
            canLinkWithContestModule ? type : undefined
        )
        .filter(t => t !== undefined)
]);
export function isUgcGameAndHasRelatedPromo(gameType) {
    return ugcGamesCanLinkWithContestModule.has(gameType);
}

const hasPromoTaskGames = new Set([
    ...Object.entries(gamesConfig)
        .map(([, { type, hasRelatedPromoTask }]) => (hasRelatedPromoTask ? type : undefined))
        .filter(t => t !== undefined)
]);
export function checkIsGameHasPromoTask(gameType) {
    return hasPromoTaskGames.has(gameType);
}

const i18nKeyOfGameType = Object.entries(gamesConfig).reduce((obj, [, { type, i18nNameKey }]) => {
    if (i18nNameKey !== undefined) {
        obj[type] = i18nNameKey;
    }
    return obj;
}, {});

export function getGameTypeLabel(gameType) {
    const i18nKey = i18nKeyOfGameType[gameType];
    return i18nKey ? i18next.t(i18nKey) : '';
}

export const promotionModuleKeys = Object.entries(gamesConfig).reduce(
    (obj, [, { type, planKey }]) => {
        if (planKey !== undefined) {
            obj[type] = planKey;
        }
        return obj;
    },
    {}
);

const gameTypeModuleNames = Object.entries(gamesConfig).reduce(
    (obj, [, { type, moduleNameForWebhook }]) => {
        obj[type] = moduleNameForWebhook;
        return obj;
    },
    {}
);
export function getGameTypeModuleName(gameType) {
    const moduleNameForWebhook = gameTypeModuleNames[gameType];
    return Boolean(moduleNameForWebhook) ? moduleNameForWebhook : '';
}

const hasCheckFunctionGame = new Set([
    ...ugcGames,
    promotionTypes.INVOICE,
    promotionTypes.PURCHASE_REWARD,
    promotionTypes.SIGN_UP,
    promotionTypes.ORDER_REWARD,
    promotionTypes.CART_REWARD
]);
export function isEntriesNeedCheckPromo(type) {
    return hasCheckFunctionGame.has(type);
}

export const isCheckFunctionGamButHideTabGames = new Set([promotionTypes.SIGN_UP]);
// 查看紀錄 Dialog 是否顯示參加紀錄 tab
export function isShowEntryRecrodInfoTabGame(type) {
    return isEntriesNeedCheckPromo(type) && !isCheckFunctionGamButHideTabGames.has(type);
}

const hasVerifyFunctionGame = new Set([promotionTypes.PURCHASE_REWARD]);
export function isEntriesNeedVerifyPromo(type) {
    return hasVerifyFunctionGame.has(type);
}

export const contestGamesForUserPlayableUgc = new Set([
    ...Object.entries(gamesConfig)
        .map(([, { type, ugcLinkKeys, hasUgcFlow }]) =>
            Array.isArray(ugcLinkKeys) && hasUgcFlow ? type : undefined
        )
        .filter(t => t !== undefined)
]);
const contestGamesWithSecondaryUgc = new Set([
    ...Object.entries(gamesConfig)
        .map(([, { type, ugcLinkKeys }]) => (Array.isArray(ugcLinkKeys) ? type : undefined))
        .filter(t => t !== undefined)
]);
export const gaReportUseUgcPageDataGames = contestGamesWithSecondaryUgc;
export const contestResultShowVotesGames = contestGamesWithSecondaryUgc;
export const showDownloadVoteTypes = contestGamesWithSecondaryUgc;
export function isPromoListNeedExtend(type) {
    return contestGamesForUserPlayableUgc.has(type);
}
export function isUgcContestGame(type) {
    return contestGamesForUserPlayableUgc.has(type);
}
export function getIsNeedVotingGame(type) {
    return contestGamesWithSecondaryUgc.has(type);
}

const contestModuleMappingUsableUgcLinkKeys = Object.entries(gamesConfig).reduce(
    (obj, [, { type, ugcLinkKeys }]) => {
        if (Array.isArray(ugcLinkKeys)) {
            obj[type] = ugcLinkKeys;
        }
        return obj;
    },
    {}
);
export function contestGetUgcLinkKey(gameType, settingPromo) {
    const usableUgcLinkKeys = contestModuleMappingUsableUgcLinkKeys[gameType] ?? [];
    const [ugcLinkKey] =
        Object.entries(settingPromo).find(([key]) => usableUgcLinkKeys.includes(key)) ?? [];

    return ugcLinkKey;
}

const promoManageRoleLabelMapping = {
    [promotionTypes.PHOTO_UPLOAD]: () => i18next.t('label.nav_to_ugc_manage'),
    [promotionTypes.CONTEST_PHOTO_UPLOAD]: () => i18next.t('label.nav_to_contest_manage'),
    [promotionTypes.PHOTO_FRAME_COMPOSITE]: () => i18next.t('label.nav_to_ugc_manage'),
    [promotionTypes.COMMENT]: () => i18next.t('label.nav_to_ugc_manage'),
    [promotionTypes.CONTEST_PHOTO_FRAME_COMPOSITE]: () => i18next.t('label.nav_to_contest_manage'),
    [promotionTypes.VIDEO_UPLOAD]: () => i18next.t('label.nav_to_ugc_manage'),
    [promotionTypes.CONTEST_VIDEO_UPLOAD]: () => i18next.t('label.nav_to_contest_manage')
};
export function getPromoManageRoleLabel(type) {
    return (promoManageRoleLabelMapping[type] ?? (() => {}))() || '';
}

export function getCurrentGameTypeLabel(gameType, isRelatedPromo) {
    return isRelatedPromo
        ? whenPromoIsRelatedPromoLabelMapping[gameType] || getGameTypeLabel(gameType)
        : getGameTypeLabel(gameType);
}

const needUseRelatedPromoForQueryGaStats = new Set([
    promotionTypes.PHOTO_UPLOAD,
    promotionTypes.PHOTO_FRAME_COMPOSITE,
    promotionTypes.VIDEO_UPLOAD
]);
export function getIsNeedToUseRelatedPromoForQueryGaStats(promoType) {
    return needUseRelatedPromoForQueryGaStats.has(promoType);
}

export const whenPromoIsRelatedPromoLabelMapping = {
    [promotionTypes.PHOTO_UPLOAD]: getGameTypeLabel(promotionTypes.CONTEST_PHOTO_UPLOAD),
    [promotionTypes.PHOTO_FRAME_COMPOSITE]: getGameTypeLabel(
        promotionTypes.CONTEST_PHOTO_FRAME_COMPOSITE
    ),
    [promotionTypes.VIDEO_UPLOAD]: getGameTypeLabel(promotionTypes.CONTEST_VIDEO_UPLOAD)
};

export const photoResultShowImageGames = new Set([
    promotionTypes.PHOTO_FRAME_COMPOSITE,
    promotionTypes.PHOTO_UPLOAD,
    promotionTypes.CHALLENGE_PHOTO_FRAME_COMPOSITE,
    promotionTypes.INSTAGRAM
]);

export const commentResultShowTextsGames = new Set([promotionTypes.COMMENT]);
export const invoiceResultShowInvoiceGames = new Set([promotionTypes.PURCHASE_REWARD]);
export const videoResultShowVideoGames = new Set([promotionTypes.VIDEO_UPLOAD]);
export const multiChallengeResultShowTaskRelatedGames = new Set([promotionTypes.MULTI_CHALLENGE]);
export const orderResultShowGames = new Set([promotionTypes.ORDER_REWARD]);

// 任務挑戰分頁
export const challengeTaskGamesSet = new Set([
    promotionTypes.SIGN_FORM,
    promotionTypes.RED_ENVELOPE_RAIN,
    promotionTypes.CHECK_IN,
    promotionTypes.MEMORY_MATCH,
    promotionTypes.CHALLENGE_PHOTO_FRAME_COMPOSITE,
    promotionTypes.MGM_SHARE,
    promotionTypes.SIGN_UP,
    promotionTypes.MULTI_CHALLENGE,
    promotionTypes.CART_REWARD
]);

// 購買獎勵分頁
export const purchaseRewardTabGamesTypes = new Set([
    promotionTypes.INVOICE,
    promotionTypes.PURCHASE_REWARD,
    promotionTypes.ORDER_REWARD
]);

// 是否顯示消費金額確認 Dialog
const hasShowConsumaAmountConfirmGameType = new Set([
    promotionTypes.PURCHASE_REWARD,
    promotionTypes.ORDER_REWARD,
    promotionTypes.CART_REWARD
]);
export function isCanShowConsumaAmountConfirmGame(type) {
    return hasShowConsumaAmountConfirmGameType.has(type);
}

// 業務推薦可以與點數系統一起使用的模組
export const suggestToBindPointSystemGamesSet = new Set([
    promotionTypes.QUIZ,
    promotionTypes.SIGN_FORM,
    promotionTypes.SURVEY,
    promotionTypes.WIN_WHEEL,
    promotionTypes.POKE_GAME,
    promotionTypes.SCRATCH_CARD,
    promotionTypes.RED_ENVELOPE_RAIN,
    promotionTypes.PHOTO_UPLOAD,
    promotionTypes.CHECK_IN,
    promotionTypes.SLOTS,
    promotionTypes.PICK_THE_PRIZE,
    promotionTypes.MEMORY_MATCH,
    promotionTypes.COMMENT,
    promotionTypes.CHALLENGE_PHOTO_FRAME_COMPOSITE,
    promotionTypes.VIDEO_UPLOAD,
    promotionTypes.INSTAGRAM,
    promotionTypes.POINTS_REDEEM,
    promotionTypes.ORDER_REWARD,
    promotionTypes.CART_REWARD
]);

// 邀請任務及現階段可加入的任務模組放入 MGM 分類
export const mgmRelationGamesSet = new Set([
    promotionTypes.PSYCHO_QUIZ,
    promotionTypes.QUIZ,
    promotionTypes.SIGN_FORM,
    promotionTypes.SURVEY,
    promotionTypes.PURCHASE_REWARD,
    promotionTypes.MGM_SHARE,
    promotionTypes.SIGN_UP
]);

// 隱藏參加者列表參加結果欄位
const hasHideMemberListJoinResultGames = new Set([
    promotionTypes.SIGN_UP,
    promotionTypes.MGM_SHARE,
    promotionTypes.CART_REWARD
]);
export function isHideJoinResultFieldGames(type) {
    return hasHideMemberListJoinResultGames.has(type);
}

// 需要有會員登入的活動
const hasNeedLoginEnterpriseGames = new Set([promotionTypes.SIGN_UP]);
export function isNeedLoginEnterpriseGames(type) {
    return hasNeedLoginEnterpriseGames.has(type);
}

// 可以設定 Promotion 審核機制模組
const canSetVerifyMechanismGames = new Set([
    promotionTypes.INVOICE,
    promotionTypes.PURCHASE_REWARD,
    promotionTypes.SIGN_UP,
    promotionTypes.PSYCHO_QUIZ,
    promotionTypes.QUIZ,
    promotionTypes.SURVEY,
    promotionTypes.SIGN_FORM,
    promotionTypes.VOTE,
    promotionTypes.CHECK_IN,
    promotionTypes.PICK_THE_PRIZE,
    promotionTypes.MEMORY_MATCH,
    promotionTypes.MULTI_CHALLENGE,
    promotionTypes.ORDER_REWARD,
    promotionTypes.CART_REWARD
]);
export function isCanSetVerifyMechanismGame(gameType) {
    return canSetVerifyMechanismGames.has(gameType) || isUgcGames(gameType);
}

// 強制開啟 Promotion 審核機制模組
const hasForceVerifyMechanismGames = new Set([
    promotionTypes.PURCHASE_REWARD,
    promotionTypes.SIGN_UP,
    promotionTypes.ORDER_REWARD,
    promotionTypes.CART_REWARD
]);
export function isForceVerifyMechanismGame(gameType) {
    return hasForceVerifyMechanismGames.has(gameType) || isUgcGames(gameType);
}

// 顯示驗證欄位
const hasShowVerificationStatusField = new Set([
    promotionTypes.PURCHASE_REWARD,
    promotionTypes.ORDER_REWARD,
    promotionTypes.CART_REWARD
]);
export function isShowVerificationStatusField(type) {
    return hasShowVerificationStatusField.has(type);
}

// 有啟用審核機制但查看紀錄 Dialog 不顯示參加紀錄 tab
const hasVerifyMechanismGameButHideTabGames = new Set([promotionTypes.SIGN_UP]);
export function isVerifyMechanismGameButHideTabGame(type) {
    return !hasVerifyMechanismGameButHideTabGames.has(type);
}

// 顯示下載消費明細按鈕
const hasDownloadPurchaseDetailButtonGames = new Set([
    promotionTypes.CART_REWARD,
    promotionTypes.ORDER_REWARD
]);
export function isDownloadPurchaseDetailButtonGame(type) {
    return hasDownloadPurchaseDetailButtonGames.has(type);
}
