import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import i18next from 'i18next';
import { isMobile } from 'react-device-detect';
import { css } from '@emotion/core';
import queryString from 'query-string';
import Typography from '@material-ui/core/Typography';
import { white, system800 } from 'features/common/Mui/styles/color';
import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';
import {
    EmailAuthProvider,
    OAuthProvider,
    FacebookAuthProvider,
    signInWithPopup,
    signInWithCredential,
    linkWithCredential
} from 'firebase/auth';
import useApiFailedHandler from 'features/webhooks/useApiErrorHandler';
import { mainBtnStyle } from 'features/login/styles/comman';
import {
    Providers,
    auth,
    firebaseProviderPlatformMapping,
    firebaseProviderIds,
    firebaseApiErrCodes,
    isUseOAuthProvider,
    firebaseEmailLinkQueryStrKeys,
    signout
} from 'features/login/utils/firebaseManager';
import { getQueryString } from 'features/login/utils/manipulateUrl';
import { queryStrKeys, verifyEmailTypes } from 'features/login/utils/config';
import { paths, useRedirectApp } from 'features/login/hooks/useInitAndRedirectApp';
import useHistoryWithCurrentSearchHash from 'features/login/hooks/useHistoryWithCurrentSearchHash';
import { loginSignUpErrorTypes, setLoginSignUpErrorType, setUserInfo } from 'store/module/firebase';
import useLineEnv from 'hooks/useLineEnv';
import { ADMIN_LOGIN_LIFF_ID } from 'config/envVariable';
import {
    MANUAL_SIGN_IN_FAILED,
    FIREBASE_SIGN_IN_FAILED,
    FIREBASE_SIGN_IN_CREDENTIAL_FAILED,
    FIREBASE_LINK_WITH_CREDENTIAL_FAILED
} from 'config/errorTypes';
import { useInitFacebookSDK } from 'hooks/useInitFacebook';
import triggerBrowserOpenNewWindow from 'utils/triggerBrowserOpenNewWindow';

export default function ContinueWithLoginBtn({
    setLogining = () => {},
    isLogining,
    providerId,
    iconFileName = '',
    text = '',
    disabled = false,
    loginSuccessCallback
}) {
    const apiFailedHandler = useApiFailedHandler();
    const { isInLineAppBrowser, isInLiffBrowser } = useLineEnv();
    const redirectApp = useRedirectApp();
    const dispatch = useDispatch();
    const history = useHistoryWithCurrentSearchHash();
    const Provider = Providers[providerId];
    const provider = useMemo(
        () => (isUseOAuthProvider(providerId) ? new Provider(providerId) : new Provider()),
        [Provider, providerId]
    );
    const [isLoading, setLoading] = useState(false);
    const { initFb, shortLivedUserToken, loginWithFacebook } = useInitFacebookSDK({
        shouldInit: providerId === firebaseProviderIds.FACEBOOK
    });

    const handleFirebaseSignError = useCallback(
        async (error, errorType, errorInfo = {}) => {
            switch (error.code) {
                case firebaseApiErrCodes.AUTH_ACCOUNT_EXISTS_WITH_DIFF_CREDENTIAL:
                    dispatch(setLoginSignUpErrorType(loginSignUpErrorTypes.LOGIN_EMAIL_EXIST));
                    history.push({ pathname: paths.ERROR });
                    break;
                case firebaseApiErrCodes.AUTH_INVALID_ACTION_CODE:
                    dispatch(setLoginSignUpErrorType(loginSignUpErrorTypes.INVALID_ACTION_CODE));
                    history.push({ pathname: paths.ERROR });
                    break;
                case firebaseApiErrCodes.AUTH_POPUP_CLOSED_BY_USER:
                case firebaseApiErrCodes.AUTH_POPUP_BLOCKED:
                    await handleManuallyLogin(providerId);
                    break;
                case firebaseApiErrCodes.AUTH_ARGUMENT_ERROR:
                    apiFailedHandler(
                        error,
                        i18next.t('notify.sign_in.failed'),
                        errorType,
                        errorInfo
                    );
                    break;
                default:
                    await signout();
                    apiFailedHandler(error, i18next.t('notify.sign_in.failed'), errorType);
                    break;
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    async function handleSignIn() {
        try {
            setLoading(true);
            setLogining(true);
            if (isMobile) {
                // 因為第三方Cookie政策，手機透過 pup-up 做登入，在登入成功拿到 token 後，cookie 會因為不同 domain 而遺失，
                // 導致 access token 沒有回到登入頁，所以 mobile 登入不走，firebase signInWithPopup 的 API 登入流程 (#86eq5ew50)
                await handleManuallyLogin(providerId);
                return;
            } else {
                await signInWithPopup(auth, provider);
            }
            // 通常是點擊信件上的 link 後執行 handleVerifyEmail
            if (loginSuccessCallback && typeof loginSuccessCallback === 'function') {
                await loginSuccessCallback();
                return;
            }
            await redirectApp({
                action: 'doLogin'
            });
        } catch (error) {
            await handleFirebaseSignError(error, FIREBASE_SIGN_IN_FAILED);
        } finally {
            setLoading(false);
            setLogining(false);
        }
    }

    const manuallyLogin = useCallback(
        async credential => {
            try {
                await signInWithCredential(auth, credential);
            } catch (error) {
                await handleFirebaseSignError(error, FIREBASE_SIGN_IN_CREDENTIAL_FAILED);
            }
        },
        [handleFirebaseSignError]
    );

    const bindEmailWithCredential = async ({ email, emailVerifyType, qsStr }) => {
        if (emailVerifyType === verifyEmailTypes.EMAIL_VERIFY) {
            try {
                await linkWithCredential(
                    auth.currentUser,
                    EmailAuthProvider.credentialWithLink(
                        email,
                        `${window.location.host}${paths.EMAIL_VERIFY_LANDING}${qsStr}`
                    )
                );
                dispatch(setUserInfo({ email }));
                history.replace({
                    search: getQueryString({
                        remove: [
                            ...firebaseEmailLinkQueryStrKeys,
                            queryStrKeys.VERIFY_EMAIL_TYPE,
                            queryStrKeys.VERIFY_EMAIL,
                            queryStrKeys.FIREBASE_UID,
                            queryStrKeys.FEVER_HOST
                        ]
                    })
                });
                await redirectApp();
            } catch (error) {
                await handleFirebaseSignError(error, FIREBASE_LINK_WITH_CREDENTIAL_FAILED);
            }
        } else throw new Error('Email verify type is not correct');
    };

    const handleManuallyLogin = useCallback(
        async providerId => {
            try {
                setLoading(true);
                setLogining(true);
                switch (providerId) {
                    case firebaseProviderIds.FACEBOOK:
                        if (initFb) {
                            let accessToken = shortLivedUserToken;
                            if (!shortLivedUserToken) {
                                accessToken = await loginWithFacebook();
                            }
                            accessToken = shortLivedUserToken || accessToken;
                            const credential = FacebookAuthProvider.credential(accessToken);
                            await manuallyLogin(credential);
                            const qsObj = queryString.parse(window.location.search);
                            const isInEmailVerifyLandingPage = Boolean(
                                qsObj[queryStrKeys.FIREBASE_UID]
                            );
                            if (!isInEmailVerifyLandingPage) {
                                await redirectApp({ action: 'doLogin' });
                            }
                        } else throw new Error('Facebook SDK is not initialized yet');
                        break;
                    case firebaseProviderIds.LINE:
                        // 從 Line 打開連結出現的視窗不是 isInLiffBrowser，是 isInLineAppBrowser
                        if (Boolean(isInLiffBrowser)) {
                            const qsObj = queryString.parse(window.location.search);
                            const emailVerifyType = qsObj[queryStrKeys.VERIFY_EMAIL_TYPE];
                            const email = qsObj[queryStrKeys.VERIFY_EMAIL];
                            const accessTokenHash = window.location.hash;
                            const hashString = accessTokenHash.substring(1);
                            const parsedHash = queryString.parse(hashString);
                            const accessToken = parsedHash?.access_token;
                            const idToken = parsedHash?.id_token;
                            const credential = new OAuthProvider(providerId).credential({
                                idToken,
                                accessToken
                            });
                            await manuallyLogin(credential);
                            const isEmailCanBindFirebase = emailVerifyType && email;
                            // 驗證流程需要的資訊在網址上
                            const next = isEmailCanBindFirebase
                                ? () =>
                                      bindEmailWithCredential({
                                          email,
                                          emailVerifyType,
                                          qsStr: window.location.search
                                      })
                                : () => redirectApp({ action: 'doLogin' });
                            await next();
                        } else {
                            const liffUrl = `https://liff.line.me/${ADMIN_LOGIN_LIFF_ID}`;
                            const qs = Boolean(window.location.search)
                                ? `${window.location.search}&fever_host=${window.location.host}`
                                : `?fever_host=${window.location.host}`;

                            triggerBrowserOpenNewWindow(`${liffUrl}${qs}`);
                        }
                        break;
                    default:
                        await signInWithPopup(auth, provider);
                        await redirectApp({ action: 'doLogin' });
                        break;
                }
            } catch (error) {
                await handleFirebaseSignError(error, MANUAL_SIGN_IN_FAILED, {
                    providerId,
                    url: window.location.href,
                    initFb
                });
            } finally {
                setLoading(false);
                setLogining(false);
            }
        },

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [initFb, shortLivedUserToken]
    );

    // TODO: 登出造成的 error
    useEffect(() => {
        if (
            providerId === firebaseProviderIds.LINE &&
            Boolean(isInLineAppBrowser) &&
            Boolean(isInLiffBrowser)
        ) {
            handleManuallyLogin(providerId);
        }
    }, [handleManuallyLogin, isInLiffBrowser, isInLineAppBrowser, providerId]);

    return (
        <ButtonWithLoadingEffect
            css={btnStyle}
            variant="outlined"
            onClick={handleSignIn}
            isLoading={isLoading}
            disabled={disabled || isLogining}
        >
            <img src={`https://assets.fevercdn.com/nc-admin/icons/${iconFileName}`} alt={text} />
            <Typography
                variant="button"
                css={css(textStyle)}
                className={`login-btn-${firebaseProviderPlatformMapping[providerId]}`}
            >
                {i18next.t(text)}
            </Typography>
        </ButtonWithLoadingEffect>
    );
}

const btnStyle = css`
    ${mainBtnStyle}
    background-color: ${white.color};
    color: ${system800.color};
    margin-top: 8px;
    padding: 12px 0;

    :hover {
        background-color: ${white.color};
    }

    & img {
        width: 24px;
        height: 24px;
        margin-right: 8px;
    }
`;

const textStyle = css`
    font-family: Noto Sans TC, Roboto;
    text-transform: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 1.25px;
    text-align: center;
`;
