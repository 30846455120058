//  設定 觸發 Webhook 的事件是什麼行為 Ex: 填寫領獎表單、參加活動的報名表單
export const STEP_TRIGGER_EVENT = 'STEP_TRIGGER_EVENT';
// 設定 觸發 Webhook 的事件是要在特定一檔活動還是全部的活動 (愛酷的活動都限定是Line登入)
export const STEP_TRIGGER_SCOPE = 'STEP_TRIGGER_SCOPE';
// 設定 Webhook
export const STEP_SET_WEBHOOK = 'STEP_SET_WEBHOOK';
// 設定 Facebook Pixel ID & Access Token
export const STEP_SET_FACEBOOK_TOKEN = 'STEP_SET_TOKEN';
// 設定漸強 Token
export const STEP_SET_CRESCENDO_TOKEN = 'STEP_SET_TOKEN';
// 設定標籤
export const STEP_SET_LABEL = 'STEP_SET_LABEL';

export const INITIAL_STEP = '';
// 設定 LINE BOT ID、與 愛酷 API TOKEN
export const STEP_SET_ACCU_TOKEN = 'STEP_SET_ACCU_TOKEN';
// 設定 威許 app-id、sign_key、aes_key、brand_code、store_code、member_identity
export const STEP_SET_WISH_TOKEN = 'STEP_SET_WISH_TOKEN';

// Webhook
export const WEBHOOK_STEPS = [STEP_TRIGGER_EVENT, STEP_TRIGGER_SCOPE, STEP_SET_WEBHOOK];

// 轉換追蹤 (Facebook)
export const FACEBOOK_STEPS = [STEP_TRIGGER_EVENT, STEP_TRIGGER_SCOPE, STEP_SET_FACEBOOK_TOKEN];

// 企業會員綁定 LINE ID (漸強實驗室)
// export const CRESCENDO_STEPS = [STEP_SET_CRESCENDO_TOKEN, STEP_SET_LABEL];

// 愛酷 Webhook 串接 (ACCU)
export const ACCU_STEPS = [STEP_TRIGGER_EVENT, STEP_TRIGGER_SCOPE, STEP_SET_ACCU_TOKEN];

// 威許 Webhook 串接 (Wish)
export const WISH_STEPS = [STEP_TRIGGER_SCOPE, STEP_SET_WISH_TOKEN];

// 漸強 CRESCLAB 串接 (MAAC)
export const CRESCLAB_STEPS = [STEP_TRIGGER_EVENT, STEP_TRIGGER_SCOPE, STEP_SET_WEBHOOK];

// Omnichat 串接
export const OMNICHAT_STEPS = [STEP_TRIGGER_EVENT, STEP_TRIGGER_SCOPE, STEP_SET_WEBHOOK];

// Bot Bonnie 串接
export const BOT_BONNIE_STEPS = [STEP_TRIGGER_EVENT, STEP_TRIGGER_SCOPE, STEP_SET_WEBHOOK];
