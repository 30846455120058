import { setFailed } from 'store/module/notify';
import {
    getWebhooksListApi,
    deleteWebhookApi,
    updateFacebookConversionApi,
    updateAccuApi
} from 'api/webhooks';
import { availableEventIds } from 'utils/convertWebhookEvent';
import { MAX_LENGTH_PER_REQUEST } from 'features/webhooks/config/triggerScope';
import { FACEBOOK_WEBHOOKS, ACCU_WEBHOOKS } from 'features/webhooks/config/module';

const INIT_WEBHOOKLIST = 'webhook/INIT';
const FETCH_WEBHOOKLIST_START = 'webhook/LOADING';
const FETCH_WEBHOOKLIST_FAILED = 'webhook/FAILED';
const UPDATE_WEBHOOK_ITEM = 'webhook/UPDATE';
const UPDATE_WEBHOOK_SETTING = 'webhook/UPDATE_WEBHOOK_SETTING';

const defaultState = {
    webhookList: [],
    currentPage: 1,
    isFetching: false,
    isEnd: false
};

export default function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case FETCH_WEBHOOKLIST_START:
            return { ...state, isFetching: true };
        case FETCH_WEBHOOKLIST_FAILED:
            return { ...state, isFetching: false };
        case INIT_WEBHOOKLIST:
            return { ...payload };
        case UPDATE_WEBHOOK_ITEM:
            return { ...state, webhookList: [...payload] };
        case UPDATE_WEBHOOK_SETTING:
            return { ...state, ...payload };
        default:
            return state;
    }
}

export function updateWebhookSetting(setting) {
    return {
        type: UPDATE_WEBHOOK_SETTING,
        payload: setting
    };
}

export function fetchWebhookList(page) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: selectedSponsorId },
            webhook: { webhookList, currentPage: oldPage }
        } = getState();
        page = page || oldPage;
        if (selectedSponsorId) {
            try {
                dispatch({ type: FETCH_WEBHOOKLIST_START });
                const resp = await getWebhooksListApi(selectedSponsorId, page);
                if (resp.status > 401) {
                    throw new Error('fetch failed.');
                } else {
                    const { items = [] } = await resp.json();
                    const isEnd = items.length <= MAX_LENGTH_PER_REQUEST;
                    const filteredItems = items.filter(i => availableEventIds.has(i.event));
                    let mergeWebhookList = [...webhookList, ...filteredItems];
                    if (page === 1) {
                        mergeWebhookList = filteredItems;
                    }
                    const data = {
                        webhookList: mergeWebhookList,
                        currentPage: page,
                        isFetching: false,
                        isEnd
                    };
                    dispatch({
                        type: INIT_WEBHOOKLIST,
                        payload: data
                    });
                }
            } catch (error) {
                dispatch({ type: FETCH_WEBHOOKLIST_FAILED });
                dispatch(setFailed('message_connection_error'));
            }
        }
    };
}

export function updateItemInWebhookList(selectId, data) {
    return async (dispatch, getState) => {
        const {
            webhook: { webhookList }
        } = getState();
        const updateWebhookList = [...webhookList].map(webhook => {
            const { id } = webhook;
            if (id === selectId) {
                return { ...webhook, ...data };
            }
            return webhook;
        });
        dispatch({
            type: UPDATE_WEBHOOK_ITEM,
            payload: updateWebhookList
        });
    };
}

export function deleteItemInWebhookList(deleteTarget) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: selectedSponsorId },
            webhook: { webhookList }
        } = getState();
        try {
            // TODO: 優化
            if (Number(deleteTarget.moduleId) === FACEBOOK_WEBHOOKS) {
                await updateFacebookConversionApi(selectedSponsorId, deleteTarget.webhookId, {
                    deleted: ['pixelId', 'accessToken']
                });
            }

            if (Number(deleteTarget.moduleId) === ACCU_WEBHOOKS) {
                await updateAccuApi(selectedSponsorId, deleteTarget.webhookId, {
                    deleted: ['lineBotId', 'apiToken']
                });
            }

            const resp = await deleteWebhookApi(selectedSponsorId, deleteTarget.webhookId);
            if (resp.status > 401) {
                throw new Error('fetch failed.');
            } else {
                const updateWebhookList = [...webhookList].filter(webhook => {
                    const { id: webhookId } = webhook;
                    return webhookId !== deleteTarget.webhookId;
                });
                dispatch({
                    type: UPDATE_WEBHOOK_ITEM,
                    payload: updateWebhookList
                });
                return true;
            }
        } catch (error) {
            dispatch(setFailed('message_connection_error'));
        }
        return false;
    };
}
