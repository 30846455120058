import React, { useState } from 'react';
import i18next from 'i18next';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { css } from '@emotion/core';
import { sendSignInLinkToEmail, fetchSignInMethodsForEmail } from 'firebase/auth';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { system700, system900 } from 'features/common/Mui/styles/color';
import { queryStrKeys, verifyEmailTypes } from 'features/login/utils/config';
import { auth, signout } from 'features/login/utils/firebaseManager';
import { getQueryString } from 'features/login/utils/manipulateUrl';
import {
    errMsgWrapperStyle,
    textFieldDefaultStyle,
    mainButtonSolidStyle
} from 'features/login/styles/comman';
import { generateEmailValidateRegexp } from 'features/login/utils/validate';
import { setLoginSignUpErrorType, loginSignUpErrorTypes } from 'store/module/firebase';
import { paths } from 'features/login/hooks/useInitAndRedirectApp';
import useHistoryWithCurrentSearchHash from 'features/login/hooks/useHistoryWithCurrentSearchHash';
import useCheckIsValidEmailDomain from 'features/login/hooks/useCheckIsValidEmailDomain';
import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';
import ConfirmVerifyEmailSended from 'features/login/components/pageEmailVerify/ConfirmVerifyEmailSended';
import useApiFailedHandler from 'features/webhooks/useApiErrorHandler';
import useLineEnv from 'hooks/useLineEnv';
import { FIREBASE_VERIFY_EMAIL_FAILED } from 'config/errorTypes';

const getProviderId = () => {
    const user = auth.currentUser;
    if (user && user.providerData.length > 0) {
        // Return the first providerId found in the providerData array
        const providerIdList = user.providerData.map(provider => provider.providerId);
        return providerIdList[0];
    } else {
        // Return null if no providerData is available
        return null;
    }
};

export default function EmailVerifyIdentifier() {
    const { isInLineAppBrowser, isInLiffBrowser } = useLineEnv();
    const apiFailedHandler = useApiFailedHandler();
    const isValidEmailDomain = useCheckIsValidEmailDomain();
    const history = useHistoryWithCurrentSearchHash();
    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [isVerifyEmailSend, setIsVerifyEmailSend] = useState(false);
    const [isSendingEmail, setIsSendingEmail] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    function validateInput() {
        let valid = true;
        if (!generateEmailValidateRegexp().test(email)) {
            valid = false;
            setErrMsg(i18n.t('label.invalid_email'));
        }
        return valid;
    }
    function handleEmailChange({ target: { value } }) {
        setErrMsg('');
        setEmail(value);
    }
    async function handleSendVerifyEmail() {
        try {
            if (validateInput()) {
                if (!isValidEmailDomain(email)) {
                    dispatch(setLoginSignUpErrorType(loginSignUpErrorTypes.INVALID_EMAIL_DOMAIN));
                    return history.push({
                        pathname: paths.ERROR,
                        state: { fromPath: window.location.pathname }
                    });
                }
                setIsSendingEmail(true);
                // 取得給定電子郵件地址的可能登入方法清單
                const signInMethods = await fetchSignInMethodsForEmail(auth, email);
                // 如果輸入的 email 跟此 firebase 的 email 不同 (通常此時 currentUser email 是 null) + 此 email 有登入方法在 list 內 => 代表此 email 已經被註冊過
                if (email !== auth.currentUser.email && signInMethods.length > 0) {
                    return setErrMsg(i18n.t('label.firebase_email_exist'));
                } else {
                    // 將會把要綁定的Email與firebase帳號(FB, Line, Microsoft..等等)透過寄信的方式做綁定，User 要去信箱收信並點擊 link 做綁定
                    await sendSignInLinkToEmail(auth, email, {
                        handleCodeInApp: true,
                        url: `${window.location.origin}${
                            paths.EMAIL_VERIFY_LANDING
                        }${getQueryString({
                            add: {
                                [queryStrKeys.VERIFY_EMAIL_TYPE]: verifyEmailTypes.EMAIL_VERIFY,
                                [queryStrKeys.VERIFY_EMAIL]: email,
                                [queryStrKeys.FIREBASE_UID]: auth.currentUser.uid,
                                [queryStrKeys.PROVIDER_ID]: getProviderId()
                            }
                        })}`
                    });

                    if (Boolean(isInLineAppBrowser) && Boolean(isInLiffBrowser)) {
                        await signout();
                    }
                    setIsVerifyEmailSend(true);
                }
            }
        } catch (error) {
            apiFailedHandler(
                error,
                i18next.t('notify.verify_email.failed'),
                FIREBASE_VERIFY_EMAIL_FAILED
            );
        } finally {
            setIsSendingEmail(false);
        }
    }

    return (
        <div css={containerStyle}>
            {isVerifyEmailSend ? (
                <ConfirmVerifyEmailSended email={email} />
            ) : (
                <>
                    <Typography variant="h5" css={titleStyle}>
                        {i18next.t('label.validate_email')}
                    </Typography>
                    <Typography variant="subtitle1" css={descStyle}>
                        {i18next.t('desc.validate_email')}
                    </Typography>
                    <TextField
                        variant="filled"
                        fullWidth
                        value={email}
                        onChange={handleEmailChange}
                        placeholder={i18next.t('placeholder.input_email')}
                        css={[textFieldDefaultStyle, inputStyle]}
                        error={Boolean(errMsg)}
                    />
                    <div css={errMsgWrapperStyle}>
                        {Boolean(errMsg) && <Typography variant="caption">{errMsg}</Typography>}
                    </div>
                    <div css={btnWrapperStyle}>
                        <ButtonWithLoadingEffect
                            isLoading={isSendingEmail}
                            css={[mainButtonSolidStyle, btnStyle]}
                            onClick={handleSendVerifyEmail}
                            disabled={!email}
                        >
                            <Typography variant="subtitle2">
                                {i18next.t('btn.send_validate_email')}
                            </Typography>
                        </ButtonWithLoadingEffect>
                    </div>
                </>
            )}
        </div>
    );
}

const containerStyle = css`
    max-width: 360px;
    > * {
        letter-spacing: 0.5px;
    }
`;

const titleStyle = css`
    text-align: center;
    color: ${system900.color};
`;

const descStyle = css`
    margin-top: 24px;
    font-weight: 400;
    color: ${system700};
`;

const inputStyle = css`
    margin-top: 24px;
`;

const btnWrapperStyle = css`
    margin-top: 24px;
    text-align: center;
`;

const btnStyle = css`
    width: 60%;
`;
