import React from 'react';
import { useSelector } from 'react-redux';

import { verifyEmailTypes } from 'features/login/utils/config';

import LayoutInner from 'features/login/components/LayoutInner';
import EmailVerifyIdentifier from 'features/login/components/pageEmailVerify/EmailVerifyIdentifier';
import EmailVerifySignup from 'features/login/components/pageEmailVerify/EmailVerifySignup';
import EmailVerifyForgetPassword from 'features/login/components/pageEmailVerify/EmailVerifyForgetPassword';

export default function PageEmailVerify() {
    const { verifyEmailType } = useSelector(s => s.firebase);

    const content = (() => {
        if (verifyEmailType === verifyEmailTypes.SIGN_UP) {
            return <EmailVerifySignup />;
        }
        if (verifyEmailType === verifyEmailTypes.EMAIL_VERIFY) {
            return <EmailVerifyIdentifier />;
        }
        if (verifyEmailType === verifyEmailTypes.FORGET_PASSWORD) {
            return <EmailVerifyForgetPassword />;
        }
        return null;
    })();
    return <LayoutInner>{content}</LayoutInner>;
}
