import queryString from 'query-string';

import { pathsShouldNotSetAsRefUrl } from 'features/login/hooks/useInitAndRedirectApp';

export function openWindow(url, { name = '', width = 800, height = 800 } = {}) {
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    return window.open(
        url,
        name,
        `menubar=0,resizable=0,width=${width},height=${height}, top=${top}, left=${left}`
    );
}

export const loginMessage = `@feverevent/editor-login`;

export const generateLoginUrl = customRefUrl => {
    const { origin, pathname, search, href } = window.location;
    const { refUrl } = queryString.parse(search);
    const nextRefUrl = (() => {
        if (customRefUrl) {
            return customRefUrl;
        } else if (refUrl) {
            return refUrl;
        } else if (!pathsShouldNotSetAsRefUrl.has(pathname)) {
            return href;
        } else {
            return undefined;
        }
    })();
    const qs = queryString.stringify({ refUrl: nextRefUrl });
    return `${origin}/app/login${qs ? `?${qs}` : ''}`;
};

export const loginTypes = {
    ANONYMOUS: 0,
    FACEBOOK: 1,
    LINE: 2,
    ENTERPRISE: 3,
    CODE_VALIDATION: 4,
    INSTAGRAM: 5
};

export const loginMethod = {
    [loginTypes.ANONYMOUS]: 'anonymous',
    [loginTypes.FACEBOOK]: 'facebook',
    [loginTypes.LINE]: 'line',
    [loginTypes.ENTERPRISE]: 'enterprise',
    [loginTypes.CODE_VALIDATION]: 'codeValidation',
    [loginTypes.INSTAGRAM]: 'instagram'
};

export const webhookLoginType = {
    [loginTypes.FACEBOOK]: 'label.facebook',
    [loginTypes.LINE]: 'label.line',
    [loginTypes.ENTERPRISE]: 'label.enterprise'
};

export const loginTypeByLoginMethod = {
    anonymous: loginTypes.ANONYMOUS,
    facebook: loginTypes.FACEBOOK,
    line: loginTypes.LINE,
    enterprise: loginTypes.ENTERPRISE,
    codeValidation: loginTypes.CODE_VALIDATION,
    instagram: loginTypes.INSTAGRAM
};
