import { connect } from 'react-redux';
import { IntlProvider } from 'react-intl';

import messages from 'assets/locale/lang.json';

function mapStateToProps(state) {
    const { locale } = state.i18n;
    return { locale, messages: messages[locale] };
}

export default connect(mapStateToProps)(IntlProvider);
