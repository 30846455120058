import { useMemo } from 'react';

export default function useLineEnv() {
    const isInLineAppBrowser = useMemo(
        () => navigator.userAgent.toLowerCase().includes('line'),
        []
    );
    const isInLiffBrowser = useMemo(() => {
        if (window?.liff) {
            return window.liff?.isInClient();
        }
        return false;
    }, []);

    return { isInLiffBrowser, isInLineAppBrowser };
}
