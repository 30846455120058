export const planKeys = {
    modules: {
        PSYCHO_QUIZ: 'promotionPsychologicalTest',
        SIGN_FORM: 'promotionRegistration',
        QUIZ: 'promotionQuiz',
        SURVEY: 'promotionSurvey',
        WIN_WHEEL: 'promotionRoulette',
        VOTE: 'promotionIssueVote',
        POKE_GAME: 'promotionPoke',
        SCRATCH_CARD: 'promotionScratchCard',
        INVOICE: 'promotionInvoiceTW',
        RED_ENVELOPE_RAIN: 'promotionRedEnvelope',
        PHOTO_UPLOAD: 'promotionImageUpload',
        CHECK_IN: 'promotionCheckIn',
        CONTEST_PHOTO_UPLOAD: 'promotionPhotoContest',
        PHOTO_FRAME_COMPOSITE: 'promotionCreativeFrame',
        SLOTS: 'promotionSlot',
        PICK_THE_PRIZE: 'promotionPickThePrize',
        MEMORY_MATCH: 'promotionMemoryMatch',
        COMMENT: 'promotionComment',
        CONTEST_ADMIN_UPLOAD: 'promotionVotingContest',
        CONTEST_PHOTO_FRAME_COMPOSITE: 'promotionFramedPhotoContest',
        CHALLENGE_PHOTO_FRAME_COMPOSITE: 'promotionPhotoChallenge',
        VIDEO_UPLOAD: 'promotionVideoUpload',
        CONTEST_VIDEO_UPLOAD: 'promotionVideoContest',
        INSTAGRAM: 'promotionInstagram',
        PURCHASE_REWARD: 'promotionPurchaseReward',
        POINTS_REDEEM: 'promotionPointsRedeem',
        MGM_SHARE: 'promotionMGMShare',
        SIGN_UP: 'promotionSignUp',
        MULTI_CHALLENGE: 'promotionMultichallenge',
        ORDER_REWARD: 'promotionOrderReward',
        CARD_REWARD: 'promotionCartReward'
    }
};

/**
 * @namespace 方案 planGroup
 * @property {number} [general=1] - 活動方案
 * @property {number} [point=2] - 點數方案
 */
export const planTypes = {
    PROMO_PLAN: 1,
    POINT_PLAN: 2
};
