import i18n from 'i18next';
import dayjs from 'dayjs';
import { setSuccess, setFailed } from 'store/module/notify';
import {
    getCampaignApi,
    getCampaignMessageApi,
    createCampaignApi,
    createCampaignMessageApi,
    updateCampaignApi,
    updateCampaignMessageApi,
    updateCampaignStatusApi
} from 'api/campaign';
import { subscribeIgWebhook } from 'api/sponsor';
import { uploadEditorImage } from 'api/commom';
import { timezoneDTStr2UtcDTStr, getUserTimezone } from 'utils/dateTime';
import {
    CAMPAIGN_STATUS,
    ONLINE_BUTTON_STATUS,
    SAVE_BUTTON_STATUS,
    campaignTypes,
    convertCampaignStatus2Online
} from 'config/campaignType';
import { defaultCampaignUTMParam } from 'features/campaigns/components/CampaignFieldUtm';
import campaignFailMsg from 'features/campaigns/config/error';
import { MESSAGE_TYPES } from 'features/campaigns/CampaignOnsite/const';
import { isJSONParsable } from 'utils/objectManipulate';
import { filterUndefinedOrNull } from 'utils/filter';

const SAVING_CAMPAIGN_DATA = 'campaignChat/SAVING_CAMPAIGN_DATA';
const SAVE_CAMPAIGN_DATA_FAILED = 'campaignChat/SAVE_CAMPAIGN_DATA_FAILED';
const SAVE_CAMPAIGN_DATA_SUCCESS = 'campaignChat/SAVE_CAMPAIGN_DATA_SUCCESS';
const PUBLISHING_CAMPAIGN_DATA = 'campaignChat/PUBLISHING_CAMPAIGN_DATA';
const PUBLISH_CAMPAIGN_FAILED = 'campaignChat/PUBLISH_CAMPAIGN_FAILED';
const PUBLISH_CAMPAIGN_SUCCESS = 'campaignChat/PUBLISH_CAMPAIGN_SUCCESS';
const PAUSE_CAMPAIGN_SUCCESS = 'campaignChat/PAUSE_CAMPAIGN_SUCCESS';
const FETCHING_CAMPAIGN_DATA = 'campaignChat/FETCHING_CAMPAIGN_DATA';
const FETCH_CAMPAIGN_DATA_FAILED = 'campaignChat/FETCH_CAMPAIGN_DATA_FAILED';
const FETCH_CAMPAIGN_ID_SUCCESS = 'campaignChat/FETCH_CAMPAIGN_ID_SUCCESS';
const FETCH_CAMPAIGN_MESSAGING_SUCCESS = 'campaignChat/FETCH_CAMPAIGN_MESSAGING_SUCCESS';
const FETCH_CAMPAIGN_SETTING_SUCCESS = 'campaignChat/FETCH_CAMPAIGN_SETTING_SUCCESS';
const FETCH_CAMPAIGN_MESSAGING_SETTING_SUCCESS =
    'campaignChat/FETCH_CAMPAIGN_MESSAGING_SETTING_SUCCESS';
export const CHANGE_CAMPAIGN_FIELD = 'campaignChat/CHANGE_CAMPAIGN_FIELD';
export const RESET_CAMPAIGN_DATA = 'campaignChat/RESET_CAMPAIGN_DATA';
export const VALIDATE_INPUT_FIELD = 'campaignChat/VALIDATE_INPUT_FIELD';
export const SET_CAMPAIGN_SETTING_DATA = 'campaignChat/SET_CAMPAIGN_SETTING_DATA';

async function handlerUploadCampaignImg(file, campaignId) {
    try {
        const { file_path } = await uploadEditorImage(file, {
            id: campaignId,
            type: 'Campaigns'
        });
        return file_path;
    } catch (error) {
        setFailed('campaign_image_upload_failed');
    }
}

const getInstagramPostTemplate = (title, subtitle, imageUrl, buttonText) => ({
    template_type: 'generic',
    elements: [
        {
            title,
            image_url: imageUrl,
            subtitle,
            default_action: {
                type: 'web_url',
                url: ''
            },
            buttons: [
                {
                    type: 'web_url',
                    url: '',
                    title: buttonText
                }
            ]
        }
    ]
});

export function createCampaignDataWithApi(data) {
    return async (dispatch, getState) => {
        const {
            campaignType,
            // 發送活動名稱
            campaignName,
            // 限制發送次數
            campaignMessageLimitTimes,
            // 貼文預計建立的時間區間
            periodStartDate,
            periodEndDate,
            periodTimezone,
            // 發送時間
            campaignStartDate,
            campaignEndDate,
            campaignTimezone,
            // 設定回覆訊息
            campaignMessageType,
            // 選擇活動
            campaignPromotionUuid,
            // 設定 Google UTM
            campaignUTMSetting,
            // 關鍵字設定
            instagramReplyKeyword,
            // 設定回覆訊息 - 活動訊息
            instagramReplyTemplateTitle,
            instagramReplyTemplateSubTitle,
            instagramReplyTemplateButton,
            instagramReplyTemplateImageUrl,
            // 設定回覆訊息 - 提示訊息
            instagramReplyMessage,
            // 設定回覆訊息 - 切換客服
            quickReplyMsg,
            quickReplyOption
        } = data;

        try {
            const {
                sponsor: { id: sponsorId }
            } = getState();

            dispatch({ type: SAVING_CAMPAIGN_DATA });

            const campaignPayload = {
                name: campaignName,
                type: campaignType,
                limit: campaignMessageLimitTimes
            };

            const campaign = await createCampaignApi(sponsorId, campaignPayload);
            const campaignId = campaign.id;

            // NOTE: 可能是 File 檔案，也可能是圖片網址
            const imageUrl =
                typeof instagramReplyTemplateImageUrl === 'string'
                    ? instagramReplyTemplateImageUrl
                    : await handlerUploadCampaignImg(instagramReplyTemplateImageUrl, campaignId);

            const campaignSettingPayload = {
                Campaigns: {
                    // 限時動態預計建立時間
                    periodStartDate,
                    periodEndDate,
                    periodTimezone,
                    // 發送時間
                    startDate: campaignStartDate,
                    endDate: campaignEndDate,
                    timezone: campaignTimezone,
                    // 關鍵字設定
                    instagramReplyKeyword
                }
            };

            const messagePayload = filterUndefinedOrNull({
                promotionUuid: campaignPromotionUuid,
                type: campaignMessageType,
                content: getInstagramPostTemplate(
                    instagramReplyTemplateTitle,
                    instagramReplyTemplateSubTitle,
                    imageUrl,
                    instagramReplyTemplateButton
                )
            });

            const messageSettingsPayload = filterUndefinedOrNull({
                Campaigns: {
                    googleUtm: JSON.stringify(campaignUTMSetting),
                    instagramReplyMessage,
                    quickReplyMsg,
                    quickReplyOption
                }
            });

            const message = await createCampaignMessageApi(sponsorId, campaignId, messagePayload);

            await updateCampaignApi(sponsorId, campaignId, {
                ...campaignPayload,
                settings: campaignSettingPayload
            });

            await updateCampaignMessageApi(sponsorId, campaignId, message.id, {
                ...messagePayload,
                settings: messageSettingsPayload
            });

            dispatch({ type: SAVE_CAMPAIGN_DATA_SUCCESS, payload: campaignId });
            dispatch(setSuccess('label.save_success'));
        } catch (error) {
            console.error(error);
            dispatch({ type: SAVE_CAMPAIGN_DATA_FAILED });
            dispatch(setFailed(campaignFailMsg[error?.payload?.message] || 'label.save_fail'));
        }
    };
}

export function updateCampaignDataWithApi(data) {
    return async (dispatch, getState) => {
        const {
            campaignType,
            campaignName,
            // 限制發送次數
            campaignMessageLimitTimes,
            // 貼文預計建立的時間區間
            periodStartDate,
            periodEndDate,
            periodTimezone,
            // 發送時間
            campaignStartDate,
            campaignEndDate,
            campaignTimezone,
            // 設定回覆訊息
            campaignMessageType,
            // 選擇活動
            campaignPromotionUuid,
            // 設定 Google UTM
            campaignUTMSetting,
            // 關鍵字設定
            instagramReplyKeyword,
            // 設定回覆訊息 - 活動訊息
            instagramReplyTemplateTitle,
            instagramReplyTemplateSubTitle,
            instagramReplyTemplateButton,
            instagramReplyTemplateImageUrl: imgUrl,
            // 設定回覆訊息 - 提示訊息
            instagramReplyMessage,
            // 設定回覆訊息 - 切換客服
            quickReplyMsg,
            quickReplyOption
        } = data;

        try {
            const {
                sponsor: { id: sponsorId },
                campaignChat: {
                    id: campaignIdFromRedux,
                    status: campaignStatus,
                    setting: { instagramReplyTemplateImageUrl }
                }
            } = getState();

            /**
             * NOTE: 防呆設計 => 當要編輯活動時，避免在 fetch data 時，某隻 api 有意外錯誤導致程式終止，
             * 沒有確實更新把 api response data 塞入 redux，導致 redux 的 campaignId 一直是預設值 0
             */
            const campaignIdFromPath = window.location.pathname.split('/')[5];
            const campaignId = Boolean(campaignIdFromRedux)
                ? campaignIdFromRedux
                : campaignIdFromPath;

            // NOTE: 可能是 File 檔案，也可能是圖片網址
            const imageUrl =
                instagramReplyTemplateImageUrl === imgUrl
                    ? imgUrl
                    : await handlerUploadCampaignImg(imgUrl, campaignId);

            dispatch({ type: SAVING_CAMPAIGN_DATA });

            const campaignPayload = {
                name: campaignName,
                type: campaignType,
                limit: campaignMessageLimitTimes
            };

            const campaignSettingsPayload = {
                Campaigns: {
                    // 限時動態預計建立時間
                    periodStartDate,
                    periodEndDate,
                    periodTimezone,
                    // 發送時間
                    startDate: campaignStartDate,
                    endDate: campaignEndDate,
                    timezone: campaignTimezone,
                    // 關鍵字設定
                    instagramReplyKeyword,
                    ...(() => {
                        if (!Boolean(instagramReplyKeyword)) {
                            return { deleted: ['instagramReplyKeyword'] };
                        }
                        return {};
                    })()
                }
            };

            const messagePayload = filterUndefinedOrNull({
                promotionUuid: campaignPromotionUuid,
                type: campaignMessageType,
                content: getInstagramPostTemplate(
                    instagramReplyTemplateTitle,
                    instagramReplyTemplateSubTitle,
                    imageUrl,
                    instagramReplyTemplateButton
                )
            });

            const messageSettingsPayload = filterUndefinedOrNull({
                Campaigns: {
                    googleUtm: JSON.stringify(campaignUTMSetting),
                    instagramReplyMessage,
                    quickReplyMsg,
                    quickReplyOption
                }
            });

            await updateCampaignApi(sponsorId, campaignId, {
                ...campaignPayload,
                settings: campaignSettingsPayload
            });

            await updateCampaignMessageApi(sponsorId, campaignId, null, {
                ...messagePayload,
                settings: messageSettingsPayload
            });

            if (campaignStatus === CAMPAIGN_STATUS.PUBLISHED) {
                await subscribeIgWebhook({ sponsorId, campaignId, subscribeMode: 'campaign-tool' });
            }

            dispatch({ type: SAVE_CAMPAIGN_DATA_SUCCESS, payload: campaignId });
            dispatch(setSuccess('label.save_success'));
        } catch (err) {
            console.error(err);
            dispatch({ type: SAVE_CAMPAIGN_DATA_FAILED });
            dispatch(setFailed(campaignFailMsg[err?.payload?.message] || 'label.save_fail'));
        }
    };
}

export function publishCampaignDataWithApi(nextStatus) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId },
            campaignChat: { id: campaignId }
        } = getState();
        try {
            dispatch({ type: PUBLISHING_CAMPAIGN_DATA });
            if (nextStatus === CAMPAIGN_STATUS.PAUSED) {
                await updateCampaignStatusApi(sponsorId, campaignId, CAMPAIGN_STATUS.PAUSED);
                dispatch({ type: PAUSE_CAMPAIGN_SUCCESS });
                dispatch(setSuccess('campaign.complete_succeed'));
            } else {
                await subscribeIgWebhook({ sponsorId, campaignId, subscribeMode: 'campaign-tool' });
                await updateCampaignStatusApi(sponsorId, campaignId, CAMPAIGN_STATUS.PUBLISHED);
                dispatch({ type: PUBLISH_CAMPAIGN_SUCCESS });
                dispatch(setSuccess('campaign.publish_succeed'));
            }
        } catch (error) {
            console.error(error);
            dispatch({ type: PUBLISH_CAMPAIGN_FAILED });
            dispatch(setFailed(campaignFailMsg[error?.payload?.message] || 'label.save_fail'));
        }
    };
}

export function fetchCampaignDataWithApi({ campaignId }) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId }
        } = getState();

        try {
            dispatch({ type: FETCHING_CAMPAIGN_DATA, payload: true });

            const {
                name: campaignName,
                status: campaignStatus,
                limit: campaignMessageLimitTimes,
                type: campaignType,
                settings: { Campaigns: settingCampaigns }
            } = await getCampaignApi(sponsorId, campaignId);

            const { id: messageId } = (await getCampaignMessageApi(sponsorId, campaignId))[0];

            const {
                promotionUuid: campaignPromotionUuid,
                type: campaignMessageType,
                content: campaignMessageContent,
                settings: { Campaigns: settingMessage }
            } = await getCampaignMessageApi(sponsorId, campaignId, messageId);

            const {
                periodStartDate,
                periodEndDate,
                periodTimezone,
                startDate: campaignStartDate,
                endDate: campaignEndDate,
                timezone: campaignTimezone,
                instagramReplyKeyword
            } = settingCampaigns;

            const {
                googleUtm,
                instagramReplyMessage,
                quickReplyMsg,
                quickReplyOption
            } = settingMessage;

            dispatch({
                type: FETCH_CAMPAIGN_ID_SUCCESS,
                payload: filterUndefinedOrNull({
                    id: campaignId,
                    saveButton: campaignId ? SAVE_BUTTON_STATUS.SAVED : SAVE_BUTTON_STATUS.IDLE,
                    onlineButton: convertCampaignStatus2Online(campaignStatus),
                    status: campaignStatus,
                    setting: {
                        campaignName,
                        campaignStatus,
                        campaignType,
                        campaignMessageLimitTimes,
                        campaignPromotionUuid,
                        campaignMessageType,
                        campaignUTMSetting: isJSONParsable(googleUtm) ? JSON.parse(googleUtm) : {},
                        periodStartDate,
                        periodEndDate,
                        periodTimezone,
                        campaignStartDate,
                        campaignEndDate,
                        campaignTimezone,
                        instagramReplyKeyword:
                            instagramReplyKeyword && instagramReplyKeyword.length > 0
                                ? instagramReplyKeyword.split(',')
                                : undefined,
                        ...(() => {
                            try {
                                if (!campaignMessageContent) {
                                    return {};
                                }
                                const parsed = JSON.parse(campaignMessageContent);
                                if (!parsed.elements?.[0]) {
                                    return {};
                                }
                                const { title, image_url, subtitle, buttons } = parsed.elements[0];
                                return {
                                    instagramReplyTemplateTitle: title || '',
                                    instagramReplyTemplateImageUrl: image_url || '',
                                    instagramReplyTemplateSubTitle: subtitle || '',
                                    instagramReplyTemplateButton: buttons[0].title || ''
                                };
                            } catch (error) {
                                return {};
                            }
                        })(),
                        instagramReplyMessage,
                        quickReplyMsg,
                        quickReplyOption
                    }
                })
            });
        } catch (error) {
            console.error(error.message);
            dispatch({ type: FETCH_CAMPAIGN_DATA_FAILED });
            const errCode = error?.payload?.message || error?.message;
            dispatch(setFailed(campaignFailMsg[errCode] || 'campaign.fetch_fail'));
        } finally {
            dispatch({ type: FETCHING_CAMPAIGN_DATA, payload: false });
        }
    };
}

export function validateCampaignData(fields) {
    return {
        type: VALIDATE_INPUT_FIELD,
        payload: fields
    };
}

export function resetCampaignChat() {
    return {
        type: RESET_CAMPAIGN_DATA
    };
}

const defaultTimezone = getUserTimezone();
const defaultStartDate = timezoneDTStr2UtcDTStr(
    dayjs(new Date()).format('YYYY-MM-DD') + ' 00:00:00',
    defaultTimezone
);
const defaultEndDate = timezoneDTStr2UtcDTStr(
    dayjs(new Date()).format('YYYY-MM-DD') + ' 23:59:59',
    defaultTimezone
);

export const getDefaultState = () => {
    // Match URL pattern to get toolId
    const getToolIdFromUrl = () => {
        const path = window.location.pathname;
        const matches = path.match(/^\/admin\/campaign\/chat\/(\d+)/);
        return matches ? matches[1] : null;
    };

    const toolId = getToolIdFromUrl();

    const campaignTypeMap = {
        '1': campaignTypes.STORY_MENTION,
        '2': campaignTypes.POST_REPLY,
        '3': campaignTypes.STORY_REPLY,
        '4': campaignTypes.LIVE_REPLY,
        '5': campaignTypes.KEYWORD_REPLY
    };

    const campaignConfigMap = {
        '1': {
            campaignStartDate: defaultStartDate,
            campaignEndDate: defaultEndDate,
            campaignTimezone: defaultTimezone,
            quickReplyMsg: i18n.t('campaign.instagram_quick_reply.message'),
            quickReplyOption: i18n.t('campaign.instagram_quick_reply.option')
        },
        '2': {
            campaignPostType: 0,
            periodStartDate: defaultStartDate,
            periodEndDate: defaultEndDate,
            periodTimezone: defaultTimezone,
            campaignStartDate: defaultStartDate,
            campaignEndDate: defaultEndDate,
            campaignTimezone: defaultTimezone,
            instagramReplyKeyword: []
        },
        '3': {
            campaignStoryType: 0,
            periodStartDate: defaultStartDate,
            periodEndDate: defaultEndDate,
            periodTimezone: defaultTimezone,
            instagramReplyKeyword: []
        },
        '4': {
            campaignStartDate: defaultStartDate,
            campaignEndDate: defaultEndDate,
            campaignTimezone: defaultTimezone,
            instagramReplyKeyword: []
        },
        '5': {
            campaignStartDate: defaultStartDate,
            campaignEndDate: defaultEndDate,
            campaignTimezone: defaultTimezone,
            instagramReplyKeyword: ''
        }
    };

    return {
        id: 0,
        isFetching: false,
        saveButton: SAVE_BUTTON_STATUS.IDLE,
        onlineButton: ONLINE_BUTTON_STATUS.ONLINE,
        status: CAMPAIGN_STATUS.UNPUBLISHED,
        setting: {
            campaignType: campaignTypeMap[toolId] || campaignTypes.POST_REPLY, // Default to POST_REPLY if toolId not found
            campaignName: '',
            // 限制發送次數: 0 = 不限次數, 1 = 限定發送一次
            campaignMessageLimitTimes: 1,
            // 選擇訊息類型: 1 = 自行輸入網址, 2 = 限有設定通關序號的活動, 3 = 一般的活動
            campaignMessageType: MESSAGE_TYPES.PASSCODE_PROMO,
            campaignPromotionUuid: null,
            campaignUTMSetting: defaultCampaignUTMParam,
            instagramReplyMessage: i18n.t('campaign.instagram_reply.message'),
            instagramReplyTemplateTitle: i18n.t('campaign.instagram_reply_template.title'),
            instagramReplyTemplateSubTitle: i18n.t('campaign.instagram_reply_template.subtitle'),
            instagramReplyTemplateButton: i18n.t('campaign.instagram_reply_template.button'),
            instagramReplyTemplateImageUrl:
                'https://assets.fevercdn.com/promotion/img/memoryMatch_cardBack.jpg',
            settingType: 'assign_publish',
            ...(campaignConfigMap[toolId] || {})
        },
        validating: {
            campaignName: false,
            instagramReplyKeyword: false,
            campaignPromotionUuid: false
        }
    };
};

export default function reducer(state = getDefaultState(), { type, payload }) {
    switch (type) {
        case SAVING_CAMPAIGN_DATA:
            return { ...state, saveButton: SAVE_BUTTON_STATUS.FETCHING };
        case SAVE_CAMPAIGN_DATA_FAILED:
            return { ...state, saveButton: SAVE_BUTTON_STATUS.IDLE };
        case SAVE_CAMPAIGN_DATA_SUCCESS:
            return { ...state, saveButton: SAVE_BUTTON_STATUS.SAVED, id: payload };
        case PUBLISHING_CAMPAIGN_DATA:
            return {
                ...state,
                saveButton: SAVE_BUTTON_STATUS.FETCHING,
                onlineButton: ONLINE_BUTTON_STATUS.LOADING
            };
        case PUBLISH_CAMPAIGN_FAILED:
            return {
                ...state,
                saveButton: SAVE_BUTTON_STATUS.SAVED,
                onlineButton: ONLINE_BUTTON_STATUS.ONLINE
            };
        case PUBLISH_CAMPAIGN_SUCCESS:
            return {
                ...state,
                saveButton: SAVE_BUTTON_STATUS.SAVED,
                onlineButton: ONLINE_BUTTON_STATUS.OFFLINE,
                status: CAMPAIGN_STATUS.PUBLISHED
            };
        case PAUSE_CAMPAIGN_SUCCESS:
            return {
                ...state,
                saveButton: SAVE_BUTTON_STATUS.SAVED,
                onlineButton: ONLINE_BUTTON_STATUS.ONLINE,
                status: CAMPAIGN_STATUS.PAUSED
            };
        case FETCHING_CAMPAIGN_DATA:
            return { ...state, isFetching: payload };
        case FETCH_CAMPAIGN_DATA_FAILED:
            return { ...state };
        case FETCH_CAMPAIGN_ID_SUCCESS:
            return {
                ...state,
                id: payload.id,
                saveButton: payload.saveButton,
                onlineButton: payload.onlineButton,
                status: payload.status,
                setting: {
                    ...state.setting,
                    ...payload.setting
                }
            };
        case FETCH_CAMPAIGN_MESSAGING_SUCCESS:
            return {
                ...state,
                setting: {
                    ...state.setting,
                    ...payload.setting
                }
            };
        case FETCH_CAMPAIGN_SETTING_SUCCESS:
            return {
                ...state,
                setting: {
                    ...state.setting,
                    ...payload.setting
                }
            };
        case FETCH_CAMPAIGN_MESSAGING_SETTING_SUCCESS:
            return {
                ...state,
                setting: {
                    ...state.setting,
                    ...payload.setting
                }
            };
        case CHANGE_CAMPAIGN_FIELD:
            return {
                ...state,
                saveButton: SAVE_BUTTON_STATUS.IDLE
            };
        case RESET_CAMPAIGN_DATA:
            return getDefaultState();
        case VALIDATE_INPUT_FIELD:
            return {
                ...state,
                validating: {
                    ...state.validating,
                    ...payload
                }
            };
        case SET_CAMPAIGN_SETTING_DATA:
            return {
                ...state,
                setting: {
                    ...state.setting,
                    ...payload
                }
            };
        default:
            return state;
    }
}
