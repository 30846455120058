const INIT_ACCOUNT = 'account/INIT_ACCOUNT';
const UPDATE_ACCOUNT = 'account/UPDATE_ACCOUNT';
const CHANGE_ACCOUNT = 'account/CHANGE_ACCOUNT';

const initialState = {
    isInit: false
};

export default function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case INIT_ACCOUNT:
            return { ...payload };
        case CHANGE_ACCOUNT:
        case UPDATE_ACCOUNT:
            return { ...state, ...payload };
        default:
            return state;
    }
}

export function initAccount(data) {
    return {
        type: INIT_ACCOUNT,
        payload: data
    };
}

export function updateAccount(data) {
    return {
        type: UPDATE_ACCOUNT,
        payload: data
    };
}

export function changeAccount(data) {
    return {
        type: CHANGE_ACCOUNT,
        payload: data
    };
}
