import { getTemplateSettings } from 'features/campaigns/config/campaignDefaultSettingValue';
import { MESSAGE_TYPES, CLICK_DELAY_TIME } from 'features/campaigns/CampaignOnsite/const';
import {
    updateReduxObjKeyHandleDelete,
    deleteReduxObjKey,
    mergeAndRemoveKey,
    transformApiResponse,
    isJSONParsable
} from 'utils/objectManipulate';
import {
    createCampaignMessageApi,
    getCampaignMessageApi,
    updateCampaignMessageApi
} from 'api/campaign';
import { isURL } from 'validator';
import { campaignGroupTypes, getCampaignGroupTypeByTemplateId } from 'config/campaignType';

export const REPLACE_CAMPAIGN_MESSAGE = 'REPLACE_CAMPAIGN_MESSAGE';
export const REPLACE_SETTING_CAMPAIGN_MESSAGE = 'REPLACE_SETTING_CAMPAIGN_MESSAGE';
export const REPLACE_SETTING_CAMPAIGN_STYLE = 'REPLACE_SETTING_CAMPAIGN_STYLE';
export const UPDATE_CAMPAIGN_MESSAGE = 'UPDATE_CAMPAIGN_MESSAGE';
export const UPDATE_SETTING_CAMPAIGN_MESSAGE = 'UPDATE_SETTING_CAMPAIGN_MESSAGE';
export const UPDATE_SETTING_CAMPAIGN_STYLE = 'UPDATE_SETTING_CAMPAIGN_STYLE';
export const DELETE_CAMPAIGN_MESSAGE_SPEC_KEY = 'DELETE_CAMPAIGN_MESSAGE_SPEC_KEY';
export const SET_VALIDATION_ERRORS = 'MESSAGE/SET_VALIDATION_ERRORS';
export const CLEAR_VALIDATION_ERRORS = 'MESSAGE/CLEAR_VALIDATION_ERRORS';
export const CLEAR_SPECIFIC_VALIDATION_ERROR = 'MESSAGE/CLEAR_SPECIFIC_VALIDATION_ERROR';
export const START_PUBLISHED_EDITING = 'START_PUBLISHED_EDITING';
export const CANCEL_PUBLISHED_EDITING = 'CANCEL_PUBLISHED_EDITING';

export const defaultState = {
    id: 0,
    content: {},
    // Store previous state for cancellation
    previousData: null,
    isEditing: false,
    validationErrors: null,
    settingCampaignMessage: {
        ...getTemplateSettings().settingMessage
    },
    settingCampaignStyle: {
        ...getTemplateSettings().settingStyle
    }
};

const reducer = (state = defaultState, action = {}) => {
    switch (action.type) {
        case REPLACE_CAMPAIGN_MESSAGE:
            return action.payload;
        case REPLACE_SETTING_CAMPAIGN_MESSAGE:
            return {
                ...state,
                settingCampaignMessage: {
                    ...state.settingCampaignMessage,
                    ...action.payload
                }
            };
        case REPLACE_SETTING_CAMPAIGN_STYLE:
            return {
                ...state,
                settingCampaignStyle: {
                    ...state.settingCampaignStyle,
                    ...action.payload
                }
            };
        case UPDATE_CAMPAIGN_MESSAGE: {
            return updateReduxObjKeyHandleDelete(state, action.payload, action.updateAction);
        }
        case UPDATE_SETTING_CAMPAIGN_MESSAGE: {
            return {
                ...state,
                settingCampaignMessage: {
                    ...state.settingCampaignMessage,
                    ...updateReduxObjKeyHandleDelete(
                        state.settingCampaignMessage,
                        action.payload,
                        action.updateAction
                    )
                }
            };
        }
        case UPDATE_SETTING_CAMPAIGN_STYLE: {
            return {
                ...state,
                settingCampaignStyle: {
                    ...state.settingCampaignStyle,
                    ...updateReduxObjKeyHandleDelete(
                        state.settingCampaignStyle,
                        action.payload,
                        action.updateAction
                    )
                }
            };
        }
        case DELETE_CAMPAIGN_MESSAGE_SPEC_KEY: {
            return deleteReduxObjKey(state, action.payload);
        }
        case SET_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: action.payload
            };
        case START_PUBLISHED_EDITING:
            return {
                ...state,
                previousData: structuredClone({
                    settingCampaignMessage: state.settingCampaignMessage,
                    settingCampaignStyle: state.settingCampaignStyle
                }),
                isEditing: true
            };
        case CANCEL_PUBLISHED_EDITING:
            return {
                ...state,
                previousData: null,
                settingCampaignMessage:
                    state.previousData?.settingCampaignMessage || state.settingCampaignMessage,
                settingCampaignStyle:
                    state.previousData?.settingCampaignStyle || state.settingCampaignStyle,
                isEditing: false,
                validationErrors: null
            };
        case CLEAR_VALIDATION_ERRORS:
            return {
                ...state,
                validationErrors: null
            };
        case CLEAR_SPECIFIC_VALIDATION_ERROR: {
            if (!state.validationErrors) {
                return state;
            }

            // Create new validation errors object without the specified key
            const { [action.payload]: removedError, ...remainingErrors } = state.validationErrors;

            // If there are no remaining errors, set to null instead of empty object
            const newValidationErrors =
                Object.keys(remainingErrors).length === 0 ? null : remainingErrors;

            return {
                ...state,
                validationErrors: newValidationErrors
            };
        }
        default:
            return state;
    }
};

// Action creators
export const setValidationMessageErrors = errors => ({
    type: SET_VALIDATION_ERRORS,
    payload: errors
});

export const clearValidationMessageErrors = () => ({
    type: CLEAR_VALIDATION_ERRORS
});

// Clear specific validation error by key
export const clearSpecificValidationMessageError = key => ({
    type: CLEAR_SPECIFIC_VALIDATION_ERROR,
    payload: key
});

/* ---- PATCH ---- */
export function updateCampaignMessage(payload, updateAction) {
    return {
        type: UPDATE_CAMPAIGN_MESSAGE,
        payload,
        updateAction
    };
}

export function updateSettingCampaignMessage(payload, updateAction) {
    return {
        type: UPDATE_SETTING_CAMPAIGN_MESSAGE,
        payload,
        updateAction
    };
}

export function updateSettingCampaignStyle(payload, updateAction) {
    return {
        type: UPDATE_SETTING_CAMPAIGN_STYLE,
        payload,
        updateAction
    };
}

export function saveAndUpdateSettingCampaignMessage(payload) {
    return async (dispatch, getState) => {
        const {
            campaignOnsite: { id: campaignId },
            message: { id: messageId, settingCampaignMessage, settingCampaignStyle },
            sponsor: { id: sponsorId }
        } = getState();

        const { Campaigns, Styles } = payload;

        await updateCampaignMessageApi(sponsorId, campaignId, messageId, {
            settings: { Campaigns, Styles }
        });

        if (Styles) {
            dispatch({
                type: REPLACE_SETTING_CAMPAIGN_STYLE,
                payload: mergeAndRemoveKey(settingCampaignStyle, Styles)
            });
        }

        if (Campaigns) {
            dispatch({
                type: REPLACE_SETTING_CAMPAIGN_MESSAGE,
                payload: mergeAndRemoveKey(settingCampaignMessage, Campaigns)
            });
        }
    };
}

/* ---- POST ---- */
export function createCampaignMessage({ type, content, promotionUuid }) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId },
            campaignOnsite: { id: campaignId }
        } = getState();

        const campaignMessagePayload = {
            type,
            content,
            promotionUuid: promotionUuid
        };
        const message = await createCampaignMessageApi(
            sponsorId,
            campaignId,
            campaignMessagePayload
        );

        dispatch(
            updateCampaignMessage({
                id: message.id,
                ...campaignMessagePayload
            })
        );
    };
}

/* ---- GET ---- */
export function getCampaignMessage(messageId) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId },
            campaignOnsite: { id: campaignId }
        } = getState();

        const campaignMessage = await getCampaignMessageApi(sponsorId, campaignId, messageId);

        const { settings, content, ...message } = campaignMessage;
        const { Campaigns, Styles } = settings;

        const payload = {
            ...message,
            content: isJSONParsable(content) ? JSON.parse(content) : content,
            settingCampaignMessage: transformApiResponse(Campaigns, {
                prefixes: ['enable', 'delayMessageSeconds'],
                suffixes: ['Type'],
                Constructor: Number
            }),
            settingCampaignStyle: transformApiResponse(Styles, {
                prefixes: [
                    'enable',
                    'messageAnimation',
                    'messageBorderRadius',
                    'messageGapX',
                    'messageGapY',
                    'messagePlacement',
                    'messageFrequency',
                    'messageMaskEnabled'
                ],
                suffixes: ['Type', 'Id'],
                Constructor: Number
            })
        };

        dispatch({
            type: REPLACE_CAMPAIGN_MESSAGE,
            payload
        });
    };
}

/* ---- DELETE ---- */
/**
 * @param {Array} payload - [wantToDeletKeyA, wantToDeletKeyB] 把要刪除的 key name 丟進陣列
 */
export function deleteCampaignMessageSpecKey(payload) {
    return {
        type: DELETE_CAMPAIGN_MESSAGE_SPEC_KEY,
        payload
    };
}

export const validateCampaignMessage = settingMessage => {
    const errors = {};

    if (
        getCampaignGroupTypeByTemplateId(settingMessage.messageTemplateI) ===
        campaignGroupTypes.Icon
    ) {
        if (settingMessage.messageGapX === undefined || settingMessage.messageGapX === '') {
            // 驗證間距 X 軸
            errors.messageGapX = 'label.is_required';
        }

        // 驗證間距 Y 軸
        if (!settingMessage.messageGapY === undefined || settingMessage.messageGapY === '') {
            errors.messageGapY = 'label.is_required';
        }
    }

    // 驗證訊息類型
    if (!settingMessage.messageType) {
        errors.messageType = 'label.errors.setting_message_type';
    }

    // 驗證訊息類型 (網頁)
    if (settingMessage.messageType === MESSAGE_TYPES.CUSTOM_URL) {
        if (
            typeof settingMessage.messageTargetUrl === 'string' &&
            !isURL(settingMessage.messageTargetUrl, {
                require_protocol: true,
                protocols: ['https']
            })
        ) {
            errors.messageTargetUrl = 'label.invalid_href';
        } else if (!settingMessage.messageTargetUrl) {
            errors.messageTargetUrl = 'label.is_required';
        }
    }

    // 驗證訊息類型 (活動 / 給予參加資格)
    if (
        settingMessage.messageType === MESSAGE_TYPES.PASSCODE_PROMO ||
        settingMessage.messageType === MESSAGE_TYPES.PROMO
    ) {
        if (!settingMessage.promotionUuid) {
            errors.promotionUuid = 'label.is_required';
        } else if (settingMessage.promotionUuid === 'Promotion not found') {
            errors.promotionUuid = 'label.related_promo_not_exist';
        }
    }

    // 驗證點擊行為
    if (!settingMessage.clickBehaviorType) {
        errors.clickBehaviorType = 'label.errors.setting_click_behavior_type';
    }

    // 驗證延遲點擊時間
    if (settingMessage.enableDelayMessageClick === CLICK_DELAY_TIME.ENABLE) {
        if (!settingMessage.delayMessageSeconds) {
            errors.delayMessageSeconds = 'label.errors.setting_delay_message_seconds_type';
        }
    }
    return errors;
};

export default reducer;
