export const MOBILE_PREVIEW_HEIGHT = 720;
export const MOBILE_PREVIEW_WIDTH = 400;
export const MOBILE_PREVIEW_PADDING = 48;
export const NAV_HEADER_HEIGHT = 64;
export const DIALOG_MAX_WIDTH = 320;
export const BANNER_MAX_WIDTH = 720;

/**
 * 訊息類型
 ** 客製化網頁 - 1
 ** 發送通關序號 - 2
 ** 活動 - 3
 */
export const MESSAGE_TYPES = {
    PROMO: 3,
    PASSCODE_PROMO: 2,
    CUSTOM_URL: 1
};

/**
 * 點擊行為
 ** 開啟彈跳視窗 - 1 (有 cname 才能設定)
 ** 另開頁面 - 2
 ** 原頁面開啟 - 3
 ** 背景參加活動 - 4 (只有訊息類型是「活動」、「給予參加資格」時，才能設定背景參加活動)
 */
export const CLICK_BEHAVIOR = {
    OPEN_POPUP: 1,
    OPEN_NEW_WINDOW: 2,
    OPEN_IN_SAME_PAGE: 3
    // PRELOAD_AND_JOIN_PROMO: 4
};

/**
 * 延遲點擊時間
 ** 關閉 - 0
 ** 開啟 - 1
 */
export const CLICK_DELAY_TIME = {
    DISABLE: 0,
    ENABLE: 1
};

/**
 * 指定排程
 ** 關閉 - 0
 ** 開啟 - 1
 */
export const SCHEDULE_DISPLAY = {
    DISABLE: 0,
    ENABLE: 1
};

/**
 * 訊息顯示位置
 ** 全部頁面 - 0
 ** 部分頁面 - 1
 ** 指定關卡瀏覽頁 - 2
 */
export const MESSAGE_DISPLAY_POSITION = {
    ALL_PAGE: 0,
    SPECIFIC_PAGE: 1,
    MULTI_TASK_PROMO_PAGE: 2
};

/**
 * 觸發時機
 ** 頁面載入時 - 0
 ** 停留在頁面X秒 - 1
 */
export const TRIGGER_TIMING = {
    ON_PAGE_LOAD: 0,
    WAIT_ON_PAGE: 1
};

/**
 * 發送頻率
 ** 每次訪問 - 0
 ** 每天一次 - 1
 ** 只顯示一次 - 2
 */
export const DISPLAY_FREQUENCY = {
    EVERY_VISIT: 0,
    ONCE_PER_DAY: 1,
    SHOW_ONCE: 2
};

/**
 * 顯示位置
 ** top-left - 1
 ** top-center - 2
 ** top-right - 3
 ** center-left - 4
 ** center-center - 5
 ** center-right - 6
 ** Bottom-left - 7
 ** Bottom-center - 8
 ** Bottom-right - 9
 */
export const DISPLAY_POSITION = {
    TOP_LEFT: 1,
    TOP_CENTER: 2,
    TOP_RIGHT: 3,
    CENTER_LEFT: 4,
    CENTER_CENTER: 5,
    CENTER_RIGHT: 6,
    BOTTOM_LEFT: 7,
    BOTTOM_CENTER: 8,
    BOTTOM_RIGHT: 9
};

/**
 * 顯示位置
 ** none - 1
 ** medium - 2
 ** large - 3
 */
export const BORDER_RADIUS = {
    NONE: 1,
    MEDIUM: 2,
    LARGE: 3
};

/**
 * 動態效果
 ** 無 - 0
 ** 縮放 - 1
 ** 上下跳動 - 2
 ** 左右跳動 - 3
 ** 左右晃動 - 4
 ** 旋轉晃動 - 5
 */
export const DYNAMIC_EFFECTS = {
    NONE: 0,
    ZOOM: 1,
    JUMP_UP_DOWN: 2,
    JUMP_LEFT_RIGHT: 3,
    SHAKE_LEFT_RIGHT: 4,
    ROTATIONAL_SHAKING: 5
};

/**
 * 播放頻率
 ** 持續不停 - 0
 ** 3次後停止 - 1
 ** 10次後停止 - 2
 ** 循環播放 3次停 5秒 - 3
 */
export const PLAYBACK_FREQUENCY = {
    CONTINUOUS: 0,
    STOP_AFTER_THREE_TIMES: 1,
    STOP_AFTER_TEN_TIMES: 2,
    LOOP_THREE_TIMES_STOP_FIVE_SEC: 3
};

/**
 * onSitePanel
 ** 樣式 - 1
 ** 設定 - 2
 */
export const ONSITE_PANEL = {
    STYLES: 1,
    SETTINGS: 2
};

/**
 * BACKGROUND_MODE
 ** 圖片 - image
 ** 顏色 - color
 */
export const BACKGROUND_MODE = {
    IMAGE: 'image',
    COLOR: 'color'
};

/**
 * DIALOG_CLOSE_BUTTON_MODE
 ** 文字 - text
 ** 顏色 - color
 */
export const DIALOG_CLOSE_BUTTON_MODE = {
    TEXT: 'text',
    COLOR: 'color'
};

// Mapping labels for different types
export const MESSAGE_TYPE_LABELS = {
    [MESSAGE_TYPES.CUSTOM_URL]: 'label.campaign.onsite_settings.message_type.custom_url',
    [MESSAGE_TYPES.PROMO]: 'label.campaign.onsite_settings.message_type.promo',
    [MESSAGE_TYPES.PASSCODE_PROMO]: 'label.campaign.onsite_settings.message_type.passcode_promo'
};

export const CLICK_BEHAVIOR_LABELS = {
    [CLICK_BEHAVIOR.OPEN_POPUP]: 'label.campaign.onsite_settings.click_behavior.open_popup',
    [CLICK_BEHAVIOR.OPEN_NEW_WINDOW]:
        'label.campaign.onsite_settings.click_behavior.open_new_window',
    [CLICK_BEHAVIOR.OPEN_IN_SAME_PAGE]:
        'label.campaign.onsite_settings.click_behavior.open_in_same_page'
    // [CLICK_BEHAVIOR.PRELOAD_AND_JOIN_PROMO]: '背景參加活動'
};

export const MESSAGE_DISPLAY_POSITION_LABELS = {
    [MESSAGE_DISPLAY_POSITION.ALL_PAGE]:
        'label.campaign.onsite_settings.message_display_position.all_page',
    [MESSAGE_DISPLAY_POSITION.SPECIFIC_PAGE]:
        'label.campaign.onsite_settings.message_display_position.specific_page',
    [MESSAGE_DISPLAY_POSITION.MULTI_TASK_PROMO_PAGE]:
        'label.campaign.onsite_settings.message_display_position.multi_task_promo_page'
};

export const DISPLAY_FREQUENCY_LABELS = {
    [DISPLAY_FREQUENCY.EVERY_VISIT]: 'label.campaign.onsite_settings.display_frequency.every_visit',
    [DISPLAY_FREQUENCY.SHOW_ONCE]: 'label.campaign.onsite_settings.display_frequency.show_once',
    [DISPLAY_FREQUENCY.ONCE_PER_DAY]:
        'label.campaign.onsite_settings.display_frequency.once_per_day'
};

export const TRIGGER_TIMING_LABELS = {
    [TRIGGER_TIMING.ON_PAGE_LOAD]: 'label.campaign.onsite_settings.trigger_timing.on_page_load',
    [TRIGGER_TIMING.WAIT_ON_PAGE]: 'label.campaign.onsite_settings.trigger_timing.wait_on_page'
};

export const DISPLAY_POSITION_LABELS = {
    [DISPLAY_POSITION.TOP_LEFT]: 'top-left',
    [DISPLAY_POSITION.TOP_CENTER]: 'top',
    [DISPLAY_POSITION.TOP_RIGHT]: 'top-right',
    [DISPLAY_POSITION.CENTER_LEFT]: 'left',
    [DISPLAY_POSITION.CENTER_CENTER]: 'center',
    [DISPLAY_POSITION.CENTER_RIGHT]: 'right',
    [DISPLAY_POSITION.BOTTOM_LEFT]: 'bottom-left',
    [DISPLAY_POSITION.BOTTOM_CENTER]: 'bottom',
    [DISPLAY_POSITION.BOTTOM_RIGHT]: 'bottom-right'
};

export const BORDER_RADIUS_LABELS = {
    [BORDER_RADIUS.NONE]: 0,
    [BORDER_RADIUS.MEDIUM]: 16,
    [BORDER_RADIUS.LARGE]: '50%'
};

export const DIALOG_BORDER_RADIUS_LABELS = {
    [BORDER_RADIUS.NONE]: 0,
    [BORDER_RADIUS.MEDIUM]: 16,
    [BORDER_RADIUS.LARGE]: 40
};

export const DYNAMIC_EFFECTS_LABELS = {
    [DYNAMIC_EFFECTS.NONE]: 'label.campaign.onsite_settings.dynamic_effects.none',
    [DYNAMIC_EFFECTS.ZOOM]: 'label.campaign.onsite_settings.dynamic_effects.zoom',
    [DYNAMIC_EFFECTS.JUMP_UP_DOWN]: 'label.campaign.onsite_settings.dynamic_effects.jump_up_down',
    [DYNAMIC_EFFECTS.JUMP_LEFT_RIGHT]:
        'label.campaign.onsite_settings.dynamic_effects.jump_left_right',
    [DYNAMIC_EFFECTS.SHAKE_LEFT_RIGHT]:
        'label.campaign.onsite_settings.dynamic_effects.shake_left_right',
    [DYNAMIC_EFFECTS.ROTATIONAL_SHAKING]:
        'label.campaign.onsite_settings.dynamic_effects.rotational_shaking'
};

export const PLAYBACK_FREQUENCY_LABELS = {
    //TODO: 增加i18n
    [PLAYBACK_FREQUENCY.CONTINUOUS]: 'label.campaign.onsite_settings.playback_frequency.continuous',
    [PLAYBACK_FREQUENCY.STOP_AFTER_THREE_TIMES]:
        'label.campaign.onsite_settings.playback_frequency.stop_after_three_times',
    [PLAYBACK_FREQUENCY.STOP_AFTER_TEN_TIMES]:
        'label.campaign.onsite_settings.playback_frequency.stop_after_ten_times',
    [PLAYBACK_FREQUENCY.LOOP_THREE_TIMES_STOP_FIVE_SEC]:
        'label.campaign.onsite_settings.playback_frequency.loop_three_times_stop_five_sec'
};

export const ONSITE_FIELD_KEY_MAPPING = {
    dateRange: 'campaign.onsite.field.settings.enable_message_datetime_limit',
    messagePageExclude: 'campaign.onsite.field.settings.message_page_exclude',
    messagePageInclude: 'campaign.onsite.field.settings.message_page_include',
    messagePromoUuid: 'campaign.onsite.field.settings.message_promo_uuid',
    messageTaskUuid: 'campaign.onsite.field.settings.message_task_uuid',
    triggerTimingSeconds: 'campaign.onsite.field.settings.trigger_timing_seconds',
    delayMessageSeconds: 'campaign.onsite.field.settings.delay_message_seconds',
    messageTargetUrl: 'campaign.onsite.field.settings.message_target_url',
    promotionUuid: 'campaign.onsite.field.settings.promotion_uuid',
    messageGapX: 'campaign.onsite.field.style.message_gap_x',
    messageGapY: 'campaign.onsite.field.style.message_gap_y'
};

export const BACKGROUND_MODE_LABELS = {
    [BACKGROUND_MODE.IMAGE]: 'label.Image',
    [BACKGROUND_MODE.COLOR]: 'label.color'
};

export const DIALOG_CLOSE_BUTTON_MODE_LABELS = {
    [DIALOG_CLOSE_BUTTON_MODE.TEXT]: 'label.text',
    [DIALOG_CLOSE_BUTTON_MODE.COLOR]: 'label.icon'
};

// 尺寸比例
export const ratio = {
    mobile: {
        width: 590,
        height: 250
    },
    desktop: {
        width: 2880,
        height: 600
    }
};
