/* 「權限值」定義: 
    0 => 無權限
    1 => 可異動該項目狀態
    2 => 可異動該項目資料
    3 => 1 + 2
    4 => 可讀取該項目資料
    5 => 1 + 4
    6 => 2 + 4
    7 => 1 + 2 + 4
*/
const permission = {
    setAllKeys: function (initStatus = false) {
        return {
            canRead: initStatus,
            canEdit: initStatus,
            canDelete: initStatus,
            canPublish: initStatus
        };
    },
    0: function () {
        return this.setAllKeys(false);
    },
    1: function () {
        return { canPublish: true, canDelete: true };
    },
    2: function () {
        return { canEdit: true };
    },
    3: function () {
        return { ...this[1](), ...this[2]() };
    },
    4: function () {
        return { canRead: true };
    },
    5: function () {
        return { ...this[1](), ...this[4]() };
    },
    6: function () {
        return { ...this[2](), ...this[4]() };
    },
    7: function () {
        return { ...this[1](), ...this[2](), ...this[4]() };
    }
};

export const subjectKeys = {
    ADMINS: 'admins',
    FORMS: 'forms',
    ONSITE_TOOL: 'onSiteTool',
    ZINES: 'pages',
    PARTNERS: 'partners',
    PROMOTIONS: 'promotions',
    REPORTS: 'reports',
    SPONSORS: 'sponsors',
    POINT_SYSTEMS: 'pointSystems'
};

const functionKeys = {
    READ: 'canRead',
    EDIT: 'canEdit',
    DELETE: 'canDelete',
    PUBLISH: 'canPublish'
};

export const basenameRouteKeys = {
    ADMIN: '/admin',
    FORM: '/form',
    POINT: '/point',
    PROMOTIONS: '/promotions',
    UGC: '/ugc',
    ON_SITE_ASSISTANT: '/on-site-assistant',
    ALLOCATION_POINT: '/allocation-point'
};

export const adminRouteKeys = {
    DASHBOARD: '/dashboard',
    PROMOS: '/promos',
    ZINES: '/zines',
    FORM: '/form',
    POINT: '/point',
    CAMPAIGN: '/campaign',
    WEBHOOK: '/webhook',
    SPONSOR: '/sponsor',
    ON_SITE_ASSISTANT: '/on-site-assistant',
    ACCOUNT: '/account',
    DOWNLOAD: '/download',
    ALLOCATION_POINT: '/allocation-point'
};

const subjectsUsableFunction = {
    [subjectKeys.ADMINS]: new Set([functionKeys.READ, functionKeys.EDIT, functionKeys.DELETE]),
    [subjectKeys.FORMS]: new Set([
        functionKeys.READ,
        functionKeys.EDIT,
        functionKeys.DELETE,
        functionKeys.PUBLISH
    ]),
    [subjectKeys.ONSITE_TOOL]: new Set([
        functionKeys.READ,
        functionKeys.DELETE,
        functionKeys.PUBLISH
    ]),
    [subjectKeys.ZINES]: new Set([
        functionKeys.READ,
        functionKeys.EDIT,
        functionKeys.DELETE,
        functionKeys.PUBLISH
    ]),
    [subjectKeys.PARTNERS]: new Set([functionKeys.READ]),
    [subjectKeys.PROMOTIONS]: new Set([
        functionKeys.READ,
        functionKeys.EDIT,
        functionKeys.DELETE,
        functionKeys.PUBLISH
    ]),
    [subjectKeys.REPORTS]: new Set([functionKeys.READ]),
    [subjectKeys.SPONSORS]: new Set([functionKeys.READ, functionKeys.EDIT]),
    [subjectKeys.POINT_SYSTEMS]: new Set([
        functionKeys.READ,
        functionKeys.EDIT,
        functionKeys.DELETE,
        functionKeys.PUBLISH
    ])
};

const subjectsUsableRoute = {
    [subjectKeys.ADMINS]: new Set([basenameRouteKeys.ADMIN]),
    [subjectKeys.FORMS]: new Set([basenameRouteKeys.FORM]),
    [subjectKeys.ONSITE_TOOL]: new Set([basenameRouteKeys.ON_SITE_ASSISTANT]),
    [subjectKeys.ZINES]: new Set([basenameRouteKeys.ADMIN]),
    [subjectKeys.PARTNERS]: new Set([basenameRouteKeys.ADMIN]),
    [subjectKeys.PROMOTIONS]: new Set([basenameRouteKeys.PROMOTIONS, basenameRouteKeys.UGC]),
    [subjectKeys.REPORTS]: new Set([basenameRouteKeys.ADMIN]),
    [subjectKeys.SPONSORS]: new Set([basenameRouteKeys.ON_SITE_ASSISTANT]),
    [subjectKeys.POINT_SYSTEMS]: new Set([basenameRouteKeys.POINT])
};

function filterSubjectsUsableFunction(subjectKey, payload) {
    const sets = subjectsUsableFunction[subjectKey] ?? new Set([]);
    return Object.entries(payload).reduce((obj, [key, val]) => {
        if (sets.has(key)) {
            obj[key] = val;
        }
        return obj;
    }, {});
}

export default function permissionsFunctionMapping(permissions = {}) {
    return Object.values(subjectKeys).reduce((obj, subjectKey) => {
        const roleNumber = permissions[subjectKey];
        obj[subjectKey] = filterSubjectsUsableFunction(subjectKey, {
            ...permission.setAllKeys(false),
            ...(permission[roleNumber] ? permission[roleNumber]() : {})
        });
        return obj;
    }, {});
}

export function permissionsRouteMapping(permissions = {}) {
    return Object.entries(subjectsUsableRoute).reduce((obj, [subjectKey, routeSet]) => {
        const accessRoutePath = Boolean(
            permissionsFunctionMapping(permissions)[subjectKey]?.canRead
        )
            ? Array.from(routeSet)
            : [];

        obj[subjectKey] = accessRoutePath;
        return obj;
    }, {});
}
