import React from 'react';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';

import { system900, system700 } from 'features/common/Mui/styles/color';
import { mainButtonSolidStyle } from 'features/login/styles/comman';

import Button from 'features/common/Mui/Button';

export default function BlockInfoAndConfirmRedirect({ title, desc, btnText, redirectPath }) {
    return (
        <div css={containerStyle}>
            <Typography variant="h5" css={titleStyle}>
                {title}
            </Typography>
            {Boolean(desc) && (
                <Typography variant="subtitle1" css={descStyle}>
                    {desc}
                </Typography>
            )}
            <div>
                <Link css={btnStyle} to={historyObj => ({ ...historyObj, pathname: redirectPath })}>
                    <Button variant="outlined" css={[mainButtonSolidStyle, btnStyle]}>
                        <Typography variant="subtitle2">{btnText}</Typography>
                    </Button>
                </Link>
            </div>
        </div>
    );
}

const containerStyle = css`
    width: 360px;
    > * {
        letter-spacing: 0.5px;
        text-align: center;
    }
`;

const titleStyle = css`
    color: ${system900.color};
`;

const descStyle = css`
    color: ${system700.color};
    margin-top: 24px;
    white-space: pre-wrap;
`;

const btnStyle = css`
    width: 60%;
    margin-top: 24px;
`;
