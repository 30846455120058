import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';
import Avatar from '@material-ui/core/Avatar';
import HelpIcon from '@material-ui/icons/Help';
import IconButton from '@material-ui/core/IconButton';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { system300, system800 } from 'features/common/Mui/styles/color';

import AvatarInfoPanel from 'features/login/components/avatarBlock/AvatarInfoPanel';

export default function AvatarBlock({ disableBackgroundColor = false }) {
    const { isLogin, user } = useSelector(s => s.firebase);
    const [anchorEl, setAnchorEl] = useState(null);

    function handleToHelp() {
        window.open('https://info.feversocial.com/help', '_blank');
    }
    function handleAvatarClick(e) {
        setAnchorEl(e.currentTarget);
    }

    if (!isLogin) {
        return false;
    }
    return (
        <>
            <div css={getContainerStyle(disableBackgroundColor)}>
                {!disableBackgroundColor && (
                    <HelpIcon css={[helpIconStyle, marginStyle]} onClick={handleToHelp} />
                )}
                <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
                    <IconButton size="small" onClick={handleAvatarClick} css={marginStyle}>
                        <Avatar css={avatarStyle} src={user.avatarSrc} alt={user.name} />
                        <Popper
                            open={Boolean(anchorEl)}
                            anchorEl={anchorEl}
                            placement="bottom-end"
                            transition
                            css={popperStyle}
                        >
                            {({ TransitionProps }) => (
                                <Fade {...TransitionProps} timeout={200}>
                                    <Paper>
                                        <AvatarInfoPanel />
                                    </Paper>
                                </Fade>
                            )}
                        </Popper>
                    </IconButton>
                </ClickAwayListener>
            </div>
        </>
    );
}

const getContainerStyle = disableBackgroundColor => css`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    ${disableBackgroundColor ? '' : `background-color: ${system300.color};`}
    padding: 0 8px;
`;

const marginStyle = css`
    margin: 0 8px;
`;

const avatarStyle = css`
    width: 35px;
    height: 35px;
`;

const helpIconStyle = css`
    width: 22px;
    height: 22px;
    color: ${system800.color};
    cursor: pointer;
`;

const popperStyle = css`
    z-index: 10;
`;
