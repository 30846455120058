import querystring from 'query-string';

export function getQueryString({ filter = [], remove = [], add = {} } = {}) {
    const currentQs = querystring.parse(window.location.search);
    remove.forEach(dkey => delete currentQs[dkey]);
    const filtered =
        filter.length > 0
            ? Object.entries(currentQs)
                  .filter(([key]) => filter.includes(key))
                  .reduce((obj, [key, value]) => {
                      obj[key] = value;
                      return obj;
                  }, {})
            : currentQs;
    const qs = querystring.stringify({ ...filtered, ...add });
    return Boolean(qs) ? `?${qs}` : '';
}
