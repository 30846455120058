import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { above } from 'utils/breakpoint';
import { system500, system900 } from 'features/common/Mui/styles/color';
import {
    mainButtonSolidStyle,
    errMsgWrapperStyle,
    textFieldDefaultStyle
} from 'features/login/styles/comman';
import { generatePasswordRegexp } from 'features/login/utils/validate';

import BtnBackToAccountSetting from 'features/login/components/account/BtnBackToAccountSetting';
import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';

const BlockPasswordInput = forwardRef(
    ({ email, onSave = () => {}, needPrevPassword, showBackToAccountSettingBtn }, ref) => {
        const { t } = useTranslation();
        const [isSaving, setIsSaving] = useState(false);
        const [showPassword, setShowPassword] = useState(false);
        const [prevPassword, setPrevPassword] = useState('');
        const [prevPasswordErrMsg, setPrevPasswordErrMsg] = useState('');
        const [password, setPassword] = useState('');
        const [passwordErrMsg, setPasswordErrMsg] = useState('');
        const [confirmPassword, setConfirmPassword] = useState('');
        const [confirmPasswordErrMsg, setConfirmPasswordErrMsg] = useState('');

        useImperativeHandle(ref, () => ({ setPrevPasswordErrMsg }));

        function handlePrevPasswordChange({ target: { value } }) {
            setPrevPassword(value);
            setPrevPasswordErrMsg('');
        }
        function handlePasswordChange({ target: { value } }) {
            setPassword(value);
            setPasswordErrMsg('');
        }
        function handleConfirmPasswordChange({ target: { value } }) {
            setConfirmPassword(value);
            setConfirmPasswordErrMsg('');
        }
        function validateInput() {
            let valid = true;
            if (needPrevPassword) {
                const prevPassResult = validatePassword(prevPassword);
                valid = prevPassResult.valid;
                setPrevPasswordErrMsg(prevPassResult.msg);
            }
            const passResult = validatePassword(password);
            valid = passResult.valid;
            setPasswordErrMsg(passResult.msg);
            if (confirmPassword !== password) {
                valid = false;
                setConfirmPasswordErrMsg(i18n.t('label.confirm_pass_not_same'));
            }
            return valid;
        }
        async function handleSavePassword() {
            if (validateInput()) {
                setIsSaving(true);
                await onSave({ prevPassword, password });
                setIsSaving(false);
            }
        }

        return (
            <div css={containerStyle}>
                <Typography variant="h5" css={titleStyle}>
                    {t('label.password_setting')}
                </Typography>
                <Typography variant="subtitle1" css={descStyle}>
                    {t('label.account')}
                    {email}
                </Typography>
                {needPrevPassword && (
                    <>
                        <TextField
                            variant="filled"
                            type={showPassword ? 'text' : 'password'}
                            fullWidth
                            value={prevPassword}
                            onChange={handlePrevPasswordChange}
                            placeholder={t('placehoder.input_old_password')}
                            css={[textFieldDefaultStyle, css(`margin-top: 24px;`)]}
                            error={Boolean(prevPasswordErrMsg)}
                        />
                        <div css={errMsgWrapperStyle}>
                            {Boolean(prevPasswordErrMsg) && (
                                <Typography variant="caption">{prevPasswordErrMsg}</Typography>
                            )}
                        </div>
                    </>
                )}
                <TextField
                    variant="filled"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder={t(
                        needPrevPassword
                            ? 'placehoder.input_new_password'
                            : 'placehoder.input_password'
                    )}
                    css={[textFieldDefaultStyle, !needPrevPassword && css(`margin-top: 24px;`)]}
                    error={Boolean(passwordErrMsg)}
                />
                <div css={errMsgWrapperStyle}>
                    {Boolean(passwordErrMsg) && (
                        <Typography variant="caption">{passwordErrMsg}</Typography>
                    )}
                </div>
                <TextField
                    variant="filled"
                    type={showPassword ? 'text' : 'password'}
                    fullWidth
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    placeholder={t(
                        needPrevPassword
                            ? 'placehoder.input_new_password_again'
                            : 'placehoder.input_password_again'
                    )}
                    css={textFieldDefaultStyle}
                    error={Boolean(confirmPasswordErrMsg)}
                />
                <div css={errMsgWrapperStyle}>
                    {Boolean(confirmPasswordErrMsg) && (
                        <Typography variant="caption">{confirmPasswordErrMsg}</Typography>
                    )}
                </div>
                <FormControlLabel
                    value={showPassword}
                    control={
                        <Checkbox
                            color="primary"
                            onChange={({ target: { checked } }) => setShowPassword(checked)}
                        />
                    }
                    label={t('label.show_password')}
                />
                <div css={btnWrapperStyle}>
                    <ButtonWithLoadingEffect
                        isLoading={isSaving}
                        css={[mainButtonSolidStyle, btnStyle]}
                        onClick={handleSavePassword}
                        disabled={
                            (needPrevPassword && !prevPassword) || !password || !confirmPassword
                        }
                    >
                        <Typography variant="subtitle2">{t('btn.save')}</Typography>
                    </ButtonWithLoadingEffect>
                    {showBackToAccountSettingBtn && (
                        <BtnBackToAccountSetting style={backBtnStyle} />
                    )}
                </div>
            </div>
        );
    }
);

export default BlockPasswordInput;

function validatePassword(password) {
    if ([...password].length < 6) {
        return { valid: false, msg: i18n.t('label.error.word_limit.password') };
    } else if (!generatePasswordRegexp().test(password)) {
        return { valid: false, msg: i18n.t('label.error.invalid_char.password') };
    } else {
        return { valid: true, msg: '' };
    }
}

const containerStyle = css`
    width: 360px;
    > * {
        letter-spacing: 0.5px;
    }
`;

const titleStyle = css`
    color: ${system900.color};
    text-align: center;
`;

const descStyle = css`
    color: ${system500.color};
    text-align: center;
    margin-top: 24px;
`;

const btnWrapperStyle = css`
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const btnStyle = css`
    width: 60%;
`;

const backBtnStyle = `
    width: 214px;
    margin-top: 8px;
    ${above('xlg')} {
        margin-top: 24px;
    }
`;
