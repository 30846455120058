import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { unregister } from './registerServiceWorker';

import IntlProvider from 'features/common/IntlProvider';
import MuiPickersUtilsProvider from 'features/common/MuiPickersUtilsProvider';
import MuiThemeProvider, { formTheme } from 'features/common/Mui/ThemeProvider';
import App from 'features/app/App';

import 'assets/locale/i18n';
import 'utils/extendDayjs';
import initStore from 'store';
import { IS_LOCAL_DEV } from 'config/envVariable';
import { createRoot } from 'react-dom/client';

if (typeof Storage !== 'undefined') {
    window.localStorage.setItem('zines_limit_hint', 'false');
}

const store = initStore();
export const history = createBrowserHistory();

const renderApp = () => {
    const container = document.getElementById('root');
    const root = createRoot(container);
    return root.render(
        <Provider store={store}>
            <MuiThemeProvider theme={formTheme}>
                <IntlProvider>
                    <MuiPickersUtilsProvider>
                        <Router history={history}>
                            <App />
                        </Router>
                    </MuiPickersUtilsProvider>
                </IntlProvider>
            </MuiThemeProvider>
        </Provider>
    );
};

if (IS_LOCAL_DEV && module.hot) {
    module.hot.accept('features/app/App', renderApp);
}

renderApp();
unregister();
