import queryString from 'query-string';
import _orderBy from 'lodash/orderBy';
import { feverApiFetchOpts, fetches, fetchDownload, methods } from 'api/commom';
import { getMgmRelationByPromoApi } from 'api/mgm';
import { FEVER_HOST } from 'config/envVariable';
import * as formTypes from 'config/formTypes';
import { checkIsGameHasPromoTask } from 'config/promoFormType';
import { isJSONParsable } from 'utils/objectManipulate';

export function createFormApi(sponsorId, promotionId, templateType, body = {}) {
    return fetches(
        `/sponsors/${sponsorId}/forms`,
        feverApiFetchOpts({ method: methods.POST, body: { templateType, promotionId, ...body } }),
        { dataKeys: 'items' }
    );
}

export function getAccumalateMemberPlaytimeAnalysis(sponsorId, promotionId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promotionId}/analysis/member`,
        feverApiFetchOpts()
    );
}

export function getJoinQuantityAnalysis(sponsorId, promotionId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promotionId}/analysis/entry`,
        feverApiFetchOpts()
    );
}

export function getVoteResultApi(sponsorId, formId) {
    return fetches(`/sponsors/${sponsorId}/forms/${formId}/votes`, feverApiFetchOpts(), {
        dataKeys: 'items'
    });
}

export function getFormEditorData(sponsorId, formId) {
    return fetches(`/sponsors/${sponsorId}/forms/${formId}`, feverApiFetchOpts(), {
        dataKeys: 'items'
    });
}

/**
 * @method getPromosListApi 取得 Promotion List
 * https://app.clickup.com/t/86epmr6j1
 * @param  {string} sponsorId
 * @param  {Number} perPage 每一頁撈幾筆資料
 * @param  {Number} page 要撈第幾頁的資料
 * @param  {string} searchKey 用搜尋關鍵字過濾活動
 * @param  {string} additionalQs
 * @param  {string[]} types 過濾要加入的 promo type
 * @param  {string[]} excludeType 過濾要排除的 promo type
 * @param  {Number[]} loginMethod 要過濾的 login type
 * @param {string} creator 建立者
 * @param {string} dateFilterType 活動時間搜尋類型 (開始/結束)
 * @param {string} filterStartDay 開始日期 （ISO 8601 日期格式，URL 編碼）
 * @param {string} filterEndDay 開始日期 （ISO 8601 日期格式，URL 編碼）
 * @param {Number} status 活動狀態
 * @return  {promise}
 */
export function getPromosListApi(
    sponsorId,
    {
        perPage,
        page,
        searchKey,
        additionalQs,
        type = [],
        excludeType = [],
        loginMethod = [],
        creator,
        dateFilterType,
        filterStartDay,
        filterEndDay,
        status
    }
) {
    const includePromoTypesQs = type.join(',');
    const excludePromoTypesQs = excludeType.join(',');
    const promoLoginMethodQs = loginMethod.join(',');
    const qs = queryString.stringify({
        perPage,
        page,
        search: searchKey || undefined,
        'filter[type]': includePromoTypesQs || undefined,
        'filter[excludeType]': excludePromoTypesQs || undefined,
        loginMethod: promoLoginMethodQs || undefined,
        'filter[dateFilterType]': dateFilterType,
        'filter[filterStartDay]': filterStartDay || undefined,
        'filter[filterEndDay]': filterEndDay || undefined,
        status: status === undefined ? undefined : status,
        creator: creator || undefined
    });
    const composedQs = qs ? `${qs}${additionalQs ? `&${additionalQs}` : ''}` : additionalQs;

    return fetches(
        `/services/sponsors/${sponsorId}/promotions${composedQs ? `?${composedQs}` : ''}`,
        feverApiFetchOpts()
    ).then(res => res);
}

export const isPromoReportAsyncDownload = true;

/**
 * @method getPromoRelatedApi 取得指定 Promo 關聯的 Promo
 * @description https://app.clickup.com/t/866aqpq4x
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promotionId 活動 Id
 * @param  {string} type 查詢類型 ( related(預設) : 只回傳 related promotoion, mgm：只回傳 mgm mission promotion , all : 同時回傳 related promotoion 與 mgm mission promotion )
 * @return  {promise}
 */
export function getPromoRelatedApi(sponsorId, promotionId, type) {
    const qs = queryString.stringify({ type });

    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promotionId}/related${qs ? `?${qs}` : ''}`,
        feverApiFetchOpts()
    ).then(res => res);
}

export function updatePromoStatusApi(sponsorId, promotionId, status) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promotionId}/status`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: {
                status
            }
        })
    ).then(res => res);
}

export function updatePromoSetting(sponsorId, promoId, body) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/settings`,
        feverApiFetchOpts({ method: methods.PATCH, body }),
        { dataKeys: 'items' }
    );
}

export function getPromoTemplatesApi(sponsorId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/templates`,
        feverApiFetchOpts()
    ).then(res => res);
}

export function createPromoWithTemplateApi(sponsorId, templateUuid) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions`,
        feverApiFetchOpts({
            method: methods.POST,
            body: {
                templateUuid
            }
        })
    ).then(res => res);
}

export function createPromoCache(sponsorId, promoId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/cache`,
        feverApiFetchOpts({ method: methods.PUT })
    );
}

export function createLotteryPool(sponsorId, promoId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/box`,
        feverApiFetchOpts({ method: methods.POST })
    );
}

export function getTestcode(sponsorId, promoId) {
    return fetches(
        `/services/sponsors/${sponsorId}/previewcode?promotionId=${promoId}`,
        feverApiFetchOpts()
    );
}

export function deletePromoWithPromotionId(sponsorId, promotionId, mgmRole) {
    const qs = queryString.stringify({
        mgmRole
    });

    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promotionId}${qs && `?${qs}`}`,
        feverApiFetchOpts({
            method: methods.DELETE
        })
    );
}

export function getPromotion(sponsorId, promoId) {
    return fetches(`/services/sponsors/${sponsorId}/promotions/${promoId}`, feverApiFetchOpts());
}

export function getPromoSetting(sponsorId, promoId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/settings`,
        feverApiFetchOpts()
    );
}

export function getPromoTaskListApi({ sponsorId, promoId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/tasks`,
        feverApiFetchOpts()
    );
}

export function getLotteriesTemplate(sponsorId, promoId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/templates`,
        feverApiFetchOpts()
    );
}

export function createLottery(sponsorId, promoId, body) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries`,
        feverApiFetchOpts({ method: methods.POST, body })
    );
}

export function updateLottery(sponsorId, promoId, lotteryId, body) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryId}`,
        feverApiFetchOpts({ method: methods.PATCH, body })
    );
}

export function updateLotterySettings(sponsorId, promoId, lotteryId, body) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryId}/settings`,
        feverApiFetchOpts({ method: methods.PATCH, body })
    );
}

export function getLottery(sponsorId, promoId, lotteryUuid) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}`,
        feverApiFetchOpts()
    );
}

export function getLotterySetting(sponsorId, promoId, lotteryUuid) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}/settings`,
        feverApiFetchOpts()
    );
}

export function getPrizes(sponsorId, promoId, lotteryId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryId}/prizes`,
        feverApiFetchOpts()
    );
}

export function getRedeemFormList(promotionId, sponsorId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promotionId}/forms?type=${formTypes.REDEEM_FORM}`,
        feverApiFetchOpts()
    );
}

async function getTasksPrizes({ lotteryTasksUuids = [], promoId, sponsorId }) {
    const promises = lotteryTasksUuids.map(uuid => getPrizes(sponsorId, promoId, uuid));
    const taskPrizes = await Promise.all(promises);
    const prizeTask = lotteryTasksUuids.reduce((obj, uuid, index) => {
        const prize = taskPrizes[index];
        obj = { ...obj, [uuid]: prize };
        return obj;
    }, {});
    return prizeTask;
}

async function getTasksLotteries({ lotteryTaskInfo = [], promoId, sponsorId }) {
    if (lotteryTaskInfo.length === 0) {
        return {};
    }
    const promises = lotteryTaskInfo.map(([uuid]) => getLottery(sponsorId, promoId, uuid));
    const lotteries = await Promise.all(promises);
    const decorateLotteriesWithUUUD = lotteries.reduce((obj, lottery) => {
        const { uuid } = lottery;
        const [, checkInQuantity] = lotteryTaskInfo.find(l => l[0] === uuid) ?? [];
        obj = { ...obj, [uuid]: { ...lottery, checkInQuantity } };
        return obj;
    }, {});
    return decorateLotteriesWithUUUD;
}

export async function getPromoData(sponsorId, promoId) {
    const [promo = {}, promoSetting = {}, mgmRelation = {}] = await Promise.all([
        getPromotion(sponsorId, promoId),
        getPromoSetting(sponsorId, promoId),
        getPromoMgmRelation(sponsorId, promoId)
    ]);
    const {
        LotteryInstant: { uuid: lotteryInstantUuid } = {},
        LotteryParticipant: { uuid: lotteryParticipantUuid } = {},
        LotteryAdmin: { uuid: lotteryAdminUuid } = {},
        LotteryTasks: { uuid: lotteryTask = {} } = {}
    } = promoSetting;

    const lotteryTaskInfo = Object.entries(
        isJSONParsable(lotteryTask) ? JSON.parse(lotteryTask) : lotteryTask
    );
    const lotteryTasksUuids = lotteryTaskInfo.map(l => l[0]);
    const [
        lotteryInstant = {},
        lotteryParticipant = {},
        lotteryAdmin = {},
        lotteriesTask,
        promoTask = {},
        prizes = {},
        formList = []
    ] = await Promise.all([
        lotteryInstantUuid
            ? getLottery(sponsorId, promoId, lotteryInstantUuid)
            : Promise.resolve(undefined),
        lotteryParticipantUuid
            ? getLottery(sponsorId, promoId, lotteryParticipantUuid)
            : Promise.resolve(undefined),
        lotteryAdminUuid
            ? getLottery(sponsorId, promoId, lotteryAdminUuid)
            : Promise.resolve(undefined),
        getTasksLotteries({ lotteryTaskInfo, promoId, sponsorId }),
        checkIsGameHasPromoTask(promo.type)
            ? getPromoTaskListApi({ sponsorId, promoId })
            : Promise.resolve(undefined),
        getPrizeList({
            promoId,
            sponsorId,
            lotteryInstantUuid,
            lotteryParticipantUuid,
            lotteryAdminUuid,
            lotteryTasksUuids
        }),
        getRedeemFormList(promoId, sponsorId)
    ]);
    const { settings: lotteryInstantSetting, ...lotteryInstantRest } = lotteryInstant;
    const { settings: lotteryParticipantSetting, ...lotteryParticipantRest } = lotteryParticipant;
    const { settings: lotteryAdminSetting, ...lotteryAdminRest } = lotteryAdmin;

    return {
        promo,
        games: promoSetting.Games,
        entryValues: promoSetting.EntryValues,
        gameStyles: promoSetting.GameStyles,
        signUpForm: promoSetting.SignUpForm,
        settingPromo: promoSetting.Promotions,
        promoTask: _orderBy(promoTask, ['seq']),
        lotteryTasks: promoSetting.LotteryTasks,
        lotteries: {
            lotteryInstant: { ...lotteryInstantRest, ...lotteryInstantSetting },
            lotteryParticipant: { ...lotteryParticipantRest, ...lotteryParticipantSetting },
            lotteryAdmin: { ...lotteryAdminRest, ...lotteryAdminSetting },
            lotteriesTask
        },
        prizes,
        formList,
        mgmRelation
    };
}

export async function getPromoMgmRelation(sponsorId, promoId) {
    const response = await getMgmRelationByPromoApi({ sponsorId, promoId });
    if (response.httpStatus === 226) {
        return {};
    }
    return {
        ...response.payload
    };
}

export async function getPrizeList({
    sponsorId,
    promoId,
    lotteryInstantUuid,
    lotteryParticipantUuid,
    lotteryAdminUuid,
    lotteryTasksUuids
}) {
    const [
        lotteryInstantPrize = [],
        lotteryParticipantPrize = [],
        lotteryAdminPrize = [],
        lotteryTasksPrize = []
    ] = await Promise.all([
        lotteryInstantUuid ? getPrizes(sponsorId, promoId, lotteryInstantUuid) : undefined,
        lotteryParticipantUuid ? getPrizes(sponsorId, promoId, lotteryParticipantUuid) : undefined,
        lotteryAdminUuid ? getPrizes(sponsorId, promoId, lotteryAdminUuid) : undefined,
        getTasksPrizes({ sponsorId, promoId, lotteryTasksUuids })
    ]);

    return {
        prizeInstant: lotteryInstantPrize,
        prizeParticipant: lotteryParticipantPrize,
        prizeAdmin: lotteryAdminPrize,
        prizeTask: lotteryTasksPrize
    };
}

export function clearTestMemberData(sponsorId, promoIdOrPromoUuid) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoIdOrPromoUuid}/test-data`,
        feverApiFetchOpts({ method: methods.DELETE })
    );
}

export function clearPromoRedirectCache(promoUUID) {
    return fetch(`https://${FEVER_HOST}/r/promotions/purge/${promoUUID}`, { method: 'GET' });
}

export function getUUidbyEntryId({ sponsorId, promoId, lotteryUuid, prizeUuid, body }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}/prizes/${prizeUuid}/draw/assignation`,
        feverApiFetchOpts({ method: methods.POST, body })
    );
}

export function getEntriesList({
    promoId,
    sponsorId,
    offset,
    page = 1,
    search,
    mediaSearch,
    searchColumn,
    orderColumn,
    order,
    orderStatus,
    isRevoked,
    signUpForm,
    qualified,
    deleted,
    isVotingContest,
    perPage = 10,
    receiptSearch,
    searchMGMColumn,
    referralSearch
}) {
    const parameter = {
        perPage,
        page,
        search,
        mediaSearch,
        searchColumn,
        orderColumn,
        order,
        offset,
        signUpForm,
        qualified,
        orderStatus,
        isRevoked,
        receiptSearch,
        searchMGMColumn, // 邀請碼要搜尋邀請模組還是任務模組
        referralSearch // 邀請碼內容
    };
    const mediaLibraryDeleted = deleted;

    const qs = queryString.stringify({
        ...parameter,
        ...(isVotingContest ? { mediaLibraryDeleted } : { deleted })
    });

    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries${qs && `?${qs}`}`,
        feverApiFetchOpts()
    );
}

export function getEntriesCountsInfo({ sponsorId, promoId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/counts`,
        feverApiFetchOpts()
    );
}

export function getEntriesGameRecord({ sponsorId, promoId, entryId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}/record`,
        feverApiFetchOpts()
    );
}

export function getEntriesGameRecordOfMember({ sponsorId, promoId, entryId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}/user-records`,
        feverApiFetchOpts()
    );
}

export function recoverMemberEntry(sponsorId, promoId, entryId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}/restore`,
        feverApiFetchOpts({ method: methods.PUT })
    );
}

export function deleteMemberEntry(sponsorId, promoId, entryId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}`,
        feverApiFetchOpts({ method: methods.DELETE })
    );
}

export function setMemberQualify(sponsorId, promoId, entryId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}/flows`,
        feverApiFetchOpts({
            method: methods.POST,
            body: {
                flow: 'qualified'
            }
        })
    );
}

export function getPrizeWinnerList({
    sponsorId,
    promoId,
    prizeUuid,
    page = 1,
    perPage = 20,
    redeemed,
    transaction
}) {
    const qs = queryString.stringify({
        prizeUuid,
        page,
        perPage,
        redeemed,
        transaction
    });
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/winners?${qs}`,
        feverApiFetchOpts()
    );
}

export function drawPrizeWinners({ sponsorId, promoId, lotteryUuid, prizeUuid, data }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}/prizes/${prizeUuid}/draw/normal`,
        feverApiFetchOpts({
            method: methods.POST,
            body: {
                ...data
            }
        })
    );
}

export function drawPrizeWinnersAssignUuids({ sponsorId, promoId, lotteryUuid, prizeUuid, data }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}/prizes/${prizeUuid}/draw/assignation`,
        feverApiFetchOpts({
            method: methods.POST,
            body: {
                ...data
            }
        })
    );
}

export function createPrizeWinners({ sponsorId, promoId, lotteryUuid, prizeUuid, entryUuids }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}/prizes/${prizeUuid}/draw/winners`,
        feverApiFetchOpts({
            method: methods.POST,
            body: {
                entryUuids
            }
        })
    );
}

export function deletePrizeWinner({ sponsorId, promoId, winnerUuid }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/winners/${winnerUuid}`,
        feverApiFetchOpts({
            method: methods.DELETE
        })
    );
}

export function updateRedeem({ sponsorId, promoId, winnerUuid }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/winners/${winnerUuid}/redeemed`,
        feverApiFetchOpts({
            method: methods.POST
        })
    );
}

export function deleteRedeem({ sponsorId, promoId, winnerUuid }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/winners/${winnerUuid}/redeemed`,
        feverApiFetchOpts({
            method: methods.DELETE
        })
    );
}

export function updatePrizeAnnouncedStatus({
    sponsorId,
    promoId,
    lotteryUuid,
    prizeUuid,
    announced
}) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}/prizes/${prizeUuid}/announced`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: {
                announce: announced
            }
        })
    );
}

export function claimBusinessPrize({ sponsorId, promoId, lotteryUuid, prizeUuid }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}/prizes/${prizeUuid}/transact`,
        feverApiFetchOpts({
            method: methods.POST
        })
    );
}

/**
 * 計算活動歸戶總數，有帶 qs 就是算指定那個狀態的數量，沒帶就是算未歸戶
 * 未歸戶：0
 * 歸戶中：1
 * 歸戶成功：2
 * 歸戶失敗：3
 */
export function getClaimStatusCount({ sponsorId, promoId, status }) {
    const qs = queryString.stringify({ status });
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/winners/transactions/counts?${qs}`,
        feverApiFetchOpts(),
        'data'
    );
}

export function deleteUgcContent({ sponsorId, promoId, entryId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}/ugc`,
        feverApiFetchOpts({
            method: methods.DELETE
        })
    );
}

export function updateMediaWallDisplayType({ sponsorId, promoId, entryId, mediaUUID, body }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}/media/${mediaUUID}`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body
        })
    );
}

export function sendPrizesWinnerEmail({
    sponsorId,
    promoId,
    lotteryUuid,
    prizes,
    replyTo,
    content
}) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/lotteries/${lotteryUuid}/prizes/notification`,
        feverApiFetchOpts({
            method: methods.POST,
            body: {
                prizes,
                replyTo,
                content
            }
        })
    );
}

export function uploadLineMemberApi(sponsorId, promoId) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/users/report/upload`,
        feverApiFetchOpts({
            method: methods.POST
        })
    );
}

export function getMediaVoteRecordList({ sponsorId, promoId, mediaLibraryUUID, query = {} }) {
    const qs = queryString.stringify(query);
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/media-libraries/${mediaLibraryUUID}/votes${
            qs ? `?${qs}` : qs
        }`,
        feverApiFetchOpts()
    );
}

export function updateUgcVoteStatus({ sponsorId, promoId, mediaLibraryUUID, voteId = [], status }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/media-libraries/${mediaLibraryUUID}/votes/${
            status === 0 ? 'invalid' : 'valid'
        }`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: {
                voteId
            }
        })
    );
}

export function getMediaVoteIpRankList({ sponsorId, promoId, mediaLibraryUUID }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/media-libraries/${mediaLibraryUUID}`,
        feverApiFetchOpts()
    );
}

/**
 * admin 操作方從 target 複製活動到自家 - https://app.clickup.com/t/86epugjz0 ( comment )
 * @param {string} sponsorId 複製到哪個 sponsorId
 * @param {object} body api body，body 內部的 target 資料是帶入複製的目標
 * @returns
 */
export function copyPromoFromApi({ sponsorId, body }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/copy/from`,
        feverApiFetchOpts({ method: methods.POST, body })
    );
}

export function copyPromoApi({ sponsorId, promoId, body }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/copy`,
        feverApiFetchOpts({ method: methods.POST, body })
    );
}

export function downloadPromoReport(sponsorId, promoId) {
    return fetchDownload(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/report/download`,
        feverApiFetchOpts()
    );
}

export function downloadInvoiceDetail(sponsorId, promoId) {
    return fetchDownload(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/invoices/report/download`,
        feverApiFetchOpts()
    );
}

export function getAsyncDownloadPromoReportLink(sponsorId, promotionId, jobUUID) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promotionId}/report/jobs/${jobUUID}`,
        feverApiFetchOpts()
    );
}

export function getAsyncDownloadPointReportLink(sponsorId, poolId, jobUuid) {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${poolId}/getJobResult?jobUuid=${jobUuid}`,
        feverApiFetchOpts()
    );
}

export function downloadTrackingReport(sponsorId, promoId) {
    return fetchDownload(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/tracks/report/download`,
        feverApiFetchOpts()
    );
}

export function downloadAppUserData(sponsorId, promoId) {
    return fetchDownload(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/users/report/download`,
        feverApiFetchOpts()
    );
}

export function downloadClaimFailedReportApi({ sponsorId, promoId }) {
    return fetchDownload(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/winners/transactions/failed/report/download`,
        feverApiFetchOpts()
    );
}

export function downloadUgcVoteRecordsApi({ sponsorId, promoId, mediaLibraryUUID }) {
    return fetchDownload(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/media-libraries/${mediaLibraryUUID}/votes/report/download`,
        feverApiFetchOpts()
    );
}

/**
 * @method getReferralRankListApi 取得邀請人數排行
 * @description https://app.clickup.com/t/866apy5pb
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promoId 活動 Id
 * @return  {promise}
 */
export function getReferralRankListApi({ sponsorId, promoId, search }) {
    const qs = queryString.stringify({ referralCode: search || undefined });

    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/referral/rank${qs ? `?${qs}` : ''}`,
        feverApiFetchOpts()
    );
}

/**
 * @method getMgmMissionReferralListApi 取得任務模組邀請清單
 * @description https://app.clickup.com/t/860rqt3yj
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promoId 活動 Id
 * @return  {promise}
 */
export function getMgmMissionReferralListApi({
    sponsorId,
    promoId,
    search,
    page,
    perPage,
    qs = {}
}) {
    const finalQs = queryString.stringify({
        perPage,
        page,
        referredBy: search || undefined,
        ...qs
    });

    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/mgm/mission${
            finalQs ? `?${finalQs}` : ''
        }`,
        feverApiFetchOpts()
    );
}

/**
 * @method getReferralStatisticsApi 取得邀請用模組統計資料
 * @description https://app.clickup.com/t/866aqfxdp
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promoId 活動 Id
 * @return  {promise}
 */
export function getReferralStatisticsApi({ sponsorId, promoId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/referral/statistics`,
        feverApiFetchOpts()
    );
}

/**
 * @method getReferralChartApi 取得邀請人圖表
 * @description https://app.clickup.com/t/866apy62f
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promoId 活動 Id
 * @return  {promise}
 */
export function getReferralChartApi({ sponsorId, promoId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/referral/entries`,
        feverApiFetchOpts()
    );
}

/**
 * @method getReferralRecord 查看參加紀錄 推薦紀錄 api
 * @description https://app.clickup.com/t/866aqnvnf
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promoId 活動 Id
 * @param  {string} entryId entry Id
 * @return  {promise}
 */
export function getReferralRecord({ sponsorId, promoId, entryId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}/referrals`,
        feverApiFetchOpts()
    );
}
// 產生工具連結 - 指定活動的活動下拉選單 api - https://app.clickup.com/t/860rzww5b
export function getOnSiteAssistantPromoListApi({ sponsorId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/on-site-assistant`,
        feverApiFetchOpts(),
        {
            dataKeys: 'data'
        }
    );
}

/**
 * @method abandonedReceiptApi 作廢 API
 * @description https://app.clickup.com/t/86ep510tm
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promoId 活動 Id
 * @param  {string} entryId entry Id
 * @return  {promise}
 */
export function abandonedReceiptApi({ sponsorId, promoId, entryId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/${entryId}/abandoned`,
        feverApiFetchOpts({ method: methods.PUT })
    );
}

/**
 * @method getTaskMemberStatisticsApi 多元任務模組 - 參加者列表統計資料 API
 * @description https://app.clickup.com/t/86epmr878
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promoId 活動 Id
 * @return  {promise}
 */
export function getTaskMemberStatisticsApi({ sponsorId, promoId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/statistics`,
        feverApiFetchOpts(),
        {
            dataKeys: 'data'
        }
    );
}

/**
 * @method getTaskMemberListApi 多元任務模組 - 參加者列表資料
 * @description https://app.clickup.com/t/86epmr88g
 * @param  {string} sponsorId Sponsor Uuid
 * @param  {string} promoId 活動 Id
 * @return  {promise}
 */
export function getTaskMemberListApi(
    sponsorId,
    promoId,
    { perPage = 10, page, searchKey, filterCounts, additionalQs }
) {
    const qs = queryString.stringify({
        perPage,
        page,
        search: searchKey || undefined,
        'filter[counts]': filterCounts || undefined
    });
    const composedQs = qs ? `${qs}${additionalQs ? `&${additionalQs}` : ''}` : additionalQs;
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/entries/check-point-cards${
            composedQs ? `?${composedQs}` : ''
        }`,
        feverApiFetchOpts(),
        {
            dataKeys: 'data'
        }
    );
}
