export const breakpoints = {
    xsm: 480,
    sm: 768,
    md: 1024,
    lg: 1200,
    xlg: 1440
};

export function getBreakpoint(size) {
    const breakpoint = breakpoints[size];
    if (!breakpoint) {
        console.error('Size not defined in default Breakpoints');
    }
    return breakpoint;
}

export function above(size) {
    const breakpoint = getBreakpoint(size);
    return `@media screen and (min-width: ${breakpoint + 1}px)`;
}

export function below(size) {
    const breakpoint = getBreakpoint(size);
    return `@media screen and (max-width: ${breakpoint - 1}px)`;
}

export function belowContain(size) {
    const breakpoint = getBreakpoint(size);
    return `@media screen and (max-width: ${breakpoint}px)`;
}

export function between(minSize, maxSize) {
    const minBreakpoint = getBreakpoint(minSize);
    const maxBreakpoint = getBreakpoint(maxSize);
    if (minBreakpoint > maxBreakpoint) {
        console.error('Breakpoint size incorrect');
    }
    return `
    @media screen and (min-width: ${minBreakpoint}px) and (max-width: ${maxBreakpoint - 1}px)`;
}
