import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';

import { above } from 'utils/breakpoint';
import { mainBtnStyle } from 'features/login/styles/comman';
import { paths } from 'features/login/hooks/useInitAndRedirectApp';

import Button from 'features/common/Mui/Button';

export default function ToSignupBlock() {
    const { t } = useTranslation();
    return (
        <div css={containerStyle}>
            <Typography variant="subtitle2" css={labelStyle}>
                {t('label.to_signup')}
            </Typography>
            <Link to={historyObj => ({ ...historyObj, pathname: paths.SIGN_UP })}>
                <Button variant="outlined" css={btnStyle}>
                    <Typography variant="subtitle2">{t('btn.to_signup')}</Typography>
                </Button>
            </Link>
        </div>
    );
}

const containerStyle = css`
    margin-top: 12px;
    ${above('xlg')} {
        margin-top: 32px;
    }
`;

const labelStyle = css`
    color: #e55b61;
    text-align: center;
`;

const btnStyle = css`
    ${mainBtnStyle}
    margin-top: 8px;
    ${above('xlg')} {
        margin-top: 16px;
    }
    border-color: #e55b61;
    color: #e55b61;
`;
