import { feverApiFetchOpts, fetchWith, methods } from 'api/commom';
import { FEVER_API_ENDPOINT } from 'config/envVariable';

const feverFetch = fetchWith(FEVER_API_ENDPOINT);

export function getWebhooksListApi(sponsorId, page) {
    return feverFetch(`/sponsors/${sponsorId}/webhooks?page=${page}`, feverApiFetchOpts());
}

export function getWebhookApi(sponsorId, webhookId) {
    return feverFetch(`/sponsors/${sponsorId}/webhooks/${webhookId}`, feverApiFetchOpts());
}

/**
 * @param {Number} sponsorId - sponsor id
 * @param {Object} data - webhook setting data which will be created
 * @param {Number} [data.eventId] - event id
 * @param {Boolean} [data.isGlobal] - webhook effect scope
 * @param {String} [data.name] - webhook service name
 * @param {String} [data.url] - webhook url
 * @param {String} [data.secretToken=] - webhook secret token
 * @param {Object[]} [data.targets=] - webhook targets
 * @returns {}
 */
export function createWebhookApi(sponsorId, data) {
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks`,
        feverApiFetchOpts({ method: methods.POST, body: data })
    );
}

/**
 * @param {Number} sponsorId - sponsor id
 * @param {String} webhookId - webhook id
 * @param {Object} data - webhook setting data which will be updated
 * @param {Boolean} [data.isGlobal=] - webhook effect scope
 * @param {String} [data.name=] - webhook service name
 * @param {String} [data.url=] - webhook url
 * @param {String} [data.secretToken=] - webhook secret token
 * @param {Object[]} [data.targets=] - webhook targets
 * @returns {}
 */
export function updateWebhookApi(sponsorId, webhookId, data) {
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/${webhookId}`,
        feverApiFetchOpts({ method: methods.PUT, body: data })
    );
}

export function deleteWebhookApi(sponsorId, webhookId) {
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/${webhookId}`,
        feverApiFetchOpts({ method: methods.DELETE })
    );
}

export function createWebhookTargetApi(sponsorId, webhookId, data) {
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/${webhookId}/targets`,
        feverApiFetchOpts({ method: methods.POST, body: data })
    );
}

export function updateWebhookTargetApi(sponsorId, webhookId, data) {
    const { targets } = data;
    const [{ id: target_id, ...restData }] = targets;
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/${webhookId}/targets/${target_id}`,
        feverApiFetchOpts({ method: methods.PUT, body: restData })
    );
}

export function getWebhookTriggerScopeListApi(sponsorId, { page, service, customQs }) {
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/scopes?page=${page}&service=${service}${
            customQs ? `&${customQs}` : ''
        }`,
        feverApiFetchOpts()
    ).then(resp => resp.json());
}

export function updateWebhookStatusApi(sponsorId, webhookId, data) {
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/${webhookId}`,
        feverApiFetchOpts({ method: methods.PATCH, body: data })
    );
}

/**
 * @param {Number} sponsorId
 * @param {String} webhookId
 * @param {Object} data
 * @param {String=} [data.pixelId]
 * @param {String=} [data.accessToken]
 * @param {String[]=} [data.deleted]
 * @returns
 */
export function updateFacebookConversionApi(sponsorId, webhookId, data) {
    const payload = data.deleted
        ? { pixelId: data.pixelId, accessToken: data.accessToken, deleted: data.deleted }
        : { pixelId: data.pixelId, accessToken: data.accessToken };
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/${webhookId}/settings`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: payload
        })
    );
}

// TODO: 優化 deleted 寫法
export function updateAccuApi(sponsorId, webhookId, data) {
    const payload = data.deleted
        ? {
              lineBotId: data.lineBotId,
              apiToken: data.apiToken,
              loginMethodLimit: data.loginMethodLimit,
              deleted: data.deleted
          }
        : {
              lineBotId: data.lineBotId,
              apiToken: data.apiToken,
              loginMethodLimit: data.loginMethodLimit
          };
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/${webhookId}/settings`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: payload
        })
    );
}

// TODO: 優化 deleted 寫法
export function updateWishApi(sponsorId, webhookId, data) {
    const payload = data.deleted
        ? {
              appId: data.appId,
              url: data.url,
              signKey: data.signKey,
              memberIdentityType: data.memberIdentityType,
              brandCode: data.brandCode,
              storeCode: data.storeCode,
              loginMethodLimit: data.loginMethodLimit,
              deleted: data.deleted
          }
        : {
              appId: data.appId,
              url: data.url,
              signKey: data.signKey,
              memberIdentityType: data.memberIdentityType,
              brandCode: data.brandCode,
              storeCode: data.storeCode,
              loginMethodLimit: data.loginMethodLimit
          };
    return feverFetch(
        `/sponsors/${sponsorId}/webhooks/${webhookId}/settings`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: payload
        })
    );
}
