import _merge from 'lodash/merge';
import querystring from 'query-string';

import { MOCK_API_ENDPOINT, FEVER_API_ENDPOINT, FEVER_HOST } from 'config/envVariable';
import { getFirebaseIdToken, signout } from 'features/login/utils/firebaseManager';
import { generateLoginUrl } from 'utils/login';
import { toPermissionNotDeniedPage, paths } from 'features/login/hooks/useInitAndRedirectApp';
import { history } from 'index';
import { generateApiErrObj } from 'features/webhooks/useApiErrorHandler';
import { isJSONParsable } from 'utils/objectManipulate';

export const methods = {
    GET: 'GET',
    POST: 'POST',
    PUT: 'PUT',
    PATCH: 'PATCH',
    DELETE: 'DELETE'
};

export const IMG_UPLOAD_API_PATH = '/services/images';

export async function fetchOpts(method, body = null, setting = {}) {
    return _merge(
        {},
        {
            method,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: `Bearer ${(await getFirebaseIdToken()) || ''}`
            }
        },
        body ? { body: JSON.stringify(body) } : {},
        setting
    );
}

export function getRedirectUrlWithFallbackSponsor() {
    // 移除 refUrl 的 sponsorId，並作為新 url 回傳
    const { origin, pathname, search } = window.location;
    const searchObj = querystring.parse(search);
    if (searchObj?.refUrl) {
        const refUrl = new URL(searchObj?.refUrl);
        const params = new URLSearchParams(refUrl.search);
        params.delete('sponsorId');
        refUrl.search = params;
        return refUrl.toString();
    } else if (pathname === paths.PERMISSION_DENIED) {
        return null;
    } else {
        delete searchObj.sponsorId;
        const qs = querystring.stringify(searchObj);

        return `${origin}${pathname}${Boolean(qs) ? `?${qs}` : ``}`;
    }
}

async function redirectIfInvalid(resp) {
    if (resp.status === 401) {
        await signout();
        window.location.href = generateLoginUrl();
    } else if (resp.status === 403) {
        toPermissionNotDeniedPage(history);
    }
    return resp;
}

export function fetchWith(origin) {
    return async (path, optionsPromise, { redirect = false } = {}) => {
        const options = await optionsPromise;
        return fetch(`${origin}${path}`, options).then(res =>
            redirect ? redirectIfInvalid(res) : res
        );
    };
}

export async function feverApiFetchOpts({
    method = methods.GET,
    headers = {},
    body = null,
    formData,
    apiVersion = 1,
    feverHost,
    ...rest
} = {}) {
    const firebaseToken = await getFirebaseIdToken();
    return {
        method,
        headers: new Headers({
            'Fever-Host': feverHost || FEVER_HOST,
            Accept: `application/x.api.v${apiVersion}+json`,
            ...(firebaseToken ? { Authorization: `Bearer ${firebaseToken}` } : {}),
            ...(formData ? {} : { 'Content-Type': 'application/json; charset=utf-8' }),
            ...headers
        }),
        body: formData || (body && JSON.stringify(body)),
        ...rest
    };
}

export function normalizeResponse(resp) {
    return resp.json().then(payload => ({ httpStatus: resp.status, payload }));
}

export async function fetches(
    path,
    optionsPromise,
    { dataKeys = 'data', isMock = false, strictErrThrow = true, throwErrConfigs } = {}
) {
    const options = await optionsPromise;
    const baseUrl = isMock ? MOCK_API_ENDPOINT : FEVER_API_ENDPOINT;
    return fetch(`${baseUrl}${path}`, options)
        .then(normalizeResponse)
        .then(resp => {
            if (resp.httpStatus >= 400) {
                if (throwErrConfigs) throwError(resp, throwErrConfigs, options.body);
                else throw resp;
            }

            if (!strictErrThrow) {
                return { ...resp, payload: resp.payload[dataKeys] };
            }

            return resp.payload[dataKeys];
        });
}

export async function fetchDownload(path, optionsPromise) {
    const options = await optionsPromise;
    return fetch(`${FEVER_API_ENDPOINT}${path}`, options).then(resp => {
        if (resp.status !== 200) {
            throw resp;
        }
        return resp.blob();
    });
}

export function uploadEditorImage(file, { type = '', id = '', folderType = '' } = {}) {
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', type);
    formData.append('uuid', id);
    if (folderType) {
        formData.append('folderType', folderType);
    }
    return fetches(IMG_UPLOAD_API_PATH, feverApiFetchOpts({ method: methods.POST, formData }));
}

export function throwError(resp, throwErrConfigs = [], body = '') {
    const configs = Object.entries(throwErrConfigs);
    for (const [errType, checker] of configs) {
        if (typeof checker === 'function' && checker(resp)) {
            const apiBody = isJSONParsable(body) ? JSON.parse(body) : body;
            throw generateApiErrObj(resp, errType, apiBody);
        }
    }
}
