import React, { useState, useEffect, useImperativeHandle } from 'react';
import i18n from 'i18next';
import { css } from '@emotion/core';

import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from 'features/common/Mui/Dialog';
import Button from 'features/common/Mui/Button';
import { error, success, system800 } from 'features/common/Mui/styles/color';
import { helperTextStyle } from 'features/login/styles/comman';

export const defaultCampaignUTMParam = {
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_term: '',
    utm_content: ''
};

const useStyles = makeStyles({
    root: {
        background: '#FFFFFF',
        boxShadow:
            '0px 1px 3px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.12), 0px 0px 2px rgba(0, 0, 0, 0.14)',
        borderRadius: '4px',

        '& .MuiListItemIcon-root': {
            minWidth: 38,
            color: success.color
        }
    }
});

const CampaignFieldUtm = ({ campaign, style, callback }, ref) => {
    const {
        setting: { campaignUTMSetting }
    } = campaign;

    const GTM_FIELD = [
        { required: true, placeholder: 'utm_campaign*', id: 'utm_campaign' },
        { required: true, placeholder: 'utm_source*', id: 'utm_source' },
        { required: true, placeholder: 'utm_medium*', id: 'utm_medium' },
        { required: false, placeholder: 'utm_term', id: 'utm_term' },
        { required: false, placeholder: 'utm_content', id: 'utm_content' }
    ];

    const [isSubmit, setIsSubmit] = useState(false);
    const [open, setOpen] = useState(false);
    const [editedUtmSetting, setEditedUtmSetting] = useState(campaignUTMSetting);
    const [utmSetting, setUtmSetting] = useState(campaignUTMSetting);
    const openUTMDialog = () => setOpen(true);
    const closeUTMDialog = () => setOpen(false);

    const classes = useStyles();

    const handleChange = event => {
        setIsSubmit(false);
        event.persist();
        setUtmSetting(prev => ({
            ...prev,
            [event.target.name]: event.target.value
        }));
    };

    const isInputNotVerify = i => {
        const isNotValid = isSubmit && i.required && utmSetting[i.id].length === 0;
        return isNotValid;
    };

    const handleSubmit = () => {
        setIsSubmit(true);
        const isUTMVerify = GTM_FIELD.filter(i => i.required).every(
            i => utmSetting[i.id].length !== 0
        );
        const DonNotUseUtmSetting = Object.values(utmSetting).every(i => i.length === 0);

        if (DonNotUseUtmSetting) {
            setIsSubmit(false);
        }

        if (isUTMVerify || DonNotUseUtmSetting) {
            setEditedUtmSetting(utmSetting);
            closeUTMDialog();
            callback();
        }
    };

    const handleCancel = () => {
        setIsSubmit(false);
        setUtmSetting(editedUtmSetting);
        closeUTMDialog();
    };

    const clearUTMParam = () => {
        setIsSubmit(false);
        setUtmSetting(defaultCampaignUTMParam);
    };

    useImperativeHandle(ref, () => ({
        getGoogleGtmData: () => ({
            editedUtmSetting
        })
    }));

    useEffect(() => {
        setUtmSetting(campaignUTMSetting);
        setEditedUtmSetting(campaignUTMSetting);
    }, [campaignUTMSetting]);

    return (
        <>
            <Dialog
                disableBackdropClick
                title={i18n.t('label.campaign.utm_dialog.title')}
                startButton
                startButtonVariant="outlined"
                startButtonText={i18n.t('button.label.cancel')}
                endButton
                endButtonVariant="contained"
                endButtonText={i18n.t('button.label.confirm')}
                color="primary"
                open={open}
                onSuccess={handleSubmit}
                onCancel={handleCancel}
                startButtonStyle={css(`border: none`)}
                dialogContentStyle={css(`padding-block: 16px`)}
            >
                {Object.values(GTM_FIELD).map(i => {
                    return (
                        <Box mb={2} key={i.placeholder}>
                            <FormControl>
                                <TextField
                                    variant="outlined"
                                    value={utmSetting[i.id]}
                                    onChange={handleChange}
                                    placeholder={i.placeholder}
                                    css={[inputStyle, isInputNotVerify(i) && validateInputStyle]}
                                    name={i.id}
                                />
                                {isInputNotVerify(i) && (
                                    <FormHelperText css={helperTextStyle(true)}>
                                        {i18n.t('label.is_required')}
                                    </FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                    );
                })}
                <Button
                    onClick={clearUTMParam}
                    startIcon={<DeleteIcon />}
                    css={css(`position: absolute; bottom: 17px`)}
                >
                    {i18n.t('label.campaign.utm_dialog.button')}
                </Button>
            </Dialog>
            <Box
                style={{ cursor: 'pointer', ...style }}
                width={400}
                height={48}
                onClick={openUTMDialog}
            >
                <List classes={classes} disablePadding={true}>
                    <ListItem>
                        {editedUtmSetting &&
                            editedUtmSetting.utm_campaign &&
                            editedUtmSetting.utm_source &&
                            editedUtmSetting.utm_medium && (
                                <ListItemIcon>
                                    <CheckCircleIcon />
                                </ListItemIcon>
                            )}
                        <ListItemText primary={i18n.t('label.campaign.utm_list.text')} />
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="edit"
                                css={css(`color: ${system800.color}`)}
                            >
                                <EditIcon />
                            </IconButton>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Box>
        </>
    );
};

export default React.forwardRef(CampaignFieldUtm);

const inputStyle = css`
    & .MuiOutlinedInput-input {
        padding: 14px 16px;
        font-weight: 500;
        width: 400px;
        border: none;

        &:hover {
            border-color: #818181;
        }
    }

    & fieldset {
        border-color: #818181;
    }
`;

const validateInputStyle = css`
    & fieldset {
        border-color: ${error.color};
    }
`;
