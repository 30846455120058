// import { injectGlobal } from 'styled-components';

// const hash = process.env.REACT_APP_BUILD_ID ? `?${process.env.REACT_APP_BUILD_ID}` : '';
// // eslint-disable-next-line no-unused-expressions
// injectGlobal`
//     @import url("https://utility.fevercdn.com/icon-font/fever-icon-font.css.gz?${hash}");
// `;

import React from 'react';
import { Global, css } from '@emotion/core';

import normalizeCss from 'emotion-normalize';

import { BUILD_ID } from 'config/envVariable';

const hash = BUILD_ID ? `?${BUILD_ID}` : '';

function GlobalCss() {
    return (
        <Global
            styles={css`
                @import url('https://utility.fevercdn.com/icon-font/fever-icon-font.css.gz${hash}');
                ${normalizeCss}
                body {
                    font-family: 'Lato', 'Roboto', 'Arial', 'Source Han Sans TC', 'Droid Sans',
                        'Noto Sans CJK TC', 'Noto Sans TC', 'Microsoft JhengHei', '微軟正黑體',
                        '微軟正黑體', 'Microsoft YaHei', 'Hiragino Sans GB', 'sans-serif';
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    letter-spacing: 0.1em;
                }

                html,
                body,
                #root {
                    height: 100%;
                    width: 100%;
                }

                * {
                    box-sizing: border-box;
                }

                // scrollbar custom css
                .ScrollbarsCustom-Track {
                    background: #f8f8f8 !important;
                    border-radius: 4px !important;
                }

                .ScrollbarsCustom-Thumb {
                    background: #ccc !important;
                    border-radius: 4px !important;
                    &:hover {
                        background: #9b9b9b !important;
                    }
                }

                /* Mui Global Css */
                .MuiButtonBase-root.Mui-disabled,
                .MuiSelect-select.Mui-disabled,
                .MuiInputBase-input.Mui-disabled {
                    cursor: not-allowed;
                }
                .MuiButtonBase-root.Mui-disabled {
                    pointer-events: initial;
                }
            `}
        />
    );
}

export default GlobalCss;
