import {
    WEBHOOK_STEPS,
    FACEBOOK_STEPS,
    ACCU_STEPS,
    WISH_STEPS,
    CRESCLAB_STEPS,
    OMNICHAT_STEPS,
    BOT_BONNIE_STEPS
} from './step';

import {
    EVENT_ID_INDEPENDENCE_FORM_SUBMIT,
    EVENT_ID_USER_LOGIN,
    EVENT_ID_PROMOTION_JOIN,
    EVENT_ID_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_FORM_SUBMIT_AWARDEE,
    EVENT_ID_PRIZE_AWARD,
    EVENT_ID_FB_INDEPENDENCE_FORM_SUBMIT,
    EVENT_ID_FB_PROMOTION_JOIN,
    EVENT_ID_FB_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_PROMOTION_VERIFY,
    EVENT_ID_ACCU_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_ACCU_FORM_SUBMIT_AWARDEE,
    EVENT_ID_WISH_MECHANISM_VERIFY,
    EVENT_ID_INDEPENDENCE_TAG_SUBMIT,
    EVENT_ID_MAAC_TAG_SUBMIT,
    EVENT_ID_OMNICHAT_TAG_SUBMIT,
    EVENT_ID_BOT_BONNIE_TAG_SUBMIT
} from './eventId';
import { loginTypes } from 'utils/login';

export const CUSTOM_WEBHOOKS = 1;
export const FACEBOOK_WEBHOOKS = 2;
// export const LINE_WEBHOOKS = 3;
export const ACCU_WEBHOOKS = 4;
export const WISH_WEBHOOKS = 5;
export const TAG_MAAC_WEBHOOKS = 6;
export const TAG_OMNICHAT_WEBHOOKS = 7;
export const TAG_BOT_BONNIE_WEBHOOKS = 8;

export const getModuleIdByEventId = eventId => {
    switch (eventId) {
        case EVENT_ID_INDEPENDENCE_FORM_SUBMIT:
        case EVENT_ID_USER_LOGIN:
        case EVENT_ID_PROMOTION_JOIN:
        case EVENT_ID_FORM_SUBMIT_SIGN_UP:
        case EVENT_ID_FORM_SUBMIT_AWARDEE:
        case EVENT_ID_PRIZE_AWARD:
        case EVENT_ID_PROMOTION_VERIFY:
        case EVENT_ID_INDEPENDENCE_TAG_SUBMIT:
            return CUSTOM_WEBHOOKS;
        case EVENT_ID_FB_INDEPENDENCE_FORM_SUBMIT:
        case EVENT_ID_FB_PROMOTION_JOIN:
        case EVENT_ID_FB_FORM_SUBMIT_SIGN_UP:
            return FACEBOOK_WEBHOOKS;
        case EVENT_ID_ACCU_FORM_SUBMIT_SIGN_UP:
        case EVENT_ID_ACCU_FORM_SUBMIT_AWARDEE:
            return ACCU_WEBHOOKS;
        case EVENT_ID_WISH_MECHANISM_VERIFY:
            return WISH_WEBHOOKS;
        case EVENT_ID_MAAC_TAG_SUBMIT:
            return TAG_MAAC_WEBHOOKS;
        case EVENT_ID_OMNICHAT_TAG_SUBMIT:
            return TAG_OMNICHAT_WEBHOOKS;
        case EVENT_ID_BOT_BONNIE_TAG_SUBMIT:
            return TAG_BOT_BONNIE_WEBHOOKS;
        default:
            return CUSTOM_WEBHOOKS;
    }
};

const modules = [
    {
        id: CUSTOM_WEBHOOKS,
        step: WEBHOOK_STEPS,
        haveDoc: true,
        doc: 'https://info.feversocial.com/help/webhook-459'
    },
    {
        id: FACEBOOK_WEBHOOKS,
        step: FACEBOOK_STEPS,
        haveDoc: false
    },
    // NOTE: 漸強等下個客戶簽約後處理
    // {
    //     id: LINE_WEBHOOKS,
    //     step: CRESCENDO_STEPS,
    //     haveDoc: false,
    //     eventIds: []
    // }
    {
        id: ACCU_WEBHOOKS,
        step: ACCU_STEPS,
        haveDoc: false,
        loginOption: [loginTypes.LINE]
    },
    {
        id: WISH_WEBHOOKS,
        step: WISH_STEPS,
        haveDoc: false,
        loginOption: [loginTypes.ENTERPRISE]
    },
    {
        id: TAG_MAAC_WEBHOOKS,
        step: CRESCLAB_STEPS,
        haveDoc: false
    },
    {
        id: TAG_OMNICHAT_WEBHOOKS,
        step: OMNICHAT_STEPS,
        haveDoc: false
    },
    {
        id: TAG_BOT_BONNIE_WEBHOOKS,
        step: BOT_BONNIE_STEPS,
        haveDoc: false
    }
];
export default modules;

const IS_NEED_CHECK_SETTINGS = new Set([TAG_OMNICHAT_WEBHOOKS, TAG_MAAC_WEBHOOKS]);
export const hasNeedCheckSettings = id => IS_NEED_CHECK_SETTINGS.has(Number(id));

export const ID_TO_PLATFORM = {
    [TAG_MAAC_WEBHOOKS]: 'maac',
    [TAG_OMNICHAT_WEBHOOKS]: 'omnichat',
    [TAG_BOT_BONNIE_WEBHOOKS]: 'botbonnie'
};

export const ADD_LINE_TO_PLATFORM = {
    [TAG_MAAC_WEBHOOKS]: 'maac',
    [TAG_OMNICHAT_WEBHOOKS]: 'omnichat',
    [TAG_BOT_BONNIE_WEBHOOKS]: 'botbonnie'
};

const IS_TAG_WEBHOOKS = new Set([
    TAG_OMNICHAT_WEBHOOKS,
    TAG_MAAC_WEBHOOKS,
    TAG_BOT_BONNIE_WEBHOOKS
]);

export const hasNeedAddPlatformSetting = props =>
    Object.values(ADD_LINE_TO_PLATFORM).some(value => value === props);

export const hasTagWebhooks = id => IS_TAG_WEBHOOKS.has(Number(id));
