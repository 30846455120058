import i18n from 'i18next';

/**
 * SAVED: 按鈕顯示儲存 (白底黑框)
 * FETCHING: 按鈕顯示 Loading
 * IDLE: 按鈕顯示儲存 (藍底無框)
 */
export const SAVE_BUTTON_STATUS = {
    SAVED: 'saved',
    FETCHING: 'fetching',
    IDLE: 'idle'
};

/**
 * ONLINE: 按鈕顯示上線
 * LOADING: 按鈕顯示上線 + Loading
 * OFFLINE: 按鈕顯示停用
 */
export const ONLINE_BUTTON_STATUS = {
    ONLINE: 'online',
    LOADING: 'loading',
    OFFLINE: 'offline'
};

/**
 *  UNPUBLISHED: 未上線
 *  PUBLISHED: 運作中
 *  PAUSED: 停用
 */
export const CAMPAIGN_STATUS = {
    UNPUBLISHED: 0,
    PUBLISHED: 1,
    PAUSED: 2
};

/**
 *  campaign section 元件的 key
 *  NAME: 發送活動名稱
 *  ACCOUNT: 發送對象
 *  CONTENT: 訊息內容
 */
export const CAMPAIGN_SECTION = {
    NAME: 'name',
    ACCOUNT: 'account',
    CONTENT: 'content'
};

// campaign field 元件的 key
export const CAMPAIGN_FIELD = {
    CAMPAIGN_NAME: 'campaign_name',
    CAMPAIGN_SENT_NUMBER: 'campaign_sent_number',
    INSTAGRAM_ACCOUNT: 'instagram_account',
    INSTAGRAM_POST: 'instagram_post',
    INSTAGRAM_STORY: 'instagram_story',
    COMMENT_KEYWORD_SETTING: 'comment_keyword_setting',
    REPLY_KEYWORD_SETTING: 'reply_keyword_setting',
    DATE_SETTING: 'date_setting',
    POST_PERIOD_SETTING: 'post_period_setting',
    STORY_PERIOD_SETTING: 'story_period_setting',
    TYPE_SELECT_MESSAGES: 'type_select_messages',
    PROMOTION_UUID_SELECT_MESSAGES: 'promotion_uuid_select_messages',
    GOOGLE_UTM_MESSAGES_SETTING: 'google_utm_messages_setting',
    INSTAGRAM_REPLY_MESSAGES_SETTING: 'instagram_reply_messages_setting'
};

export const MAX_LENGTH_PER_REQUEST = 10;

export const campaignTypes = {
    STORY_MENTION: 1,
    POST_REPLY: 2,
    STORY_REPLY: 3,
    LIVE_REPLY: 4,
    KEYWORD_REPLY: 5,
    ONSITE_POPUP: 6,
    // 以下尚未開發
    LINE_KEYWORD: 7,
    NEWS_LETTER: 8,
    SMS_SENDER: 9,
    QRCODE_SCAN: 10
};

/**
 * Campaign 類型
 *
 * - 1: Instagram｜@mention 機器人
 * - 2: Instagram｜貼文回覆機器人
 * - 3: Instagram｜限動回覆機器人
 * - 4: Instagram｜直播回覆機器人
 * - 5: Instagram｜關鍵詞回覆機器人
 * - 7: LINE｜回覆關鍵詞
 * - 8: 電子報行銷
 * - 9: 發送簡訊
 * - 10: QRcode 顯示
 */
export const campaignChatConfig = {
    [campaignTypes.STORY_MENTION]: {
        type: campaignTypes.STORY_MENTION,
        i18nNameKey: 'label.story_mention',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/icons/logo_instagram.svg',
        displaySeq: 1,
        disabled: false
    },
    [campaignTypes.POST_REPLY]: {
        type: campaignTypes.POST_REPLY,
        i18nNameKey: 'label.post_reply',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/icons/logo_instagram.svg',
        displaySeq: 2,
        disabled: false
    },
    [campaignTypes.STORY_REPLY]: {
        type: campaignTypes.STORY_REPLY,
        i18nNameKey: 'label.story_reply',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/icons/logo_instagram.svg',
        displaySeq: 3,
        disabled: false
    },
    [campaignTypes.LIVE_REPLY]: {
        type: campaignTypes.LIVE_REPLY,
        i18nNameKey: 'label.live_reply',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/icons/logo_instagram.svg',
        displaySeq: 4,
        disabled: false
    },
    [campaignTypes.KEYWORD_REPLY]: {
        type: campaignTypes.KEYWORD_REPLY,
        i18nNameKey: 'label.keyword_reply',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/icons/logo_instagram.svg',
        displaySeq: 5,
        disabled: false
    }
    // [campaignTypes.LINE_KEYWORD]: {
    //     type: campaignTypes.LINE_KEYWORD,
    //     i18nNameKey: 'label.line_keyword',
    //     imageSrc: 'https://assets.fevercdn.com/nc-admin/icons/logo_line.svg',
    //     displaySeq: 6,
    //     disabled: true
    // },
    // [campaignTypes.NEWS_LETTER]: {
    //     type: campaignTypes.NEWS_LETTER,
    //     i18nNameKey: 'label.news_letter',
    //     imageSrc: () => <EmailIcon />,
    //     displaySeq: 7,
    //     disabled: true
    // },
    // [campaignTypes.SMS_SENDER]: {
    //     type: campaignTypes.SMS_SENDER,
    //     i18nNameKey: 'label.sms_sender',
    //     imageSrc: () => <MessageIcon />,
    //     displaySeq: 8,
    //     disabled: true
    // },
    // [campaignTypes.QRCODE_SCAN]: {
    //     type: campaignTypes.QRCODE_SCAN,
    //     i18nNameKey: 'label.qrcode_scan',
    //     imageSrc: 'https://assets.fevercdn.com/nc-admin/icons/icon_qrcode.svg',
    //     displaySeq: 9,
    //     disabled: true
    // }
};

const i18nKeyOfCampaignChatType = Object.entries(campaignChatConfig).reduce(
    (obj, [, { type, i18nNameKey }]) => {
        if (i18nNameKey !== undefined) {
            obj[type] = i18nNameKey;
        }
        return obj;
    },
    {}
);

export function getCampaignChatTypeLabel(campaignType) {
    const i18nKey = i18nKeyOfCampaignChatType[campaignType];
    return i18nKey ? i18n.t(i18nKey) : '';
}

export const convertCampaignStatus2Online = status => {
    switch (status) {
        case CAMPAIGN_STATUS.UNPUBLISHED:
            return ONLINE_BUTTON_STATUS.ONLINE;
        case CAMPAIGN_STATUS.PUBLISHED:
            return ONLINE_BUTTON_STATUS.OFFLINE;
        case CAMPAIGN_STATUS.PAUSED:
            return ONLINE_BUTTON_STATUS.ONLINE;
        default:
            return ONLINE_BUTTON_STATUS.ONLINE;
    }
};

export const convertCampaignStatus2Text = (status, isCampaignChat) => {
    switch (status) {
        case CAMPAIGN_STATUS.UNPUBLISHED:
            return i18n.t('label.campaign.status.offline');
        case CAMPAIGN_STATUS.PUBLISHED:
            return i18n.t('label.campaign.status.online');
        case CAMPAIGN_STATUS.PAUSED:
            return isCampaignChat
                ? i18n.t('label.campaign.status.stop')
                : i18n.t('label.campaign.status.pause');
        default:
            return '';
    }
};

export const campaignServiceTypes = {
    CHAT: 'chat',
    ONSITE: 'onsite'
};

/**
 * Campaign pages 類型
 *
 * - 1: onsite
 * - 2: chat
 */
export const campaignConfig = {
    [campaignServiceTypes.ONSITE]: {
        type: campaignServiceTypes.ONSITE,
        i18nNameKey: 'label.campaign.onsite',
        desc: 'label.campaign.onsite.desc',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/campaignOnsite.png',
        disabled: false
    },
    [campaignServiceTypes.CHAT]: {
        type: campaignServiceTypes.CHAT,
        i18nNameKey: 'label.campaign.chat',
        desc: 'label.campaign.chat.desc',
        imageSrc: 'https://assets.fevercdn.com/nc-admin/images/campaignText.png',
        disabled: false
    }
};

const i18nKeyOfCampaignType = Object.entries(campaignConfig).reduce(
    (obj, [, { type, i18nNameKey }]) => {
        if (i18nNameKey !== undefined) {
            obj[type] = i18nNameKey;
        }
        return obj;
    },
    {}
);

export function getCampaignTypeLabel(campaignType) {
    const i18nKey = i18nKeyOfCampaignType[campaignType];
    return i18nKey ? i18n.t(i18nKey) : '';
}

/**
 * @namespace 網頁推播
 * @property {string} [TEMPLATE='0'] - 推播建立
 * @property {string} [PLUGIN='1'] - 安裝推播插件
 */
export const ON_SITE_VIEW_COMP = {
    TEMPLATE: '0',
    PLUGIN: '1'
};

/**
 * @namespace campaignGroupTypes
 * @property {string} [banner = 3] - Banner
 * @property {string} [icon = 4 ] - Icon
 * @property {string} [dialog = 5] - dialog
 */
export const campaignGroupTypes = {
    Banner: 3,
    Icon: 4,
    Dialog: 5
};

/**
 * @namespace campaignMessageTemplateTypes
 * @property {string} [floatingButtonTemplate3 = 1] - 浮動按鈕樣式 1 -> 只有圖片
 * @property {string} [floatingButtonTemplate1 = 2] - 浮動按鈕樣式 2 -> 圖片和文字平行
 * @property {string} [floatingButtonTemplate2 = 3] - 浮動按鈕樣式 3 -> 圖片和按鈕垂直
 * @property {string} [dialogTemplate1 = 4] - 彈跳視窗樣式 1 -> 只有圖片
 * @property {string} [dialogTemplate2 = 5] - 彈跳視窗樣式 2 -> 圖片＋文字＋按鈕
 * @property {string} [dialogTemplate3 = 6] - 彈跳視窗樣式 3 -> 圖片＋按鈕
 * @property {string} [bannerTemplate1 = 7] - 提示橫幅樣式 1 -> 只有背景圖片
 * @property {string} [bannerTemplate2 = 8] - 提示橫幅樣式 2 -> 背景圖片 + 文字 ＋ 按鈕
 * @property {string} [bannerTemplate3 = 9] - 提示橫幅樣式 3 -> 背景圖片 ＋ 圖片 ＋ 按鈕
 */
export const campaignMessageTemplateTypes = {
    floatingButtonTemplate1: 1,
    floatingButtonTemplate2: 2,
    floatingButtonTemplate3: 3,
    dialogTemplate1: 4,
    dialogTemplate2: 5,
    dialogTemplate3: 6,
    bannerTemplate1: 7,
    bannerTemplate2: 8,
    bannerTemplate3: 9
};

/**
 * 根據模板 ID 獲取對應的 campaignGroupType
 * @param {number} templateId - 模板 ID
 * @returns {number} 對應的 campaignGroupType，如果找不到則返回 undefined
 */
export const getCampaignGroupTypeByTemplateId = templateId => {
    if (
        templateId >= campaignMessageTemplateTypes.floatingButtonTemplate1 &&
        templateId <= campaignMessageTemplateTypes.floatingButtonTemplate3
    ) {
        return campaignGroupTypes.Icon;
    } else if (
        templateId >= campaignMessageTemplateTypes.dialogTemplate1 &&
        templateId <= campaignMessageTemplateTypes.dialogTemplate3
    ) {
        return campaignGroupTypes.Dialog;
    } else if (
        templateId >= campaignMessageTemplateTypes.bannerTemplate1 &&
        templateId <= campaignMessageTemplateTypes.bannerTemplate3
    ) {
        return campaignGroupTypes.Banner;
    }
    return undefined;
};
