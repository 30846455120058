import _groupBy from 'lodash/groupBy';
import _merge from 'lodash/merge';
import _cloneDeep from 'lodash/cloneDeep';
import { system900, promo500, white } from 'features/common/Mui/styles/color';
import {
    SCHEDULE_DISPLAY,
    MESSAGE_DISPLAY_POSITION,
    TRIGGER_TIMING,
    DISPLAY_FREQUENCY,
    CLICK_BEHAVIOR,
    CLICK_DELAY_TIME,
    DISPLAY_POSITION,
    DISPLAY_POSITION_LABELS,
    DYNAMIC_EFFECTS,
    PLAYBACK_FREQUENCY
} from 'features/campaigns/CampaignOnsite/const';
import { campaignGroupTypes, campaignMessageTemplateTypes } from 'config/campaignType';
import {
    generateFroalaHtmlByTemplateId,
    generateImageElements,
    generateButtonElements,
    generateTextareaElements
} from 'features/campaigns/CampaignOnsite/utils';
const eleId = `id${Date.now()}`;

// Common settings for each type
const commonSettings = {
    [campaignGroupTypes.Icon]: {
        messageGroupeType: campaignGroupTypes.Icon,
        messageCloseButtonColor: system900.color,
        messageBackgroundType: 'color',
        messageBackgroundColor: promo500.color,
        messageBorderRadius: '50%',
        messagePlacement: DISPLAY_POSITION_LABELS[DISPLAY_POSITION.BOTTOM_RIGHT],
        messageGapX: 16,
        messageGapY: 16,
        messageAnimation: DYNAMIC_EFFECTS.ZOOM,
        messageFrequency: PLAYBACK_FREQUENCY.CONTINUOUS
    },
    [campaignGroupTypes.Banner]: {
        messageGroupeType: campaignGroupTypes.Banner,
        messageCloseButtonColor: system900.color,
        messageBackgroundType: 'color',
        messageBackgroundColor: '#cccccc',
        messageBackgroundImageUrl: '',
        messageBorderRadius: 0,
        messagePlacement: DISPLAY_POSITION_LABELS[DISPLAY_POSITION.TOP_CENTER]
    },
    [campaignGroupTypes.Dialog]: {
        messageGroupeType: campaignGroupTypes.Dialog,
        messageCloseButtonType: 'color',
        messageCloseButtonColor: system900.color,
        messageBackgroundType: 'color',
        messageBackgroundColor: 'rgba(255, 255, 255, 0)',
        messageBackgroundImageUrl: '',
        messageBorderRadius: 16,
        messagePlacement: DISPLAY_POSITION_LABELS[DISPLAY_POSITION.CENTER_CENTER],
        messageAnimation: DYNAMIC_EFFECTS.ZOOM,
        messageFrequency: PLAYBACK_FREQUENCY.CONTINUOUS,
        messageMaskEnabled: 0,
        messageMaskColor: 'rgba(0, 0, 0, 0.5)'
    }
};

const categoryConfig = {
    [campaignGroupTypes.Icon]: {
        id: 'floating-button',
        type: campaignGroupTypes.Icon,
        name: 'lable.campaign.group_types.icon',
        displayOrder: 3
    },
    [campaignGroupTypes.Banner]: {
        id: 'banner',
        type: campaignGroupTypes.Banner,
        name: 'lable.campaign.group_types.banner',
        displayOrder: 2
    },
    [campaignGroupTypes.Dialog]: {
        id: 'popup',
        type: campaignGroupTypes.Dialog,
        name: 'lable.campaign.group_types.dialog',
        displayOrder: 1
    }
};

export const templateConfig = {
    [campaignMessageTemplateTypes.floatingButtonTemplate1]: {
        id: campaignMessageTemplateTypes.floatingButtonTemplate1,
        categoryId: campaignGroupTypes.Icon,
        description: 'label.campaign.group_types.icon1_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/IconTemplate1.png',
        enabled: true,
        displayOrder: 1,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Icon],
            messageTemplateId: campaignMessageTemplateTypes.floatingButtonTemplate1,
            imgMobileElements: generateImageElements(
                eleId,
                campaignMessageTemplateTypes.floatingButtonTemplate1
            ),
            content: {
                template_type: campaignMessageTemplateTypes.floatingButtonTemplate1,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.floatingButtonTemplate1,
                    imgMobileElements: generateImageElements(
                        eleId,
                        campaignMessageTemplateTypes.floatingButtonTemplate1
                    )
                })
            }
        }
    },
    [campaignMessageTemplateTypes.floatingButtonTemplate2]: {
        id: campaignMessageTemplateTypes.floatingButtonTemplate2,
        categoryId: campaignGroupTypes.Icon,
        description: 'label.campaign.group_types.icon2_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/IconTemplate2.png',
        enabled: true,
        displayOrder: 2,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Icon],
            messageTemplateId: campaignMessageTemplateTypes.floatingButtonTemplate2,
            messageBorderRadius: 16,
            imgMobileElements: generateImageElements(
                eleId,
                campaignMessageTemplateTypes.floatingButtonTemplate2
            ),
            textAreaElements: generateTextareaElements(
                eleId,
                campaignMessageTemplateTypes.floatingButtonTemplate2
            ),
            content: {
                template_type: campaignMessageTemplateTypes.floatingButtonTemplate2,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.floatingButtonTemplate2,
                    imgMobileElements: generateImageElements(
                        eleId,
                        campaignMessageTemplateTypes.floatingButtonTemplate2
                    ),
                    textAreaElements: generateTextareaElements(
                        eleId,
                        campaignMessageTemplateTypes.floatingButtonTemplate2
                    )
                })
            }
        }
    },
    [campaignMessageTemplateTypes.floatingButtonTemplate3]: {
        id: campaignMessageTemplateTypes.floatingButtonTemplate3,
        categoryId: campaignGroupTypes.Icon,
        description: 'label.campaign.group_types.icon3_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/IconTemplate3.png',
        enabled: true,
        displayOrder: 3,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Icon],
            messageTemplateId: campaignMessageTemplateTypes.floatingButtonTemplate3,
            messageBorderRadius: 16,
            messageBackgroundColor: 'transparent',
            imgMobileElements: generateImageElements(
                eleId,
                campaignMessageTemplateTypes.floatingButtonTemplate3
            ),
            buttonElements: generateButtonElements(
                eleId,
                campaignMessageTemplateTypes.floatingButtonTemplate3
            ),
            content: {
                template_type: campaignMessageTemplateTypes.floatingButtonTemplate3,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.floatingButtonTemplate3,
                    imgMobileElements: generateImageElements(
                        eleId,
                        campaignMessageTemplateTypes.floatingButtonTemplate3
                    ),
                    buttonElements: generateButtonElements(
                        eleId,
                        campaignMessageTemplateTypes.floatingButtonTemplate3
                    )
                })
            }
        },
        settingMessage: {
            enableDelayMessageClick: CLICK_DELAY_TIME.ENABLE,
            delayMessageSeconds: 15
        }
    },
    [campaignMessageTemplateTypes.dialogTemplate1]: {
        id: campaignMessageTemplateTypes.dialogTemplate1,
        categoryId: campaignGroupTypes.Dialog,
        description: 'label.campaign.group_types.dialog1_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/DialogTemplate1.png',
        enabled: true,
        displayOrder: 3,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Dialog],
            messageTemplateId: campaignMessageTemplateTypes.dialogTemplate1,
            imgMobileElements: generateImageElements(
                eleId,
                campaignMessageTemplateTypes.dialogTemplate1
            ),
            content: {
                template_type: campaignMessageTemplateTypes.dialogTemplate1,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.dialogTemplate1,
                    imgMobileElements: generateImageElements(
                        eleId,
                        campaignMessageTemplateTypes.dialogTemplate1
                    )
                })
            }
        }
    },
    [campaignMessageTemplateTypes.dialogTemplate2]: {
        id: campaignMessageTemplateTypes.dialogTemplate2,
        categoryId: campaignGroupTypes.Dialog,
        description: 'label.campaign.group_types.dialog2_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/DialogTemplate2.png',
        enabled: true,
        displayOrder: 3,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Dialog],
            messageTemplateId: campaignMessageTemplateTypes.dialogTemplate2,
            messageBackgroundColor: ' #BE1726',
            imgMobileElements: generateImageElements(
                eleId,
                campaignMessageTemplateTypes.dialogTemplate2
            ),
            textAreaElements: generateTextareaElements(
                eleId,
                campaignMessageTemplateTypes.dialogTemplate2
            ),
            buttonElements: generateButtonElements(
                eleId,
                campaignMessageTemplateTypes.dialogTemplate2
            ),
            content: {
                template_type: campaignMessageTemplateTypes.dialogTemplate2,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.dialogTemplate2,
                    imgMobileElements: generateImageElements(
                        eleId,
                        campaignMessageTemplateTypes.dialogTemplate2
                    ),
                    textAreaElements: generateTextareaElements(
                        eleId,
                        campaignMessageTemplateTypes.dialogTemplate2
                    ),
                    buttonElements: generateButtonElements(
                        eleId,
                        campaignMessageTemplateTypes.dialogTemplate2
                    )
                })
            }
        }
    },
    [campaignMessageTemplateTypes.dialogTemplate3]: {
        id: campaignMessageTemplateTypes.dialogTemplate3,
        categoryId: campaignGroupTypes.Dialog,
        description: 'label.campaign.group_types.dialog3_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/DialogTemplate3.png',
        enabled: true,
        displayOrder: 3,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Dialog],
            messageTemplateId: campaignMessageTemplateTypes.dialogTemplate3,
            imgMobileElements: generateImageElements(
                eleId,
                campaignMessageTemplateTypes.dialogTemplate3
            ),
            buttonElements: generateButtonElements(
                eleId,
                campaignMessageTemplateTypes.dialogTemplate3
            ),
            content: {
                template_type: campaignMessageTemplateTypes.dialogTemplate3,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.dialogTemplate3,
                    imgMobileElements: generateImageElements(
                        eleId,
                        campaignMessageTemplateTypes.dialogTemplate3
                    ),
                    buttonElements: generateButtonElements(
                        eleId,
                        campaignMessageTemplateTypes.dialogTemplate3
                    )
                })
            }
        }
    },
    [campaignMessageTemplateTypes.bannerTemplate1]: {
        id: campaignMessageTemplateTypes.bannerTemplate1,
        categoryId: campaignGroupTypes.Banner,
        description: 'label.campaign.group_types.banner1_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/BannerTemplate1.png',
        enabled: true,
        displayOrder: 3,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Banner],
            messageTemplateId: campaignMessageTemplateTypes.bannerTemplate1,
            messageCloseButtonColor: white.color,
            imgMobileElements: generateImageElements(
                eleId,
                campaignMessageTemplateTypes.bannerTemplate1
            ),
            content: {
                template_type: campaignMessageTemplateTypes.bannerTemplate1,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.bannerTemplate1,
                    imgMobileElements: generateImageElements(
                        eleId,
                        campaignMessageTemplateTypes.bannerTemplate1
                    )
                })
            }
        }
    },
    [campaignMessageTemplateTypes.bannerTemplate2]: {
        id: campaignMessageTemplateTypes.bannerTemplate2,
        categoryId: campaignGroupTypes.Banner,
        description: 'label.campaign.group_types.banner2_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/BannerTemplate2.png',
        enabled: true,
        displayOrder: 3,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Banner],
            messageTemplateId: campaignMessageTemplateTypes.bannerTemplate2,
            messageBackgroundType: 'image',
            messageBackgroundImageUrl:
                'https://assets.fevercdn.com/nc-admin/images/Banner2%20-%20BG.png',
            messageCloseButtonColor: system900.color,
            textAreaElements: generateTextareaElements(
                eleId,
                campaignMessageTemplateTypes.bannerTemplate2
            ),
            buttonElements: generateButtonElements(
                eleId,
                campaignMessageTemplateTypes.bannerTemplate2
            ),
            content: {
                template_type: campaignMessageTemplateTypes.bannerTemplate2,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.bannerTemplate2,
                    textAreaElements: generateTextareaElements(
                        eleId,
                        campaignMessageTemplateTypes.bannerTemplate2
                    ),
                    buttonElements: generateButtonElements(
                        eleId,
                        campaignMessageTemplateTypes.bannerTemplate2
                    )
                })
            }
        }
    },
    [campaignMessageTemplateTypes.bannerTemplate3]: {
        id: campaignMessageTemplateTypes.bannerTemplate3,
        categoryId: campaignGroupTypes.Banner,
        description: 'label.campaign.group_types.banner3_description',
        previewImage: 'https://assets.fevercdn.com/nc-admin/images/BannerTemplate3.png',
        enabled: true,
        displayOrder: 3,
        settingStyle: {
            ...commonSettings[campaignGroupTypes.Banner],
            messageTemplateId: campaignMessageTemplateTypes.bannerTemplate3,
            messageBackgroundType: 'image',
            messageBackgroundImageUrl:
                'https://assets.fevercdn.com/nc-admin/images/Banner3%20-%20BG.png ',
            messageCloseButtonColor: white.color,
            imgMobileElements: generateImageElements(
                eleId,
                campaignMessageTemplateTypes.bannerTemplate3
            ),
            buttonElements: generateButtonElements(
                eleId,
                campaignMessageTemplateTypes.bannerTemplate3
            ),
            content: {
                template_type: campaignMessageTemplateTypes.bannerTemplate3,
                elements: generateFroalaHtmlByTemplateId({
                    template_type: campaignMessageTemplateTypes.bannerTemplate3,
                    imgMobileElements: generateImageElements(
                        eleId,
                        campaignMessageTemplateTypes.bannerTemplate3
                    ),
                    buttonElements: generateButtonElements(
                        eleId,
                        campaignMessageTemplateTypes.bannerTemplate3
                    )
                })
            }
        }
    }
};

/**
 * Maps the template and category configurations to the onSiteTemplateList format
 * @returns {Array} Formatted template list
 */
// 此檔案拿不到 i18next，所以這邊先傳入
export const mapToOnSiteTemplateList = i18next => {
    // First create base structure from categoryConfig
    const baseStructure = Object.values(categoryConfig).map(category => ({
        campaignGroupType: category.type,
        title: i18next.t(category.name),
        campaignMessage: []
    }));

    // Sort by displayOrder
    baseStructure.sort((a, b) => {
        const orderA = categoryConfig[a.campaignGroupType].displayOrder;
        const orderB = categoryConfig[b.campaignGroupType].displayOrder;
        return orderA - orderB;
    });

    // Group templates by categoryId
    const templatesGroupedByCategory = _groupBy(
        Object.entries(templateConfig).map(([type, template]) => ({
            campaignMessageType: type,
            imgUrl: template.previewImage,
            desc: i18next.t(template.description),
            categoryId: template.categoryId
        })),
        'categoryId'
    );

    // Map templates to their respective categories
    return baseStructure.map(category => ({
        ...category,
        campaignMessage: templatesGroupedByCategory[category.campaignGroupType] || []
    }));
};

/**
 * Gets template settings by message type
 * @param {string} type - The message template type to get settings for
 * @returns {Object} Template settings
 */
export const getTemplateSettings = type => {
    return getCampaignDefaultSettingValue(type);
};

export const getCampaignDefaultSettingValue = templateType => {
    const template = templateConfig[templateType];

    const defaults = {
        settingStyle: {
            // 圓角
            messageBorderRadius: '50%',
            // 背景
            messageBackgroundType: 'image',
            messageBackgroundImageUrl: '',
            // 關閉按鈕
            messageCloseButtonType: 'color',
            messageCloseButtonColor: system900.color
        },
        settingMessage: {
            // 點擊行為
            clickBehaviorType: CLICK_BEHAVIOR.OPEN_POPUP,
            // 延遲點擊時間開關
            enableDelayMessageClick: CLICK_DELAY_TIME.DISABLE
        },
        settingCampaign: {
            // 排程顯示
            enableMessageDateTimeLimit: SCHEDULE_DISPLAY.DISABLE,
            // 訊息顯示位置
            messageTriggeredPageType: MESSAGE_DISPLAY_POSITION.ALL_PAGE,
            // 觸發時機
            triggerTimingType: TRIGGER_TIMING.ON_PAGE_LOAD,
            // 顯示頻率
            displayFrequencyType: DISPLAY_FREQUENCY.EVERY_VISIT,
            // 時區 (後端統計用)
            timezone: 'Asia/Taipei'
        }
    };

    return _merge(_cloneDeep(defaults), {
        settingStyle: template?.settingStyle || {},
        settingMessage: template?.settingMessage || {},
        settingCampaign: template?.settingCampaign || {}
    });
};
