import React, { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import ProtectedRoute from 'features/appContent/ProtectedRoute';

import 'react-placeholder/lib/reactPlaceholder.css';
import 'react-select/dist/react-select.min.css';
import 'assets/style/index.css';

import useInitAndRedirectApp from 'features/login/hooks/useInitAndRedirectApp';
import useInjectReducer from 'hooks/useInjectReducer';
import GlobalCss from 'assets/style/GlobalCss';
import ImageReader from 'features/common/ImageReader';
import LoadingAnimation from 'features/common/LoadingAnimation';
import NotificationWrapper from 'features/NotificationWrapper/NotificationWrapper';
import LoginApp from 'features/login/components/LoginApp';
import { initErrorLogService } from 'utils/sentry';
import NotFound from 'features/NotFound';
import campaignChat from 'store/module/campaignChat';

const RouteCreate = lazy(() =>
    import(/* webpackChunkName: "route_create" */ 'features/createUser/RouteCreate')
);
const Dashboard = lazy(() => import(/* webpackChunkName: "dashboard" */ 'features/Dashboard'));

const RouteProject = lazy(() =>
    import(/* webpackChunkName: "route_project" */ 'features/app/RouteProject')
);
const LoginHandler = lazy(() =>
    import(/* webpackChunkName: "redirect_handler" */ 'features/app/LoginHandler')
);
const ReportsDownload = lazy(() =>
    import(/* webpackChunkName: "reports_download" */ 'features/app/ReportsDownload')
);
const Authorize = lazy(() => import(/* webpackChunkName: "authorize" */ 'features/app/Authorize'));

initErrorLogService();

const isCampaignChatRoute = path => {
    return /^\/admin\/campaign\/chat\/\d+\/(create|\d+\/edit)/.test(path);
};

export default function App() {
    const { isInited } = useInitAndRedirectApp();
    useInjectReducer('campaignChat', campaignChat, isCampaignChatRoute);

    return (
        <>
            {!isInited ? (
                <LoadingAnimation />
            ) : (
                <Suspense fallback={<LoadingAnimation />}>
                    <Switch>
                        <Route path="/app" component={LoginApp} />
                        <Route path="/utils/loginhandler" component={LoginHandler} />
                        <Route path="/utils/img_reader" component={ImageReader} />
                        <Route path="/utils/authorize/:platform" component={Authorize} />
                        <ProtectedRoute path="/admin/dashboard" component={Dashboard} />
                        {/* // TODO : 若後端調整 Promotion Url 刪除以下程式碼 ----------  */}
                        <ProtectedRoute
                            path="/admin/download/reports/s/:sponsorId/p/:promotionId"
                            component={ReportsDownload}
                        />
                        {/* // TODO : 若後端調整 Promotion Url 刪除以上程式碼 ----------  */}
                        <ProtectedRoute
                            path="/admin/download/reports/s/:sponsorId"
                            component={ReportsDownload}
                        />
                        <ProtectedRoute
                            path="/admin"
                            component={RouteProject}
                            LoadingComp={LoadingAnimation}
                        />
                        <Route
                            path="/create"
                            component={RouteCreate}
                            LoadingComp={LoadingAnimation}
                        />
                        <Route path="*" component={NotFound} />
                    </Switch>
                </Suspense>
            )}
            <NotificationWrapper />
            <GlobalCss />
        </>
    );
}
