import { negative, positive } from 'features/common/Mui/styles/color';
import { POINT_RECORD_ACTION } from '../config';

export const getPointsColor = number => {
    return number < 0 ? negative.color : positive.color;
};

/**
 * @method getPointsColorByAction
 * @name 透過點數紀錄操作類型取得點數樣式顏色
 * @param {number} action 點數紀錄操作類型
 * @return {string}
 */
export const getPointsColorByAction = action => {
    return action === POINT_RECORD_ACTION.DEDUCT ? negative.color : positive.color;
};
