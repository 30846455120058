import React from 'react';
import { SnackbarProvider } from 'notistack';
import Notification from 'features/NotificationWrapper/Notification';

export default function NotificationWrapper() {
    return (
        <SnackbarProvider maxSnack={3}>
            <Notification />
        </SnackbarProvider>
    );
}
