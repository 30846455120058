import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import { openWindow } from 'utils/login';
import { setWarning, setFailed } from 'store/module/notify';
import { generateLoginUrl } from 'utils/login';
import { signout } from 'features/login/utils/firebaseManager';
import { sentryCaptureException } from 'utils/sentry';
import {
    getGeneralErrCode,
    isFetchNetworkError,
    isTooManyAttemptErr,
    isUnauthorizedToGetAccessToken
} from 'utils/errorObjectChecker';
import { EXCEPTION_FAILED } from 'config/errorTypes';

export default function useApiFailedHandler() {
    const dispatch = useDispatch();
    return useCallback(
        async (errObj, defaultErrMsg = i18n.t('message_save_error'), errorType, body) => {
            console.error(errObj);
            const error = generateApiErrObj(errObj, errorType, body);
            const generalErrCode = getGeneralErrCode(error);

            if (generalErrCode)
                return dispatch(setFailed(i18n.t('notify.busy_error', { code: generalErrCode })));

            switch (true) {
                case isUnauthorizedToGetAccessToken(error):
                    dispatch(setWarning(i18n.t('notify.login_session_expired')));
                    await signout().then(() => {
                        const refUrl = `${window.location.origin}/utils/loginhandler`;
                        openWindow(generateLoginUrl(refUrl), { name: 'editor-login' });
                    });
                    break;
                case isFetchNetworkError(error):
                    dispatch(setFailed(i18n.t('notify.network_error')));
                    break;
                case isTooManyAttemptErr(error):
                    dispatch(setFailed(i18n.t('notify.too_many_attempt_error')));
                    break;
                default: {
                    const eventId = sentryCaptureException(error);
                    console.error('Event Id:', eventId);
                    dispatch(setFailed(`${i18n.t(defaultErrMsg)}`));
                }
            }
        },
        [dispatch]
    );
}

export function generateApiErrObj(apiErr = {}, type = EXCEPTION_FAILED, body) {
    let errObj = new Error(type);
    apiErr.errType = type;
    errObj.extraInfo = apiErr;

    if (!Array.isArray(apiErr) && typeof apiErr === 'object' && Boolean(body)) {
        errObj.extraInfo.body = body;
    }

    return errObj;
}
