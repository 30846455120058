// import { setFailed } from 'store/module/flag';

import { getPromosListApi } from 'api/promos';

const SET_PROMO_CURRENT_PAGE = 'promos/SET_PROMO_CURRENT_PAGE';
const SET_PROMO_SEARCH_KEY = 'promos/SET_PROMO_SEARCH_KEY';
const SET_PROMO_FILTERS = 'promos/SET_PROMO_FILTERS';
const SET_LAST_ACTION = 'promos/SET_LAST_ACTION';
const SET_PROMO_DATA = 'promos/SET_PROMO_DATA';
const SET_PROMO_IS_FETCHING = 'promos/SET_PROMO_IS_FETCHING';
const RESET_PROMOS_FILTERS = 'RESET_PROMOS_FILTERS';

export function setPromosCurrentPage(currentPage) {
    return {
        type: SET_PROMO_CURRENT_PAGE,
        payload: currentPage
    };
}

export function setPromosSearchKey(searchKey) {
    return {
        type: SET_PROMO_SEARCH_KEY,
        payload: searchKey
    };
}

export function setPromosFilters(filters) {
    return {
        type: SET_PROMO_FILTERS,
        payload: filters
    };
}

export function setLastAction(actionType) {
    return {
        type: SET_LAST_ACTION,
        payload: actionType
    };
}

export function setPromosData(data) {
    return {
        type: SET_PROMO_DATA,
        payload: data
    };
}

export function setPromosIsFetching(isFetching) {
    return {
        type: SET_PROMO_IS_FETCHING,
        payload: isFetching
    };
}

export function resetPromosFilters() {
    return {
        type: RESET_PROMOS_FILTERS
    };
}

export function setPromoDataWithApi(sponsorId) {
    return async (dispatch, getState) => {
        dispatch(setPromosIsFetching(true));
        try {
            const {
                promos: { perPage, currentPage, searchKey, filters }
            } = getState();

            const apiParams = {
                perPage,
                page: currentPage,
                ...(searchKey && { searchKey }),
                ...(filters.status !== null &&
                    filters.status !== undefined && { status: filters.status }),
                ...(filters.time && {
                    dateFilterType: 'startDate',
                    filterStartDay: filters.time.filterStartDay,
                    filterEndDay: filters.time.filterEndDay
                }),
                ...(filters.gameType && { type: [filters.gameType] }),
                ...(filters.creator && { creator: filters.creator })
            };
            const data = await getPromosListApi(sponsorId, apiParams);
            dispatch(setPromosData(data));
            dispatch(setPromosIsFetching(false));
        } catch (err) {
            console.log(err);
        }
    };
}

const initState = {
    currentPage: 1,
    perPage: 10,
    totalItems: 0,
    totalPages: 1,
    itemList: [],
    searchKey: '',
    filters: {
        gameType: null,
        time: null,
        creator: null,
        status: null
    },
    lastAction: null,
    isFetching: false
};

export default function reducer(state = initState, { type, payload }) {
    switch (type) {
        case SET_PROMO_CURRENT_PAGE:
            return {
                ...state,
                currentPage: payload
            };
        case SET_PROMO_SEARCH_KEY:
            return {
                ...state,
                searchKey: payload
            };
        case SET_PROMO_FILTERS:
            return {
                ...state,
                filters: payload
            };
        case SET_LAST_ACTION:
            return {
                ...state,
                lastAction: payload
            };
        case SET_PROMO_DATA:
            return {
                ...state,
                itemList: payload.items,
                totalItems: payload.totalItems,
                totalPages: payload.totalPages
            };
        case RESET_PROMOS_FILTERS:
            return {
                ...state,
                filters: {
                    gameType: null,
                    time: null,
                    creator: null,
                    status: null
                }
            };
        case SET_PROMO_IS_FETCHING:
            return {
                ...state,
                isFetching: payload
            };
        default:
            return state;
    }
}
