import { css } from '@emotion/core';
import { system400, system800 } from 'features/common/Mui/styles/color';

export function getFullCoverBgProps({ imgUrl, color = 'transparent' } = {}) {
    return `
    background-color: ${color};
    ${imgUrl ? `background-image: url('${imgUrl}');` : ''}
    background-attachment: fixed;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    `;
}

export const removeBtnDefaultStyle = css`
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
`;

export const absolutePositionCenterStyle = css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
`;

export const mainFunctionBtnStyle = `
    min-width: 70px;
    height: 36px;
    padding: 8px 16px;
    border-radius: 8px;
    
`;

export const mainFunctionBtnBlurStyle = `
    border-color: ${system400.color};
    color: ${system800.color};
    &:hover {
        border-color: ${system800.color};
    }
`;
