// Check Safari blocking popups and return boolean value
export default function triggerBrowserOpenNewWindow(href) {
    const popUp = window.open(href);
    if (popUp == null || typeof popUp == 'undefined') {
        return false;
    } else {
        popUp.focus();
        return true;
    }
}
