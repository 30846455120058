const INIT_PARTNER = 'sponsor/INIT_PARTNER';
const defaultState = { settings: {} };

export default function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case INIT_PARTNER:
            return { ...payload };
        default:
            return state;
    }
}

export function initPartner(data) {
    return {
        type: INIT_PARTNER,
        payload: data
    };
}
