import {
    getPagesAPI,
    setPageAPI,
    deletePageAPI,
    getLabelsAPI,
    createLabelAPI,
    setLabelAPI,
    setLabelOfPageAPI,
    deleteLabelOfPageAPI,
    deleteLabelAPI
} from 'api/zines';

const GET_PAGES = 'pages/GET_PAGES';
const SET_PAGE = 'pages/SET_PAGE';
const DELETE_PAGE = 'pages/DELETE_PAGE';
const GET_LABELS = 'pages/GET_LABELS';
const CREATE_LABEL = 'pages/CREATE_LABEL';
const SET_LABEL = 'pages/SET_LABEL';
const DELETE_LABEL = 'pages/DELETE_LABEL';

const reducer = (
    state = {
        data: [],
        total: -1,
        username: '',
        hasHome: false
    },
    action
) => {
    switch (action.type) {
        case GET_PAGES:
            return action.data;
        case SET_PAGE:
        case DELETE_PAGE:
        case GET_LABELS:
        case CREATE_LABEL:
        case SET_LABEL:
        case DELETE_LABEL:
        default:
            return state;
    }
};

export default reducer;

export function getPages(sponsorId, queries = {}, callback = () => {}) {
    return async dispatch => {
        const { shouldFetchChannelPage = false, channelPageId } = queries;
        const json = await Promise.all([
            shouldFetchChannelPage
                ? getPagesAPI(sponsorId, { _pageId: channelPageId })
                : Promise.resolve({}),
            getPagesAPI(sponsorId, queries)
        ]).then(([channelPageResp, pageResp]) => {
            const { data: channelPage = [], total: channelPageTotal } = channelPageResp;
            const { data: pages = [], total: pageTotal } = pageResp;
            let total;
            if (!shouldFetchChannelPage) {
                total = pageTotal;
            } else {
                total = pageTotal || channelPageTotal;
            }
            return {
                ...pageResp,
                data: [...channelPage, ...pages],
                total
            };
        });
        dispatch({
            type: GET_PAGES,
            data: json
        });
        callback();
    };
}

export function setPage(sponsorId, pageId, queries, callback) {
    return async dispatch => {
        try {
            await setPageAPI(sponsorId, pageId, queries);
            dispatch({ type: SET_PAGE });
            callback(true);
        } catch (err) {
            console.log(err);
            callback(false);
        }
    };
}

export function deletePage(sponsorId, pageId, callback) {
    return async dispatch => {
        try {
            await deletePageAPI(sponsorId, pageId);
            dispatch({ type: DELETE_PAGE });
            callback(true);
        } catch (err) {
            console.log(err);
            callback(false);
        }
    };
}

export function getLabels(sponsorId, callback) {
    return async dispatch => {
        const json = await getLabelsAPI(sponsorId);
        dispatch({ type: GET_LABELS });
        callback(json);
    };
}

export function createLabel(sponsorId, text, callback) {
    return async dispatch => {
        try {
            const json = await createLabelAPI(sponsorId, text);
            dispatch({ type: CREATE_LABEL });
            callback(json);
        } catch (err) {
            console.log(err);
            callback(false);
        }
    };
}

export function setLabel(sponsorId, id, text, callback) {
    return async dispatch => {
        try {
            await setLabelAPI(sponsorId, id, text);
            dispatch({ type: SET_LABEL });
            callback(true);
        } catch (err) {
            console.log(err);
            callback(false);
        }
    };
}

export function setLabelOfPage(sponsorId, pageId, status, callback) {
    return async dispatch => {
        try {
            await setLabelOfPageAPI(sponsorId, pageId, status);
            dispatch({ type: SET_LABEL });
            callback(true);
        } catch (err) {
            console.log(err);
            callback(false);
        }
    };
}

export function deleteLabelOfPage(sponsorId, pageId, labelId, callback) {
    return async dispatch => {
        try {
            await deleteLabelOfPageAPI(sponsorId, pageId, labelId);
            dispatch({ type: SET_LABEL });
            callback(true);
        } catch (err) {
            console.log(err);
            callback(false);
        }
    };
}

export function deleteLabel(sponsorId, id, callback) {
    return async dispatch => {
        try {
            await deleteLabelAPI(sponsorId, id);
            dispatch({ type: DELETE_LABEL });
            callback(true);
        } catch (err) {
            console.log(err);
            callback(false);
        }
    };
}
