export const ADD_NOTIFY = 'ADD_NOTIFY';
export const CLOSE_NOTIFY = 'CLOSE_NOTIFY';
export const REMOVE_NOTIFY = 'REMOVE_NOTIFY';
const SUCCESS = 'notify/SUCCESS';
const FAILED = 'notify/FAILED';
const WARNING = 'notify/WARNING';
const INFO = 'notify/INFO';

const defaultState = {
    notifications: []
};

export default (state = defaultState, action) => {
    switch (action.type) {
        case ADD_NOTIFY:
        case SUCCESS:
        case FAILED:
        case WARNING:
        case INFO: {
            const { notification } = action.payload;
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        ...notification
                    }
                ]
            };
        }
        case CLOSE_NOTIFY: {
            const { key } = action.payload;
            return {
                ...state,
                notifications: state.notifications.map(notification =>
                    notification.key === key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                )
            };
        }
        case REMOVE_NOTIFY: {
            const { key } = action.payload;
            return {
                ...state,
                notifications: state.notifications.filter(notification => notification.key !== key)
            };
        }
        default:
            return state;
    }
};

export const addNotify = ({ message, mode }) => ({
    type: ADD_NOTIFY,
    payload: {
        notification: {
            key: new Date().getTime() + Math.random(),
            message,
            options: {
                variant: mode
            }
        }
    }
});

export const closeNotify = key => ({
    type: CLOSE_NOTIFY,
    payload: {
        key
    }
});

export const removeNotify = key => ({
    type: REMOVE_NOTIFY,
    payload: {
        key
    }
});

export function setFailed(text) {
    return {
        type: FAILED,
        payload: {
            ...createMessageUniqueKey(text, 'error')
        }
    };
}

export function setSuccess(text) {
    return {
        type: SUCCESS,
        payload: {
            ...createMessageUniqueKey(text, 'success')
        }
    };
}

export function setWarning(text) {
    return {
        type: WARNING,
        payload: {
            ...createMessageUniqueKey(text, 'warning')
        }
    };
}

export function setInfo(text) {
    return {
        type: INFO,
        payload: {
            ...createMessageUniqueKey(text, 'info')
        }
    };
}

function createMessageUniqueKey(message, mode) {
    return {
        notification: {
            key: new Date().getTime() + Math.random(),
            message,
            options: {
                variant: mode
            }
        }
    };
}
