import i18next from 'i18next';
import { auth } from 'features/login/utils/firebaseManager';
import getNavigatorLangForAdmin from 'utils/getNavigatorLangForAdmin';

const UPDATE_I18N = 'i18n/update';

const defaultState = {
    locale: getNavigatorLangForAdmin()
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case UPDATE_I18N:
            return { ...state, locale: action.locale };
        default:
            return state;
    }
}

export function switchLocale(locale = defaultState.locale) {
    return async dispatch => {
        await new Promise(res => i18next.changeLanguage(locale, () => res()));
        auth.languageCode = locale;

        dispatch({
            type: UPDATE_I18N,
            locale
        });
    };
}
