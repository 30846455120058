import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import MuiDialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { css } from '@emotion/core';

import Button from 'features/common/Mui/Button';
import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';
import { system700 } from './styles/color';

const useStyles = makeStyles(theme => ({
    root: {
        '& .MuiBackdrop-root': {
            display: 'none'
        }
    },
    title: {
        padding: theme.spacing(2)
    },
    subtitle: {
        color: system700.color
    },
    content: {
        padding: props =>
            props.disableContentPadding ? '0' : `${theme.spacing(3)}px ${theme.spacing(2)}px`,
        boxSizing: 'border-box'
    },
    paperScrollPaper: {
        maxHeight: 'initial',
        borderRadius: 8,
        display: props => (props.hide ? 'none' : 'flex')
    },
    paperScrollBody: {
        borderRadius: 8
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500]
    },
    button: {
        minWidth: '90px'
    }
}));

function Dialog({
    disableBackdropClick = false,
    disableEscapeKeyDown = false,
    disableStartButton,
    disableEndButton,
    disableButtons,
    disableContentPadding,
    disableBackdrop,
    showClose = false,
    title,
    subTitle,
    onClose = () => {},
    onCancel = () => {},
    onSuccess = () => {},
    open,
    hide,
    startButton = true,
    startButtonText,
    startButtonStyle,
    startButtonVariant = 'contained',
    endButton = true,
    endButtonVariant = 'contained',
    endButtonText,
    endButtonStyle,
    endButtonLoading,
    children,
    color,
    className,
    maxWidth,
    scroll = 'body',
    dialogContentStyle,
    dialogStyle,
    leftButton = false,
    leftButtonText,
    leftButtonStyle,
    leftButtonIcon,
    leftButtonColor,
    onLeftButtonClick = () => {}
}) {
    const classes = useStyles({ hide, disableContentPadding });

    const handleClose = (event, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
            return false;
        }

        if (disableEscapeKeyDown && reason === 'escapeKeyDown') {
            return false;
        }

        if (typeof onClose === 'function') {
            onClose();
        }
    };

    function handleSuccess() {
        onSuccess();
    }

    const divActionWrapper = (children, hasLeftButton) =>
        hasLeftButton ? <div css={css(divActionWrapperStyle)}>{children}</div> : children;

    return (
        <MuiDialog
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={open}
            classes={{
                root: `${disableBackdrop && classes.root} ${className}`,
                paperScrollPaper: classes.paperScrollPaper,
                paperScrollBody: classes.paperScrollBody
            }}
            maxWidth={maxWidth}
            scroll={scroll}
            css={css(dialogStyle)}
        >
            {showClose ? (
                <IconButton
                    aria-label="close"
                    classes={{ root: classes.closeButton }}
                    onClick={handleClose}
                    size="small"
                >
                    <CloseIcon />
                </IconButton>
            ) : null}
            {Boolean(title) && (
                <DialogTitle
                    disableTypography
                    id="simple-dialog-title"
                    classes={{ root: classes.title }}
                >
                    <Typography variant="h6">{title}</Typography>
                    {Boolean(subTitle) && (
                        <Typography variant="subtitle2" classes={{ root: classes.subtitle }}>
                            {subTitle}
                        </Typography>
                    )}
                </DialogTitle>
            )}
            <DialogContent classes={{ root: classes.content }} css={dialogContentStyle}>
                {children}
            </DialogContent>
            {!disableButtons && (
                <DialogActions
                    classes={{ root: classes.title }}
                    css={css(actionWrapperStyle(leftButton))}
                >
                    {leftButton && (
                        <Button
                            color={leftButtonColor}
                            onClick={onLeftButtonClick}
                            className={classes.button}
                            css={css([leftButtonDefaultStyle, leftButtonStyle])}
                            startIcon={leftButtonIcon}
                        >
                            {leftButtonText}
                        </Button>
                    )}

                    {divActionWrapper(
                        <>
                            {startButton && (
                                <Button
                                    className={classes.button}
                                    onClick={onCancel || handleClose}
                                    variant={startButtonVariant}
                                    css={startButtonStyle}
                                    disabled={disableStartButton}
                                >
                                    {startButtonText}
                                </Button>
                            )}
                            {endButton && (
                                <ButtonWithLoadingEffect
                                    className={classes.button}
                                    variant={endButtonVariant}
                                    color={color}
                                    onClick={handleSuccess}
                                    disabled={disableEndButton}
                                    css={endButtonStyle}
                                    isLoading={endButtonLoading}
                                >
                                    {endButtonText}
                                </ButtonWithLoadingEffect>
                            )}
                        </>,
                        leftButton
                    )}
                </DialogActions>
            )}
        </MuiDialog>
    );
}

const leftButtonDefaultStyle = css`
    padding: 0px;

    .MuiSvgIcon-root {
        font-size: 18px;
    }
`;

const actionWrapperStyle = leftButton => css`
    ${leftButton &&
    `&.MuiDialogActions-root{
        display:flex; 
        align-items:center; 
        justify-content:space-between;
    }`}
`;

const divActionWrapperStyle = css`
    display: flex;
`;

Dialog.propTypes = {
    title: PropTypes.node,
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onSuccess: PropTypes.func,
    open: PropTypes.bool,
    hide: PropTypes.bool,
    children: PropTypes.any,
    disableBackdropClick: PropTypes.bool,
    disableEscapeKeyDown: PropTypes.bool,
    disableEndButton: PropTypes.bool,
    disableButtons: PropTypes.bool,
    disableContentPadding: PropTypes.bool,
    startButton: PropTypes.bool,
    startButtonText: PropTypes.string,
    startButtonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    startButtonVariant: PropTypes.string,
    endButton: PropTypes.bool,
    endButtonText: PropTypes.string,
    endButtonVariant: PropTypes.string,
    endButtonStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    showClose: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string,
    maxWidth: PropTypes.string,
    dialogContentStyle: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
};

export default Dialog;
