import React from 'react';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import { getFullCoverBgProps } from 'styles/common';
import { system800 } from 'features/common/Mui/styles/color';
import AvatarBlock from 'features/login/components/avatarBlock/AvatarBlock';
import { below } from 'utils/breakpoint';

export default function LayoutInner({ showHeader = true, showMenu = true, children }) {
    const { t } = useTranslation();

    return (
        <div css={containerStyle}>
            {showHeader && (
                <div css={headerWrapperStyle(showMenu)}>
                    <img
                        src="https://assets.fevercdn.com/nc-admin/icons/fever_logo.svg"
                        alt="feversocial"
                        css={brandLogoStyle}
                    />
                    {showMenu && (
                        <div css={tabWrapperStyle}>
                            <a
                                href="https://info.feversocial.com/tw/products-358"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('promo_header_tools')}
                            </a>
                            <a
                                href="https://info.feversocial.com/tw/campaign-manager-6"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {t('promo_header_modules')}
                            </a>
                            <AvatarBlock />
                        </div>
                    )}
                </div>
            )}
            <div css={contentWrapperStyle}>
                <div>{children}</div>
            </div>
        </div>
    );
}

const containerStyle = css`
    height: 100%;
    display: flex;
    flex-direction: column;
    ${getFullCoverBgProps({
        imgUrl: 'https://assets.fevercdn.com/nc-admin/images/admin_inner.jpg'
    })}
`;

const headerWrapperStyle = showMenu => css`
    flex: 0 0 64px;
    width: 100%;
    height: 64px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 16px;

    ${below('xsm')} {
        ${showMenu
            ? ''
            : `
        max-width: 100%;
        height: 84px;
        padding: 24px 16px;
        justify-content: center;
        `}
    }
`;

const contentWrapperStyle = css`
    flex: 1 1 100%;
    overflow: auto;
    > div {
        height: 100%;
        min-height: 700px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

const brandLogoStyle = css`
    height: 36px;
`;

const tabWrapperStyle = css`
    height: 100%;
    display: flex;
    align-items: center;
    > a {
        margin: 0 20px;
        font-size: 14px;
        color: ${system800.color};
    }
`;
