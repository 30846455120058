import React from 'react';
import { css } from '@emotion/core';
import PropTypes from 'prop-types';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from 'features/common/Mui/Button';
import { absolutePositionCenterStyle } from 'styles/common';

export default function ButtonWithLoadingEffect({ isLoading, children, disabled, ...restProps }) {
    return (
        <Button
            css={css({ position: 'relative' })}
            color="primary"
            disabled={disabled || isLoading}
            {...restProps}
        >
            {isLoading && <CircularProgress css={css(absolutePositionCenterStyle)} size={24} />}
            {children}
        </Button>
    );
}

ButtonWithLoadingEffect.propTypes = {
    ...Button.propTypes,
    isLoading: PropTypes.bool,
    children: PropTypes.node
};
