export const META = 'META';
export const SHOPLINE = 'SHOPLINE';
export const LINE_NOTIFY = 'LINE_NOTIFY';
export const LIFF_LOGIN = 'LIFF_LOGIN';

export const authorizePlatformMapping = {
    meta: {
        name: META,
        path: '/utils/authorize/meta'
    },
    shopline: {
        name: SHOPLINE,
        path: '/utils/authorize/shopline'
    },
    line: {
        name: LINE_NOTIFY,
        path: '/utils/authorize/line'
    },
    liff: {
        name: LIFF_LOGIN,
        path: '/utils/authorize/liff'
    }
};
