export const langTypes = {
    ZH_TW: 'zh-TW',
    ZH_CN: 'zh-CN',
    ZH_HK: 'zh-HK',
    EN_US: 'en-US',
    JA_JP: 'ja',
    PT_BR: 'pt-BR',
    ES_419: 'es-419'
};

export const facebookSdkLangsMapping = {
    [langTypes.ZH_TW]: 'zh_TW',
    [langTypes.ZH_CN]: 'zh_CN',
    [langTypes.ZH_HK]: 'zh_HK',
    [langTypes.EN_US]: 'en_US',
    [langTypes.JA_JP]: 'ja_JP',
    [langTypes.PT_BR]: 'pt_BR',
    [langTypes.ES_419]: 'es_419'
};

export const sponsorLangOptions = {
    [langTypes.ZH_TW]: '繁體中文',
    [langTypes.ZH_CN]: '简体中文',
    [langTypes.EN_US]: 'English',
    [langTypes.JA_JP]: '日本語',
    [langTypes.PT_BR]: 'português',
    [langTypes.ES_419]: 'Española (latinoamericana)'
};

export const adminLangOptions = {
    [langTypes.ZH_TW]: '繁體中文',
    [langTypes.EN_US]: 'English'
};
