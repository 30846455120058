import { combineReducers } from 'redux';

import app from 'store/module/app';
import pages from 'store/module/pages';
import forms from 'store/module/forms';
import promos from 'store/module/promos';
import promo from 'store/module/promo';
import formReport from 'store/module/formReport';
import webhook from 'store/module/webhook';
import voteManage from 'store/module/voteManage';
import i18n from 'store/module/i18n';
import flag from 'store/module/flag';
import flags from 'store/module/flags';
import notify from 'store/module/notify';
import partner from 'store/module/partner';
import sponsor from 'store/module/sponsor';
import sponsors from 'store/module/sponsors';
import account from 'store/module/account';
import firebase from 'store/module/firebase';
import availablePlanItems from 'store/module/availablePlanItems';
import pointSystem from 'store/module/pointSystem';
import mgmRelation from 'store/module/mgmRelation';
import campaigns from 'store/module/campaigns';
import campaignOnsite from 'store/module/campaignOnsite';
import message from 'store/module/message';

export const baseReducers = {
    app,
    pages,
    forms,
    promos,
    promo,
    formReport,
    voteManage,
    webhook,
    partner,
    sponsor,
    sponsors,
    account,
    availablePlanItems,
    i18n,
    flag,
    flags,
    notify,
    firebase,
    campaigns,
    campaignOnsite,
    message,
    pointSystem,
    mgmRelation
};

const createRootReducer = asyncReducers => {
    return combineReducers({
        ...baseReducers,
        // Dynamic reducers
        ...asyncReducers
    });
};

export default createRootReducer;
