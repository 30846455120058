import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { loginSignUpErrorTypes } from 'store/module/firebase';

import { paths } from 'features/login/hooks/useInitAndRedirectApp';

import LayoutInner from 'features/login/components/LayoutInner';
import BlockInfoAndConfirmRedirect from 'features/login/components/BlockInfoAndConfirmRedirect';

export default function PageError() {
    const { t } = useTranslation();
    const { state: { fromPath } = {} } = useLocation();
    const { loginSignUpErrorType } = useSelector(s => s.firebase);

    const content = (() => {
        if (loginSignUpErrorType === loginSignUpErrorTypes.LOGIN_EMAIL_EXIST) {
            return (
                <BlockInfoAndConfirmRedirect
                    title={t('label.login_failed.already_exist')}
                    desc={t('desc.login_failed.already_exist')}
                    btnText={t('btn.back_to_login')}
                    redirectPath={paths.LOGIN}
                />
            );
        }
        if (loginSignUpErrorType === loginSignUpErrorTypes.INVALID_EMAIL_DOMAIN) {
            const path = fromPath ?? paths.LOGIN;
            const btnText = t(btnTextMappings[path]);
            return (
                <BlockInfoAndConfirmRedirect
                    title={t('label.login_failed.invalid_domain')}
                    desc={t('desc.login_failed.invalid_domain')}
                    btnText={btnText}
                    redirectPath={path}
                />
            );
        }
        if (loginSignUpErrorType === loginSignUpErrorTypes.INVALID_ACTION_CODE) {
            const path = fromPath ?? paths.LOGIN;
            const btnText = t(btnTextMappings[path]);
            return (
                <BlockInfoAndConfirmRedirect
                    title={t('label.login_failed.invalid_action_code')}
                    desc={t('desc.login_failed.invalid_action_code')}
                    btnText={btnText}
                    redirectPath={path}
                />
            );
        }
        return null;
    })();
    return <LayoutInner>{content}</LayoutInner>;
}

const btnTextMappings = {
    [paths.EMAIL_VERIFY]: 'btn.back_to_email_verify',
    [paths.LOGIN]: 'btn.back_to_login'
};
