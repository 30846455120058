import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { css } from '@emotion/core';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import { formatNumberWithSign } from 'utils/currency';
import { loginMethod } from 'utils/login';
import { getPointsColor } from 'features/point/styles';
import FeverTable from './FeverTable';
import i18next from 'i18next';
import { system900 } from 'features/common/Mui/styles/color';

/**
 * @method SearchEntryTable - 搜尋流水號列表
 * @param {Object}   props
 * @param {String}   props.pointPoolUuid         - 點數池 Uuid
 * @param {Boolean}  props.isFetching            - 是否讀取中
 * @param {Function} props.closePopover          - 關閉 Popover 事件
 * @param {Object[]} props.data                  - 列表資料
 * @param {String}   props.data[].entryId        - Entry Id ( 流水號 )
 * @param {String}   props.data[].entryUuid      - Entry Uuid
 * @param {Number}   props.data[].loginMethod    - 登入方式 （ 0:匿名 , 1:FB , 2:LINE , 3:企業登入 , 4. 驗證碼登入 , 5. IG )
 * @param {String}   props.data[].memberId       - 會員 Id
 * @param {String}   props.data[].memberName     - 會員名稱
 * @param {String}   props.data[].memberUuid     - 會員 Uuid
 * @param {String}   props.data[].points         - 剩餘點數
 * @returns {JSX.Element}
 */
const SearchEntryTable = ({
    pointPoolUuid,
    isFetching,
    closePopover = () => {},
    data = [],
    isSponsorUseGlobalPoints = false
}) => {
    const history = useHistory();
    const location = useLocation();
    const tableConfig = [
        {
            key: 'entryId',
            style: { width: 120, paddingLeft: 24, fontSize: 12, fontWeight: 400 },
            headerCell: () => i18next.t('label.serial_number'),
            bodyStyle: { paddingLeft: 24 },
            bodyCell: data => data.entryId
        },
        {
            key: 'memberName',
            style: { width: 160, fontSize: 12, fontWeight: 400 },
            headerCell: () => i18next.t('label.member.name'),
            bodyCell: data => data.memberName
        },
        {
            key: 'points',
            style: { width: 120, fontSize: 12, fontWeight: 400 },
            headerCell: () => i18next.t('label.remaining_points'),
            bodyCell: data => (
                <Typography variant="subtitle1" style={{ color: getPointsColor(data?.points) }}>
                    {formatNumberWithSign(data?.points)}
                </Typography>
            )
        },
        {
            key: 'action',
            headerCell: () => '',
            bodyCell: data =>
                !isSponsorUseGlobalPoints ? (
                    <div css={btnGroupsStyle}>
                        <IconButton
                            component="span"
                            className="icon-button-style"
                            css={iconStyle}
                            onClick={() => {
                                closePopover();
                                history.push({
                                    ...location,
                                    pathname: `/admin/point/${pointPoolUuid}/member/${
                                        data.memberUuid
                                    }/${loginMethod[data.loginMethod]}`
                                });
                            }}
                        >
                            <AccountBoxIcon />
                        </IconButton>
                    </div>
                ) : null
        }
    ];

    return (
        <FeverTable
            tableConfig={tableConfig}
            loadingRow={1}
            list={data}
            isFetching={isFetching}
            tableContainerStyle={tableContainerStyle}
            isHideHeaderWhenNoData={true}
            tableStyle={tableStyle}
        />
    );
};

export default SearchEntryTable;

const tableContainerStyle = css`
    min-height: unset;
`;

const tableStyle = css`
    min-width: unset;
    width: 100%;

    & .MuiTableCell-root.MuiTableCell-head {
        border-bottom: none;
        padding: 15px 16px 15px 0;
        box-sizing: content-box;
    }

    & .MuiTableCell-root.MuiTableCell-body {
        border-bottom: none;
        padding: 18px 16px 18px 0;
        box-sizing: content-box;
    }
`;

const iconStyle = css`
    color: ${system900.color};

    & .MuiSvgIcon-root {
        font-size: 24px;
    }
`;

const btnGroupsStyle = css`
    display: flex;
    justify-content: flex-end;
    align-items: center;

    & .icon-button-style.MuiIconButton-root {
        margin: 0 16px 0 0;
        &:hover {
            background-color: transparent;
        }
    }

    & span.MuiIconButton-root {
        padding: 0;
    }
`;
