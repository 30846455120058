export default function generateUpdatePayload(original, target) {
    const deleted = Object.keys(original).filter(
        k => original[k] !== undefined && target[k] === undefined
    );
    return deleted.length > 0
        ? {
              ...target,
              deleted
          }
        : target;
}
