import React from 'react';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';

import {
    EVENT_ID_INDEPENDENCE_FORM_SUBMIT,
    EVENT_ID_USER_LOGIN,
    EVENT_ID_PROMOTION_JOIN,
    EVENT_ID_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_FORM_SUBMIT_AWARDEE,
    EVENT_ID_PRIZE_AWARD,
    EVENT_ID_FB_PROMOTION_JOIN,
    EVENT_ID_FB_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_FB_INDEPENDENCE_FORM_SUBMIT,
    EVENT_ID_PROMOTION_VERIFY,
    EVENT_ID_ACCU_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_ACCU_FORM_SUBMIT_AWARDEE,
    EVENT_ID_WISH_MECHANISM_VERIFY,
    EVENT_ID_INDEPENDENCE_TAG_SUBMIT,
    EVENT_ID_MAAC_TAG_SUBMIT,
    EVENT_ID_OMNICHAT_TAG_SUBMIT,
    EVENT_ID_BOT_BONNIE_TAG_SUBMIT
} from 'features/webhooks/config/eventId';
import {
    CUSTOM_WEBHOOKS,
    FACEBOOK_WEBHOOKS,
    ACCU_WEBHOOKS,
    WISH_WEBHOOKS,
    getModuleIdByEventId,
    TAG_MAAC_WEBHOOKS,
    TAG_OMNICHAT_WEBHOOKS,
    TAG_BOT_BONNIE_WEBHOOKS
} from 'features/webhooks/config/module';
import { SERVICE_PROMOTION, SERVICE_FORM, SERVICE_TAG } from 'features/webhooks/config/service';

// 定義此模組的活動有哪些 webhook 事件
export const promoEventList = {
    [CUSTOM_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.sign_in',
            Icon: <HowToRegIcon />,
            value: EVENT_ID_USER_LOGIN
        },
        {
            textId: 'webhook.edit.event.join',
            Icon: <HowToVoteIcon />,
            value: EVENT_ID_PROMOTION_JOIN
        },
        {
            textId: 'webhook.edit.event.submit_sign_up_form',
            Icon: <AssignmentIcon />,
            value: EVENT_ID_FORM_SUBMIT_SIGN_UP
        },
        {
            textId: 'webhook.edit.event.prize_record',
            Icon: <CardGiftcardIcon />,
            value: EVENT_ID_PRIZE_AWARD
        },
        {
            textId: 'webhook.edit.event.submit_redeem_form',
            Icon: <AssignmentIcon />,
            value: EVENT_ID_FORM_SUBMIT_AWARDEE
        },
        {
            textId: 'webhook.edit.event.promo_verify',
            Icon: <CheckCircleIcon />,
            value: EVENT_ID_PROMOTION_VERIFY
        }
    ],
    [FACEBOOK_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.join',
            Icon: <HowToVoteIcon />,
            value: EVENT_ID_FB_PROMOTION_JOIN
        },
        {
            textId: 'webhook.edit.event.submit_sign_up_form',
            Icon: <AssignmentIcon />,
            value: EVENT_ID_FB_FORM_SUBMIT_SIGN_UP
        }
    ],
    [ACCU_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.submit_sign_up_form',
            Icon: <AssignmentOutlinedIcon />,
            value: EVENT_ID_ACCU_FORM_SUBMIT_SIGN_UP
        },
        {
            textId: 'webhook.edit.event.submit_redeem_form',
            Icon: <AssignmentIcon />,
            value: EVENT_ID_ACCU_FORM_SUBMIT_AWARDEE
        }
    ],
    [WISH_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.mechanism_verify',
            Icon: <CheckCircleIcon />,
            value: EVENT_ID_WISH_MECHANISM_VERIFY
        }
    ],
    [TAG_MAAC_WEBHOOKS]: [],
    [TAG_OMNICHAT_WEBHOOKS]: [],
    [TAG_BOT_BONNIE_WEBHOOKS]: []
};

// 定義此模組的表單有哪些 webhook 事件
export const formEventList = {
    [CUSTOM_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.submit_form',
            Icon: <AssignmentIcon />,
            value: EVENT_ID_INDEPENDENCE_FORM_SUBMIT
        }
    ],
    [FACEBOOK_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.submit_form',
            Icon: <AssignmentIcon />,
            value: EVENT_ID_FB_INDEPENDENCE_FORM_SUBMIT
        }
    ],
    [ACCU_WEBHOOKS]: [],
    [WISH_WEBHOOKS]: [],
    [TAG_MAAC_WEBHOOKS]: [],
    [TAG_OMNICHAT_WEBHOOKS]: [],
    [TAG_BOT_BONNIE_WEBHOOKS]: []
};

// 定義此模組的表單有哪些 webhook 事件
export const tagEventList = {
    [CUSTOM_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.submit_tag',
            Icon: <LocalOfferIcon />,
            value: EVENT_ID_INDEPENDENCE_TAG_SUBMIT
        }
    ],
    [FACEBOOK_WEBHOOKS]: [],
    [ACCU_WEBHOOKS]: [],
    [WISH_WEBHOOKS]: [],
    [TAG_MAAC_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.submit_tag',
            Icon: <LocalOfferIcon />,
            value: EVENT_ID_MAAC_TAG_SUBMIT
        }
    ],
    [TAG_OMNICHAT_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.submit_tag',
            Icon: <LocalOfferIcon />,
            value: EVENT_ID_OMNICHAT_TAG_SUBMIT
        }
    ],
    [TAG_BOT_BONNIE_WEBHOOKS]: [
        {
            textId: 'webhook.edit.event.submit_tag',
            Icon: <LocalOfferIcon />,
            value: EVENT_ID_BOT_BONNIE_TAG_SUBMIT
        }
    ]
};

export function convertWebhookEvent(eventId) {
    const moduleId = getModuleIdByEventId(eventId);
    const allEventList = [
        ...formEventList[moduleId],
        ...promoEventList[moduleId],
        ...tagEventList[moduleId]
    ];
    const DEFAULT_VALUE = {
        Icon: () => {},
        textId: ''
    };
    return allEventList.find(event => event.value === eventId) ?? DEFAULT_VALUE;
}

export const availableEventIds = new Set([
    EVENT_ID_INDEPENDENCE_FORM_SUBMIT,
    EVENT_ID_PROMOTION_JOIN,
    EVENT_ID_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_FORM_SUBMIT_AWARDEE,
    EVENT_ID_PRIZE_AWARD,
    EVENT_ID_PROMOTION_VERIFY,
    EVENT_ID_FB_PROMOTION_JOIN,
    EVENT_ID_FB_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_FB_INDEPENDENCE_FORM_SUBMIT,
    EVENT_ID_ACCU_FORM_SUBMIT_SIGN_UP,
    EVENT_ID_ACCU_FORM_SUBMIT_AWARDEE,
    EVENT_ID_WISH_MECHANISM_VERIFY,
    EVENT_ID_INDEPENDENCE_TAG_SUBMIT,
    EVENT_ID_MAAC_TAG_SUBMIT,
    EVENT_ID_OMNICHAT_TAG_SUBMIT,
    EVENT_ID_BOT_BONNIE_TAG_SUBMIT
]);

export const formEventIds = moduleId =>
    new Set([...formEventList[moduleId].map(event => event.value)]);

export const promoEventIds = moduleId =>
    new Set([...promoEventList[moduleId].map(event => event.value)]);

export const tagEventIds = moduleId =>
    new Set([...tagEventList[moduleId].map(event => event.value)]);

export function getService(eventId) {
    const moduleId = getModuleIdByEventId(eventId);
    switch (true) {
        case formEventIds(moduleId).has(eventId):
            return { service: SERVICE_FORM };
        case promoEventIds(moduleId).has(eventId):
            return { service: SERVICE_PROMOTION };
        case tagEventIds(moduleId).has(eventId):
            return { service: SERVICE_TAG };
        default:
            return { service: SERVICE_FORM };
    }
}

export function getTriggerScopeTextIdByService(service) {
    const textIdMapping = {
        [SERVICE_PROMOTION]: {
            global: 'webhook.edit.trigger.promo_all',
            login: 'webhook.edit.trigger.promo_login',
            'label.facebook': 'webhook.edit.trigger.facebook_login',
            'label.line': 'webhook.edit.trigger.line_login',
            'label.enterprise': 'webhook.edit.trigger.enterprise_login',
            specific: 'webhook.edit.trigger.promo_single',
            placeholder: 'webhook.edit.target.select_form_title'
        },
        [SERVICE_FORM]: {
            global: 'webhook.edit.trigger.form_all',
            specific: 'webhook.edit.trigger.form_single',
            placeholder: 'webhook.edit.target.select_promo_title'
        },
        [SERVICE_TAG]: {
            global: 'webhook.edit.trigger.tag_all'
        }
    };

    return textIdMapping[service] ?? textIdMapping[SERVICE_FORM];
}
