import i18n from 'i18next';
import { FACEBOOK_LOGIN, LINE_LOGIN, ENTERPRISE_LOGIN } from 'config/promoLoginTypes';
import { negative, positive, reminder, error, system900 } from 'features/common/Mui/styles/color';
import { froalaKeyVisionTypeMapping } from 'features/common/KeyVision/config';
import pointSystemLogo from 'assets/svg/point_system_logo.svg';
import { bgTypes } from 'styles/generateBgStyleByType';

/**
 *
 * @namespace 點數池狀態
 * @property {number} [UNPUBLISHED=0] - 待啟用
 * @property {number} [PUBLISHED=1] - 啟用 ( 上線 )
 * @property {number} [PAUSED=2] - 停用
 * @property {number} [EXPIRED=9] - 已到期 （前端邏輯使用）
 */
export const POINT_POOL_STATUS = {
    UNPUBLISHED: 0,
    PUBLISHED: 1,
    PAUSED: 2,
    EXPIRED: 9
};

/**
 * @namespace 點數池設定 Tab
 * @property {string} [BASIC='0'] - 點數設定
 * @property {string} [THEME='1'] - 點數紀錄樣式
 * @property {string} [PLUGIN='2'] - 點數查詢條件
 * @property {string} [ALLOCATION_LINK='3'] - 配點管理中心
 */
export const POINT_POOL_SETTING = {
    BASIC: '0',
    THEME: '1',
    PLUGIN: '2',
    ALLOCATION_LINK: '3'
};

/**
 * @namespace 點數池狀態更新失敗訊息
 * @property {string} [PROMOTION_PUBLISHED='Update status failed - promotion published'] - 點數使用中
 * @property {string} [PLAN_ORDER_NOT_FOUND='Plan orders not found'] - 尚未啟用方案
 * @property {string} [HAS_TOTAL_POINT_SYSTEM_POOL_LIMIT='Total point system pool limit'] - 已達啟用上限
 */
export const UPDATE_POINT_POOL_STATUS_ERROR_MESSAGE = {
    PROMOTION_PUBLISHED: 'Update status failed - promotion published',
    PLAN_ORDER_NOT_FOUND: 'Plan orders not found',
    HAS_TOTAL_POINT_SYSTEM_POOL_LIMIT: 'Total point system pool limit'
};
/**
 * @namespace 人工調整失敗訊息
 * @property {string} [POINTS_NOT_ENOUGH='target history's points is not enough'] - 剩餘點數不足
 */
export const MANUAL_ADJUSTMENT_ERROR_MESSAGE = {
    POINTS_NOT_ENOUGH: "target history's points is not enough",
    MEMBER_POINTS_IS_NOT_ENOUGH: 'Member points is not enough'
};

export const PROMO_LOGIN_TYPE = {
    [FACEBOOK_LOGIN]: 'Facebook',
    [LINE_LOGIN]: 'Line',
    [ENTERPRISE_LOGIN]: 'Enterprise'
};

/**
 *
 * @namespace 點數池路徑
 * @property {string} [HOME_PAGE='/admin/point'] - 點數中心根目錄
 * @property {string} [CREATE_POOL_PAGE='/admin/point/create'] - 點數池建立頁
 */
export const HOME_PAGE = '/admin/point';
export const CREATE_POOL_PAGE = '/admin/point/create';

/**
 * @namespace 點數紀錄類型
 * @description 參考 https://app.clickup.com/t/866a8k3xc comment
 * @property {number} [MANUAL_ADJUSTMENT_REWARD=-4] - 人工操作 - 給點
 * @property {number} [MANUAL_ADJUSTMENT_DEDUCT=-3] - 人工操作 - 扣點
 * @property {number} [POINT_REVOKE_REWARD=-2] - 點數撤銷操作 - 給點
 * @property {number} [POINT_REVOKE_DEDUCT=-1] - 點數撤銷操作 - 扣點
 * @property {number} [POINT_EXPIRED=0] - 點數過期失效
 * @property {number} [PROMO_JOIN_DEDUCT=1] - 活動 - 參加限制扣點
 * @property {number} [PRIZE_REWARD=2] - 獎品 - 給點
 * @property {number} [PROMO_PRIZE_DEDUCT=3] - 活動 - 獎品兌換扣點
 * @property {number} [ZINES_WEB_VIEW_REWARD=4] - Zines - 網頁閱覽給點
 * @property {number} [ZINES_WEB_VIEW_DEDUCT=5] - Zines - 網頁閱覽扣點
 * @property {number} [OUTSIDE_BEHAVIOR_REWARD=6] - 外站行為 - 給點
 * @property {number} [OUTSIDE_BEHAVIOR_DEDUCT=7] - 外站行為 - 扣點
 */
export const POINT_RECORD_TYPE = {
    MANUAL_ADJUSTMENT_REWARD: -4,
    MANUAL_ADJUSTMENT_DEDUCT: -3,
    POINT_REVOKE_REWARD: -2,
    POINT_REVOKE_DEDUCT: -1,
    POINT_EXPIRED: 0,
    PROMO_JOIN_DEDUCT: 1,
    PRIZE_REWARD: 2,
    PROMO_PRIZE_DEDUCT: 3,
    ZINES_WEB_VIEW_REWARD: 4,
    ZINES_WEB_VIEW_DEDUCT: 5,
    OUTSIDE_BEHAVIOR_REWARD: 6,
    OUTSIDE_BEHAVIOR_DEDUCT: 7
};

export const POINT_RECORD_TYPE_I18N_KEY = {
    [POINT_RECORD_TYPE.MANUAL_ADJUSTMENT_REWARD]: 'label.manual_adjustment',
    [POINT_RECORD_TYPE.MANUAL_ADJUSTMENT_DEDUCT]: 'label.manual_adjustment',
    [POINT_RECORD_TYPE.POINT_EXPIRED]: 'label.point_center.points_expired'
};

export const EXTERNAL_SUBTYPE = {
    EXTERNAL_ORDER_REWARD: 1,
    EXTERNAL_ORDER_REDEEM: 2,
    EXTERNAL_DONATE: 3,
    EXTERNAL_REVOKE: 102
};

export const EXTERNAL_POINT_RECORD_TYPE_I18N_KEY = {
    [EXTERNAL_SUBTYPE.EXTERNAL_ORDER_REWARD]: 'label.external_order_reward',
    [EXTERNAL_SUBTYPE.EXTERNAL_ORDER_REDEEM]: 'label.external_order_redeem',
    [EXTERNAL_SUBTYPE.EXTERNAL_DONATE]: 'label.external_donate',
    [EXTERNAL_SUBTYPE.EXTERNAL_REVOKE]: 'label.external_revoke'
};

/**
 * @namespace 人工調整的點數紀錄類型
 * @description https://app.clickup.com/t/866a85dc5
 * @property {number} [REWARD=-4] - 人工操作 - 給點
 * @property {number} [DEDUCT=-3] - 人工操作 - 扣點
 */
export const MANUAL_ADJUSTMENT_TYPE = {
    REWARD: -4,
    DEDUCT: -3
};

/**
 * @namespace 點數紀錄操作行為
 * @description 參考 https://app.clickup.com/t/866a8k3xc comment
 * @property {number} [DEDUCT=0] - 扣點
 * @property {number} [REWARD=1] - 給點
 */
export const POINT_RECORD_ACTION = {
    DEDUCT: 0,
    REWARD: 1
};

/**
 * @namespace 可使用人工調整的紀錄類型
 * @method hasCanManualAdjustmentType
 * @param {number} recordType 紀錄類型
 * @return {boolean}
 */
const isCanManualAdjustmentTypes = new Set([POINT_RECORD_TYPE.PRIZE_REWARD]);
export const hasCanManualAdjustmentType = recordType => isCanManualAdjustmentTypes.has(recordType);

/**
 * @namespace 要隱藏人工調整按鈕的紀錄類型
 * @method hasHideManualAdjustmentButtonTypes
 * @param {number} recordType 紀錄類型
 * @return {boolean}
 */
const isHideManualAdjustmentButtonTypes = new Set([
    POINT_RECORD_TYPE.MANUAL_ADJUSTMENT_REWARD,
    POINT_RECORD_TYPE.MANUAL_ADJUSTMENT_DEDUCT
]);
export const hasHideManualAdjustmentButtonTypes = recordType =>
    isHideManualAdjustmentButtonTypes.has(recordType);

/**
 * @namespace 活動相關的紀錄類型
 * @method hasPromoPointRecord
 * @param {number} recordType 紀錄類型
 * @return {boolean}
 */
const isPromoPointRecord = new Set([
    POINT_RECORD_TYPE.PROMO_JOIN_DEDUCT,
    POINT_RECORD_TYPE.PRIZE_REWARD,
    POINT_RECORD_TYPE.PROMO_PRIZE_DEDUCT
]);
export const hasPromoPointRecord = recordType => isPromoPointRecord.has(recordType);

/**
 * @namespace 依據活動相關的紀錄類型取得點數樣式顏色
 * @method getPointsColorByAction
 * @param {number} action 紀錄類型
 * @param {object} cusColor 自訂顏色
 * @return {string}
 */
export const getPointsColorByAction = (action, cusColor = {}) => {
    return action === POINT_RECORD_ACTION.DEDUCT
        ? cusColor.negative || negative.color
        : cusColor.positive || positive.color;
};

/**
 * @namespace 依據活動相關的紀錄類型取得調整顯示數值
 * @method formatPointsWithSignByAction
 * @param {number} action 紀錄類型
 * @param {number} action 點數數量
 * @param {boolean} action 是否顯示符號
 * @return {string}
 */
export const formatPointsWithSignByAction = (action, number, prefix) => {
    if (typeof number !== 'number') return '-';
    const sign = action === POINT_RECORD_ACTION.DEDUCT ? '-' : '+';
    const formattedNumber = new Intl.NumberFormat().format(prefix ? Math.abs(number) : number);
    return prefix ? sign + formattedNumber : formattedNumber;
};

/**
 * @namespace 依據點數數量取得點數樣式顏色
 * @method getPointsColorByPoints
 * @param {number} number 點數數量
 * @param {object} cusColor 自訂顏色
 * @return {string}
 */
export const getPointsColorByPoints = (number, cusColor = {}) => {
    if (typeof number !== 'number') return '#000';
    return number > 0 ? cusColor.positive || positive.color : cusColor.negative || negative.color;
};

/**
 * 點數設定 - 主視覺
 * isShowKeyVision: 是否顯示主視覺
 * defaultMainVisionType: 背景類型 ( 1 : 自訂背景 / 2 : 預設背景 )
 * bgType: 主視覺背景類型 ( 1 : 顏色 / 2 : 圖片 / 3 : 文字＋背景  )
 * bgColor: 主視覺背景(顏色 / 文字＋背景) 之 預設背景顏色
 * customImgUrl: 自訂背景圖片 url (電腦)
 * customMobileImgUrl: 自訂背景圖片 url (手機)
 * froalaEditor: 主視覺背景(文字＋背景) 之 Froala 編輯器內容
 **/
export const defaultKeyVisionConfig = {
    isShowKeyVision: true,
    isUseCustomKeyVision: false,
    defaultMainVisionType: froalaKeyVisionTypeMapping.COLOR,
    bgType: bgTypes.FROALA,
    bgColor: '#003BBD',
    customImgUrl: '',
    customMobileImgUrl: '',
    froalaEditor: `<h2 class="section-title" style="text-align: center; padding: 24px; width:100%"> <span style="color:#FFF;"> <strong class="bold">${i18n.t(
        'label.point_center.point_record'
    )}</strong> </span> </h2>`
};

/**
 * 點數設定 - 點數樣式
 * isUseCustomImg: 自訂樣式 (boolean)
 * customImgUrl: 點數圖片 (string)
 * iconColor: 點數顏色 (string)
 **/
export const defaultPointIconStyleConfig = {
    isUseCustomImg: false,
    customImgUrl: pointSystemLogo,
    iconColor: reminder.color
};

/**
 * 點數設定 - 主題
 * textColor: 文字 (string)
 * highLightTextColor: 重點 (string)
 * bgColor: 背景 (string)
 **/
export const defaultThemeStyleConfig = {
    textColor: system900.color,
    highLightTextColor: error.color,
    bgColor: '#FFF'
};

export const defaultPointPoolInfoSetting = {
    keyVision: defaultKeyVisionConfig,
    pointIconStyle: defaultThemeStyleConfig,
    themeStyle: defaultThemeStyleConfig
};

export const POINT_POOL_VIEW_MODE = {
    ALL: '0',
    SPONSOR: '1'
};
