import React, { useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { removeNotify } from 'store/module/notify';
import Message from 'features/NotificationWrapper/Message';

function Notification() {
    const displayed = useRef([]);
    const dispatch = useDispatch();
    const { notifications = [] } = useSelector(state => state.notify);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = useCallback(
        id => {
            displayed.current = [...displayed.current, id];
        },
        [displayed]
    );

    const removeDisplayed = useCallback(
        id => {
            const removeSelected = displayed.current.filter(key => id !== key);
            displayed.current = removeSelected;
        },
        [displayed]
    );
    useEffect(() => {
        notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
            if (dismissed) {
                closeSnackbar(key);
                return;
            }
            if (displayed.current.includes(key)) return;
            enqueueSnackbar(message, {
                key,
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                },
                autoHideDuration: 3000,
                ...options,
                onClose: (event, reason, keyData) => {
                    if (options.onClose) {
                        options.onClose(event, reason, keyData);
                    }
                },
                onExited: (event, keyData) => {
                    removeDisplayed(keyData);
                    dispatch(removeNotify(keyData));
                },
                content: function MessageWrapper(keyData, messageData) {
                    return <Message id={keyData} message={messageData} variant={options.variant} />;
                }
            });
            storeDisplayed(key);
        });
    }, [
        closeSnackbar,
        enqueueSnackbar,
        notifications,
        removeDisplayed,
        storeDisplayed,
        dispatch,
        displayed
    ]);
    return null;
}

export default Notification;
