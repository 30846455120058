const UPDATE_STATE = 'firebase/UPDATE_STATE';
const UPDATE_STATE_USER = 'firebase/UPDATE_STATE_USER';

export const loginSignUpErrorTypes = {
    LOGIN_EMAIL_EXIST: 'LOGIN_EMAIL_EXIST',
    INVALID_EMAIL_DOMAIN: 'INVALID_EMAIL_DOMAIN',
    INVALID_ACTION_CODE: 'INVALID_ACTION_CODE'
};

const defaultState = {
    isLogin: false,
    currentInputEmail: '',
    verifyEmail: '',
    verifyEmailType: '',
    loginSignUpErrorType: '',
    user: {
        name: '',
        email: '',
        avatarSrc: '',
        signInMethods: []
    }
};

export default function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case UPDATE_STATE:
            return { ...state, ...payload };
        case UPDATE_STATE_USER:
            return {
                ...state,
                user: { ...state.user, ...payload.user }
            };
        default:
            return state;
    }
}

export function setIsLogin(status) {
    return {
        type: UPDATE_STATE,
        payload: { isLogin: status }
    };
}

export function setVerifyEmailInfo({ verifyEmail = '', verifyEmailType = '' } = {}) {
    return {
        type: UPDATE_STATE,
        payload: { verifyEmail, verifyEmailType }
    };
}

export function setInputEmail(email) {
    return {
        type: UPDATE_STATE,
        payload: { currentInputEmail: email }
    };
}

export function setUserInfo(user = {}) {
    return {
        type: UPDATE_STATE_USER,
        payload: { user }
    };
}

export function setLoginSignUpErrorType(type) {
    return {
        type: UPDATE_STATE,
        payload: { loginSignUpErrorType: type }
    };
}
