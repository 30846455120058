import { feverApiFetchOpts, fetches, methods } from 'api/commom';

export function createAccountApi(data) {
    return fetches(`/services/admin`, feverApiFetchOpts({ method: methods.POST, body: data }));
}

export function updateAccountApi(data) {
    return fetches(`/services/admin`, feverApiFetchOpts({ method: methods.PATCH, body: data }));
}

export function getRolesListApi() {
    return fetches(`/services/admin/roles`, feverApiFetchOpts()).then(res => {
        return res;
    });
}

export function getAccountApi() {
    return fetches(`/services/admin`, feverApiFetchOpts()).then(res => {
        return res;
    });
}

export function getIsHasOldAccountInfoApi() {
    return fetches(`/services/admin/facebook/related`, feverApiFetchOpts());
}

export function getUserAccountData() {
    return getAccountApi()
        .then(({ id, uuid, name, subscribed, settings = {} }) => ({
            ...settings,
            subscribe: subscribed,
            name,
            id,
            uuid
        }))
        .catch(err => {
            if (err.httpStatus === 404) {
                return null;
            } else {
                throw err;
            }
        });
}

// 因修改信箱後，要去更新 admin-setting 與 member 表的 Email
export function updateAccountEmail({ sponsorId, adminUuid, body }) {
    return fetches(
        `/services/sponsors/${sponsorId}/admins/${adminUuid}/emails`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body
        })
    );
}
