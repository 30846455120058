import {
    UPDATE_CAMPAIGN,
    UPDATE_SETTING_CAMPAIGN,
    REPLACE_SETTING_CAMPAIGN,
    DELETE_CAMPAIGN_ONSITE_SPEC_KEY,
    setEditing
} from 'store/module/campaignOnsite';

import {
    UPDATE_CAMPAIGN_MESSAGE,
    REPLACE_SETTING_CAMPAIGN_MESSAGE,
    UPDATE_SETTING_CAMPAIGN_MESSAGE,
    UPDATE_SETTING_CAMPAIGN_STYLE
} from 'store/module/message';

export const campaignEditingMiddleware = store => next => action => {
    const editingActions = [
        UPDATE_CAMPAIGN,
        UPDATE_SETTING_CAMPAIGN,
        REPLACE_SETTING_CAMPAIGN,
        UPDATE_CAMPAIGN_MESSAGE,
        REPLACE_SETTING_CAMPAIGN_MESSAGE,
        UPDATE_SETTING_CAMPAIGN_MESSAGE,
        UPDATE_SETTING_CAMPAIGN_STYLE,
        DELETE_CAMPAIGN_ONSITE_SPEC_KEY
    ];

    if (editingActions.includes(action.type)) {
        const currentState = store.getState();
        if (!currentState.campaignOnsite.isEditing && !currentState.campaignOnsite.isSaving) {
            store.dispatch(setEditing(true));
        }
    }

    return next(action);
};
