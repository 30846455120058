import * as errorTypes from 'config/errorTypes';

const errTypes = {
    STORY_MENTION_ALREADY_ONLINE: 'sponsor already have story-mention campaign online',
    LIVE_COMMENT_ALREADY_ONLINE: 'sponsor already have live comment campaign online',
    STORY_REPLY_DATE_OVERLAP: 'story reply period start or end date overlap',
    POST_COMMENT_DATE_OVERLAP: 'post comment period start or end date overlap',
    IG_ID_NOT_FOUND: 'sponsor instagram business account id not found',
    VERIFY_CAMPAIGN_ID: 'please verify campaign id is correct',
    SUBSCRIBE_WEBHOOK_FAIL: 'subscribe webhook fail',
    DUPLICATE_REPLY_KEYWORD_BETWEEN_CAMPAIGN: 'campaign reply keyword already exists',
    DUPLICATE_REPLY_KEYWORD_BETWEEN_PROMOTION: 'promotion reply keyword already exists'
};

const defaultErrMsg = {
    [errTypes.STORY_MENTION_ALREADY_ONLINE]: 'desc.campaign.error.story_mention.publish',
    [errTypes.LIVE_COMMENT_ALREADY_ONLINE]: 'desc.campaign.error.live_comment.publish',
    [errTypes.STORY_REPLY_DATE_OVERLAP]: 'desc.campaign.error.story_reply.publish',
    [errTypes.POST_COMMENT_DATE_OVERLAP]: 'desc.campaign.error.post_comment.publish',
    [errTypes.IG_ID_NOT_FOUND]: 'desc.campaign.error.id_notfound',
    [errTypes.VERIFY_CAMPAIGN_ID]: 'desc.campaign.error.campaign_id',
    [errTypes.SUBSCRIBE_WEBHOOK_FAIL]: 'desc.campaign.error.subscribe_webhook',
    [errTypes.DUPLICATE_REPLY_KEYWORD_BETWEEN_CAMPAIGN]:
        'desc.campaign.error.keyword_reply.publish',
    [errTypes.DUPLICATE_REPLY_KEYWORD_BETWEEN_PROMOTION]:
        'desc.campaign.error.keyword_reply.publish2',
    [errorTypes.CAMPAIGN_NOT_FOUND]: 'desc.campaign.error.campaign_not_found',
    [errorTypes.MESSAGE_NOT_FOUND]: 'desc.campaign.error.message_not_found',
    [errorTypes.PUBLISH_CAMPAIGN_DOMAIN_WHITE_LIST_FAILED]:
        'desc.campaign.error.publish_campaign_sdk_domain_white_list_failed',
    [errorTypes.PUBLISH_CAMPAIGN_TIMEZONE_VALIDATE_FAILED]:
        'desc.campaign.error.publish_campaign_setting_timezone_failed',
    [errorTypes.PUBLISH_CAMPAIGN_PLAN_VALIDATE_FAILED]:
        'desc.campaign.error.publish_campaign_plan_validate_failed'
};

export default defaultErrMsg;
