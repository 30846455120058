const flex = `display: flex;`;
const wrap = `flex-wrap: wrap;`;
const row = `flex-direction: row;`;
const col = `flex-direction: column;`;

export const rowVlefHlef = `
    align-items: flex-start;
    justify-content: flex-start;
    ${flex}${wrap}${row})`;

export const rowVcenHlef = `
    align-items: center;
    justify-content: flex-start;
    ${flex}${wrap}${row}`;

export const rowVcenHcen = `
    align-items: center;
    justify-content: center;
    ${flex}${wrap}${row}`;

export const colVcenHcen = `
    align-items: center;
    justify-content: center;
    ${flex}${wrap}${col}`;

export const colVlefHcen = `
    align-items: flex-start;
    justify-content: center;
    ${flex}${wrap}${col}`;
