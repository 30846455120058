import { initializeApp } from 'firebase/app';

const app = initializeApp(
    {
        apiKey: 'AIzaSyBoQHIyiH4g9Q7hWLO3oxjxxdohaMhPQf0',
        authDomain: 'auth.feversocial.com',
        projectId: 'feversocial-178308',
        storageBucket: 'feversocial-178308.appspot.com',
        messagingSenderId: '459719630125',
        appId: '1:459719630125:web:e06586c1c1ce6d4d9307a4',
        measurementId: 'G-77MXQYZT6C'
    },
    'fever-firebase'
);

export default app;
