import { feverApiFetchOpts, fetches } from 'api/commom';

/**
 * 取得 MGM 關聯的模組編號資料 api https://app.clickup.com/t/866apr624
 * @param   {string} sponsorId sponsorId
 * @param   {string} promoId promoId
 * @return  {promise}
 */
export function getMgmRelationByPromoApi({ sponsorId, promoId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/promotions/${promoId}/mgm/relation`,
        feverApiFetchOpts(),
        { strictErrThrow: false }
    );
}
