import i18next from 'i18next';
import ICU from 'i18next-icu';
import { initReactI18next } from 'react-i18next';

import translation from './lang.json';
import { langTypes } from 'config/langType';

const resources = Object.entries(translation).reduce((newObj, [key, value]) => {
    newObj[key] = { translation: value };
    return newObj;
}, {});

i18next
    .use(ICU)
    .use(initReactI18next)
    .init({
        resources,
        lng: langTypes.ZH_TW,
        fallbackLng: langTypes.ZH_TW,
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });
