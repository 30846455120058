import { useSelector } from 'react-redux';

export default function useCheckIsValidEmailDomain() {
    let { adminEmailDomains = [], inviteCode } = useSelector(s => s.app);
    const inviteCodeModeIgnoreDomainCheck = Boolean(inviteCode);

    return (email = '') => {
        const userEmailDomain = email.split('@')[1];
        return (
            adminEmailDomains.length === 0 ||
            userEmailDomain === 'feversocial.com' ||
            adminEmailDomains.includes(`@${userEmailDomain}`) ||
            inviteCodeModeIgnoreDomainCheck
        );
    };
}
