import { feverApiFetchOpts, fetches, methods } from 'api/commom';
import { getWebsiteAPI } from 'api/zines';
import permissionsFunctionMapping, {
    permissionsRouteMapping
} from 'config/permissionsFunctionMapping';

export function getSponsorListApi() {
    return fetches(`/services/sponsors`, feverApiFetchOpts());
}

export function getUserSponsors() {
    return getSponsorListApi()
        .then(sponsors => (Array.isArray(sponsors) ? sponsors : null))
        .catch(err => {
            if (err.httpStatus === 404) {
                return null;
            } else {
                throw err;
            }
        });
}

export async function getSponsor(sponsorId) {
    try {
        const sponsorData = await getSponsorApi(sponsorId);
        const [
            sponsorSettingData,
            accountRole,
            orders = [],
            availablePlanItems,
            zinesInfo
        ] = await Promise.all([
            getSponsorSettingsApi(sponsorId),
            getAccountRoleInfo(sponsorId),
            getSponsorOrder(sponsorId),
            getSponsorAvailPlanItems(sponsorId),
            getWebsiteAPI(sponsorId)
        ]);
        const existOrder = orders.filter(order => !order.deletedAt);
        const { username, isCustomDomain, domain, bindZines } = zinesInfo;
        return {
            sponsor: {
                ...sponsorData,
                settings: { ...sponsorSettingData },
                accountRole: {
                    ...accountRole,
                    permissionsFunction: permissionsFunctionMapping(accountRole.permissions),
                    permissionsRoute: permissionsRouteMapping(accountRole.permissions)
                },
                plans: existOrder,
                zines: {
                    username,
                    isCustomDomain,
                    domain,
                    bindZines
                }
            },
            availablePlanItems
        };
    } catch (err) {
        // 你沒有被加入到你要訪問的 sponsor，所以沒有權限
        if (err.httpStatus === 403) {
            return 403;
        }

        if (err.httpStatus >= 400) {
            return null;
        } else {
            throw err;
        }
    }
}

export function getSponsorApi(sponsorId) {
    return fetches(`/services/sponsors/${sponsorId}`, feverApiFetchOpts());
}

export function getSponsorSettingsApi(sponsorId) {
    return fetches(`/services/sponsors/${sponsorId}/settings`, feverApiFetchOpts());
}

export function createSponsorApi({ name }) {
    return fetches(
        `/services/sponsors`,
        feverApiFetchOpts({ method: methods.POST, body: { name } })
    );
}

export function createSponsorByFansPageApi({ sponsorId = '', facebookFanPageId, name }) {
    return fetches(
        `/services/sponsors/fans`,
        feverApiFetchOpts({
            method: methods.POST,
            body: { facebookFanPageId, name, sponsorId, facebookFanPageName: name }
        })
    );
}

export function getSponsorOrder(sponsorId) {
    return fetches(`/services/sponsors/${sponsorId}/plans/orders`, feverApiFetchOpts());
}

export function updateSponsorApi(sponsorId, data) {
    return fetches(
        `/services/sponsors/${sponsorId}`,
        feverApiFetchOpts({ method: methods.PATCH, body: data })
    );
}

export function updateSponsorSettingsApi(sponsorId, data) {
    return fetches(
        `/services/sponsors/${sponsorId}/settings`,
        feverApiFetchOpts({ method: methods.PATCH, body: data })
    );
}

export function getAccountRoleInfo(sponsorId) {
    return fetches(`/services/sponsors/${sponsorId}/member/role`, feverApiFetchOpts());
}

export function createInviteCodeApi(sponsorId, roleUuid) {
    return fetches(
        `/services/sponsors/${sponsorId}/admins/roles/${roleUuid}/invitation`,
        feverApiFetchOpts({ method: methods.POST })
    );
}

export function sendInviteEmailApi(sponsorId, roleUuid, data) {
    return fetches(
        `/services/sponsors/${sponsorId}/admins/roles/${roleUuid}/invitation`,
        feverApiFetchOpts({ method: methods.POST, body: data })
    );
}

export function validateInviteCodeApi(invitationUuid = '') {
    return fetches(
        `/services/admin/invitation/verification?invitationUuid=${invitationUuid}`,
        feverApiFetchOpts()
    );
}

export function bindInviteCodeApi(invitationUuid) {
    return fetches(
        `/services/sponsors/invitation`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: { invitationUuid }
        })
    );
}

export function getAdminsApi(sponsorId, page = 1, itemsNum = 15) {
    return fetches(
        `/services/sponsors/${sponsorId}/admins?perPage=${itemsNum}&page=${page}`,
        feverApiFetchOpts()
    );
}

export function updateAdminRoleApi(sponsorId, adminUuid, roleUuid) {
    return fetches(
        `/services/sponsors/${sponsorId}/admins/${adminUuid}/roles/${roleUuid}`,
        feverApiFetchOpts({ method: methods.PATCH })
    );
}

export function deleteAdminApi(sponsorId, adminUuid) {
    return fetches(
        `/services/sponsors/${sponsorId}/admins/${adminUuid}`,
        feverApiFetchOpts({ method: methods.DELETE })
    );
}

export function getSecretKey(sponsorId) {
    return fetches(`/services/sponsors/${sponsorId}/secret-key`, feverApiFetchOpts());
}

export function createSecretKey(sponsorId) {
    return fetches(
        `/services/sponsors/${sponsorId}/secret-key`,
        feverApiFetchOpts({ method: methods.PATCH })
    );
}

export function getSponsorAvailPlanItems(sponsorId) {
    return fetches(`/services/sponsors/${sponsorId}/plans/orders/items`, feverApiFetchOpts());
}

export function syncUserOldFbRelatedSponsor() {
    return fetches(`/services/admin/facebook/related`, feverApiFetchOpts({ method: methods.POST }));
}

// NOTE: 當在 partner site 開授權視窗且打的 API 時，授權視窗的 fever-host 這個 header 需要是開啟授權視窗網站的 header
export function getFacebookFanPage({ sponsorId, shortLiveFacebookUserToken }) {
    return fetches(
        `/services/sponsors/${sponsorId}/instagram-token/store-long-live-user-token`,
        feverApiFetchOpts({
            method: methods.POST,
            body: { userToken: shortLiveFacebookUserToken }
        })
    );
}

// NOTE: 用 page id 請後端去跟 FB 取 Page Token 並存在這個 sponsor
export function storeFacebookFanPageToken(sponsorId, fanPageId) {
    return fetches(
        `/services/sponsors/${sponsorId}/instagram-token/store-long-live-page-token`,
        feverApiFetchOpts({
            method: methods.POST,
            body: { pageId: fanPageId }
        })
    );
}

export function subscribeIgWebhook({ sponsorId, campaignId, subscribeMode }) {
    const formData = new FormData();
    formData.append('campaignId', campaignId);
    formData.append('subscribe-mode', subscribeMode);
    return fetches(
        `/services/sponsors/${sponsorId}/ig-webhook/subscription`,
        feverApiFetchOpts({
            method: methods.POST,
            formData
        })
    );
}

export function retryHookdeck({ sponsorId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/ig-webhook/retry`,
        //  NOTE: for dev test (remove headers as localhost:3000)
        // feverApiFetchOpts({ method: methods.POST, headers: { 'Fever-Host': feverHost } })
        feverApiFetchOpts({ method: methods.POST })
    );
}

// 取得平台資訊
export function getThirdPartyPlatformInfo({ sponsorId }) {
    return fetches(
        `/services/sponsors/${sponsorId}/third-party-platforms/infos`,
        feverApiFetchOpts({ method: methods.GET })
    );
}

// 取得平台設定
export function getThirdPartyPlatformSettings({ sponsorId, platform }) {
    return fetches(
        `/services/sponsors/${sponsorId}/third-party-platforms/settings?platform=${platform}`,
        feverApiFetchOpts({ method: methods.GET })
    );
}

// 寫入平台設定
export function updateThirdPartyPlatformInfo({ sponsorId, platform, settings }) {
    return fetches(
        `/services/sponsors/${sponsorId}/third-party-platforms/settings`,
        feverApiFetchOpts({ method: methods.POST, body: { platform, settings } })
    );
}

// 寫入平台設定
export function bindingECommerceSponsor({ state }) {
    return fetches(
        `/services/ec/binding`,
        feverApiFetchOpts({ method: methods.POST, body: { state } })
    );
}

// 儲存後台授權流程的平台串接 sponsor setting
export function updateECSponsorSetting({ sponsorId, platform, state, feverHost }) {
    return fetches(
        `/services/ec/sponsors/${sponsorId}/settings/${platform}`,
        feverApiFetchOpts({ method: methods.POST, body: { state }, feverHost })
    );
}
