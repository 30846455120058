import { feverApiFetchOpts, fetches, methods } from 'api/commom';
import { objFirstLevelJSONStringify } from 'utils/objectManipulate';
import querystring from 'query-string';
import { searchTypes } from 'features/point/components/PointPoolSearchBar';

/**
 * 建立點數池
 * @param   {string} sponsorId sponsorId
 * @param   {string} name 點數池名稱
 * @return  {promise}
 */
export function createPointPoolApi({ sponsorId, name }) {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools`,
        feverApiFetchOpts({
            method: methods.POST,
            body: { name }
        })
    ).then(resp => resp);
}

/**
 * 取得點數池列表
 * @param   {string} sponsorId sponsorId
 * @param   {string} status 搜尋條件：不帶，全搜尋且不含軟刪除的資料 / 帶入 all 會全搜尋但含軟刪除的資料 / 帶入 usable 會排除停用狀態，也不含軟刪除的資料
 * @param   {string} order 排序：不帶，使用 id，由小至大 / 帶入 updated 會依據更新時間，由最新至最舊
 * @param   {string} group 分類：帶入 status 會依據狀態分類
 * @return  {promise}
 */
export const getPointPoolsApi = ({ sponsorId, status, order, group }) => {
    const qs = querystring.stringify({ status, order, group });

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools${Boolean(qs) ? `?${qs}` : ``}`,
        feverApiFetchOpts()
    );
};

/**
 * 取得點數池底下的活動列表 - https://app.clickup.com/t/866a84th4
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid  點數池 Uuid
 * @return  {promise}
 */
export const getPromosByPointPoolApi = ({
    sponsorId,
    pointPoolUuid,
    perPage,
    page: currentPage,
    specifySponsorUuid
}) => {
    const qs = querystring.stringify({
        page: currentPage,
        perPage,
        sponsorUuid: specifySponsorUuid
    });

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/promotions${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    );
};

/**
 * 取得點數池資訊
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid  點數池 Uuid
 * @return  {promise}
 */
export const getPointPoolInfoApi = (sponsorId, pointPoolUuid) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}`,
        feverApiFetchOpts()
    );
};

/**
 * 更新點數池狀態 Api
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolId  點數池 Id
 * @param   {number} status 狀態 - 未啟用:0 / 啟用:1 / 停用:2
 * @return  {promise}
 */
export const updatePointPoolStatusApi = ({ sponsorId, pointPoolUuid, status }) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/status`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: { status }
        })
    );
};

/**
 * 更新點數池 info Api - https://app.clickup.com/t/866a85v0u
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid 點數池 Uuid
 * @param   {object} body api body - { name:'點數池名稱'}
 * @return  {promise}
 */
export const updatePointPoolInfoApi = ({ sponsorId, pointPoolUuid, body }) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body
        })
    );
};

/**
 * 搜尋點數池下的流水號紀錄 Api - https://app.clickup.com/t/866a84w50
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid 點數池 Uuid
 * @param   {string} entryId 流水號
 * @return  {promise}
 */
export const getPointPoolEntryRecordApi = ({ sponsorId, pointPoolUuid, searchType, searchKey }) => {
    const getQs = () => {
        if (searchType === searchTypes.SERIAL_NUMBER) {
            return `?entryId=${searchKey}`;
        } else if (searchType === searchTypes.LINE_UID) {
            return `?memberLineId=${searchKey}`;
        }
    };
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/entries${getQs()}`,
        feverApiFetchOpts()
    );
};

/**
 * 取得點數池下指定活動的所有點數歷程 list Api - https://app.clickup.com/t/866a857nx
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid 點數池 Uuid
 * @param   {string} promoId 活動 Id
 * @param   {string} page 第幾頁
 * @param   {string} perPage 每頁幾筆
 * @return  {promise}
 */
export const getHistoriesByPromoApi = ({
    sponsorId,
    promoId,
    pointPoolUuid,
    page: currentPage,
    perPage
}) => {
    const qs = querystring.stringify({ page: currentPage, perPage });

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/promotions/${promoId}/histories${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    );
};

/**
 * 更新點數池設定 api
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid pointPoolUuid
 * @param   {string} body pointPoolSettings keyVisionSettings pointIconSettings themeSettings
 * @return  {promise}
 */
export function updatePointPoolSettings({ sponsorId, pointPoolUuid, Styles }) {
    const stringifyData = objFirstLevelJSONStringify(Styles);

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/settings`,
        feverApiFetchOpts({
            method: methods.POST,
            body: { Styles: stringifyData }
        })
    ).then(resp => resp);
}

/**
 * 取得點數池的點數統計資料 api
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid pointPoolUuid
 * @return  {promise}
 */
export function getPointPoolStatistics({ sponsorId, pointPoolUuid, allocationSponsorUuid }) {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/statistics${
            Boolean(allocationSponsorUuid)
                ? `?allocation_sponsor_uuid=${allocationSponsorUuid}`
                : ''
        }`,
        feverApiFetchOpts()
    ).then(resp => resp);
}

/**
 * 外部交易統計資料 API
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid pointPoolUuid
 * @return  {promise}
 */
export function getExternalPointStatistics({ sponsorId, pointPoolUuid }) {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/statistics/external`,
        feverApiFetchOpts()
    ).then(resp => resp);
}

/**
 * 外部交易點數歷程 API
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid pointPoolUuid
 * @return  {promise}
 */
export function getExternalPointStatisticsHistories({
    sponsorId,
    pointPoolUuid,
    page: currentPage,
    perPage,
    subtype,
    loginMethod
}) {
    const qs = querystring.stringify({ page: currentPage, perPage, loginMethod, subtype });
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/external/histories${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    ).then(resp => resp);
}

/**
 *
 * @method getStatisticsByMemberApi
 * @name 取得點數池下的個人點數統計資料Api
 * @description https://app.clickup.com/t/866a85cjm
 * @example  GET:`/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/statistics/members/${memberUuid}`
 * @param {{
 *   sponsorId: string,
 *   pointPoolUuid:string,
 *   memberUuid:string,
 * }}  props
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid 點數池 Uuid
 * @param   {string} memberUuid 會員 Uuid
 * @return  {promise}
 */
export function getStatisticsByMemberApi({ sponsorId, pointPoolUuid, memberUuid }) {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/statistics/members/${memberUuid}`,
        feverApiFetchOpts()
    );
}

/**
 * @method getStatisticsByPromotionsApi
 * @name 點數池下的指定活動的點數統計資料 api
 * @description https://app.clickup.com/t/866a85480
 * @example  GET:`/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/statistics/promotions/${promotionId}`
 * @param {{
 *   sponsorId: string,
 *   pointPoolUuid:string,
 *   promotionsId:string,
 * }}  props
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid 點數池 Uuid
 * @param   {string} promotionsId promotionId
 * @return  {promise}
 */
export function getStatisticsByPromotionsApi({ sponsorId, pointPoolUuid, promotionsId }) {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/statistics/promotions/${promotionsId}`,
        feverApiFetchOpts()
    );
}

/**
 *
 * @method getHistoriesByMemberApi
 * @name 取得點數池下的個人活動點數歷程清單Api
 * @description https://app.clickup.com/t/866a859mz
 * @example  GET:`/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/members/${memberUuid}/histories`
 * @param {{
 *   sponsorId: string,
 *   pointPoolUuid:string,
 *   memberUuid:string,
 * }}  props
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid 點數池 Uuid
 * @param   {string} memberUuid 會員 Uuid
 * @return  {promise}
 */
export function getHistoriesByMemberApi({ sponsorId, pointPoolUuid, memberUuid, perPage, page }) {
    const qs = querystring.stringify({ page, perPage });

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/members/${memberUuid}/histories${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    );
}

/**
 *
 * 取得點數池下的所有『個人點數紀錄』 list Api - https://app.clickup.com/t/866a85r6k
 * @param   {string} sponsorId sponsorId
 * @param   {string} pointPoolUuid 點數池 Uuid
 * @param   {string} page 第幾頁
 * @param   {string} perPage 每頁幾筆
 * @return  {promise}
 */
export const getHistoriesByMembersApi = ({
    sponsorId,
    pointPoolUuid,
    page: currentPage,
    perPage,
    loginMethod
}) => {
    const qs = querystring.stringify({ page: currentPage, perPage, loginMethod });

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/members${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    );
};

/**
 * @method createPromoPointRecordApi
 * @name 建立活動點數紀錄資料Api(人工調整)
 * @description https://app.clickup.com/t/866a85dc5
 * @example  POST:`/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/members/${memberUuid}/histories`
 * @param {{
 *   sponsorId: string,
 *   pointPoolUuid:string,
 *   memberUuid:string,
 *   requestBody:{
 *      type: -3 | -4 ,
 *      loginMethod : 1 | 2 | 3 | 4,
 *      points: number,
 *      depiction: string,
 *      timezone: string,
 *      validFrom: datetime string,
 *      validUntil: datetime string,
 *      historyUuid: string
 * }
 * }}  props
 * @param   {string} props.sponsorId sponsorId
 * @param   {string} props.pointPoolUuid 點數池 Uuid
 * @param   {string} props.memberUuid 會員 Uuid
 * @param   {string} props.requestBody request body
 * @param   {string} props.requestBody.type - 紀錄操作類型:-3 (人工扣點)| -4 (人工給點)
 * @param   {string} props.requestBody.loginMethod - 登入方式：1 (facebook) | 2 (line) | 3 (enterprise) | 4 (codeValidation) ，不允許 0 (anonymous) | 5 (instagram)
 * @param   {string} props.requestBody.points - 操作的點數數量 （需大於 0 的整數)
 * @param   {string} props.requestBody.depiction - 調整原因
 * @param   {string} props.requestBody.timezone - 時區
 * @param   {string} props.requestBody.validFrom - 生效日 ( 扣點不需要帶入 )
 * @param   {string} props.requestBody.validUntil - 到期日 ( 扣點不需要帶入 )
 * @param   {string} props.requestBody.historyUuid - 紀錄 Uuid ( 有指定才需帶入 )
 * @return  {promise}
 */
export const createPromoPointRecordApi = ({
    sponsorId,
    pointPoolUuid,
    memberUuid,
    requestBody
}) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/members/${memberUuid}/histories`,
        feverApiFetchOpts({
            method: methods.POST,
            body: requestBody
        })
    ).then(resp => resp);
};

/**
 * @method getMemberNameApi
 * @name 取得會員名稱
 * @description https://app.clickup.com/t/866ag6are
 * @example  GET:`/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/members/${memberUuid}/name?login=${loginType}`
 * @param {{
 *   sponsorId: string,
 *   pointPoolUuid:string,
 *   memberUuid:string,
 *   loginType:number
 * }} props
 * @param   {string} props.sponsorId      - sponsorId
 * @param   {string} props.pointPoolUuid  - 點數池 Uuid
 * @param   {string} props.memberUuid     - 會員 Uuid
 * @param   {string} props.loginType      - 登入方式：1 (facebook) | 2 (line) | 3 (enterprise) | 4 (codeValidation) ，不允許 0 (anonymous) | 5 (instagram)
 * @return  {promise}
 */
export const getMemberNameApi = ({ sponsorId, pointPoolUuid, memberUuid, loginType }) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/members/${memberUuid}/name?login=${loginType}`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method updateAllocationSponsorRoleApi
 * @name 更新Sponsor的全域點數池權限
 * @description https://app.clickup.com/t/86enebtqk
 * @param   {string} props.sponsorId             - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid         - 全域點數池的 Uuid
 * @param   {string} props.allocationSponsorUuid - 使用全域點數池的 Sponsor Uuid
 * @param   {array} props.permission             - 陣列，權限 ( 允許發送點數 = 1, 允許扣除點數 = 2)
 * @return  {promise}
 */
export const updateAllocationSponsorRoleApi = ({
    sponsorId,
    pointPoolUuid,
    allocationSponsorUuid,
    permission
}) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/sponsors/${allocationSponsorUuid}/roles`,
        feverApiFetchOpts({
            method: methods.POST,
            body: { permission }
        })
    ).then(resp => resp);
};

/**
 * @method getGlobalPoolRemainAllocationPointApi
 * @name 即時-取得全域點數池可配置點數數量
 * @description https://app.clickup.com/t/86eneknjd
 * @param   {string} props.sponsorId     - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid - 全域點數池的 Uuid
 * @return  {promise}
 */
export const getGlobalPoolRemainAllocationPointApi = ({ sponsorId, pointPoolUuid }) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/remainder`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method getSponsorRemainAllocationPointApi
 * @name 即時-取得Sponsor在全域點數池可使用點數數量
 * @description https://app.clickup.com/t/86eneknjq
 * @param   {string} props.sponsorId             - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid         - 全域點數池的 Uuid
 * @param   {string} props.allocationSponsorUuid - 使用全域點數池的 Sponsor Uuid
 * @return  {promise}
 */
export const getSponsorRemainAllocationPointApi = ({
    sponsorId,
    pointPoolUuid,
    allocationSponsorUuid
}) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/sponsors/${allocationSponsorUuid}/remainder`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method setGlobalPointPoolToSponsorApi
 * @name 全域點數池配置點數給Sponsor
 * @description https://app.clickup.com/t/86enebtpw
 * @param   {string} props.sponsorId             - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid         - 全域點數池的 Uuid
 * @param   {string} props.allocationSponsorUuid - 使用全域點數池的 Sponsor Uuid
 * @param   {string} props.depiction             - 備註
 * @param   {string} props.points                - 點數數量
 * @return  {promise}
 */
export const setGlobalPointPoolToSponsorApi = ({
    sponsorId,
    pointPoolUuid,
    allocationSponsorUuid,
    depiction,
    points
}) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/sponsors/${allocationSponsorUuid}/points`,
        feverApiFetchOpts({
            method: 'POST',
            body: {
                depiction,
                points
            }
        })
    ).then(resp => resp);
};

/**
 * @method addGlobalPointPoolPointApi
 * @name 全域點數池增點
 * @description https://app.clickup.com/t/86enebtpd
 * @param   {string} props.sponsorId     - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid - 全域點數池的 Uuid
 * @param   {string} props.points        - 要增加點數的數量
 * @param   {string} props.value         - 點數價值
 * @param   {string} props.source        - 點數來源
 * @param   {string} props.depiction     - 備註
 * @return  {promise}
 */
export const addGlobalPointPoolPointApi = ({
    sponsorId,
    pointPoolUuid,
    points,
    value,
    source,
    depiction
}) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation`,
        feverApiFetchOpts({ method: 'POST', body: { points, value, source, depiction } })
    ).then(resp => resp);
};

/**
 * @method getGlobalPointPoolStatisticsApi
 * @name 給予全域點數池統計資料
 * @description https://app.clickup.com/t/86enebtnn
 * @param   {string} props.sponsorId     - 全域點數池的 sponsorId
 * @param   {string} props.pointPoolUuid - 全域點數池 uuid
 * @return  {promise}
 */
export const getGlobalPointPoolStatisticsApi = ({ sponsorId, pointPoolUuid }) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/statistics/allocation`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method getSponsorGlobalPointPoolStatisticsApi
 * @name 取得Sponsor在全域點數池統計資料
 * @description https://app.clickup.com/t/86enebtq4
 * @param   {string} props.sponsorId             - 全域點數池的 Sponsor Id
 * @param   {string} props.sponsorUuid             - 全域點數池的 Sponsor Uuid
 * @param   {string} props.pointPoolUuid         - 全域點數池的 Uuid
 * @param   {string} props.allocationSponsorUuid - 使用全域點數池的 Sponsor Uuid
 * @return  {promise}
 */
export const getSponsorGlobalPointPoolStatisticsApi = ({
    sponsorId,
    sponsorUuid,
    pointPoolUuid,
    allocationSponsorUuid
}) => {
    return fetches(
        `/services/points/sponsors/${
            sponsorId || sponsorUuid
        }/pools/${pointPoolUuid}/statistics/allocation/sponsors/${allocationSponsorUuid}`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method getAllocationSponsorListApi
 * @name 取得配點管理系統的Sponsor列表
 * @description https://app.clickup.com/t/86enebtnt
 * @param   {string} props.sponsorId     - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid - 全域點數池的 Uuid
 * @return  {promise}
 */
export const getAllocationSponsorListApi = ({
    sponsorId,
    pointPoolUuid,
    perPage = 10,
    page = 1,
    search,
    searchColumn
}) => {
    const qs = querystring.stringify({
        perPage,
        page,
        search,
        searchColumn
    });

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/sponsors${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method cancelSponsorAllocationPointsApi
 * @name 取消配置點數給Sponsor
 * @description https://app.clickup.com/t/86enebtqe
 * @param   {string} props.sponsorId                             - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid                         - 全域點數池的 Uuid
 * @param   {string} props.allocationSponsorUuid                 - 使用全域點數池的 Sponsor Uuid
 * @param   {string} props.pointSystemPoolAllocationHistoryUuid - 被取消配置的點數紀錄 Uuid
 * @param   {string} props.depiction                             - 備註
 * @return  {promise}
 */
export const cancelSponsorAllocationPointsApi = ({
    sponsorId,
    pointPoolUuid,
    allocationSponsorUuid,
    pointSystemPoolAllocationHistoryUuid,
    depiction
}) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/sponsors/${allocationSponsorUuid}/points/${pointSystemPoolAllocationHistoryUuid}`,
        feverApiFetchOpts({ method: methods.PATCH, body: { depiction } })
    ).then(resp => resp);
};

/**
 * @method cancelGlobalAdditionPointsApi
 * @name 取消新增全域點數池點數
 * @description https://app.clickup.com/t/86enebtpm
 * @param   {string} props.sponsorId                            - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid                        - 全域點數池的 Uuid
 * @param   {string} props.pointSystemPoolAllocationHistoryUuid - 要取消新增的全域點數紀錄
 * @param   {string} props.depiction                            - 備註
 * @return  {promise}
 */
export const cancelGlobalAdditionPointsApi = ({
    sponsorId,
    pointPoolUuid,
    pointSystemPoolAllocationHistoryUuid,
    depiction
}) => {
    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/${pointSystemPoolAllocationHistoryUuid}`,
        feverApiFetchOpts({ method: methods.PATCH, body: { depiction } })
    ).then(resp => resp);
};

/**
 * @method getSponsorAllocationWithGlobalPointPoolApi
 * @name 取得Sponsor在全域點數池配點紀錄列表
 * @description https://app.clickup.com/t/86enebtq0
 * @param   {string} props.sponsorId             - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid         - 全域點數池的 Uuid
 * @param   {string} props.allocationSponsorUuid - 可選，使用全域點數池的 Sponsor Uuid
 * @param   {string} props.perPage               - 每頁幾筆
 * @param   {string} props.page                  - 頁數
 * @param   {number} props.type                  - 點數配點紀錄類型 ( 個人捐點 - 1 / 配置點數 - 3)
 * @return  {promise}
 */
export const getSponsorAllocationWithGlobalPointPoolApi = ({
    sponsorId,
    pointPoolUuid,
    allocationSponsorUuid,
    perPage = 10,
    page = 1,
    type
}) => {
    const qs = querystring.stringify(
        {
            page,
            perPage,
            type,
            ...(Boolean(allocationSponsorUuid) ? { allocationSponsorUuid } : {})
        },
        { arrayFormat: 'bracket' }
    );

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/sponsors/histories?${qs}`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method cancelGlobalAdditionPointsApi
 * @name 全域點數池點數異動紀錄列表
 * @description https://app.clickup.com/t/86enebtp8
 * @param   {string} props.sponsorId                            - 全域點數池的 Sponsor Id
 * @param   {string} props.pointPoolUuid                        - 全域點數池的 Uuid
 * @param   {string} page                                       - 第幾頁
 * @param   {string} perPage                                    - 每頁幾筆
 * @param   {string} type                                       - 點數新增紀錄type
 * @return  {promise}
 */
export const getAllocationHistoryApi = ({
    sponsorId,
    pointPoolUuid,
    perPage = 10,
    page = 1,
    type
}) => {
    const qs = querystring.stringify(
        {
            page,
            perPage,
            type
        },
        { arrayFormat: 'bracket' }
    );

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/histories${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method getAllocationSponsorDonateListApi
 * @name 取得全域點數池sponsor配點紀錄的捐點列表
 * @description https://app.clickup.com/t/86entpj48
 * @param   {string} sponsorId      - 全域點數池的 Sponsor Id
 * @param   {string} pointPoolUuid  - 全域點數池的 Uuid
 * @param   {string} page           - 第幾頁
 * @param   {string} perPage        - 每頁幾筆
 * @param   {string} loginType      - 登入方式
 * @return  {promise}
 */
export const getAllocationSponsorDonateListApi = ({
    sponsorId,
    pointPoolUuid,
    perPage = 10,
    page = 1,
    loginType
}) => {
    const qs = querystring.stringify({
        page,
        perPage,
        loginType
    });

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/sponsors/donations${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    ).then(resp => resp);
};

/**
 * @method getAllocationDonateListApi
 * @name 取得全域點數池配點紀錄的捐點列表
 * @description https://app.clickup.com/t/86entpj48
 * @param   {string} sponsorId      - 全域點數池的 Sponsor Id
 * @param   {string} pointPoolUuid  - 全域點數池的 Uuid
 * @param   {string} page           - 第幾頁
 * @param   {string} perPage        - 每頁幾筆
 * @param   {string} loginType      - 登入方式
 * @return  {promise}
 */
export const getAllocationDonateListApi = ({
    sponsorId,
    pointPoolUuid,
    perPage = 10,
    page = 1,
    loginType
}) => {
    const qs = querystring.stringify({
        page,
        perPage,
        loginType
    });

    return fetches(
        `/services/points/sponsors/${sponsorId}/pools/${pointPoolUuid}/allocation/donations${
            Boolean(qs) ? `?${qs}` : ``
        }`,
        feverApiFetchOpts()
    ).then(resp => resp);
};
