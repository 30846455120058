export const functional = {
    color: '#0071ff'
};
export const success = {
    color: '#16cb4c'
};
export const reminder = {
    color: '#ffce00'
};
export const error100 = {
    color: 'rgba(255, 53, 17, 0.16)'
};
export const error = {
    color: '#ff3511'
};

export const positive = {
    color: '#00B235'
};

export const negative = error;

export const system900 = {
    color: '#333333'
};
export const system800 = {
    color: '#484848'
};
export const system700 = {
    color: '#666666'
};
export const system600 = {
    color: '#818181'
};
export const system500 = {
    color: '#9b9b9b'
};
export const system400 = {
    color: '#b6b6b6'
};
export const system300 = {
    color: '#cccccc'
};
export const system200 = {
    color: '#dbdbdb'
};
export const system100 = {
    color: '#f5f5f5'
};
export const system050 = {
    color: '#F8F8F8'
};

export const fever700 = {
    color: '#9d0000'
};
export const fever500 = {
    color: '#d52222'
};
export const fever300 = {
    color: '#ff9077'
};
export const fever100 = {
    color: '#ffe8dd'
};

export const form700 = {
    color: '#007c33'
};
export const form500 = {
    color: '#00c033'
};
export const form300 = {
    color: '#66e166'
};
export const form100 = {
    color: '#dcffcc'
};

export const form = {
    color: '#01A82D'
};

export const zines700 = {
    color: '#cc4900'
};
export const zines500 = {
    color: '#ff9900'
};
export const zines300 = {
    color: '#ffce55'
};
export const zines100 = {
    color: '#fff3cc'
};

export const zines = {
    color: '#F1670E'
};

export const promo700 = {
    color: '#0053bb'
};
export const promo500 = {
    color: '#0092ff'
};
export const promo300 = {
    color: '#66ccff'
};
export const promo100 = {
    color: '#ccf4ff'
};

export const promo = {
    color: '#4A8BF6'
};

export const common = {
    color: '#757575'
};

export const share = {
    color: promo500.color
};

export const mission = {
    color: zines500.color
};

export const white = {
    color: '#FFFFFF'
};

export const black = {
    color: '#000000'
};

export const zines_dialog_title = {
    color: '#d52027'
};

export const zines_dialog_footer = {
    color: '#f1f1f1'
};
