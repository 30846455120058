import { getEntriesList } from 'api/promos';
import { setFailed } from 'store/module/notify';
import { promotionTypes } from 'config/promoFormType';

const UPDATE_COLLECTIONS = 'voteManage/UPDATE_COLLECTIONS';
const CLEAR_COLLECTIONS = 'voteManage/CLEAR_COLLECTIONS';
const UPDATE_FLAGS = 'voteManage/UPDATE_FLAGS';

export const sortingTypes = {
    VOTE: 1,
    TIME: 2
};

export const apiSortingTypes = {
    [sortingTypes.VOTE]: 'votes',
    [sortingTypes.TIME]: 'createdAt'
};

export const apiOrderTypes = {
    [sortingTypes.VOTE]: 'desc',
    [sortingTypes.TIME]: 'desc'
};

export const searchTypes = {
    SERIAL: 0,
    AUTHOR_NAME: 1,
    PORTFOLIO_NAME: 2
};

export const apiSearchTypes = {
    [searchTypes.AUTHOR_NAME]: 'author',
    [searchTypes.PORTFOLIO_NAME]: 'title'
};

const defaultState = {
    isFetching: false,
    collections: [],
    page: 1,
    perItems: 20,
    totalItems: 0,
    searchType: searchTypes.SERIAL,
    searchKeyword: '',
    sortingType: sortingTypes.TIME
};

const reducer = (state = defaultState, action) => {
    switch (action.type) {
        case UPDATE_COLLECTIONS:
            return { ...state, ...action.payload };
        case CLEAR_COLLECTIONS: {
            return { ...state, collections: [], totalItems: 0 };
        }
        case UPDATE_FLAGS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default reducer;

function setIsFetchingUgc(status) {
    return {
        type: UPDATE_COLLECTIONS,
        payload: {
            isFetching: status
        }
    };
}

export function getUgcCollection({ page, perItems, setIsFetching = true, deleted = false } = {}) {
    return async (dispatch, getState) => {
        try {
            if (setIsFetching) {
                dispatch(setIsFetchingUgc(true));
            }
            const {
                voteManage: {
                    page: currentPage,
                    perItems: currentPerItems,
                    searchKeyword,
                    searchType,
                    sortingType
                },
                promo: { relatedPromoId, type: promoType },
                sponsor: { id: sponsorId }
            } = getState();
            const isVotingContest = promoType === promotionTypes.CONTEST_ADMIN_UPLOAD;
            const p = page ?? currentPage;
            const i = perItems ?? currentPerItems;
            const d = deleted ? 1 : 0;
            const search =
                searchType === searchTypes.SERIAL
                    ? { search: searchKeyword }
                    : { mediaSearch: searchKeyword, searchColumn: apiSearchTypes[searchType] };
            const { items = [] } = await getEntriesList({
                promoId: relatedPromoId,
                sponsorId,
                page: p,
                perPage: i,
                orderColumn: apiSortingTypes[sortingType],
                order: apiOrderTypes[sortingType],
                qualified: 1,
                deleted: d,
                isVotingContest,
                ...search
            });

            dispatch({
                type: UPDATE_COLLECTIONS,
                payload: {
                    collections: items,
                    page: p,
                    perItems: i,
                    totalItems: items.length
                }
            });
        } catch (error) {
            dispatch(setFailed('message_connection_error'));
        } finally {
            if (setIsFetching) {
                dispatch(setIsFetchingUgc(false));
            }
        }
    };
}

export function clearCollection() {
    return {
        type: CLEAR_COLLECTIONS
    };
}

export function setSearchType(searchType) {
    return {
        type: UPDATE_FLAGS,
        payload: {
            searchType
        }
    };
}

export function setSearchKeyword(searchKeyword) {
    return {
        type: UPDATE_FLAGS,
        payload: {
            searchKeyword
        }
    };
}

export function setSortingType(sortingType) {
    return {
        type: UPDATE_FLAGS,
        payload: {
            sortingType
        }
    };
}
