import i18next from 'i18next';

/**
 * 即時給獎類型
 *
 * - 1: 參加獎
 * - 3: 刮刮卡
 * - 4: 大輪盤
 * - 5: 戳戳樂
 * - 6: 紅包雨
 * - 7: 拉霸
 * - 8: 好禮自由選
 * - 9: 點數兌獎
 */
export const JOIN_AND_GET = 1;
export const SCRATCH_CARD = 3;
export const WIN_WHEEL = 4;
export const POKE_GAME = 5;
export const RED_ENVELOPE_RAIN = 6;
export const SLOTS = 7;
export const PICK_THE_PRIZE = 8;
export const POINTS_REDEEM = 9;
/**
 * 非即時給獎類型
 *
 * - 0: 後台抽獎
 */
export const ADMIN = 0;

/**
 * 獎品分配規則算法
 *
 * - 0: 機率
 * - 1: 順序
 * - 2: 沒限制，自由選
 */
export const lotteryAlgorithmTypes = {
    PERCENT: 0,
    ORDER: 1,
    SELF_PICK: 2
};

/**
 * 獎品數量顯示
 *
 * - 0: 不顯示
 * - 1: 顯示獎品總數
 * - 2: 顯示獎品剩餘數量總數
 */
const NOT_SHOW = 0;
const SHOW_REMAIN_AMOUNT = 1;
const SHOW_TOTAL_AMOUNT = 2;
export const lotteryRestShowTypes = {
    NOT_SHOW,
    SHOW_TOTAL_AMOUNT,
    SHOW_REMAIN_AMOUNT
};

/**
 * 獎品發完處理方式
 *
 * - 1: 自動暫停活動
 * - 0: 繼續進行活動
 */
const CONTINUE = 0;
const SUSPEND = 1;
export const lotteryWhenNoPrizeTypes = {
    SUSPEND,
    CONTINUE
};

export function isLotteryDrawPrize(type) {
    return type === ADMIN;
}

export function generateLotteryTypeTitle(lotteryType) {
    switch (lotteryType) {
        case ADMIN:
            return i18next.t('label.lottery');
        case JOIN_AND_GET:
            return i18next.t('label.instant_win');
        case SCRATCH_CARD:
            return i18next.t('label.sketch_prize');
        case WIN_WHEEL:
            return i18next.t('label.winwheel');
        case POKE_GAME:
            return i18next.t('label.poke_game');
        case RED_ENVELOPE_RAIN:
            return i18next.t('label.red_envelope_rain');
        case SLOTS:
            return i18next.t('label.slots');
        case PICK_THE_PRIZE:
            return i18next.t('label.game_type_pick_the_prize');
        case POINTS_REDEEM:
            return i18next.t('label.game_type_points_redeem');
        default:
            return '';
    }
}
