import { useEffect } from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { resetCampaignChat } from 'store/module/campaignChat';

const useInjectReducer = (key, reducer, matcher) => {
    const store = useStore();
    const location = useLocation();

    useEffect(() => {
        const shouldInject = matcher(location.pathname);

        if (shouldInject) {
            store.injectReducer(key, reducer);
        }

        // Cleanup on unmount or when route changes
        return () => {
            if (shouldInject) {
                // Dispatch reset action before removing
                store.dispatch(resetCampaignChat());
                store.removeReducer(key);
            }
        };
    }, [location.pathname, store, key, reducer, matcher]);
};

export default useInjectReducer;
