import {
    getPointPoolInfoApi,
    getPointPoolsApi,
    updatePointPoolInfoApi,
    getPromosByPointPoolApi,
    updatePointPoolSettings
} from 'api/point';
import { objFirstLevelJSONParse } from 'utils/objectManipulate';
import { setSponsorUseGlobalPoints } from './flags';
import { POINT_POOL_VIEW_MODE } from 'features/point/config';
const INIT_SYSTEM = 'pointSystem/INIT_SYSTEM';
export const UPDATE_POINT_SYSTEM = 'pointSystem/UPDATE_POINT_SYSTEM';
// Dashboard 活動列表
const SET_PROMO_LIST_CURRENT_PAGE = 'pointSystem/SET_PROMO_LIST_CURRENT_PAGE';
const SET_PROMO_LIST_SEARCH_KEY = 'pointSystem/SET_PROMO_LIST_SEARCH_KEY';
const SET_PROMO_LIST_DATA = 'pointSystem/SET_PROMO_LIST_DATA';
const SET_PROMO_LIST_IS_FETCHING = 'pointSystem/SET_PROMO_LIST_IS_FETCHING';
const SET_MEMBER_HISTORY_RELOAD = 'pointSystem/SET_MEMBER_HISTORY_RELOAD';
const SET_SECTION_FLAG = 'pointSystem/SET_SECTION_FLAG';
const SET_SPONSOR_ID_BY_PROMO = 'pointSystem/SET_SPONSOR_ID_BY_PROMO';
const SET_SPONSOR_ID_BY_MEMBER = 'pointSystem/SET_SPONSOR_ID_BY_MEMBER';

export const keyVisionSectionId = 'keyVision';
export const themeSectionId = 'theme';
export const pointIconSectionId = 'pointIcon';

const initialState = {
    // 點數池清單
    pointPools: [],
    // 當前選擇點數池資訊
    currentPointPoolInfo: {},
    // Dashboard 活動列表
    promosByPointPool: {
        currentPage: 1,
        perPage: 5,
        totalItems: 0,
        totalPages: 1,
        data: [],
        searchKey: '',
        isFetching: true
    },
    externalPoints: {},
    flag: {
        isNeedMemberHistoryListReload: false,
        selectedSectionId: '',
        sponsorIdByPromo: '',
        sponsorIdByMember: ''
    }
};

export default function reducer(state = initialState, { type, payload }) {
    switch (type) {
        case INIT_SYSTEM:
            return { ...payload };
        case UPDATE_POINT_SYSTEM:
            return { ...state, ...payload };
        case SET_PROMO_LIST_CURRENT_PAGE:
            return {
                ...state,
                promosByPointPool: { ...state.promosByPointPool, currentPage: payload }
            };
        case SET_PROMO_LIST_SEARCH_KEY:
            return {
                ...state,
                promosByPointPool: { ...state.promosByPointPool, searchKey: payload }
            };
        case SET_PROMO_LIST_DATA:
            return {
                ...state,
                promosByPointPool: {
                    ...state.promosByPointPool,
                    data: payload.items,
                    totalItems: payload.totalItems,
                    totalPages: payload.totalPages
                }
            };
        case SET_PROMO_LIST_IS_FETCHING:
            return {
                ...state,
                promosByPointPool: { ...state.promosByPointPool, isFetching: payload }
            };
        case SET_MEMBER_HISTORY_RELOAD:
            return {
                ...state,
                flag: { ...state.flag, isNeedMemberHistoryListReload: payload }
            };
        case SET_SECTION_FLAG:
            return {
                ...state,
                flag: { ...state.flag, selectedSectionId: payload }
            };
        case SET_SPONSOR_ID_BY_PROMO:
            return {
                ...state,
                flag: { ...state.flag, sponsorIdByPromo: payload }
            };
        case SET_SPONSOR_ID_BY_MEMBER:
            return {
                ...state,
                flag: { ...state.flag, sponsorIdByMember: payload }
            };
        default:
            return state;
    }
}

export function initSystem(data) {
    return {
        type: INIT_SYSTEM,
        payload: data
    };
}

export function updatePointSystem(data) {
    return {
        type: UPDATE_POINT_SYSTEM,
        payload: data
    };
}
export function setMemberHistoryReloadFlag(data) {
    return {
        type: SET_MEMBER_HISTORY_RELOAD,
        payload: data
    };
}
export function setSponsorIdByPromoFlag(data) {
    return {
        type: SET_SPONSOR_ID_BY_PROMO,
        payload: data
    };
}
export function setSponsorIdByMemberFlag(data) {
    return {
        type: SET_SPONSOR_ID_BY_MEMBER,
        payload: data
    };
}

/**
 * 取得點數池資訊 ( api & redux ) - promotion-tool
 * @param   {string} pointPoolUuid 點數池 uuid
 * @return  {promise}
 */
export function getPointPoolInfo(pointPoolUuid, viewMode) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId, uuid: sponsorUuid }
        } = getState();

        if (pointPoolUuid) {
            const pointPoolInfo = await getPointPoolInfoApi(sponsorId, pointPoolUuid);
            pointPoolInfo.settings = objFirstLevelJSONParse(pointPoolInfo.settings.Styles);

            const isSponsorUseGlobalPoints = !(
                (Boolean(pointPoolInfo.isGlobal) &&
                    pointPoolInfo.sponsorUuid === sponsorUuid &&
                    viewMode === POINT_POOL_VIEW_MODE.ALL) ||
                !Boolean(pointPoolInfo.isGlobal)
            );

            dispatch(setSponsorUseGlobalPoints(isSponsorUseGlobalPoints ? true : false));
            dispatch({
                type: UPDATE_POINT_SYSTEM,
                payload: { currentPointPoolInfo: pointPoolInfo }
            });
        }
    };
}

export function getPointPools() {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId }
        } = getState();

        const pointPools = await getPointPoolsApi({ sponsorId, order: 'updated' });

        dispatch({
            type: UPDATE_POINT_SYSTEM,
            payload: { pointPools }
        });

        return pointPools;
    };
}

/**
 * 更新點數池 info ( api & redux )
 * @param   {object} body 更新的內容
 * @return  {promise}
 */
export function updatePointPoolInfo(body) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId },
            pointSystem: { currentPointPoolInfo = {} }
        } = getState();
        await updatePointPoolInfoApi({
            sponsorId,
            pointPoolUuid: currentPointPoolInfo.uuid,
            body
        });
        const newPointPoolInfo = { ...currentPointPoolInfo, ...body };
        dispatch({
            type: UPDATE_POINT_SYSTEM,
            payload: { currentPointPoolInfo: newPointPoolInfo }
        });
    };
}

export function updatePointPoolePromosByApi(sponsorId, pointPoolUuid, specifySponsorUuid) {
    return async (dispatch, getState) => {
        dispatch(setPointSystemPromosIsFetching(true));

        const {
            pointSystem: {
                promosByPointPool: { perPage, currentPage, searchKey }
            }
        } = getState();

        const data = await getPromosByPointPoolApi({
            sponsorId,
            pointPoolUuid,
            perPage,
            page: currentPage,
            searchKey,
            ...(Boolean(specifySponsorUuid) ? { specifySponsorUuid } : {})
        });
        dispatch(setPointSystemPromosData(data));
        dispatch(setPointSystemPromosIsFetching(false));
    };
}

export function setPointSystemPromosCurrentPage(currentPage) {
    return {
        type: SET_PROMO_LIST_CURRENT_PAGE,
        payload: currentPage
    };
}

export function setPointSystemPromosSearchKey(searchKey) {
    return {
        type: SET_PROMO_LIST_SEARCH_KEY,
        payload: searchKey
    };
}

export function setPointSystemPromosData(data) {
    return {
        type: SET_PROMO_LIST_DATA,
        payload: data
    };
}

export function setPointSystemPromosIsFetching(isFetching) {
    return {
        type: SET_PROMO_LIST_IS_FETCHING,
        payload: isFetching
    };
}

/**
 * 更新 活動 by點數池( api & redux )
 * @param   {object} data 更新的內容
 * @return  {promise}
 */
export function updatePointSystemSettings(body) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId },
            pointSystem: { currentPointPoolInfo }
        } = getState();
        const { settings, uuid } = currentPointPoolInfo;
        const newPointPoolSettings = { ...settings, ...body };

        await updatePointPoolSettings({
            sponsorId,
            pointPoolUuid: uuid,
            Styles: newPointPoolSettings
        });
        getPointPools();
        dispatch({
            type: UPDATE_POINT_SYSTEM,
            payload: {
                currentPointPoolInfo: { ...currentPointPoolInfo, settings: newPointPoolSettings }
            }
        });
    };
}

export function setSelectedSectionId(selectedSectionId) {
    return {
        type: SET_SECTION_FLAG,
        payload: selectedSectionId
    };
}
