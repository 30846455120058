import { getFormRepliesApi } from 'api/forms';
import { normalizeList } from 'utils/common';
import { setFailed } from 'store/module/flag';
import { CHANGE_SPONSOR } from 'store/module/sponsor';

export const FETCH_REPLIES_START = 'FETCH_REPLIES_START';
export const FETCH_REPLIES_SUCCESS = 'FETCH_REPLIES_SUCCESS';
export const FETCH_REPLIES_FAILED = 'FETCH_REPLIES_FAILED';

export default function reducer(
    state = {
        replies: [],
        fields: [],
        formInfo: {},
        currentPage: 1,
        totalItems: 0,
        isFetching: false
    },
    { type, payload }
) {
    switch (type) {
        case FETCH_REPLIES_START:
            return { ...state, isFetching: true };
        case FETCH_REPLIES_SUCCESS:
            return {
                ...state,
                ...payload,
                isFetching: false
            };
        case FETCH_REPLIES_FAILED:
            return { ...state, isFetching: false };
        case CHANGE_SPONSOR:
            return { ...state, currentPage: 1 };
        default:
            return state;
    }
}

export function fetchFormReplies(formId, page, decrypt) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: selectedSponsorId },
            formReport: { currentPage: oldPage }
        } = getState();
        page = page || oldPage;
        if (selectedSponsorId) {
            try {
                dispatch({ type: FETCH_REPLIES_START });
                const resp = await getFormRepliesApi(selectedSponsorId, formId, page, decrypt);
                if (resp.status > 401) {
                    throw new Error('fetch form replies failed.');
                } else {
                    const {
                        items: { currentPage, totalItems, formInfo, fields, replies }
                    } = await resp.json();
                    dispatch({
                        type: FETCH_REPLIES_SUCCESS,
                        payload: {
                            currentPage,
                            totalItems,
                            formInfo,
                            fields,
                            replies: replies.map(r => ({
                                ...r,
                                data: normalizeList('fieldId', r.data)
                            }))
                        }
                    });
                }
            } catch (error) {
                dispatch({ type: FETCH_REPLIES_FAILED });
                dispatch(setFailed('getFormRepliesApi', 'message_connection_error'));
            }
        }
    };
}
