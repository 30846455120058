import React, { useState, useRef } from 'react';
import { css } from '@emotion/core';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import SearchField from 'features/common/SearchField';
import useOnClickOutside from 'hooks/useOnClickOutside';
import { system900, system400, system200 } from 'features/common/Mui/styles/color';
import { dp24 } from 'features/common/Mui/styles/shadow';
import { getPointPoolEntryRecordApi } from 'api/point';
import useApiFailedHandler from 'features/webhooks/useApiErrorHandler';
import SearchEntryTable from './table/SearchEntryTable';
import i18next from 'i18next';
import { SEARCH_POINT_POOL_ENTRY_RECORD_FAILED } from 'config/errorTypes';
import ListInputSearch from 'features/common/ListInputSearch';

// ListInputSearch 搜尋類型
export const searchTypes = {
    SERIAL_NUMBER: 1,
    LINE_UID: 2
};

const PointPoolSearchBar = ({ sponsorId, pointPoolUuid, isSponsorUseGlobalPoints = false }) => {
    const ref = useRef('');
    const anchorEl = useRef(null);
    const clickedOutsideRef = useRef(false);
    const apiFailedHandler = useApiFailedHandler();
    const [data, setData] = useState(null);
    const [isFocused, setIsFocused] = useState(false);
    const [isFetching, setFetching] = useState(false);
    const [isSelectOpen, setIsSelectOpen] = useState(false);
    const [searchType, setSearchType] = useState(1);
    const [searchKey, setSearchKey] = useState('');

    const handleSearchType = searchType => {
        setSearchType(searchType);
    };

    const handleChange = searchText => {
        setIsFocused(true);
        setSearchKey(searchText);
    };

    const closePopover = () => {
        setIsFocused(false);
        setSearchKey('');
        setData(null);
        clickedOutsideRef.current = true; // 標記為外部點擊
    };

    useOnClickOutside(ref, () => {
        if (!isSelectOpen) {
            closePopover();
        }
    });

    const handleSearch = async () => {
        try {
            if (searchKey.trim().length > 0) {
                setFetching(true);

                const result = await getPointPoolEntryRecordApi({
                    sponsorId,
                    pointPoolUuid,
                    searchType,
                    searchKey
                });

                setData(result || []);
                setFetching(false);
            }
        } catch (error) {
            apiFailedHandler(
                error,
                i18next.t('notify.search_fail'),
                SEARCH_POINT_POOL_ENTRY_RECORD_FAILED
            );
        }
    };

    const searchTypeOptionsConfig = [
        {
            value: searchTypes.SERIAL_NUMBER,
            label: () => i18next.t('label.serial_number')
        },
        {
            value: searchTypes.LINE_UID,
            label: () => i18next.t('LINE UID')
        }
    ];

    return (
        <div>
            <SearchField
                ref={anchorEl}
                placeholder={i18next.t('label.press_serial_number')}
                onChange={handleChange}
                style={searchFieldStyle({ haveBorder: true, isSticky: false })}
                defaultValue={searchKey}
                onFocus={e => {
                    if (!clickedOutsideRef.current) {
                        e.stopPropagation();
                        setIsFocused(true);
                    } else {
                        clickedOutsideRef.current = false; // reset 外部點擊狀態
                    }
                }}
            />
            {isFocused && anchorEl?.current && (
                <Popover
                    open={isFocused}
                    css={[popoverStyle]}
                    anchorEl={anchorEl.current}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right'
                    }}
                >
                    <div ref={ref}>
                        <ListInputSearch
                            style={searchFieldStyle({ haveBorder: false, isSticky: true })}
                            onSearch={handleSearch}
                            updateSearchType={handleSearchType}
                            updateSearchKey={handleChange}
                            searchType={searchType}
                            search={searchKey}
                            placeholder={i18next.t('搜尋...')}
                            searchTypeOptions={searchTypeOptionsConfig}
                            // 監控 Select 開關
                            onHandleOpenSelect={() => setIsSelectOpen(true)} // 打開
                            onHandleCloseSelect={() => setIsSelectOpen(false)} // 關閉
                        />

                        {Boolean(data) ? (
                            <SearchEntryTable
                                isFetching={isFetching}
                                closePopover={closePopover}
                                data={data}
                                pointPoolUuid={pointPoolUuid}
                                isSponsorUseGlobalPoints={isSponsorUseGlobalPoints}
                            />
                        ) : (
                            <Box mx={3} my={2}>
                                <Typography variant="caption" style={{ color: system900.color }}>
                                    {i18next.t('label.point_center.press_serial_number_content')}
                                </Typography>
                            </Box>
                        )}
                    </div>
                </Popover>
            )}
        </div>
    );
};

export default PointPoolSearchBar;

const popoverStyle = css`
    pointer-events: auto;
    white-space: pre-wrap;
    background: rgba(0, 0, 0, 0.38);
    overflow: hidden;

    & .MuiPopover-paper {
        min-width: 560px;
        width: auto;
        border-radius: 8px;
        box-shadow: ${dp24.boxShadow};
    }
`;

const searchFieldStyle = ({ haveBorder, isSticky }) => css`
    width: 100%;
    background: #ffffff;
    color: ${system900.color};
    border: ${haveBorder ? `1px solid ${system200.color}` : `none`};
    border-radius: ${haveBorder ? `8px` : `0`};
    font-weight: 500;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.15px;

    & .MuiInputBase-root:before,
    & .MuiInputBase-root:after {
        content: none;
    }

    &::placeholder: {
        color: ${system400.color};
    }

    ${isSticky &&
    `
        position: sticky;
        top: 0;
        background: #fff;
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        z-index: 998;
        margin:unset;
        max-width:unset;
        `}
`;
