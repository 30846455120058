import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/core';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import LanguageIcon from '@material-ui/icons/Language';
import InputAdornment from '@material-ui/core/InputAdornment';

import { adminLangOptions } from 'config/langType';
import { switchLocale } from 'store/module/i18n';

export default function SelectorAdminLang({ color, style }) {
    const dispatch = useDispatch();
    const { locale: lang } = useSelector(s => s.i18n);

    return (
        <TextField
            css={[selectorStyle, colorStyle(color), css(style)]}
            select
            value={lang}
            onChange={e => dispatch(switchLocale(e.target.value))}
            variant="standard"
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <LanguageIcon />
                    </InputAdornment>
                )
            }}
        >
            {Object.keys(adminLangOptions).map(l => (
                <MenuItem key={l} value={l}>
                    {adminLangOptions[l]}
                </MenuItem>
            ))}
        </TextField>
    );
}

const selectorStyle = css`
    & .MuiInputBase-root:before,
    & .MuiInputBase-root:after {
        content: none;
    }
    & .MuiSelect-root {
        padding: 0;
        padding-right: 24px;
        padding-top: 4px;
    }
`;

const colorStyle = (color = '#000') => css`
    color: ${color};
    & * {
        color: inherit;
    }
`;
