/**
 * Filter out the keys with values that are undefined or null from the input object
 * @typedef {Object} filterUndefinedOrNull
 * @property {string} obj - 要過濾的的物件
 */
export const filterUndefinedOrNull = obj => {
    if (!obj || typeof obj !== 'object') return {};
    if (Object.keys(obj).length === 0) return obj;

    for (const key in obj) {
        if (obj[key] === undefined || obj[key] === null) {
            delete obj[key];
        }
    }
    return obj;
};
