import { setFailed } from 'store/module/notify';
import { getCampaignListApi, deleteCampaignApi } from 'api/campaign';
import { MAX_LENGTH_PER_REQUEST } from 'config/campaignType';
import { produce } from 'immer';

const FETCHING_CAMPAIGN_LIST = 'campaign/FETCHING_CAMPAIGN_LIST';
const FETCH_CAMPAIGN_LIST_FAILED = 'campaign/FETCH_CAMPAIGN_LIST_FAILED';
const FETCH_CAMPAIGN_LIST_SUCCESS = 'campaign/FETCH_CAMPAIGN_LIST_SUCCESS';
const DELETING_CAMPAIGN = 'campaign/DELETING_CAMPAIGN';
const DELETED_CAMPAIGN = 'campaign/DELETED_CAMPAIGN';
export const RESET_CAMPAIGN_LIST = 'campaign/RESET_CAMPAIGN_LIST';

export function fetchCampaignList({ page, type }) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: sponsorId },
            campaigns: { campaignList, currentPage: perviousPage }
        } = getState();
        let currentPage = page || perviousPage;
        if (sponsorId) {
            try {
                dispatch({ type: FETCHING_CAMPAIGN_LIST });
                const resp = await getCampaignListApi(sponsorId, { page: currentPage, type });
                if (resp.status > 401) {
                    throw new Error('fetch failed.');
                } else {
                    const { items = [], totalPages, totalItems } = await resp;
                    const isEnd =
                        totalItems <= MAX_LENGTH_PER_REQUEST || currentPage === totalPages;
                    let mergeCampaignList = [...campaignList, ...items];
                    if (currentPage === 1) {
                        mergeCampaignList = items;
                    }
                    const data = {
                        campaignType: type,
                        campaignList: mergeCampaignList,
                        currentPage,
                        isEnd,
                        totalItems
                    };

                    dispatch({
                        type: FETCH_CAMPAIGN_LIST_SUCCESS,
                        payload: data
                    });
                }
            } catch (error) {
                dispatch({ type: FETCH_CAMPAIGN_LIST_FAILED });
                dispatch(setFailed('notify.get_campaign_onsite_list_fail'));
                console.error(error);
            }
        }
    };
}

export function deleteCampaign(sponsorId, campaignId) {
    return async dispatch => {
        try {
            dispatch({
                type: DELETING_CAMPAIGN,
                payload: { deletingId: campaignId }
            });
            await deleteCampaignApi(sponsorId, campaignId);
            dispatch({
                type: DELETED_CAMPAIGN,
                payload: { deletingId: campaignId }
            });
        } catch (error) {
            dispatch(setFailed('label.delete_fail'));
            console.error(error);
        }
    };
}

const defaultState = {
    campaignList: [],
    campaignType: null,
    currentPage: 1,
    isFetching: false,
    isEnd: false,
    totalItems: 0
};

export default function reducer(state = defaultState, { type, payload }) {
    switch (type) {
        case FETCHING_CAMPAIGN_LIST:
            return { ...state, isFetching: true };
        case FETCH_CAMPAIGN_LIST_FAILED:
            return { ...state, isFetching: false };
        case FETCH_CAMPAIGN_LIST_SUCCESS:
            return {
                ...state,
                campaignList: payload.campaignList,
                campaignType: payload.campaignType,
                currentPage: payload.currentPage,
                isFetching: false,
                isEnd: payload.isEnd,
                totalItems: payload.totalItems
            };
        case DELETING_CAMPAIGN:
            return produce(state, draft => {
                draft.campaignList.find(
                    campaign => campaign.id === payload.deletingId
                ).isDeleting = true;
            });
        case DELETED_CAMPAIGN:
            return produce(state, draft => {
                draft.campaignList = draft.campaignList.filter(
                    campaign => campaign.id !== payload.deletingId
                );
            });
        case RESET_CAMPAIGN_LIST:
            return defaultState;
        default:
            return state;
    }
}
