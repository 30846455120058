import { feverApiFetchOpts, fetches, methods } from 'api/commom';
import queryString from 'query-string';
import { MAX_LENGTH_PER_REQUEST, campaignTypes } from 'config/campaignType';
import * as errorTypes from 'config/errorTypes';

export function getCampaignApi(sponsorId, campaignId) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}`,
        feverApiFetchOpts(),
        {
            throwErrConfigs: {
                [errorTypes.CAMPAIGN_NOT_FOUND]: r =>
                    r.httpStatus === 400 && r?.payload?.message === '404'
            }
        }
    ).then(resp => resp);
}

export function getCampaignMessageApi(sponsorId, campaignId, messageId) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}/messages${
            messageId ? `/${messageId}` : ''
        }`,
        feverApiFetchOpts(),
        {
            throwErrConfigs: {
                [errorTypes.MESSAGE_NOT_FOUND]: r =>
                    r.httpStatus === 400 && r?.payload?.message === 'Campaign message not found'
            }
        }
    ).then(resp => resp);
}

export function getCampaignListApi(
    sponsorId,
    { perPage = MAX_LENGTH_PER_REQUEST, page, type = campaignTypes.STORY_MENTION }
) {
    const qs = queryString.stringify({
        perPage,
        page,
        type
    });
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools${qs && `?${qs}`}`,
        feverApiFetchOpts()
    ).then(resp => resp);
}

export function getCampaignMemberList(sponsorId, campaignId, { page = 1, perPage = 10 }) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}/sent?page=${page}&perPage=${perPage}`,
        feverApiFetchOpts(),
        { dataKeys: 'data' }
    );
}

export function getPasscodePromos(sponsorId) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/passcode-promos`,
        feverApiFetchOpts()
    ).then(resp => resp);
}

export function updateCampaignMessageApi(
    sponsorId,
    campaignId,
    messageId,
    { type, promotionUuid, content, settings }
) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}/messages${
            messageId ? `/${messageId}` : ''
        }`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: { type, content: JSON.stringify(content), promotionUuid, settings }
        })
    ).then(resp => resp);
}

export function updateCampaignStatusApi(sponsorId, campaignId, status) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}/status`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body: { status }
        }),
        {
            throwErrConfigs: {
                [errorTypes.PUBLISH_CAMPAIGN_DOMAIN_WHITE_LIST_FAILED]: r =>
                    r.httpStatus === 400 &&
                    r?.payload?.message === 'sponsor setting validate failed - sdkDomainWhitelist',
                [errorTypes.PUBLISH_CAMPAIGN_TIMEZONE_VALIDATE_FAILED]: r =>
                    r.httpStatus === 400 &&
                    r?.payload?.message === 'campaign setting validate failed - timezone',
                [errorTypes.PUBLISH_CAMPAIGN_PLAN_VALIDATE_FAILED]: r =>
                    r.httpStatus === 401 && r?.payload?.message === 'Plan validate failed'
            }
        }
    ).then(resp => resp);
}

export function updateCampaignApi(sponsorId, campaignId, body) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}`,
        feverApiFetchOpts({
            method: methods.PATCH,
            body
        })
    ).then(resp => resp);
}

export function createCampaignApi(sponsorId, { name, type, limit }) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools`,
        feverApiFetchOpts({
            method: methods.POST,
            body: { name, type, limit }
        })
    ).then(resp => resp);
}

export function createCampaignMessageApi(sponsorId, campaignId, { promotionUuid, type, content }) {
    const formData = new FormData();
    if (promotionUuid) {
        formData.append('promotionUuid', promotionUuid);
    }
    formData.append('type', type);
    formData.append('content', JSON.stringify(content));
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}/messages`,
        feverApiFetchOpts({ method: methods.POST, formData })
    ).then(resp => resp);
}

export function deleteCampaignApi(sponsorId, campaignId) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}`,
        feverApiFetchOpts({ method: methods.DELETE })
    ).then(resp => resp);
}

export function getCampaignPreviewCodeApi(sponsorId, campaignId) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}/preview-code`,
        feverApiFetchOpts({ method: methods.POST })
    ).then(resp => resp);
}

export function getCampaignAnalysisApi({ sponsorId, campaignId, startDate, endDate }) {
    return fetches(
        `/services/campaigns/sponsors/${sponsorId}/tools/${campaignId}/analysis?startDate=${startDate}&endDate=${endDate}`,
        feverApiFetchOpts()
    ).then(resp => resp);
}
