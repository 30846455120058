import React, { useEffect } from 'react';
import { css } from '@emotion/core';
import i18n from 'i18next';

function NotFound({ title = i18n.t('label.page_not_found') }) {
    useEffect(() => {
        document.title = `Status 404`;
    }, []);
    return (
        <div css={css(wrapperStyle)}>
            <img src={`https://assets.fevercdn.com/nc/icons/http${404}.png`} alt={`http${404}`} />
            <div css={css(textStyle)}>{title}</div>
        </div>
    );
}

export default NotFound;

const wrapperStyle = `
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const textStyle = `
    font-size: 1.5rem;
    color: #b3b3b3;
    margin-top: 1rem;
`;
