import { getFormsListApi, deleteFormApi, createFormApi, updateFormApi } from 'api/forms';
import { setFailed } from 'store/module/flag';
import { CHANGE_SPONSOR } from 'store/module/sponsor';
import getNavigatorLangForAdmin from 'utils/getNavigatorLangForAdmin';
import { getLocalTimezone } from 'utils/dateTime';

export const FETCH_FORM_START = 'FETCH_FORM_START';
export const FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS';
export const FETCH_FORM_FAILED = 'FETCH_FORM_FAILED';

export default function reducer(
    state = {
        formsList: [],
        currentPage: 1,
        totalItems: 1,
        isFetching: false
    },
    { type, payload }
) {
    switch (type) {
        case FETCH_FORM_START:
            return { ...state, isFetching: true };
        case FETCH_FORM_SUCCESS:
            return {
                ...state,
                ...payload,
                isFetching: false
            };
        case FETCH_FORM_FAILED:
            return { ...state, isFetching: false };
        case CHANGE_SPONSOR:
            return { ...state, currentPage: 1 };
        default:
            return state;
    }
}

export function createForm() {
    return async (dispatch, getState) => {
        const {
            sponsor: {
                id: selectedSponsorId,
                settings: { language: sponsorLanguage, timezone: sponsorTimezone }
            },
            account: { language: accountLanguage }
        } = getState();

        const timezone = sponsorTimezone || getLocalTimezone();
        const language = sponsorLanguage || accountLanguage || getNavigatorLangForAdmin();
        try {
            const data = {
                settings: { form: { timezone } }
            };
            const resp = await createFormApi(selectedSponsorId, { lang: language });
            if (resp.status > 401) {
                throw new Error('create form failed.');
            }
            const {
                items: { id: formId }
            } = await resp.json();

            await updateFormApi(selectedSponsorId, formId, data);
            dispatch(fetchForms());
            return { sponsorId: selectedSponsorId, id: formId };
        } catch (error) {
            dispatch(setFailed('createFormApi', 'message_connection_error'));
            throw new Error(error);
        }
    };
}

export function deleteForm(formId) {
    return async (dispatch, getState) => {
        try {
            const {
                sponsor: { id: selectedSponsorId }
            } = getState();
            const resp = await deleteFormApi(selectedSponsorId, formId);
            if (resp.status > 401) {
                throw new Error('delete form failed.');
            }
            dispatch(fetchForms());
        } catch (error) {
            dispatch(setFailed('deleteFormApi', 'message_connection_error'));
        }
    };
}

export function fetchForms({ page, search } = {}) {
    return async (dispatch, getState) => {
        const {
            sponsor: { id: selectedSponsorId },
            forms: { currentPage: oldPage }
        } = getState();
        page = page || oldPage;
        if (selectedSponsorId) {
            try {
                dispatch({ type: FETCH_FORM_START });
                const resp = await getFormsListApi(selectedSponsorId, { page, search });
                if (resp.status > 401) {
                    throw new Error('fetch form failed.');
                } else {
                    const {
                        items: { currentPage, totalItems, forms: formsList }
                    } = await resp.json();
                    dispatch({
                        type: FETCH_FORM_SUCCESS,
                        payload: { currentPage, totalItems, formsList }
                    });
                }
            } catch (error) {
                dispatch({ type: FETCH_FORM_FAILED });
                dispatch(setFailed('getFormsListApi', 'message_connection_error'));
            }
        }
    };
}
