import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';

import { verifyEmailTypes } from 'features/login/utils/config';
import { auth } from 'features/login/utils/firebaseManager';
import { setVerifyEmailInfo, setInputEmail, setLoginSignUpErrorType } from 'store/module/firebase';
import {
    mainButtonSolidStyle,
    errMsgWrapperStyle,
    textFieldDefaultStyle
} from 'features/login/styles/comman';
import { generateEmailValidateRegexp } from 'features/login/utils/validate';
import { loginSignUpErrorTypes } from 'store/module/firebase';
import { paths } from 'features/login/hooks/useInitAndRedirectApp';

import useCheckIsValidEmailDomain from 'features/login/hooks/useCheckIsValidEmailDomain';
import useHistoryWithCurrentSearchHash from 'features/login/hooks/useHistoryWithCurrentSearchHash';

import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';

export default function SignupWithEmail() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistoryWithCurrentSearchHash();
    const isValidEmailDomain = useCheckIsValidEmailDomain();
    const { currentInputEmail } = useSelector(s => s.firebase);
    const [emailErrMsg, setEmailErrMsg] = useState('');
    const [isSignuping, setIsSignuping] = useState(false);

    function validateInput() {
        let valid = true;
        if (!currentInputEmail) {
            valid = false;
            setEmailErrMsg(i18n.t('label.error.required.email'));
        } else if (!generateEmailValidateRegexp().test(currentInputEmail)) {
            valid = false;
            setEmailErrMsg(i18n.t('label.invalid_email'));
        }
        return valid;
    }
    function handleEmailChange({ target: { value } }) {
        dispatch(setInputEmail(value));
        setEmailErrMsg('');
    }
    async function handleEmailSignup() {
        try {
            if (validateInput()) {
                if (!isValidEmailDomain(currentInputEmail)) {
                    dispatch(setInputEmail(''));
                    dispatch(setLoginSignUpErrorType(loginSignUpErrorTypes.INVALID_EMAIL_DOMAIN));
                    return history.push({ pathname: paths.ERROR });
                }
                setIsSignuping(true);
                const signInMethods = await fetchSignInMethodsForEmail(auth, currentInputEmail);
                if (signInMethods.length > 0) {
                    return setEmailErrMsg(i18n.t('label.error.sign_up.email_exist'));
                }
                dispatch(
                    setVerifyEmailInfo({
                        verifyEmail: currentInputEmail,
                        verifyEmailType: verifyEmailTypes.SIGN_UP
                    })
                );
                history.push({ pathname: paths.EMAIL_VERIFY });
            }
        } catch (error) {
            console.error('email signup', error);
        } finally {
            setIsSignuping(false);
        }
    }

    return (
        <>
            <TextField
                variant="filled"
                fullWidth
                value={currentInputEmail}
                onChange={handleEmailChange}
                placeholder={t('placeholder.input_email')}
                css={textFieldDefaultStyle}
                error={Boolean(emailErrMsg)}
            />
            <div css={errMsgWrapperStyle}>
                {Boolean(emailErrMsg) && <Typography variant="caption">{emailErrMsg}</Typography>}
            </div>
            <ButtonWithLoadingEffect
                isLoading={isSignuping}
                css={[mainButtonSolidStyle]}
                onClick={handleEmailSignup}
            >
                <Typography variant="subtitle2">{t('btn.sign_up')}</Typography>
            </ButtonWithLoadingEffect>
        </>
    );
}
