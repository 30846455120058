import React from 'react';
import { css } from '@emotion/core';
import { Skeleton } from '@material-ui/lab';

export default function LoadingTable({ loadingRow = 5, customTableStyle }) {
    return (
        <div css={[tableStyle, customTableStyle]}>
            {new Array(loadingRow).fill().map((_item, index) => (
                <Skeleton variant="rect" height={45} key={index} css={css(skeletonStyle)} />
            ))}
        </div>
    );
}

const tableStyle = css`
    min-width: 700px;
    & .MuiTableCell-root {
        border-bottom: none;
    }
`;

const skeletonStyle = css`
    margin: 1rem 0;
`;
