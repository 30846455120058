/**
 * @method useFreshChat 聊天機器人相關功能
 * @returns {Object} { open: handleOpenChat }
 */
export default function useFreshChat() {
    // https://https://app.clickup.com/t/86ep0mkck

    const handleOpenChat = () => {
        try {
            window.Intercom('show');
        } catch (error) {
            console.log(`Window can't find fresh chat widget.`);
        }
    };

    return { open: handleOpenChat };
}
