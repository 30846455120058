// 背景 + 文字 有兩種類型 (1: 自訂背景 / 2: 預設背景)
export const froalaKeyVisionTypeMapping = {
    IMAGE: 1,
    COLOR: 2
};

export const coverImg = {
    default: 'https://assets.fevercdn.com/promotion/img/ugc_image/Cover1.svg',
    cover_one: 'https://assets.fevercdn.com/promotion/img/key_vision/cover_background_01.png',
    cover_two: 'https://assets.fevercdn.com/promotion/img/key_vision/cover_background_02.png'
};

// 主視覺尺寸比例
export const ratio = {
    mobile: {
        width: 590,
        height: 250
    },
    desktop: {
        width: 2880,
        height: 600
    }
};
