import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css } from '@emotion/core';
import Divider from '@material-ui/core/Divider';

import { langTypes } from 'config/langType';
import { system600 } from 'features/common/Mui/styles/color';

export default function PolicyBlock({ style }) {
    const { locale } = useSelector(s => s.i18n);
    const { t } = useTranslation();
    const { policyUrlPrivate, policyUrlMember, policyUrlSecurity } =
        locale === langTypes.EN_US
            ? {
                  policyUrlPrivate: 'https://info.feversocial.com/en/privacy-482 ',
                  policyUrlMember: 'https://info.feversocial.com/en/standard-sponsor-terms-481',
                  policyUrlSecurity: 'https://info.feversocial.com/en/info-security-policy-982'
              }
            : {
                  policyUrlPrivate: 'https://info.feversocial.com/tw/privacy-22 ',
                  policyUrlMember: 'https://info.feversocial.com/tw/standard-sponsor-terms-21',
                  policyUrlSecurity: 'https://info.feversocial.com/tw/info-security-policy-914'
              };

    return (
        <div css={[containerStyle, css(style)]}>
            <a href={policyUrlPrivate} target="_blank" rel="noopener noreferrer">
                {t('label.app_policy.private')}
            </a>
            <Divider orientation="vertical" />
            <a href={policyUrlMember} target="_blank" rel="noopener noreferrer">
                {t('label.app_policy.member')}
            </a>
            <Divider orientation="vertical" />
            <a href={policyUrlSecurity} target="_blank" rel="noopener noreferrer">
                {t('label.app_policy.security')}
            </a>
        </div>
    );
}

const containerStyle = css`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    > hr {
        height: 16px;
        margin: 0 16px;
    }
    > a {
        color: ${system600.color};
    }
`;
