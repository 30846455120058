import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import querystring from 'query-string';
import { css } from '@emotion/core';
import {
    fetchSignInMethodsForEmail,
    isSignInWithEmailLink,
    signInWithEmailLink,
    verifyBeforeUpdateEmail
} from 'firebase/auth';
import CircularProgress from '@material-ui/core/CircularProgress';
import { TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

import { queryStrKeys } from 'features/login/utils/config';
import { auth } from 'features/login/utils/firebaseManager';
import { paths } from 'features/login/hooks/useInitAndRedirectApp';
import ConfirmVerifyEmailSended from 'features/login/components/pageEmailVerify/ConfirmVerifyEmailSended';
import useCheckIsValidEmailDomain from 'features/login/hooks/useCheckIsValidEmailDomain';
import { generateEmailValidateRegexp } from 'features/login/utils/validate';
import { system900 } from 'features/common/Mui/styles/color';
import ButtonWithLoadingEffect from 'features/common/ButtonWithLoadingEffect';
import { mainButtonSolidStyle, textFieldDefaultStyle } from 'features/login/styles/comman';
import { setFailed } from 'store/module/notify';

import CheckResendUpdateEmailVerify from 'features/login/components/pageEmailVerify/CheckResendUpdateEmailVerify';

export default function EmailVerifyLandingUpdateEmail() {
    const { t } = useTranslation();
    const [email] = useState(
        () => querystring.parse(window.location.search)[queryStrKeys.UPDATE_EMAIL_FROM]
    );
    const [displayType, setDisplayType] = useState('');
    const [updateEmail, setUpdateEmail] = useState('');
    const [error, setError] = useState(false);
    useEffect(() => {
        if (isSignInWithEmailLink(auth, window.location.href)) {
            setDisplayType(displayTypes.UPDATE);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const isValidEmailDomain = useCheckIsValidEmailDomain();

    const dispatch = useDispatch();

    function validateInput() {
        let valid = true;
        if (!updateEmail) {
            valid = false;
            setError(true);
            dispatch(setFailed(t('label.error.required.email')));
        } else if (!generateEmailValidateRegexp().test(updateEmail)) {
            valid = false;
            setError(true);
            dispatch(setFailed(t('label.invalid_email')));
        } else {
            setError(false);
        }
        return valid;
    }

    const handleUpdateEmail = async () => {
        try {
            if (validateInput()) {
                if (!isValidEmailDomain(updateEmail)) {
                    dispatch(setFailed(t('label.update_email_format_error')));
                    return;
                }

                const signInMethods = await fetchSignInMethodsForEmail(auth, updateEmail);

                if (signInMethods.length === 0) {
                    const credential = await signInWithEmailLink(auth, email, window.location.href);
                    const redirectUrl =
                        window.location.origin +
                        paths.LOGIN +
                        '?redirectAction=updateEmailComplete';
                    await verifyBeforeUpdateEmail(credential.user, updateEmail, {
                        url: redirectUrl
                    })
                        .then(async () => {
                            setDisplayType(displayTypes.UPDATE_CONFIRM);
                        })
                        .catch(error => {
                            console.log('Update Email Failed.', error);
                        });
                } else {
                    dispatch(setFailed(t('desc.login_failed.already_exist')));
                }
            }
        } catch (error) {
            dispatch(setFailed(t('更新連結已失效，請重新登入並再次發送連結')));
            setDisplayType(displayTypes.RESEND_VERIFY_EMAIL);
        }
    };

    if (displayType === displayTypes.UPDATE) {
        return (
            <>
                <div css={containerStyle}>
                    <Typography variant="h5" css={titleStyle}>
                        {t('label.update_email')}
                    </Typography>
                    <Typography variant="subtitle1" css={descStyle}>
                        {t('label.update_email_enter_email')}
                    </Typography>
                    <TextField
                        variant="filled"
                        type="text"
                        fullWidth
                        value={updateEmail}
                        onChange={e => {
                            setUpdateEmail(e.target.value);
                        }}
                        placeholder={t('label.error.required.email')}
                        css={[textFieldDefaultStyle]}
                        error={Boolean(error)}
                    />
                    <div css={btnWrapperStyle}>
                        <ButtonWithLoadingEffect
                            css={[mainButtonSolidStyle, btnStyle]}
                            onClick={() => {
                                handleUpdateEmail();
                            }}
                        >
                            <Typography variant="subtitle2">
                                {t('btn.send_validate_email')}
                            </Typography>
                        </ButtonWithLoadingEffect>
                    </div>
                </div>
            </>
        );
    }
    if (displayType === displayTypes.UPDATE_CONFIRM) {
        return <ConfirmVerifyEmailSended email={updateEmail} />;
    }
    if (displayType === displayTypes.RESEND_VERIFY_EMAIL) {
        return <CheckResendUpdateEmailVerify email={email} setDisplayType={setDisplayType} />;
    }
    if (displayType === displayTypes.RESEND_CONFIRM) {
        return <ConfirmVerifyEmailSended email={email} />;
    }
    return <CircularProgress />;
}

export const displayTypes = {
    UPDATE: 'UPDATE',
    UPDATE_CONFIRM: 'UPDATE_CONFIRM',
    RESEND_VERIFY_EMAIL: 'RESEND_VERIFY_EMAIL',
    RESEND_CONFIRM: 'RESEND_CONFIRM'
};

const containerStyle = css`
    > * {
        letter-spacing: 0.5px;
        text-align: center;
    }
    max-width: 360px;
`;

const titleStyle = css`
    color: ${system900.color};
`;

const descStyle = css`
    line-height: 19px;
    letter-spacing: 0.0015em;
    color: #616161;
    margin: 24px 0;
`;

const btnWrapperStyle = css`
    margin-top: 43px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const btnStyle = css`
    width: 216px;
`;
