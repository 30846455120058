import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PageSignin from 'features/login/components/pageLogin/PageSignin';
import PageSignup from 'features/login/components/pageSignup/PageSignup';
import PageEmailVerify from 'features/login/components/pageEmailVerify/PageEmailVerify';
import PageEmailVerifyLanding from 'features/login/components/pageEmailVerifyLanding/PageEmailVerifyLanding';
import PageError from 'features/login/components/PageError';
import PagePermissionDenied from 'features/login/components/PagePermissionDenied';
import UpdateEmailComplete from './pageEmailVerifyLanding/UpdateEmailComplete';
import NotFound from 'features/NotFound';

export default function LoginApp({ match: { path: matchPath } }) {
    return (
        <Switch>
            <Route path={`${matchPath}/signup`} component={PageSignup} />
            <Route path={`${matchPath}/login`} component={PageSignin} />
            <Route path={`${matchPath}/site/login`} component={PageSignin} />
            <Route path={`${matchPath}/email_verify`} component={PageEmailVerify} />
            <Route path={`${matchPath}/email_verify_landing`} component={PageEmailVerifyLanding} />
            <Route path={`${matchPath}/update_email_complete`} component={UpdateEmailComplete} />
            <Route path={`${matchPath}/error`} component={PageError} />
            <Route path={`${matchPath}/permission_denied`} component={PagePermissionDenied} />
            <Route path="*" component={NotFound} />
        </Switch>
    );
}
